export const types = {
  GET_NOTIFICATION_ROLES_MAIN_SETTINGS: 'GET_NOTIFICATION_ROLES_MAIN_SETTINGS',
  GET_NOTIFICATION_ROLES_MAIN_SETTINGS_SUCCESS: 'GET_NOTIFICATION_ROLES_MAIN_SETTINGS_SUCCESS',
  GET_NOTIFICATION_ROLES_MAIN_SETTINGS_ERROR: 'GET_NOTIFICATION_ROLES_MAIN_SETTINGS_ERROR',

  REQUEST_NOTIFICATION_ROLES_TEST: 'REQUEST_NOTIFICATION_ROLES_TEST',
  REQUEST_NOTIFICATION_ROLES_TEST_SUCCESS: 'REQUEST_NOTIFICATION_ROLES_TEST_SUCCESS',
  REQUEST_NOTIFICATION_ROLES_TEST_ERROR: 'REQUEST_NOTIFICATION_ROLES_TEST_ERROR',

  
  REQUEST_NOTIFICATION_ROLES_ADD: 'REQUEST_NOTIFICATION_ROLES_ADD',
  REQUEST_NOTIFICATION_ROLES_ADD_SUCCESS: 'REQUEST_NOTIFICATION_ROLES_ADD_SUCCESS',
  REQUEST_NOTIFICATION_ROLES_ADD_ERROR: 'REQUEST_NOTIFICATION_ROLES_TEST_ERROR',

  
  REQUEST_NOTIFICATION_ROLES_EDIT: 'REQUEST_NOTIFICATION_ROLES_EDIT',
  REQUEST_NOTIFICATION_ROLES_EDIT_SUCCESS: 'REQUEST_NOTIFICATION_ROLES_EDIT_SUCCESS',
  REQUEST_NOTIFICATION_ROLES_EDIT_ERROR: 'REQUEST_NOTIFICATION_ROLES_EDIT_ERROR',

  
  
  REQUEST_NOTIFICATION_ROLES_DELETE: 'REQUEST_NOTIFICATION_ROLES_DELETE',
  REQUEST_NOTIFICATION_ROLES_DELETE_SUCCESS: 'REQUEST_NOTIFICATION_ROLES_DELETE_SUCCESS',
  REQUEST_NOTIFICATION_ROLES_DELETE_ERROR: 'REQUEST_NOTIFICATION_ROLES_DELETE_ERROR',

  
  
  REQUEST_NOTIFICATION_ROLE: 'REQUEST_NOTIFICATION_ROLE',
  REQUEST_NOTIFICATION_ROLE_SUCCESS: 'REQUEST_NOTIFICATION_ROLE_SUCCESS',
  REQUEST_NOTIFICATION_ROLE_ERROR: 'REQUEST_NOTIFICATION_ROLE_ERROR',

  RESET_NOTIFICATION_ROLES_PROPS: 'RESET_PROPS',
  }
  