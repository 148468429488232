import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import {totalChecks} from '../../../services/dashboard'
import classes from './style.module.scss'

interface Props {
  onRemove: () => void
}

const TotalChecks = ({onRemove}: Props) => {
  const {data: checks, isLoading}: any = useQuery(['dashboard-checks-widgets'], totalChecks, {
    cacheTime: 0,
    staleTime: 0,
  })

  const intl = useIntl()

  const onClose = () => {
    onRemove()
  }

  return (
    <div className=''>
      <div className={`card card-xl-stretch border`}>
        <div className='card-header border-0 p-4 py-0'>
          <div className='d-flex gap-2 align-items-center'>
            {/* <span
              className={`svg-icon-primary text-white svg-icon-3x ms-n1 far fa-check-circle`}
              style={{fontSize: '2.5rem'}}
            /> */}
            <h3 className={`fw-semibold text-dark fs-3 m-0`}>
              {checks?.down === 0
                ? intl.formatMessage({id: 'LABELS.ALL.CHECKS.UP'})
                : checks?.down > 0
                ? intl.formatMessage({id: 'LABELS.SOME.CHECKS.DOWN'})
                : intl.formatMessage({id: 'LABELS.TOTAL.CHECKS.STATUS'})}
            </h3>
          </div>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={onClose}
            >
              <span className='fas fa-times fs-3 ' />
            </button>
          </div>
        </div>

        <div className='card-body p-4 py-3 pt-0 text-dark' style={{cursor: 'auto'}}>
          <div
            className={` fw-bold fs-5 mb-1 mt-5 text-dark d-flex flex-row gap-4 justify-content-between`}
          >
            <Link
              to={'/uptime?status=up'}
              className='d-flex flex-column gap-2 align-items-center shadow p-2 rounded w-100 position-relative text-dark bg-hover-secondary'
            >
              <span className='fs-3x'>{checks?.up ?? 0}</span>

              <div className='d-flex align-items-center gap-2'>
                <span
                  className='fas fa-arrow-circle-up fs-2x text-success position-absolute'
                  style={{top: '4px', left: '4px'}}
                />{' '}
                <strong className='fs-3'>{intl.formatMessage({id: 'UP'})}</strong>
              </div>
            </Link>

            <Link
              to={'/uptime?status=down'}
              className='d-flex flex-column gap-2 align-items-center shadow p-2 rounded w-100 position-relative text-dark bg-hover-secondary'
            >
              <span className='fs-3x'>{checks?.down ?? 0}</span>

              <div className='d-flex align-items-center gap-2'>
                <span
                  className='fas fa-arrow-circle-down fs-2x  text-danger position-absolute'
                  style={{top: '4px', left: '4px'}}
                />{' '}
                <strong className='fs-3'>{intl.formatMessage({id: 'DOWN'})}</strong>
              </div>
            </Link>

            <Link
              to={'/uptime?status=pause'}
              className='d-flex flex-column gap-2 align-items-center shadow p-2 rounded w-100 position-relative text-dark bg-hover-secondary'
            >
              <span className='fs-3x'>{checks?.paused ?? 0}</span>

              <div className='d-flex align-items-center gap-2'>
                <span
                  className='fas fa-pause-circle fs-2x text-dark position-absolute'
                  style={{top: '4px', left: '4px'}}
                />{' '}
                <strong className='fs-3 '>{intl.formatMessage({id: 'PAUSE'})}</strong>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalChecks
