import React from 'react'
import {useIntl} from 'react-intl'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'

interface Props {
  onRemove: () => void
}

const AccountBalance = ({onRemove}: Props) => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const intl = useIntl()

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <div className={`card card-xl-stretch bg-primary`}>
        <div className='card-header border-0 p-4 py-0'>
          <h3 className='card-title align-items-start flex-column'></h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={onClose}
            >
              <span className='fas fa-times fs-3 text-white' />
            </button>
          </div>
        </div>

        <div className='card-body p-5  pt-0  d-flex gap-3 align-items-end'>
          <span
            className={`svg-icon-primary text-white  fs-4x fas fa-file-invoice-dollar`}/>

          <div className='d-flex flex-column gap-1 align-items-start mt-3 '>
            <div className={` fw-bold text-white d-flex gap-1 align-items-center lh-sm fs-1 `}>
              <span className={` fas fa-dollar-sign text-white fs-1`} />
              {user?.account_balance}
            </div>

            <div className={`fw-semibold text-white fs-7`}>
              {intl.formatMessage({id: 'LABELS.ACCOUNT_BALANCE'})}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AccountBalance)
