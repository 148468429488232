import React, {useCallback, useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {useQuery} from 'react-query'
import {getUptimeGraph} from '../../../services/uptime'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import useUser from '../../Hooks/useUser'
import moment from 'moment'
import {Form} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import WaveSpinner from '../../modules/global/WaveSpinner'
import './style.scss'
import {useThemeMode} from '../../../_metronic/partials'
import {
  compareTimestamps,
  generateTimestampsForCurrentMonth,
  generateTimestampsForCurrentWeek,
  generateTimestampsThisMonth,
  generateTimestampsThisWeek,
  getLastMonthTimestamps,
  getLastTimestamps,
  getLastWeekTimestamps,
} from '../../../helpers/timeHelper'
import { MenuItem } from '@mui/material'
import MuiSelect from '../../modules/global/UI/MuiSelect'

interface IHeartbeatGraph {
  checkId: string
  range?: string
  selectFilter?: boolean
}

const HeartbeatGraph = (props: IHeartbeatGraph) => {
  const {checkId, range, selectFilter = true} = props
  const [filter, setFilter] = useState<string>(range ?? 'this_month')
  const intl = useIntl()
  const {data, isLoading} = useQuery(
    ['heartbeat-graph', filter, checkId],
    () => getUptimeGraph(checkId, filter),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  )
  const borderColor = getCSSVariableValue('--kt-text-muted')
  const baseColor = getCSSVariableValue('--kt-text-primary')

  const user = useUser()
  const theme = useThemeMode()

  const handleFilter = (type: string) => {
    setFilter(type)
  }

  const initialValue = {
    series: [
      {
        name: 'Desktops',
        data: [],
      },
    ],
    options: {
      theme: {
        mode: theme.mode === 'dark' ? 'dark' : 'light',
        palette: 'palette3',
      },
      chart: {
        height: 150,
        type: 'line',
        zoom: {
          enabled: true,
        },

      },

      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        opacity: 1,
      },
      stroke: {
        curve: 'stepline',
        show: true,
        width: 3,
        colors: [baseColor],
      },
      states: {
        normal: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        hover: {
          filter: {
            type: 'none',
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: 'none',
            value: 0,
          },
        },
      },
      // grid: {
      //   row: {
      //     colors: ['#c3c3c3', 'transparent'], // takes an array which will be repeated on columns
      //     opacity: 0.5,
      //   },
      // },

      grid: {
        borderColor: borderColor,
        strokeDashArray: 6,
        yaxis: {
          min: 0,
          max: 1,
          lines: {
            show: true,
          },
        },
      },
      markers: {
        strokeColors: baseColor,
        strokeWidth:1,
      },
      yaxis: {
        // floating: false,
        tickAmount: 1,
        min: 0,
        max: 1,
        labels: {
          colors: '#181C32',
          fontSize: '10px',
          formatter: (value: any) => {
            return parseInt(value)
          },
        },
      },
      xaxis: {
        type: 'datetime',
        tickPlacement: 'on',
        tickAmount: 7,
        // min: new Date('01 Mar 2023').getTime(),
        labels: {
          rotateAlways: false,
          style: {
            colors: '#181C32',
            fontSize: '9px',
          },
        },

        tooltip: {
          enabled: false,
        },
      },
    },
  }

  const formatterDate = (value: any, filterDate: string) => {
    let format

    if (filterDate === 'this_year') {
      format = 'MMM yyyy'
    } else if (filterDate === 'this_year') {
      //year
      format = 'D MMM'
    } else if (
      filterDate === 'this_month' ||
      filterDate === 'last_month' ||
      filterDate == 'last_thirty_days'
    ) {
      //month
      format = 'D MMM '
    } else if (filterDate === 'this_week' || filter === 'last_week') {
      //week
      format = 'ddd hh:mm'
    } else {
      //last hour or yesterday
      format = 'HH:mm'
    }

    return moment(+value)
      .tz(user?.timezone)
      .format(format)
  }



  const [state, setState] = useState<any>(initialValue)
  const [state2, setState2] = useState<any>({data: []})

  useEffect(() => {
    let clonState = JSON.parse(JSON.stringify(initialValue))
    setState({
      ...clonState,
      options: {
        ...clonState.options,
        xaxis: {
          ...clonState.options.xaxis,
          // categories: Object.keys(data?.data),
          tickAmount: filter?.includes('today')
            ? 24
            : (filter?.includes('month')||filter=='last_thirty_days')
            ? 30
            : filter?.includes('week')
            ? 7
            : 12,
          labels: {
            ...clonState.options.xaxis.labels,
          },
        },
      },
    })
  }, [data, filter])

  const memoizedValidValue=useCallback((mainData:Record<string,any>,start:number)=>{
    return Object.keys(mainData)?.reduce?.((obj, key) => {
       return Object.assign(obj, {
         [key]: +key * 1000 > +new Date() || +key<start ?null : mainData[key],
       })
     }, {})
   },[])


  useEffect(() => {
    if (data?.data) {
      setState2({data: []})
      let timestamps: number[]

      switch (filter) {
        case 'last_thirty_days':
          timestamps = getLastTimestamps(30)
          break

        case 'last_month':
          timestamps = getLastMonthTimestamps()
          break

        case 'last_week':

          timestamps = getLastWeekTimestamps()
          break

        case 'this_month':

          timestamps = generateTimestampsForCurrentMonth()
          break

        case 'this_week':
          timestamps = generateTimestampsForCurrentWeek()
          break
        default:
          timestamps = []

          break
      }
      

      const points = compareTimestamps(timestamps, data?.data??{})
      const ValidValue:any= memoizedValidValue(points,+Object.keys(data?.data??{})?.[0]);
 
    
    

      for (const key in ValidValue) {
        let obj = {
          y: ValidValue[key],
          x: +key * 1000,
        }

        setState2((pre: any) => {
          return {
            name: 'Heartbeat',
            data: [...pre.data, obj],
          }
        })
      }
    }
  }, [filter, data])

  return (
    <section className='card w-100 p-2 tools-heartbeat'>
      {isLoading ? (
        <WaveSpinner isLoading={isLoading} height='115px' />
      ) : (
        <div id='chart'>
          <ReactApexChart
            //@ts-ignore

            options={{
              ...JSON.parse(JSON.stringify(state.options)),
              theme: {
                mode: theme.mode === 'dark' ? 'dark' : 'light',
                palette: 'palette3',
              },
              xaxis: {
                ...JSON.parse(JSON.stringify(state.options)).xaxis,
                labels: {
                  ...JSON.parse(JSON.stringify(state.options)).xaxis.labels,
                  formatter: (value: any, timestamps: any) => {
                    return formatterDate(timestamps, filter)
                  },
                },
              },
              yaxis: {
                ...JSON.parse(JSON.stringify(state.options)).yaxis,
                labels: {
                  ...JSON.parse(JSON.stringify(state.options)).yaxis.labels,
                  formatter: (value: any) => {
                    return  value==null?"": parseInt(value)
                  },
                },
              },
            }}
            series={[state2]}
            type='line'
            height={150}
          />
        </div>
      )}
      {selectFilter && (
        <MuiSelect
          value={filter}
          onChange={(e) => handleFilter(e.target.value)}
          // size={'sm'}
          className='w-100 w-lg-25'
        >
          <MenuItem value='last_hour'>
            {intl.formatMessage({id: 'LABELS.LAST'})} {intl.formatMessage({id: 'LABELS.HOUR'})}
          </MenuItem>
          <MenuItem value='today'>{intl.formatMessage({id: 'PROPERTY.UPTIME_TODAY'})}</MenuItem>
          <MenuItem value='this_week'>
            {intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_WEEK'})}{' '}
          </MenuItem>
          <MenuItem value='last_week'>{intl.formatMessage({id: 'LABELS.LAST.WEEK'})}</MenuItem>

          <MenuItem value='this_month'>
            {intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_MONTH'})}
          </MenuItem>
          <MenuItem value='last_month'>{intl.formatMessage({id: 'LABELS.LAST.MONTH'})} </MenuItem>
          <MenuItem value='this_year'>{intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_YEAR'})}</MenuItem>
        </MuiSelect>
      )}
    </section>
  )
}

export default HeartbeatGraph
