import * as React from 'react'
import {DataGrid, GridRowSelectionModel, GridColDef, useGridApiRef} from '@mui/x-data-grid'
import {useIntl} from 'react-intl'
import ChecksStatusArrow from './ChecksStatusArrow'
import {TYPES_TITLE} from '../../modules/widgets/components/Common'
import UptimeWidget from '../../pages/upTimeOverview/UptimeWidget'
import moment from 'moment'
import {Link} from 'react-router-dom'
import IconButton from './IconButton'
import useCssVariable from '../../Hooks/useCssVariable'
import { useWindowSize } from '../../Hooks/useWindowSize'
import { StyledDataGrid } from './UI/StyledComponentMUI'
import ReactTooltip from 'react-tooltip'

interface ITableGridUptime {
  rows: any[]
  range: string
  isBulkEnable: boolean
  onSelectCheck: (checks: any[]) => void
  selectedChecks: any[]
}

function TableGridUptime(props: ITableGridUptime) {
  const {rows, range, isBulkEnable, onSelectCheck, selectedChecks} = props
  const intl = useIntl()
  const secondaryColor = useCssVariable('--kt-secondary')
  const {width} = useWindowSize()


  

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'id',
      },
      {
        field: 'type',
        headerName: intl.formatMessage({id: 'LABELS.TYPE'}),
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return (
            <div className='d-flex gap-1 align-items-center'>
              <ChecksStatusArrow
                is_paused={param?.row?.is_paused}
                status={param?.row?.status}
                is_under_maintenance={param?.row?.is_under_maintenance}
              />
              <span className='text-uppercase'>{TYPES_TITLE(param?.row?.type)}</span>
            </div>
          )
        },
      },
      {
        headerName: intl.formatMessage({id: 'LABELS.LABEL'}),
        field: 'label',
        sortingOrder: ['asc', 'desc'],
        sortable: true,
        filterable: true,
        hideable: false,
        width: 200,
        renderCell: (param) => {
          return (
            <Link to={`${param?.row?.device_id}?module=${param?.row?.id}`}>
             {param?.row?.label}
            </Link>
          )
        },
      },
      {
        field: 'host',
        headerName: intl.formatMessage({id: 'LABELS.SERVER_HOST'}),
        width: 150,
        editable: false,
        sortable: false,
        filterable: false,
      },
      {
        field: 'uptime_percentage',
        headerName: intl.formatMessage({id: 'MENU.UPTIME'}),
        width: 80,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return <div>{param?.row?.uptime_percentage} %</div>
        },
      },
      {
        field: 'downtime_human_readable',
        headerName: intl.formatMessage({id: 'LABELS.UPTIME_DOWNTIME'}),
        width: 150,
        editable: false,
        sortable: false,
        filterable: false,
      },
      {
        field: 'created_at_timestamp',
        headerName: intl.formatMessage({id: 'LABELS.CREATED'}),
        width: 150,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return <div>{moment(param?.row?.created_at_timestamp * 1000).format('YYYY-MM-DD')}</div>
        },
      },
      {
        field: 'widget',
        headerName: '',
        flex: width>600? 1:undefined,
        width:200,
        // renderHeader: () => null,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return <UptimeWidget isPause={param?.row?.is_paused} range={range as any} uptime={param?.row} mode='table' />
        },
      },
      {
        field: 'link',
        headerName: '',
        width: 100,
        // renderHeader: () => null,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return (
            <div className='d-flex gap-2'>
              <Link to={`${param?.row?.device_id}/settings?module=${param?.row?.id}`}>
                <IconButton
                  icon='fas fa-cog'
                  tooltip={intl.formatMessage({ id: 'LABELS.SETTING' })}
                  id={param?.row?.device_id}
                />
              </Link>
              <Link to={`${param?.row?.device_id}?module=${param?.row?.id}`}>
                <IconButton
                  icon='far fa-eye'
                  tooltip={intl.formatMessage({ id: 'ACTIONS.VIEW' })}
                  id={param?.row?.id}
                />
              </Link>
            </div>
          )
        },
      },
    ],
    [width,range]
  )

  return (
    <div style={{width: '100%'}}>
      <StyledDataGrid
     
        checkboxSelection={isBulkEnable}
        columns={columns}
        
        onRowSelectionModelChange={(newRowSelectionModel,detail) => {
         
          onSelectCheck(newRowSelectionModel as any)
        }}
        rowSelectionModel={selectedChecks}
        rows={rows}
        hideFooterPagination
        hideFooter
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
              created_at_timestamp: false,
              downtime_human_readable: width>600
            },
          },
        }}
      />
    </div>
  )
}

export default React.memo(TableGridUptime)
