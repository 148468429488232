import {
    types
  } from "./actionTypes";


  export const getServersAction = () => ({
    type: types.GET_SERVERS,
  });

  export const getServersRefetchAction = () => ({
    type: types.GET_SERVERS_REFETCH,
  });
  
  export const getAgentKeyAction = () => ({
    type: types.GET_AGENTKEY,
  });
  
  
  export const getNotificationRolesAction = () => ({
    type: types.GET_NOTIFICATIONROLES,
  });

  
  export const updateServerAction = (params:any,id:number) => ({
    type: types.UPDATE_SERVER_REQUEST,
    params,id
  });

  
  export const deleteServerAction = (id:number) => ({
    type: types.DELETE_SERVER_REQUEST,
    id
  });

  
  export const moveServerAction = (groupId:string,serverId:number) => ({
    type: types.MOVE_SERVER_REQUEST,
    groupId,serverId
  });

  
  export const pauseServerAction = (pauseUntil:any,serverId:number) => ({
    type: types.PAUSE_SERVER_REQUEST,
    pauseUntil,serverId
  });


  export const resetProps = () => ({
    type: types.RESET_SERVER_PROPS,
  });
  
  