export const types = {
    GET_MODULE_COUNT: 'GET_MODULE_COUNT',
    GET_MODULE_COUNT_SUCCESS: 'GET_MODULE_COUNT_SUCCESS',
    GET_MODULE_COUNT_ERROR: 'GET_MODULE_COUNT_ERROR',
    RESET_PROPS: 'RESET_PROPS',
  
    GET_UPTIME: 'GET_UPTIME',
    GET_UPTIME_SUCCESS: 'GET_UPTIME_SUCCESS',
    GET_UPTIME_ERROR: 'GET_UPTIME_ERROR',
    
    ADD_NEW_CHECK: 'ADD_NEW_CHECK',
    ADD_NEW_CHECK_SUCCESS: 'ADD_NEW_CHECK_SUCCESS',
    ADD_NEW_CHECK_ERROR: 'ADD_NEW_CHECK_ERROR',

    RESET_UPTIME_PROPS: 'RESET_UPTIME_PROPS',
    GET_UPTIME_REFETCH:"GET_UPTIME_REFETCH"

  }
  