import React, {FC} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import './style.module.scss'
import {useIntl} from 'react-intl'
const ConfirmModal: FC<{
  display: boolean
  waiting?: boolean
  title: string
  description: string
  nodeText?:React.ReactNode
  handleClose: () => void
  handleConfirm: () => void
}> = ({display, handleClose, handleConfirm, title = '', description = '', waiting = false,nodeText}) => {
  const intl = useIntl()
  return (
    <Modal
      show={display}
      onHide={handleClose}
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p>{description}
          {nodeText}
          </p>
        </div>
        <div className='d-flex justify-content-end'>
          <Button variant='secondary' onClick={handleClose} className='me-2'>
            {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
          </Button>
          <Button
            variant='primary'
            className='me-2'
            onClick={handleConfirm}
            disabled={waiting}
            type='button'
          >
            {intl.formatMessage({id: 'ACTIONS.CONFIRM'})}
            {waiting && (
              <Spinner
                className='ms-2'
                style={{verticalAlign: 'middle'}}
                animation='border'
                variant='light'
              />
            )}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ConfirmModal
