/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env
const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const zapierUrl = searchParams.get('zapier')
  const cliUrl = searchParams.get('cli_login')
  const defaultDash = localStorage.getItem('defaultDash')
  const dashboardLocation = !!defaultDash ? `/customized-dashboard/${defaultDash}` : '/dashboard'

  const navigateToIfUserExist = !!zapierUrl
    ? `/zapier${location?.search}`
    : !!cliUrl
    ? `/cli${location?.search}`
    : dashboardLocation
    
  const navigateToIfUserNotExist = !!zapierUrl || !!cliUrl ? `/auth${location?.search}` : '/auth'

  // console.log(location);

  return (
    <Routes>
      <Route element={<App />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />

        {/* zapierUrl == 'allowed' || zapierUrl == 'contact_not_allowed' ? `/zapier${location?.search}` : "/dashboard" */}
        {currentUser ? (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to={navigateToIfUserExist} />} />
          </>
        ) : (
          <>
            <Route path='auth/*' element={<AuthPage />} />
            <Route
              path='*'
              element={<Navigate to={navigateToIfUserNotExist} state={{from: location.pathname}} />}
            />
          </>
        )}
      </Route>
    </Routes>
  )
}

export {AppRoutes}
