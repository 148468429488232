import classes from './User.module.css'
import {FormattedMessage, useIntl} from 'react-intl'
import {UserModel} from '../../../auth/models/UserModel'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {Link} from 'react-router-dom'
import {PasswordChange} from './PasswordChange'
import {Profile} from '../../../../../services/Profile'
import {useSocket as useClientSocket} from '../../../../../socket/client/client'
import {useSocket as useContactSocket} from '../../../../../socket/contact/contact'
import * as auth from '../../../auth/redux/AuthRedux'
import {getUserType, CLIENT, CONTACT} from '../../../../../helpers'
import ClientAvatar from '../../../client/ClientAvatar'
import ContactAvatar from '../../../contact/ContactAvatar'
import {Dropdown, DropdownButton} from 'react-bootstrap'
import {useQuery} from 'react-query'
import Http from '../../../../store/core/inceptor'
import WaveSpinner from '../../../global/WaveSpinner'
import TitleAndValues from '../../../global/UI/TitleAndValues'
import { useState } from 'react'

const User = ({showPassModal ,setShowPassModal}:any) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const {data, isLoading}: any = useQuery(['account'], () => Http.get('/account'),{
    cacheTime:0,staleTime:0
  })

  const updateClientHandler = (payload: any) => {
    const client_id = payload?.updated_client?.id ?? 0
    if (getUserType(user) === CLIENT && user.id == client_id) {
      dispatch(auth.actions.setUser({...user, ...payload.updated_client}))
    }
  }

  const updateContactHandler = (payload: any) => {
    const contact_id = payload?.updated_contact?.id ?? 0
    if (getUserType(user) === CONTACT && user.id == contact_id) {
      dispatch(auth.actions.setUser({...user, ...payload.updated_contact}))
    }
  }

  useClientSocket({
    type: 'CLIENT_UPDATED',
    callBack: updateClientHandler,
  })

  useContactSocket({
    type: 'CONTACT_UPDATED',
    callBack: updateContactHandler,
  })
  if (isLoading) {
    return <WaveSpinner isLoading={isLoading} />
  }
  const savePassowrd = (password: string) => Profile.partialUpdate({password: password})

  return (
    <div className='card p-4 rounded'>
      <PasswordChange onSavePassword={savePassowrd} username={user.username} show={showPassModal} setShow={setShowPassModal} />

      <div className='d-flex justify-content-between align-items-center'>
  

   
      </div>

      <div className={`row ${classes.userClass} g-6`}>
        <ClientAvatar srcFromParent={data?.avatar} wrapperClassName='col-md-4' />

        <div className='col-md-8 fs-4'>
          
          <TitleAndValues
            title={<FormattedMessage id='PROFILE.SETTING.EDIT.FULL_NAME' />}
            value={data?.name}
          />

          <TitleAndValues
            title={<FormattedMessage id='LABELS.USERNAME' />}
            value={data?.username || '-'}
          />

          <TitleAndValues
            title={<FormattedMessage id='LABELS.EMAIL' />}
            value={data?.email || '-'}
          />

          <TitleAndValues
            title={<FormattedMessage id='LABELS.TIMEZONE' />}
            value={data?.timezone || '-'}
          />
        </div>
      </div>
    </div>
  )
}

export default User
