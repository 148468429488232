import {parseMutationArgs} from 'react-query/types/core/utils'
import {actions} from 'react-table'
import {types} from './commonActionTypes'

const initialState: any = {
  feedbackOpen: false,
  isAuth: true,
  ignoreList: [],
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, params: any) => {
  switch (params.type) {
    case types.HANDLE_OPEN_FEEDBACK_MODAL:
      return {
        ...state,
        feedbackOpen: params.params,
      }
    case types.ADD_TO_IGNORE:
      return {
        ...state,
        ignoreList: [...state.ignoreList, params?.params],
      }

    case types.EXPIRE_AUTH:
      return {
        ...state,
        isAuth: false,
      }

    case types.RESET_AUTH:
      return {
        ...state,
        isAuth: true,
      }

    default:
      return {
        ...state,
      }
  }
}
