import { _axios } from "../app/store/core/inceptor"



export const getHeaderIncidents = (length: number) =>
_axios.get(`/incidents?length=${length}`).then(res=>res.data)


export const getHeaderAccountLogs = (length: number) =>
  _axios.get(`/servers/logs?length=${length}`).then(res=>res.data)
  

export const getAvatarLogs=(id: number,isClient:boolean) => _axios.get(`/${isClient?"client":"contacts"}/${id}/avatar`, { responseType: 'blob' })

export const markAsRead=()=>_axios.put(`/client/profile?last_read=${new Date().toISOString()}`).then(res=>res)

