import {useMemo} from "react"
import {TextField} from '@mui/material'
import Modal from 'react-bootstrap/Modal'
import {useState} from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import useCustomizedDashboardValues, {
  customizedDashboardTypes,
} from '../../modules/context/CustomizedDashboardCtx'
import ToggleDashboards from './ToggleDashboards'
import DashboardMangementForm from './DashboardMangementForm'
import CreateNewBtn from './CreateNewBtn'
import {getDashboards, useCreateDashboard} from '../../../services/customizedDashboard'
import useMainConfig from '../../Hooks/useMainConfig'
import PaidBadge from '../../modules/global/PaidBadge'
import PaidModal from '../../modules/global/PaidModal'
import useUser from '../../Hooks/useUser'
import ShareDashboard from './ShareDashboard'
import useActionControll from "../../Hooks/useActionControll"
import { useLocalStorage } from "../../Hooks/useLocalStorage"
import { setCustomizeGraphIntervalTime, setCustomizeGraphPeriod, setCustomizeGraphSince, setCustomizeGraphSync, setCustomizeGraphUntil, setGraphSync, useGraphFilterConfig } from "../../modules/context/MetricsPageProvider"
import GraphsFilters from "../metricsPage/GraphsFilters"
import CreateNewDashboardModal from "./CreateNewDashboardModal"

const DashboardManagement = ({
  customizeMode = false,
  handleShow,
  setGridList
}: {
  customizeMode?: boolean
  handleShow?: () => void
  setGridList?: any
}) => {
  const [customizedDashboards, dispatch] = useCustomizedDashboardValues()
  const user = useUser()
  const [show, setShow] = useState(false)
  const [showLimit, setShowLimit] = useState(false)
  const [name, setName] = useState('')
  const {mutateAsync, isLoading} = useCreateDashboard()
  const {is_free_plan, checkLimittedPlan} = useMainConfig()
  // const {checkLimittedPlan} = useActionControll('custom_dashboards')
  const { state, dispatch:graphDispatch } = useGraphFilterConfig();

  const intl = useIntl()
  const isSubAccount = 'access' in user && 'parent' in user

  const handleClose = () => {
    setShow(false)
  }

  const userDashboards =useMemo(()=>customizedDashboards?.filter(
    (dash: Record<string, any>) => isSubAccount ? !!dash.contact_id: !!dash.client_id
  ) ,[customizedDashboards]) 

  const clientDashboards =useMemo(()=>customizedDashboards?.filter(
    (dash: Record<string, any>) => !!dash.client_id
  ) ,[customizedDashboards]) 

  const activeDashboards=useMemo(()=>customizedDashboards?.filter(
    (dash: Record<string, any>) => (isSubAccount ? !!dash.contact_id || !dash.hidden  : !!dash.client_id)
  ) ,[customizedDashboards]) 

  
  return (
    <>
      <div className='d-flex justify-content-between align-items-start mb-4 flex-wrap gap-4'>
        <ToggleDashboards
          customizedDashboards={activeDashboards}
          onAdd={() => {
            if (is_free_plan || checkLimittedPlan('custom_dashboards')) {
              return setShowLimit(true)
            }
            setShow(true)
          }}
          limit={checkLimittedPlan('custom_dashboards')}
        />

        <div className='d-flex gap-2 ms-auto'>
          {/* {!!customizeMode && handleShow && <CreateNewBtn onClick={handleShow} />} */}
          {customizeMode ? (
            <GraphsFilters
              setIntervalTime={setCustomizeGraphIntervalTime}
              setPeriod={setCustomizeGraphPeriod}
              syncGraph={state.customizeGraphSync}
              setSyncGraph={setCustomizeGraphSync}
              setGridList={setGridList}
              intervalTime={state.customizeIntervalTime}
              period={state.customizePeriod}
              since={state.customizeSince}
              setSince={setCustomizeGraphSince}
              until={state.customizeUntil}
              setUntil={setCustomizeGraphUntil}
              hideLayoutitems={true}
              customDash
            />                 
          ) : (
            <Button size='sm' onClick={() => setShow(true)} className='w-200px '>
              <FormattedMessage id='LABELS.DASHBOARD.MANAGEMENT' />
            </Button>
          )}
        </div>
      </div>
      <CreateNewDashboardModal show={show} setShow={setShow} />
      <PaidModal
        title={intl.formatMessage({id: 'DASHBOARD.PAGE.LIMIT'})}
        open={showLimit}
        onClose={() => setShowLimit(false)}
      />
    </>
  )
}

export default DashboardManagement
