import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {calculateTimeDifference} from '../../../helpers/timeHelper'
import {getResolvedIncidents} from '../../../services/dashboard'
import {TablesWidget2} from '../../../_metronic/partials/widgets'
import LongTitle from '../../modules/global/UI/LongTitle'
import {
  createCustomIncidentMessageWithIntegrations,
  createIncidentMessages,
  createMetricMessageWithIntegrations,
  generateCheckIncidentCause,
  generateCheckResolveIncidentCause,
  moduleTypeToHumanReadable,
  TYPES_TITLE,
} from '../../modules/widgets/components/Common'
import classes from './style.module.scss'

const TypeWithCause = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '111',
  '112',
  '113',
  '114',
  '115',
  '116',
  '117',
  '118',
  '119',
  '120',
]
interface Props {
  onRemove: () => void
}

const ResolvedIncidents = ({onRemove}: Props) => {
  const intl = useIntl()

  const {data: Incidents, isLoading} = useQuery(
    ['dashboard-resolved-incidents'],
    getResolvedIncidents
  )

  const onClose = () => {
    onRemove()
  }

  const returnTypeDisplayer = (model: any) => {
    const type = TYPES_TITLE(model.details.type)
    let resultModel: any = {cause: '', value: '', title: type, resolver: {}}
    if (model?.module_id === 0) {
      resultModel.cause = createMetricMessageWithIntegrations(model, false, intl)
      resultModel.value = createIncidentMessages(model?.details, intl)
    }
    if (model?.module_id > 0) {
      resultModel.cause = generateCheckIncidentCause(model?.details, intl)
    }

    if (model.resolved_id > 0) {
      if (model.resolver?.module_id === 0) {
        resultModel.resolver.cause = createMetricMessageWithIntegrations(model, true, intl)
      }
      if (model.resolver?.module_id !== 0) {
        resultModel.resolver.cause = generateCheckResolveIncidentCause(model, intl)
      }
      if (model.resolver?.details.value) {
        resultModel.resolver.value = createIncidentMessages(model?.resolver?.details, intl)
      }
      if (model.resolver?.details?.is_manual) {
        resultModel.resolver.manual_message = createCustomIncidentMessageWithIntegrations(
          model?.resolver?.details,
          intl
        )
      }
    }

    return resultModel
  }

  return (
    <div>
      <TablesWidget2
        className={classes['custom_table']}
        isLoading={isLoading}
        title={intl.formatMessage({id: 'LABELS.RESOLVED_INCIDENTS'})}
        onClose={onClose}
        icon={<span className='fas fa-check-square fs-1' />}
        dataLength={Incidents?.data?.length}
        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '15%'}}>
              {intl.formatMessage({id: 'CHECK'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1  ' style={{minWidth: '30%'}}>
              {intl.formatMessage({id: 'LABELS.SERVER_HOST'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '20%'}}>
              {intl.formatMessage({id: 'LABELS.DATE'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '25%'}}>
              {intl.formatMessage({id: 'DURATION'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '10%'}}></th>
          </tr>
        }
      >
        {!!Incidents?.data?.length
          ? Incidents?.data?.map((incident: Record<string, any>, index: number) => (
              <tr key={incident?.id}>
                <td className='pe-0'>
                  <div className='symbol symbol-50px me-2 text-uppercase  '>
                    <span className='symbol-label text-white bg-primary fw-bold' >
                      <span
                        style={{
                          fontSize:'9px',
                        }}
                      >
                        {incident?.type_human_readable?.split('_')?.[0]}
                      </span>
                    </span>
                  </div>
                </td>
                <td className=''>
                  <span className='text-dark fw-bold mb-1 fs-6'>
                    <LongTitle title={incident?.module?.label ?? incident?.device_object?.label} id={'resolve-i-d' + index} maxWidth />
                  </span>
                  <span className='text-muted fw-semobold d-block fs-7'>
                    <div className='text-muted' style={{fontSize: '11px'}}>
                      {returnTypeDisplayer(incident)?.resolver?.cause}
                    </div>
                  </span>
                </td>
                <td className='px-1'>
                  <span>
                    <span
                      className='text-truncate d-block fs-7 text-dark'
                      data-tip
                      data-for={'create-l-' + index}
                      style={{width: '100%',maxWidth:"10ch"}}
                    >
                      {incident?.created_at_human_readable}
                    </span>
                    <ReactTooltip id={'create-l-' + index} className='tooltip-wrapper'>
                      {incident?.created_at_human_readable}
                    </ReactTooltip>
                  </span>
                </td>
                <td className='text-start '>
                  <span className='text-muted fw-semobold'>
                    <span
                      className='text-truncate d-block fs-7 text-dark'
                      data-tip
                      data-for={'resolve-l-' + index}
                      style={{width: '100%',maxWidth:"10ch"}}
                    >
                      {incident?.resolver?.downtime_human_readable}
                    </span>
                    <ReactTooltip id={'resolve-l-' + index} className='tooltip-wrapper'>
                      {incident?.resolver?.downtime_human_readable}
                    </ReactTooltip>
                  </span>
                </td>
                <td className='text-end'>
                  <Link
                    to={`/incidents/${incident.id}`}
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                  >
                    <span className='fas fa-arrow-right fs-4' />
                  </Link>
                </td>
              </tr>
            ))
          : null}
      </TablesWidget2>
    </div>
  )
}

export default React.memo(ResolvedIncidents)
