import {useMutation} from 'react-query'
import {DashboardType} from '../app/modules/context/CustomizedDashboardCtx'
import Http from '../app/store/core/inceptor'

export const createDashboardReq = (data: Omit<DashboardType, 'id'>) =>
  Http.post('/client-dashboard', data)

export const useCreateDashboard = () => {
  return useMutation(createDashboardReq)
}

export const getDashboards = () => Http.get('/client-dashboard')

export const editDashboardReq = (data: {
  dashboard: Partial<Omit<DashboardType, 'id'>>
  id: string | number
}) => Http.put(`/client-dashboard/${data.id}`, data.dashboard)

export const useEditDashboard = () => {
  return useMutation(editDashboardReq)
}

export const deleteDashboard = (data: {id: string | number}) =>
  Http.delete(`/client-dashboard/${data.id}`)


  export const useDeleteDashboard = () => {
    return useMutation(deleteDashboard)
  }