import {call, put, takeEvery} from 'redux-saga/effects'
import {
  getIps,
  getServer,
  getServerUptime,
  getServerIncident,
  postTestNewCheck,
  postNewCheck,
  removeCheck,
  getServerChecks,
  pauseServerChecks,
  unpauseServerChecks,
  updateServerCheck,
} from './requests'
import {types} from '../actionTypes'
import {handleError, handleErrorWithStatusCode} from '../../../modules/widgets/components/Common'

interface Response {
  data: any
}
function* requestServerDetailHandler(action: any) {
  try {
    const response: Response = yield call(getServer, action.params)
    yield put({
      type: types.GET_SERVER_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.GET_SERVER_ERROR,
      action: e.data.message,
    })
  }
}

function* requestServerIpsHandler(action: any) {
  try {
    const response: Response = yield call(getIps, action.params)

    yield put({
      type: types.GET_SERVER_ip_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.GET_SERVER_ip_ERROR,
      action: e.data.message,
    })
  }
}

function* requestServerUptimeHandler(action: any) {
  try {
    const response: Response = yield call(getServerUptime, action.params)

    yield put({
      type: types.GET_SERVER_UPTIME_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.GET_SERVER_UPTIME_ERROR,
      action: e.data.message,
    })
  }
}

function* requestServerIncidentHandler(action: any) {
  try {
    const response: Response = yield call(getServerIncident, action.params)

    yield put({
      type: types.GET_SERVER_INCIDENT_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.GET_SERVER_INCIDENT_ERROR,
      action: e.data.message,
    })
  }
}

function* requestTestNewCheckHandler(action: any) {
  try {
    const response: Response = yield call(postTestNewCheck, action.params)

    yield put({
      type: types.REQUEST_TEST_ADD_NEW_CHECK_SUCCESS,
      response: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_TEST_ADD_NEW_CHECK_ERROR,
      error: handleErrorWithStatusCode(e),
    })
  }
}

function* requestNewCheckHandler(action: any) {
  try {
    const response: Response = yield call(postNewCheck, action.params, action.id)

    yield put({
      type: types.REQUEST_ADD_NEW_CHECK_SUCCESS,
      payload: response?.data,
    })
  } catch (err: any) {
    const error = err.response && (err.response || err.message)
    let data = error?.data || error?.data.data.host
    yield put({
      type: types.REQUEST_ADD_NEW_CHECK_ERROR,
      action: data,
    })
  }
}

function* requestRemoveCheckHandler(action: any) {
  try {
    const response: Response = yield call(removeCheck, action.serverId, action.checkId)

    yield put({
      type: types.REQUEST_REMOVE_CHECK_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_REMOVE_CHECK_ERROR,
    })
  }
}

function* requestGetChecksHandler(action: any) {
  try {
    const response: Response = yield call(getServerChecks, action.serverId)

    yield put({
      type: types.REQUEST_GET_CHECKS_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_GET_CHECKS_ERROR,
    })
  }
}

function* requestPauseChecksHandler(action: any) {
  try {
    const response: Response = yield call(
      pauseServerChecks,
      action.serverId,
      action.checkId,
      action.param
    )

    yield put({
      type: types.REQUEST_PAUSE_CHECKS_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_PAUSE_CHECKS_ERROR,
    })
  }
}

function* requestUnpauseChecksHandler(action: any) {
  try {
    const response: Response = yield call(unpauseServerChecks, action.serverId, action.checkId)

    yield put({
      type: types.REQUEST_UNPAUSE_CHECKS_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_UNPAUSE_CHECKS_ERROR,
    })
  }
}

function* requestUpdateCheckHandler(action: any) {
  try {
    const response: Response = yield call(
      updateServerCheck,
      action.serverId,
      action.checkId,
      action.params
    )

    yield put({
      type: types.REQUEST_UPDATE_CHECKS_SUCCESS,
      payload: response.data,
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_UPDATE_CHECKS_ERROR,
      error: e.response.data,
    })
  }
}

function* watcherServerOverViewSaga() {
  yield takeEvery(types.GET_SERVER, requestServerDetailHandler)
  yield takeEvery(types.GET_SERVER_REFETCH, requestServerDetailHandler)
  yield takeEvery(types.GET_SERVER_ip, requestServerIpsHandler)
  yield takeEvery(types.GET_SERVER_UPTIME, requestServerUptimeHandler)
  yield takeEvery(types.GET_SERVER_INCIDENT, requestServerIncidentHandler)
  yield takeEvery(types.REQUEST_TEST_ADD_NEW_CHECK, requestTestNewCheckHandler)
  yield takeEvery(types.REQUEST_ADD_NEW_CHECK, requestNewCheckHandler)
  yield takeEvery(types.REQUEST_REMOVE_CHECK, requestRemoveCheckHandler)
  yield takeEvery(types.REQUEST_GET_CHECKS, requestGetChecksHandler)
  yield takeEvery(types.REQUEST_PAUSE_CHECKS, requestPauseChecksHandler)
  yield takeEvery(types.REQUEST_UNPAUSE_CHECKS, requestUnpauseChecksHandler)
  yield takeEvery(types.REQUEST_UPDATE_CHECKS, requestUpdateCheckHandler)
}

export default watcherServerOverViewSaga
