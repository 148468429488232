import React from 'react'
import useMainConfig from './useMainConfig';
import useUser from './useUser';

const useIsUserExperimentalFree = () => {
    const user=useUser()
    const main=useMainConfig()
    
    function isCreatedAtInApril(created_at: string): boolean {
        // Convert the provided date string into a Date object
        const createdAtDate = new Date(created_at);
      
        // Create a Date object for april
        const april = new Date(createdAtDate.getFullYear(), 3, 1); // Months are zero-based (0 = January, 1 = February, etc.)
      
        // Compare the provided date with april
        return createdAtDate.getTime() > april.getTime();
      }

  return main.is_free_plan && isCreatedAtInApril(user?.created_at)
}

export default useIsUserExperimentalFree