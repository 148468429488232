import React, {Dispatch, useContext, useReducer, createContext, useState, ReactNode} from 'react'

/* ========================================================================== */
/*                            METRICS CONTEXT SETUP                         */
/* ========================================================================== */

export type MetricsGridType = [
  {
    name: string
    id: string
    widgets: []
    mode_2: {}
    mode_3: {}
    mode_4: {}
  }
]

export const initialData: MetricsGridType = [
  {
    name: '',
    id: '',
    widgets: [],
    mode_2: {
      'column-1': {
        id: 'column-1',
        widgetsIds: [],
      },
      'column-2': {
        id: 'column-2',
        widgetsIds: [],
      },
    },
    mode_3: {
      'column-1': {
        id: 'column-1',
        widgetsIds: [],
      },
      'column-2': {
        id: 'column-2',
        widgetsIds: [],
      },
      'column-3': {
        id: 'column-3',
        widgetsIds: [],
      },
    },
    mode_4: {
      'column-1': {
        id: 'column-1',
        widgetsIds: [],
      },
      'column-2': {
        id: 'column-2',
        widgetsIds: [],
      },
      'column-3': {
        id: 'column-3',
        widgetsIds: [],
      },
      'column-4': {
        id: 'column-4',
        widgetsIds: [],
      },
    },
  },
]

const CustomGraphContext = React.createContext<any>(initialData)

export const MetricsGrid = {
  RE_ORDER: 'RE_ORDER',
  ADD_NEW_GRID: 'ADD_NEW_GRID',
  RESET: 'RESET',
  INITIAL: 'INITIAL',
}

const MetricsReducer = (state: any, {type, payload}: any) => {
  switch (type) {
    case MetricsGrid.RE_ORDER:
      const replace = (arr: any, newObject: any) => {
        let flag = false

        let m = arr.map((v: any, i: number) => {
          if (v.name == newObject.name) {
            flag = true
            return newObject
          }
          return v
        })

        if (!flag) m.push(newObject)
        return m
      }
      return replace(state, payload)
    case MetricsGrid.ADD_NEW_GRID:
      return [...state, payload]
    case MetricsGrid.INITIAL:
      return payload
    case MetricsGrid.RESET:
      return {...initialData}
    default:
      return state
  }
}

export const useMetricsValues = () => {
  const context = useContext(CustomGraphContext)
  if (!context) {
    throw new Error('useMetricsValues must be used within a MetricsGraphProvider')
  }
  return context
}

export const MetricsGraphProvider = ({children}: any) => {
  const [state, dispatch] = useReducer(MetricsReducer, initialData)
  return (
    <CustomGraphContext.Provider value={[state, dispatch]}>{children}</CustomGraphContext.Provider>
  )
}

/* ========================================================================== */
/*                           GRAPH FILTER CONTEXT SETUP                        */
/* ========================================================================== */

interface GraphFilterState {
  syncGraph: boolean;
  graphPeriod: string;
  graphIntervalTime: number;
  graphSince: string;
  graphUntil: string;
  customizeGraphSync: boolean;
  customizePeriod: string;
  customizeIntervalTime: number;
  customizeSince: string;
  customizeUntil: string;
}

const initialState: GraphFilterState = {
  syncGraph: false,
  graphPeriod: 'last_hour',
  graphIntervalTime: 60000,
  graphSince: '',
  graphUntil: '',
  customizeGraphSync: false,
  customizePeriod: 'last_hour',
  customizeIntervalTime: 60000,
  customizeSince: '',
  customizeUntil: '',
};

type Action =
  | { type: 'SET_SYNC_GRAPH'; payload: boolean }
  | { type: 'SET_PERIOD'; payload: string }
  | { type: 'SET_INTERVAL_TIME'; payload: number }
  | { type: 'SET_SINCE'; payload: string }
  | { type: 'SET_UNTIL'; payload: string }
  | { type: 'SET_CUSTOMIZE_GRAPH_SYNC'; payload: boolean }
  | { type: 'SET_CUSTOMIZE_PERIOD'; payload: string }
  | { type: 'SET_CUSTOMIZE_INTERVAL_TIME'; payload: number }
  | { type: 'SET_CUSTOMIZE_SINCE'; payload: string }
  | { type: 'SET_CUSTOMIZE_UNTIL'; payload: string };

// Create the reducer function
const reducer = (state: GraphFilterState, action: Action): GraphFilterState => {
  switch (action.type) {
    case 'SET_SYNC_GRAPH':
      return { ...state, syncGraph: action.payload };
    case 'SET_PERIOD':
      return { ...state, graphPeriod: action.payload };
    case 'SET_INTERVAL_TIME':
      return { ...state, graphIntervalTime: action.payload };
    case 'SET_SINCE':
      return { ...state, graphSince: action.payload };
    case 'SET_UNTIL':
      return { ...state, graphUntil: action.payload };
    case 'SET_CUSTOMIZE_GRAPH_SYNC':
      return { ...state, customizeGraphSync: action.payload };
    case 'SET_CUSTOMIZE_PERIOD':
      return { ...state, customizePeriod: action.payload };
    case 'SET_CUSTOMIZE_INTERVAL_TIME':
      return { ...state, customizeIntervalTime: action.payload };
    case 'SET_CUSTOMIZE_SINCE':
      return { ...state, customizeSince: action.payload };
    case 'SET_CUSTOMIZE_UNTIL':
      return { ...state, customizeUntil: action.payload };
    default:
      return state;
  }
};

interface GraphFilterContextType {
  state: GraphFilterState;
  dispatch: React.Dispatch<Action>;
}

const GraphFilterContext = createContext<GraphFilterContextType | undefined>(undefined);

interface GraphFilterProviderProps {
  children: ReactNode;
}

export const GraphFilterProvider: React.FC<GraphFilterProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GraphFilterContext.Provider value={{ state, dispatch }}>
      {children}
    </GraphFilterContext.Provider>
  );
};

export const useGraphFilterConfig = (): GraphFilterContextType => {
  const context = useContext(GraphFilterContext);
  if (context === undefined) {
    throw new Error('useGraphFilterConfig must be used within a GraphFilterProvider');
  }
  return context;
};

export const setGraphSync = (dispatch: React.Dispatch<Action>, value: boolean) =>
  dispatch({ type: 'SET_SYNC_GRAPH', payload: value });
export const setGraphPeriod = (dispatch: React.Dispatch<Action>, value: string) =>
  dispatch({ type: 'SET_PERIOD', payload: value });
export const setGraphIntervalTime = (dispatch: React.Dispatch<Action>, value: number) =>
  dispatch({ type: 'SET_INTERVAL_TIME', payload: value });
export const setGraphSince = (dispatch: React.Dispatch<Action>, value: string) =>
  dispatch({ type: 'SET_SINCE', payload: value });
export const setGraphUntil = (dispatch: React.Dispatch<Action>, value: string) =>
  dispatch({ type: 'SET_UNTIL', payload: value });
export const setCustomizeGraphSync = (dispatch: React.Dispatch<Action>, value: boolean) =>
  dispatch({ type: 'SET_CUSTOMIZE_GRAPH_SYNC', payload: value });
export const setCustomizeGraphPeriod = (dispatch: React.Dispatch<Action>, value: string) =>
  dispatch({ type: 'SET_CUSTOMIZE_PERIOD', payload: value });
export const setCustomizeGraphIntervalTime = (dispatch: React.Dispatch<Action>, value: number) =>
  dispatch({ type: 'SET_CUSTOMIZE_INTERVAL_TIME', payload: value });
export const setCustomizeGraphSince = (dispatch: React.Dispatch<Action>, value: string) =>
  dispatch({ type: 'SET_CUSTOMIZE_SINCE', payload: value });
export const setCustomizeGraphUntil = (dispatch: React.Dispatch<Action>, value: string) =>
  dispatch({ type: 'SET_CUSTOMIZE_UNTIL', payload: value });

/* ========================================================================== */
/*                             COMBINED PROVIDER                              */
/* ========================================================================== */

export const MetricsPageProvider = ({children}: any) => {
  return (
    <MetricsGraphProvider>
      <GraphFilterProvider>{children}</GraphFilterProvider>
    </MetricsGraphProvider>
  )
}
