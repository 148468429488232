import clsx from 'clsx'
import React, {FC, useState, lazy, Suspense, useEffect} from 'react'
import {KTSVG} from '../../../helpers'
import {
  HeaderNotificationsMenu,
  HeaderUserMenu,
  TrialUser,
  ThemeModeSwitcher,
} from '../../../partials'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth'
import ClientAvatar from '../../../../app/modules/client/ClientAvatar'
import {RootState} from '../../../../setup'
import {markAsRead} from '../../../../services/HeaderNotifications'
import {useIntl} from 'react-intl'
import {DaysFromNowTo} from '../../../../helpers/DaysFromNowTo'
import useMainConfig from '../../../../app/Hooks/useMainConfig'
import useIsTrial from '../../../../app/Hooks/useIsTrial'
import {Box, Modal, useMediaQuery} from '@mui/material'
import {useLocation, useNavigate} from 'react-router-dom'
import useSearchShortcuts from '../../../../app/Hooks/useSearchShortcuts'
import {HelperNavButton} from './HelperNavButton'
import useLocationGetter from '../../../../app/Hooks/useLocationGetter'
import {useWindowSize} from '../../../../app/Hooks/useWindowSize'
import {ToolbarHelperModal} from '../toolbar/ToolbarHelperModal'
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded'
import TimeZoneSelect from '../../../../app/modules/global/TimeZoneSelect'
const Search = lazy(() => import('../../../../app/modules/Search'))

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1 '

const Topbar: FC = () => {
  const [open, setOpen] = React.useState(false)

  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const {trial_end_at} = useMainConfig()
  const isInTrial = useIsTrial()
  const matches = useMediaQuery('(min-width:600px)')
  const location = useLocation()
  const navigate = useNavigate()
  const handleOpen = () => {
    if (matches) {
      setOpen(true)
    } else {
      navigate('/search')
    }
  }
  const handleClose = () => setOpen(false)
  useSearchShortcuts(handleOpen, handleClose)
  const [countNotif, setCountNotif] = useState<{incident: number; logs: number}>({
    incident:user?.unread_incidents_count??0,
    logs: user?.unread_device_logs_count??0,
  })

  const updateNotification=(dataNotif:{incident: number; logs: number})=>{
setCountNotif(dataNotif)
  }
  const clickHandlerToggle = () => {
    markAsRead().then((res) => {
      setCountNotif({
        incident: 0,
        logs: 0,
      })
    })
  }

  useEffect(() => {
    if (open) {
      setOpen(false)
    }
  }, [location.pathname])

  const {width} = useWindowSize()

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      {/* Search */}
      {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
        <Search />
      </div> */}
      {/* Activities */}
      {/* begin::Drawer toggle */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom',
            toolbarButtonHeightClass
          )}
          id='kt_activities_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/general/gen032.svg'
            className={toolbarButtonIconSizeClass}
          />
        </div>
      </div> */}
      {/* end::Drawer toggle */}
      <TimeZoneSelect show={width > 768} />

      {matches && (
        <div
          className={clsx(
            'd-flex align-items-center',
            toolbarButtonMarginClass
            // isInTrial || isPlanFullyFree? '' : 'd-none'
          )}
        >
          <div
            className={clsx('btn btn-icon btn-active-light-primary btn-custom w-auto px-1 fs-7')}
            data-kt-menu-trigger='hover'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <div className='d-flex align-items-center gap-2'>
              <KTSVG path='/media/icons/duotune/abstract/abs019.svg' className='svg-icon-2' />
              {isInTrial
                ? intl.formatMessage({id: 'TRIAL.MENU.HEAD'}, {days: DaysFromNowTo(trial_end_at)})
                : intl.formatMessage({id: 'LABELS.ACCOUNT_USAGE'})}
            </div>
          </div>
          <TrialUser />
        </div>
      )}
      {/* //search */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        onClick={handleOpen}
      >
        <div className={'btn btn-icon btn-active-light-primary btn-custom  px-1'}>
          <div className='d-flex align-items-center gap-2'>
            <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1' />
          </div>
        </div>
      </div>

      {/* CHAT */}
      {/* begin::Menu wrapper */}
      {/* <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          id='kt_drawer_chat_toggle'
        >
          <KTSVG
            path='/media/icons/duotune/communication/com012.svg'
            className={toolbarButtonIconSizeClass}
          />

          <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
        </div>
      </div> */}
      {/* end::Menu wrapper */}

      {/* begin::Help modal */}
         <HelperNavButton />
      {/* end::Help modal */}

      {/* begin::Theme mode */}
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <ThemeModeSwitcher
          toggleBtnClass={clsx('btn-active-light-primary btn-custom', toolbarButtonHeightClass)}
        />
      </div>
      {/* end::Theme mode */}

            {/* NOTIFICATIONS */}
            <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        {/* begin::Menu- wrapper */}
        <div
          className={clsx(
            'btn btn-icon btn-active-light-primary btn-custom position-relative',
            toolbarButtonHeightClass
          )}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
          onClick={clickHandlerToggle}
        >
          <KTSVG
            path='/media/icons/duotune/general/gen007.svg'
            className={toolbarButtonIconSizeClass}
          />
          {countNotif.incident + countNotif.logs > 0 && (
            <span
              className='position-absolute start-0 translate-middle badge rounded-pill bg-primary'
              style={{top: '20%'}}
            >
              {countNotif.incident + countNotif.logs}
            </span>
          )}
        </div>
        <HeaderNotificationsMenu updateNotification={updateNotification} />
        {/* end::Menu wrapper */}
      </div>
 
      {/* begin::User */}
      <div
        className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
        id='kt_header_user_menu_toggle'
      >
        {/* begin::Toggle */}
        <div
          className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
          data-kt-menu-trigger='click'
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='bottom'
        >
          {/* <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='metronic' /> */}
          <ClientAvatar wrapperClassName='header-profile-img' />
        </div>
        <HeaderUserMenu id={user.id} />
        {/* end::Toggle */}
      </div>
      {/* end::User */}
      {/* begin::Aside Toggler */}
      {/* {config.header.left === 'menu' && (
        <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1' />
          </div>
        </div>
      )} */}
      {matches && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='keep-mounted-modal-title'
          aria-describedby='keep-mounted-modal-description'
          sx={{
            zIndex: 50000,
          }}
          classes={{root: 'd-flex flex-center'}}
        >
          <div className=' w-500px m-auto shadow card' style={{outline: 'none', border: 'none'}}>
            <Suspense>
              <Search needFooter={matches} />
            </Suspense>
          </div>
        </Modal>
      )}
    </div>
  )
}

export {Topbar}
