import {
  types
} from "./actionTypes";

const initialState: any = {
  pending: false,
  error: null,
  testCheckError: null,
  pendingTestCheck: false,
  testSuccess: false,
  key: '',
  detail: {},
  ips: [],
  uptime: [],
  serverChecks: [],
  incident: {},
  testResponseData: {},
  addCheckError: null,
  pendingAddCheck: false,
  addCheckSuccess: false,
  removeCheckError: null,
  pendingRemoveCheck: false,
  removeCheckSuccess: false,
  getCheckError: null,
  pendingGetCheck: false,
  getCheckSuccess: false,
  pauseCheckError: null,
  pendingPauseCheck: false,
  pauseCheckSuccess: false,
  unpauseCheckError: null,
  pendingUnpauseCheck: false,
  unpauseCheckSuccess: false,
  selectedCheckToEdit: null,

};


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_SERVER:
      return {
        ...state,
        pending: true,
      };
      case types.GET_SERVER_REFETCH:
      return {
        ...state,
        pending: false,
      };
    case types.GET_SERVER_SUCCESS:
      return {
        ...state,
        pending: false,
        detail: action.payload,
        error: null
      };
    case types.GET_SERVER_ERROR:
      return {
        ...state,
        pending: false,
        error: action,
      };

    case types.GET_SERVER_ip:
      return {
        ...state,
        pendingGetIp: true,
      };
    case types.GET_SERVER_ip_SUCCESS:

      return {
        ...state,
        pending: false,
        ips: action.payload,
      };
    case types.GET_SERVER_ip_ERROR:
      return {
        ...state,
        pending: false,
        errorGetIp: action,
      };

    case types.GET_SERVER_UPTIME:
      return {
        ...state,
        pendingGetUptime: true,
        uptime: [],
        errorGetUptime: null
      };
    case types.GET_SERVER_UPTIME_SUCCESS:

      return {
        ...state,
        pendingGetUptime: false,
        uptime: action.payload,
      };
    case types.GET_SERVER_UPTIME_ERROR:
      return {
        ...state,
        pendingGetUptime: false,
        errorGetUptime: action,
      };

    // case types.GET_SERVER_ip_ERROR:
    //   return {
    //     ...state,
    //     pending: false,
    //     errorGetIp: action,
    //   };

    case types.GET_SERVER_INCIDENT:
      return {
        ...state,
        incidentError: null,
        pendingGetIncident: true,
      };
    case types.GET_SERVER_INCIDENT_SUCCESS:

      return {
        ...state,
        pendingGetIncident: false,
        incident: action.payload,
      };
    case types.GET_SERVER_INCIDENT_ERROR:
      return {
        ...state,
        pendingGetIncident: false,
        incidentError: action,
      };

    case types.REQUEST_TEST_ADD_NEW_CHECK:
      return {
        ...state,
        testCheckError: null,
        pendingTestCheck: true,
        testResponseData: {},
        testSuccess: false,
      };
    case types.REQUEST_TEST_ADD_NEW_CHECK_SUCCESS:

      return {
        ...state,
        pendingTestCheck: false,
        testSuccess: true,
        testResponseData: action.response
      };
    case types.REQUEST_TEST_ADD_NEW_CHECK_ERROR:
      return {
        ...state,
        pendingTestCheck: false,
        testCheckError: action.error,
      };

    case types.REQUEST_ADD_NEW_CHECK:
      return {
        ...state,
        addCheckError: null,
        pendingAddCheck: true,
        addCheckSuccess: false,
      };
    case types.REQUEST_ADD_NEW_CHECK_SUCCESS:

      return {
        ...state,
        pendingAddCheck: false,
        addCheckSuccess: true,
      };
    case types.REQUEST_ADD_NEW_CHECK_ERROR:
      return {
        ...state,
        pendingAddCheck: false,
        addCheckError: action,
      };


    case types.REQUEST_REMOVE_CHECK:
      return {
        ...state,
        removeCheckError: null,
        pendingRemoveCheck: true,
        removeCheckSuccess: false,
      };
    case types.REQUEST_REMOVE_CHECK_SUCCESS:

      return {
        ...state,
        pendingRemoveCheck: false,
        removeCheckSuccess: true,
      };
    case types.REQUEST_REMOVE_CHECK_ERROR:
      return {
        ...state,
        pendingRemoveCheck: false,
        removeCheckError: action,
      };



    case types.REQUEST_GET_CHECKS:
      return {
        ...state,
        getCheckError: null,
        pendingGetCheck: true,
        getCheckSuccess: false,
        serverChecks: []

      };
    case types.REQUEST_GET_CHECKS_SUCCESS:

      return {
        ...state,
        pendingGetCheck: false,
        getCheckSuccess: true,
        serverChecks: action.payload
      };
    case types.REQUEST_GET_CHECKS_ERROR:
      return {
        ...state,
        pendingGetCheck: false,
        getCheckError: action,
      };



    case types.REQUEST_PAUSE_CHECKS:
      return {
        ...state,
        pauseCheckError: null,
        pendingPauseCheck: true,
        pauseCheckSuccess: false,

      };
    case types.REQUEST_PAUSE_CHECKS_SUCCESS:

      return {
        ...state,
        pendingPauseCheck: false,
        pauseCheckSuccess: true,
      };
    case types.REQUEST_PAUSE_CHECKS_ERROR:
      return {
        ...state,
        pendingPauseCheck: false,
        pauseCheckError: action,
      };

    case types.REQUEST_UNPAUSE_CHECKS:
      return {
        ...state,
        unpauseCheckError: null,
        pendingUnpauseCheck: true,
        unpauseCheckSuccess: false,

      };
    case types.REQUEST_UNPAUSE_CHECKS_SUCCESS:

      return {
        ...state,
        pendingUnpauseCheck: false,
        unpauseCheckSuccess: true,
      };
    case types.REQUEST_UNPAUSE_CHECKS_ERROR:
      return {
        ...state,
        pendingUnpauseCheck: false,
        unpauseCheckError: action,
      };

    case types.SELECT_CHECK_TO_EDIT:
      return {
        ...state,
        selectedCheckToEdit: action.check
      };

      case types.REQUEST_UPDATE_CHECKS:
      return {
        ...state,
        addCheckError: null,
        pendingAddCheck: true,
        addCheckSuccess: false,

      };
    case types.REQUEST_UPDATE_CHECKS_SUCCESS:

      return {
        ...state,
        pendingAddCheck: false,
        addCheckSuccess: true,
      };
    case types.REQUEST_UPDATE_CHECKS_ERROR:
      return {
        ...state,
        pendingAddCheck: false,
        addCheckError: action.error,

      };


    case types.RESET_SERVICE_OVERVIEW_PROPS:
      return {
        ...state,
        pending: false,
        error: null,
        success: false,
        testSuccess: false,
        testCheckError: null,
        pendingTestCheck: false,
        addCheckError: null,
        pendingAddCheck: false,
        addCheckSuccess: false,
        removeCheckError: null,
        pendingRemoveCheck: false,
        removeCheckSuccess: false,
        getCheckError: null,
        pendingGetCheck: false,
        getCheckSuccess: false,
        pauseCheckError: null,
        pendingPauseCheck: false,
        pauseCheckSuccess: false,
        unpauseCheckError: null,
        pendingUnpauseCheck: false,
        unpauseCheckSuccess: false,
        
      };



    default:
      return {
        ...state,
      };
  }
};