/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {handleOpenFeedback} from '../../../app/store/commonAction'
import {useLayout} from '../core'
import { Button, Modal } from 'react-bootstrap'
import ShortcutKeyModal from '../../../app/modules/shortcutKey/shortcutKeyModal'
import { useIntl } from 'react-intl'
import { useMediaQuery } from '@mui/material'

const Footer: FC = () => {
  const {classes} = useLayout()
  const dispatch = useDispatch()
  const ver = process.env.REACT_APP_SW_VER || 1
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width:768px)')

  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`container d-flex flex-column flex-md-row align-items-md-center justify-content-between`}
      >
        {/* begin::Copyright */}

        {/* end::Copyright */}

        {/* begin::Nav */}
        <div className='text-dark  '>
          <ul className='nav flex-column flex-md-row align-items-md-center  gap-2 gap-lg-6  '>
            <li className='m-0'>
              <a href='//xitoring.com' className='ng-binding'>
                Copyright © <span className='ng-binding'>Xitoring</span> 2024. All rights reserved
              </a>
            </li>
            <li className='m-0'>
              <a href='//xitoring.com/privacy-policy/' target='_blank'>
                Privacy Policy
              </a>
            </li>
            <li className='m-0'>
              <a href='//xitoring.com/tos/' target='_blank'>
                TOS
              </a>
            </li>
            <li>
              <a href='mailto:support@xitoring.com'>Need help? support@xitoring.com</a>
            </li>
          </ul>
        </div>

        <ul className='nav d-flex mt-2 mt-lg-0'>
          {!isMobile && (
            <li className='m-0'>
              <a
                data-toggle='collapse'
                data-target='#footer-feedback-form'
                className='brightness-hover-effect'
                onClick={() => {
                  setOpen(true)
                }}
              >
                {intl.formatMessage({id: `LABELS.SHORTCUTS`})}
              </a>
            </li>
          )}
          <li>
            <a
              data-toggle='collapse'
              data-target='#footer-feedback-form'
              className='brightness-hover-effect'
              onClick={() => {
                dispatch(handleOpenFeedback(true))
              }}
            >
              {intl.formatMessage({id: `MENU.FEEDBACK`})}
            </a>
          </li>
          <li className='m-0'>
            <a
              href='https://xitoring.com/docs/release-notes/xitoring-release-notes/'
              className='ng-binding'
              target='_blank'
            >
              <span className='badge badge-secondary'>v{ver}</span>
            </a>
          </li>
          {/* <li>
            <a href="javascript:;" className="scroll-up" scroll-up="">
              <i className="fa fa-angle-up"></i>
            </a>
          </li> */}
        </ul>

        {/* end::Nav */}
      </div>
      {/* end::Container */}

      <Modal
        show={open}
        onHide={() => setOpen(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {intl.formatMessage({id: `LABELS.SHORTCUT_KEYS`})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{maxHeight: '50vh', overflowY: 'scroll'}}>
            <ShortcutKeyModal />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setOpen(false)}>
            {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {Footer}
