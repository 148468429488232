import React from 'react'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {useIntl} from 'react-intl'
import ChecksStatusArrow from '../../../modules/global/ChecksStatusArrow'
import {TYPES_TITLE} from '../../../modules/widgets/components/Common'
import moment from 'moment'
import {Link} from 'react-router-dom'
import {useWindowSize} from '../../../Hooks/useWindowSize'
import UptimeWidget from '../../upTimeOverview/UptimeWidget'
import IconButton from '../../../modules/global/IconButton'
import {Box, useMediaQuery} from '@mui/material'
import {useLocalStorage} from '../../../Hooks/useLocalStorage'
import { StyledDataGrid } from '../../../modules/global/UI/StyledComponentMUI'

interface Props {
  dataList: Record<string, any>[]
  range: string
  isBulkEnable: boolean
  onSelectCheck: (checks: any[]) => void
  selectedChecks: any[]
}

const UptimeListCompact = (props: Props) => {
  const {dataList, isBulkEnable, onSelectCheck, range, selectedChecks} = props
  const intl = useIntl()
  const { width } = useWindowSize()
  const isMobile = useMediaQuery('(max-width:600px)');
  
  const [columnVisible, setColumnVisible] = useLocalStorage<any>('uptime-list-visible', {
    id: false,
    type: true,
    label: true,
    group: true,
    host: true,
    uptime_percentage: true,
    action: true,
    downtime_human_readable: false,
    created_at_timestamp: false,
    widget: true,
    link: true,
  })

  const dataTable = React.useMemo<any>(() => {
    const dataOfUptime: any[] = []

    dataList?.forEach((groupData) => {
      if (groupData?.dev?.length) {
        groupData?.dev?.forEach((deviceData: Record<string, any>) => {
          if (deviceData?.devices?.length) {
            deviceData?.devices?.forEach((device: Record<string, any>) => {
              dataOfUptime.push({
                
                group: groupData?.name,
                ...device,
              })
            })
          }
        })
      }

      if (groupData?.sub?.length) {
        groupData?.sub?.forEach((subGroupData: Record<string, any>) => {
          subGroupData?.dev?.forEach((subDeviceData: Record<string, any>) => {
            if (subDeviceData?.devices?.length) {
              subDeviceData?.devices?.forEach((device: Record<string, any>) => {
                dataOfUptime.push({
              
                  group: subGroupData?.name,
                  parent: groupData?.name,
                  ...device,
                })
              })
            }
          })
        })
      }
    })
    return dataOfUptime
  }, [dataList])

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: 'id',
        editable: false,
        sortable: false,
        filterable: false,
        flex: isMobile ? 0 : .5,
        width: 60,
      },
      {
        field: 'type',
        headerName: intl.formatMessage({id: 'LABELS.TYPE'}),
        flex: isMobile ? 0 : 1,
        width: 120,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return (
            <div className='d-flex gap-1 align-items-center'>
              <ChecksStatusArrow
                is_paused={param?.row?.is_paused}
                status={param?.row?.status}
                is_under_maintenance={param?.row?.is_under_maintenance}
              />
              <span className='text-uppercase'>{TYPES_TITLE(param?.row?.type)}</span>
            </div>
          )
        },
      },
      {
        headerName: intl.formatMessage({id: 'LABELS.LABEL'}),
        field: 'label',
        sortingOrder: ['asc', 'desc'],
        sortable: true,
        filterable: true,
        hideable: false,
        flex: isMobile ? 0 : 1.5,
        width: 200,
        renderCell: (param) => {
          return (
            <Link to={`${param?.row?.device_id}?module=${param?.row?.id}`}>
             {param?.row?.label}
            </Link>
          )
        },
      },
      {
        headerName: intl.formatMessage({id: 'LABELS.SERVER_GROUP'}),
        field: 'group',
        renderCell: ({row}: any) => {
          return (
            <div className='d-flex align-items-center  fs-8'>
              {row?.parent ? (
                <span>
                  {' '}
                  {row?.parent} <i className='fas fa-arrow-right mx-2 text-dark' />
                </span>
              ) : (
                ''
              )}
              {row?.group}
            </div>
          )
        },
        flex: isMobile ? 0 : 1.5,
        width: 150,
        filterable: false,
        sortable: false,

      },
      {
        field: 'host',
        headerName: intl.formatMessage({id: 'LABELS.SERVER_HOST'}),
        flex: isMobile ? 0 : 1,
        width: 150,
        editable: false,
        sortable: false,
        filterable: true,
      },
      {
        field: 'uptime_percentage',
        headerName: intl.formatMessage({id: 'MENU.UPTIME'}),
        flex: isMobile ? 0 : .8,
        width: 80,
        editable: false,
        sortingOrder: ['asc', 'desc'],
        sortable: true,
        filterable: true,
        renderCell: (param) => {
          return <div>{param?.row?.uptime_percentage} %</div>
        },
      },
      {
        field: 'downtime_human_readable',
        headerName: intl.formatMessage({id: 'LABELS.UPTIME_DOWNTIME'}),
        flex: isMobile ? 0 : 1,
        width: 150,
        editable: false,
        sortable: false,
        filterable: false,
      },
      {
        field: 'created_at_timestamp',
        headerName: intl.formatMessage({id: 'LABELS.CREATED'}),
        flex: isMobile ? 0 : 1,
        width: 120,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return <div>{moment(param?.row?.created_at_timestamp * 1000).format('YYYY-MM-DD')}</div>
        },
      },
      {
        field: 'widget',
        headerName: '',
        flex: isMobile ? 0 : 2.5,
        width: 250,
        // renderHeader: () => null,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return (
            <UptimeWidget
              isPause={param?.row?.is_paused}
              range={range as any}
              uptime={param?.row}
              mode='table'
            />
          )
        },
      },
      {
        field: 'link',
        headerName: '',
        width: 100,
        // renderHeader: () => null,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (param) => {
          return (
            <div className='d-flex gap-2'>
              <Link to={`${param?.row?.device_id}/settings?module=${param?.row?.id}`}>
                <IconButton
                  icon='fas fa-cog'
                  tooltip={intl.formatMessage({id: 'LABELS.SETTING'})}
          
                />
              </Link>
              <Link to={`${param?.row?.device_id}?module=${param?.row?.id}`}>
                <IconButton icon='far fa-eye' tooltip={intl.formatMessage({id: 'ACTIONS.VIEW'})} />
              </Link>
            </div>
        
          )
        },
      },
    ],
    [width, range]
  )

  return (
    <Box
      sx={{
        '& .MuiDataGrid-menu': {
          zIndex: 20000,
        },
      }}
    >
      <DataGrid
     
     sx={{
      overflowX: 'hidden',
      '& .MuiDataGrid-row.Mui-selected': {
        backgroundColor: 'var(--kt-secondary)',
        '&:hover': {
          backgroundColor: 'var(--kt-secondary)',
        },
      },
      '& .MuiDataGrid-columnHeader:focus , .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeader:active': {
        outline: 'none',
      },
      '& .MuiDataGrid-cell:focus-within': {
        outline: 'none',
      },
      '& .MuiDataGrid-columnHeader:focus-within': {
        outline: 'none',
      },
    }}
    autoHeight
    initialState={{
      columns: {
        columnVisibilityModel:isMobile?{
          id: false,
          type: true,
          label: true,
          group: true,
          host: true,
          uptime_percentage: true,
          action: true,
          downtime_human_readable: false,
          created_at_timestamp: false,
          widget: true,
          link: true,
        }: columnVisible,

      },
    sorting:{
      sortModel: [
        {
          field: 'label',
          sort: 'asc', // 'asc' for ascending order, 'desc' for descending order
        },
      ],
    }
    }}
    
    disableVirtualization
    
        checkboxSelection={isBulkEnable}
        columns={columns}
        onRowSelectionModelChange={(newRowSelectionModel, detail) => {
          onSelectCheck(newRowSelectionModel as any)
        }}
        rowSelectionModel={selectedChecks}
        rows={dataTable}
        hideFooterPagination
        rowHeight={40}
        hideFooter
        onColumnVisibilityModelChange={(m, d) => {
          setColumnVisible({
            ...{
              id: false,
              type: true,
              label: true,
              group: true,
              host: true,
              uptime_percentage: true,
              action: true,
              downtime_human_readable: false,
              created_at_timestamp: false,
              widget: true,
              link: true,
            },
            ...m,
          })
        }}
       
      />
    </Box>
  )
}

export default UptimeListCompact
