import {Outlet} from 'react-router-dom'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import MyErrorBoundary from '../../ErrorBoundary'
import {toAbsoluteUrl} from '../helpers'

const AuthLayout = () => {
  return (
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 py-10 order-2 order-lg-1 card h-100 border-0'>
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='w-100 w-lg-500px p-8'>
              <Content>
                <MyErrorBoundary>
                  <Outlet />
                </MyErrorBoundary>
              </Content>
              <div className='d-flex fw-semibold text-primary fs-base justify-content-between mt-5'>
                <a
                  href='https://xitoring.com/docs/'
                  className='  text-center flex-fill'
                  target='_blank'
                >
                  Documentation
                </a>
                <a
                  href='https://xitoring.com/faq/'
                  className='  text-center flex-fill'
                  target='_blank'
                >
                  FAQ
                </a>
                <a
                  href='https://xitoring.com/contact/'
                  className=' text-center flex-fill'
                  target='_blank'
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{
            backgroundColor: '#1f1f1f',
            backgroundImage: `url(${toAbsoluteUrl('/media/icons/bg.svg')})`,
          }}
        >
          <div className='d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100'>
            <a href='#' className='mb-0 mb-lg-14'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/images/logos/logo_white.png')}
                className='h-60px h-lg-150px'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export {AuthLayout}
