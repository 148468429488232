/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {Spinner} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

interface FilterItemType {
  key: string
  value: string
  name: string
}

interface ColType {
  minWidth: string
  maxWidth?: string
  className?: string
  name?: string
  key: string
  getter: string
  node?: (rowData: Row) => React.ReactNode
  classNameTd?: string
}
interface Row {
  key: string
  [key: string]: any
}

type Props = {
  className: string
  filters: FilterItemType[]
  title: string
  description?: string
  onFilterChange: (filter: string) => void
  columns: ColType[]
  rows: Row[]
  defaultSelectedFilter?: string
  loading: boolean
  headerNode?:React.ReactNode
}

const TablesWidget6: React.FC<Props> = ({
  className,
  title,
  description,
  filters,
  onFilterChange,
  columns,
  rows,
  defaultSelectedFilter,
  loading,
  headerNode
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string>(
    defaultSelectedFilter ?? filters[0].value
  )

  useEffect(() => {
    onFilterChange(selectedFilter)
  }, [selectedFilter])

  return (
    <div className={`card ${className} h-100`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5  p-4  flex-column'>
        <h3 className='card-title align-items-start  flex-row justify-content-between'>
          <span className='card-label fw-bold fs-1 mb-1'>{title}</span>
          <span className='text-muted mt-1 fw-semobold fs-7'>{description}</span>
          {headerNode}
        </h3>
    
        <div className='card-toolbar'>
          <ul className='nav'>
            {filters.map((filter) => {
              return (
                <li
                  className='nav-item'
                  key={filter.key}
                  onClick={() => setSelectedFilter(filter.value)}
                >
                  <span
                    className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 fs-6 ${
                      selectedFilter == filter.value ? 'active' : ''
                    }`}
                  >
                    {filter.name}
                  </span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3 p-4 p-lg-6'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_6_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive pe-2' style={{maxHeight:"300px",overflow:"auto"}}>
              {/* begin::Table */}
              {loading ? (
                <div className='h-250px text-center d-flex justify-content-center align-items-center'>
                  <Spinner variant='primary' animation='grow' />
                </div>
              ) : !loading && rows?.length == 0 ? (
                <h5 className='text-center py-5'>
                  <FormattedMessage id='NO_DATA_TABLE' />
                </h5>
              ) : (
                <table className='table align-middle gs-0 gy-3'>
                  {/* begin::Table head */}
                  <thead>
                    <tr>
                      {columns.map((col) => (
                        <th
                          className={`p-0 ${col.className ?? ''}`}
                          key={col.key}
                          style={{
                            minWidth: col.minWidth,
                            maxWidth: col.maxWidth,
                            width: col.maxWidth,
                          }}
                        >
                          {col.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {rows?.map((row) => {
                      return (
                        <tr key={row.key}>
                          {columns.map((col) => {
                            return (
                              <td key={row.key + col.key} className={col.classNameTd}>
                                {col.node ? (
                                  <>{col.node(row)}</>
                                ) : (
                                  <div className='text-dark'>{row[col.getter]}</div>
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                  {/* end::Table body */}
                </table>
              )}
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}

          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget6}

//sample of rows

//  <tr>
//                     <td>
//                       <div className='symbol symbol-50px me-2'>
//                         <span className='symbol-label'>
//                           <img
//                             src={toAbsoluteUrl('/media/svg/avatars/001-boy.svg')}
//                             className='h-75 align-self-end'
//                             alt=''
//                           />
//                         </span>
//                       </div>
//                     </td>
//                     <td>
//                       <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
//                         Brad Simmons
//                       </a>
//                       <span className='text-muted fw-semobold d-block'>Successful Fellas</span>
//                     </td>
//                     <td>
//                       <span className='text-muted fw-semobold d-block fs-7'>Paid</span>
//                       <span className='text-dark fw-bold d-block fs-5'>$200,500</span>
//                     </td>
//                     <td className='text-end'>
//                       <span className='text-primary fs-7 fw-bold'>+28%</span>
//                     </td>
//                     <td className='text-end'>
//                       <a
//                         href='#'
//                         className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
//                       >
//                         <KTSVG
//                           path='/media/icons/duotune/arrows/arr064.svg'
//                           className='svg-icon-2'
//                         />
//                       </a>
//                     </td>
//                   </tr>
