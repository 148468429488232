import {Dialog} from '@mui/material'
import {useFormik} from 'formik'

import React, {useState, useEffect} from 'react'
import {Form} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {notify} from '../../../../helpers'
import * as Yup from 'yup'
import {useAuth} from '../core/Auth'
import {getProfile, getToken, login} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import clsx from 'clsx'
import {useDispatch} from 'react-redux'
import Bugsnag from '@bugsnag/js'
import {getMainServiceAction} from '../../../store/billing/actions'
import * as authMain from '../../auth/redux/AuthRedux'
import {types} from '../../../store/commonActionTypes'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const initialValues = {
  loginName: '',
  password: '',
  rememberMe: false,
  no_redirect: 'true',
}

const AuthOnModal = () => {
  const intl = useIntl()
  const [loginNameValue, setloginNameValue] = useState<string>('')
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()

  const loginSchema = Yup.object().shape({
    loginName: loginNameValue.includes('@')
      ? Yup.string()
          .email('Wrong email format')
          .min(3, 'Minimum 3 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Email/username is required')
      : Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    password: Yup.string()
      .min(8, 'Minimum 8 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        const response = await login(values.loginName, values.password, values.rememberMe)
        const token =response?.data.token||response?.data
        dispatch(authMain.actions.login(token))
        if (response?.data?.status === '2fa_required') {
          navigate('/auth/2fa')
          setLoading(false)
        } else {
      
          const profile = await getProfile()
          dispatch(authMain.actions.setUser(profile?.data))
          if (profile?.data) {
            setCurrentUser(profile?.data)
            dispatch(getMainServiceAction())
            Bugsnag.addMetadata('userInformation', {
              id: profile.data.id,
              username: profile.data.username,
              email: profile.data.email,
            })
          }
          dispatch({type: types.RESET_AUTH})
          setLoading(false)
        }
      } catch (err: any) {
        setLoading(false)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        const error = err.response && (err.response || err.message)
        let data = error?.data
        console.error('data error ', data)
        if (!data) {
          return notify({
            type: 'error',
            message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
          })
        }
        let messages = []

        for (let i in data) {
          messages.push(
            <>
              {data[i]}
              <br />
            </>
          )
        }
        notify({type: 'error', message: <>{messages}</>})
      }
    },
  })

  useEffect(() => {
    setloginNameValue(formik.values.loginName)
  }, [formik.values.loginName])

  return (
    <Dialog open fullWidth>
      <>
      <div
              className='d-flex flex-lg-row-fluid w-100 bgi-size-cover bgi-position-center  w-100'
              style={{
                backgroundColor: '#1f1f1f',
                backgroundImage: `url(${toAbsoluteUrl('/media/icons/bg.svg')})`,
              }}
            >
              <div className='d-flex flex-column flex-center py-7 py-lg-10 px-5 px-md-10 w-100'>
                <a href='#' className='mb-0 mb-lg-6'>
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/images/logos/logo_white.png')}
                    className='h-60px h-lg-100px'
                  />
                </a>
              </div>
            </div>
        <div className='card rounded-0 p-6 w-100'>
          <form
            className='form w-100'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_login_signin_form'
          >
           
            <h5 className='text-center mb-6'>{intl.formatMessage({id: 'EXPIRE.AUTH.LOGIN'})}</h5>
            {formik.status && (
              <div className='mb-lg-10 alert alert-danger text-dark'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            )}

            <Form.Group>
              <Form.Label>{intl.formatMessage({id: 'AUTH.INPUT.USERNAME'})}</Form.Label>
              <Form.Control
                type='text'
                placeholder={intl.formatMessage({id: 'USERNAME.PLACEHOLDER'})}
                {...formik.getFieldProps('loginName')}
                className={clsx(
                  'form-control bg-transparen',
                  {'is-invalid': formik.touched.loginName && formik.errors.loginName},
                  {
                    'is-valid':
                      formik.touched.loginName &&
                      !formik.errors.loginName &&
                      formik.values.loginName,
                  }
                )}
              />
            </Form.Group>

            <Form.Group className='my-6'>
              <Form.Label>{intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}</Form.Label>
              <Form.Control
                type='password'
                placeholder={intl.formatMessage({id: 'PASSWORD.PLACEHOLDER'})}
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparen',
                  {
                    'is-invalid':
                      formik.touched.password && formik.errors.password && formik.values.password,
                  },
                  {
                    'is-valid':
                      formik.touched.password && !formik.errors.password && formik.values.password,
                  }
                )}
              />
            </Form.Group>

            <Form.Group>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-lg btn-primary w-100 mb-5'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && (
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'LOGS.TABS.LOGIN.TITLE'})}
                  </span>
                )}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'PLEASE.WAIT'})}

                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </Form.Group>
          </form>
        </div>
      </>
    </Dialog>
  )
}

export default AuthOnModal
