/* eslint-disable no-useless-concat */

let sumArray = function (array: any) {
  return array.reduce(function (a: any, b: any) {
    return a + b
  }, 0)
}

export function convertBit(value: any) {
  if (typeof value == 'undefined') {
    return value
  }

  value = parseInt(value)

  if (value === 0) {
    return value
  }

  const i = Math.floor(Math.log(value) / Math.log(1024)),
    sizes = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']
  const temp = value / Math.pow(1024, i)
  return parseFloat(temp.toFixed(2)) * 1 + ' ' + sizes[i]
}

export function convertByte(value: any) {
  if (typeof value != 'undefined') {
    value = parseFloat(value)

    if (isNaN(value) ||value === 0) {
      return value
    }

    var i = Math.floor(Math.abs(Math.log(value) / Math.log(1024))),
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    return parseFloat((value / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + sizes[i]
  }

  return value
}

function toFix(value: any) {
  if (typeof value !== 'undefined') {
    var str_value = value.toString().split('.')

    if (
      (value.toString().indexOf('.') !== -1 && str_value[1].length !== 2) ||
      value.toString().indexOf('.') === -1
    ) {
      return parseFloat(value).toFixed(2)
    }
  }

  return value
}




