
import { Accordion } from "react-bootstrap";
import {
    types
  } from "./actionTypes";
  
  const initialState: any = {
    pendingCpuUsage: false,
    errorCpuUsage: null,
    successCpuUsage:false,
    pendingCpuLoadAverage: false,
    errorCpuLoadAverage: null,
    successCpuLoadAverage:false,
    pendingDiskUsage: false,
    errorDiskUsage: null,
    successDiskUsage:false,
    pendingMemoryUsage: false,
    errorMemoryUsage: null,
    successMemoryUsage:false,
    pendingNetwork: false,
    errorNetwork: null,
    successNetwork:false,
    pendingDiskIO: false,
    errorDiskIO: null,
    successDiskIO:false,
    errorCheckGraph: null,
    pendingCheckGraph: false,
    successCheckGraph: false,
  };
  
  
  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = initialState, action: any) => {
    switch (action.type) {
      
  
        case types.REQUEST_GRAPH_CPU_LOAD_AVERAGE:
        return {
          ...state,
          errorCpuLoadAverage: null,
          pendingCpuLoadAverage: true,
          successCpuLoadAverage: false,
  
        };
      case types.REQUEST_GRAPH_CPU_LOAD_AVERAGE_SUCCESS:
  
        return {
          ...state,
          pendingCpuLoadAverage: false,
          successCpuLoadAverage: true,
          cpuLoadAverageData:action.response
        };
      case types.REQUEST_GRAPH_CPU_LOAD_AVERAGE_ERROR:
        return {
          ...state,
          pendingCpuLoadAverage: false,
          errorCpuLoadAverage: action.error,
  
        };

        
        case types.REQUEST_GRAPH_CPU_USAGE:
        return {
          ...state,
          errorCpuUsage: null,
          pendingCpuUsage: true,
          successCpuUsage: false,
  
        };
      case types.REQUEST_GRAPH_CPU_USAGE_SUCCESS:
  
        return {
          ...state,
          pendingCpuUsage: false,
          successCpuUsage: true,
          cpuUsageData:action.response
        };
      case types.REQUEST_GRAPH_CPU_USAGE_ERROR:
        return {
          ...state,
          pendingCpuUsage: false,
          errorCpuUsage: action.error,
  
        };

        
        
        case types.REQUEST_GRAPH_DISK_USAGE:
        return {
          ...state,
          errorDiskUsage: null,
          pendingDiskUsage: true,
          successDiskUsage: false,
  
        };
      case types.REQUEST_GRAPH_DISK_USAGE_SUCCESS:
  
        return {
          ...state,
          pendingDiskUsage: false,
          successDiskUsage: true,
          diskUsageData:action.response
        };
      case types.REQUEST_GRAPH_DISK_USAGE_ERROR:
        return {
          ...state,
          pendingDiskUsage: false,
          errorDiskUsage: action.error,
  
        };
  
  
        
        case types.REQUEST_GRAPH_MEMORY_USAGE:
        return {
          ...state,
          errorMemoryUsage: null,
          pendingMemoryUsage: true,
          successMemoryUsage: false,
  
        };
      case types.REQUEST_GRAPH_MEMORY_USAGE_SUCCESS:
  
        return {
          ...state,
          pendingMemoryUsage: false,
          successMemoryUsage: true,
          memoryUsageData:action.response
        };
      case types.REQUEST_GRAPH_MEMORY_USAGE_ERROR:
        
        return {
          ...state,
          pendingMemoryUsage: false,
          errorMemoryUsage: action.error,
  
        };

        
        
        case types.REQUEST_GRAPH_NETWORK:
        return {
          ...state,
          errorNetwork: null,
          pendingNetwork: true,
          successNetwork: false,
  
        };
      case types.REQUEST_GRAPH_NETWORK_SUCCESS:
  
        return {
          ...state,
          pendingNetwork: false,
          successNetwork: true,
          networkData:action.response
        };
      case types.REQUEST_GRAPH_NETWORK_ERROR:
        
        return {
          ...state,
          pendingNetwork: false,
          errorNetwork: action.error,
  
        };

        
        
        case types.REQUEST_GRAPH_DISK_IO:
        return {
          ...state,
          errorDiskIo: null,
          pendingDiskIo: true,
          successDiskIo: false,
  
        };
      case types.REQUEST_GRAPH_DISK_IO_SUCCESS:
  
        return {
          ...state,
          pendingDiskIo: false,
          successDiskIo: true,
          diskIoData:action.response
        };
      case types.REQUEST_GRAPH_DISK_IO_ERROR:
        
        return {
          ...state,
          pendingDiskIo: false,
          errorDiskIo: action.error,
  
        };

        case types.REQUEST_SERVER_CHECK_GRAPH:
          return {
            ...state,
            errorCheckGraph: null,
            pendingCheckGraph: true,
            successCheckGraph: false,
            checkGraphData:undefined
            
          };
        case types.REQUEST_SERVER_CHECK_GRAPH_SUCCESS:
    
          return {
            ...state,
            pendingCheckGraph: false,
            successCheckGraph: true,
            checkGraphData:action.response
          };
        case types.REQUEST_SERVER_CHECK_GRAPH_ERROR:
          
          return {
            ...state,
            pendingCheckGraph: false,
            errorCheckGraph: action.error,
    
          };
    
  
  
  
  
      case types.RESET_Graph_PROPS:
        return {
          ...state,
          pending: false,
          error: null,
          success: false,
          errorCheckGraph: null,
          pendingCheckGraph: false,
          successCheckGraph: false,
          checkGraphData:null
          
        };
  
  
  
      default:
        return {
          ...state,
        };
    }
  };