import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

const useKeyboardShortcuts = (setOpen: any) => {
  const navigate = useNavigate()
  const [sequence, setSequence] = useState('')
  const [xClicked, setXClicked] = useState(false)
  const defaultDash = localStorage.getItem('defaultDash')
  const dashboardLocation = !!defaultDash ? `/customized-dashboard/${defaultDash}` : '/dashboard'

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      const { key } = event;
      const keyPressed = key?.toLowerCase();

      if (keyPressed === 'x') {
        setXClicked(true);
      }

      const activeElement = document.activeElement;
      const isInputFocused = activeElement && (
        activeElement.tagName === 'INPUT' ||
        activeElement.tagName === 'TEXTAREA' ||
        activeElement.getAttribute('contenteditable') === 'true'
      );

      if (!isInputFocused) {
        if (event.shiftKey && key === '?') {
          event.preventDefault()
          setOpen(true)
        } else if (event.key === 'Escape') {
          event.preventDefault()
          setOpen(false)
        }
      }

      const validSequences = [
        { sequence: 'x1', path: dashboardLocation },
        { sequence: 'x2', path: '/new' },
        { sequence: 'x3', path: '/incidents' },
        { sequence: 'x4', path: '/servers' },
        { sequence: 'x5', path: '/metrics' },
        { sequence: 'x6', path: '/uptime' },
        { sequence: 'x7', path: '/ssl' },
        { sequence: 'x8', path: '/trigger-management' },
        { sequence: 'x9', path: '/notificationRoles' },
        { sequence: 'x0', path: '/public-status-page' }
      ];

      const updatedSequence = sequence + keyPressed;

      // Check for valid sequences
      const validSequence = validSequences.find(item => item.sequence === updatedSequence);
      
      if (validSequence && !isInputFocused) {
        navigate(validSequence.path);
        setSequence(''); // Reset sequence after navigation
      } else if (!validSequences.some(item => item.sequence.startsWith(updatedSequence))) {
        // Reset sequence if the current input does not match any valid sequence prefix
        setSequence('');
      } else {
        // Update sequence with the valid prefix to continue building
        setSequence(updatedSequence);
      }
    };

    const handleKeyUp = () => {
      // Reset sequence on key up to avoid accidental sequences
      setSequence('');
      setXClicked(false);
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [sequence, navigate]);

  return xClicked // Hook doesn't render any components
}

export default useKeyboardShortcuts
