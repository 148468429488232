import { Dayjs } from 'dayjs'
import Http from '../app/store/core/inceptor'

export const getMaintenanceList = (ended:boolean,per_page:number,page:number) => Http.get(`/maintenance-schedules?${ended?"ended=true":" "}&length=${per_page}&start=${(+page)* +per_page} `)

export const getServersList = () => {
  return Http.get('/groups?append=devices')
}

export const createMaintenance = (data: {
  description: string
  start: number |null|undefined
  end: number |null|undefined
  
}) => Http.post('/maintenance-schedules/add', {...data})

export const AddCheckMaintenance = (id: string, data: any) =>
  Http.put(`/maintenance-schedules/${id}/bulk`, data)

export const getMaintenanceById = (id: string) => Http.get(`/maintenance-schedules/${id}`)

export const editMaintenance = (
  id: string,
  data: {
    description: string
    start: number |null|undefined
    end: number |null|undefined
  }
) => Http.put(`/maintenance-schedules/${id}`, data)

export const removeMaintenance=(id:string)=>Http.delete(`/maintenance-schedules/${id}`)
