import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as auth from '../../app/modules/auth'
import * as config from '../../app/modules/config/redux/ConfigRedux'
import watcherServerSaga from '../../app/store/serverStore/saga/sagaHandler'
import serversReducer from "../../app/store/serverStore/reducer";
import groupReducer from "../../app/store/groupStore/reducer";
import upTimeReducer from "../../app/store/upTimeStore/reducer";
import serverOverviewReducer from "../../app/store/serverOverview/reducer"

import watcherGroupSaga from '../../app/store/groupStore/saga/sagaHandler';
import watcherUpTimeSaga from '../../app/store/upTimeStore/saga/sagaHandler'
import requestServerDetailHandler from '../../app/store/serverOverview/saga/sagaHandler'
import commonReducer from '../../app/store/commonReducer'
import statisticReducer from '../../app/store/serverOverview/statisticReducer'
import watcherStatisticSaga from '../../app/store/serverOverview/saga/sagaStatisticRequestsHandler'
import watcherServerTriggerSaga from '../../app/store/serverOverview/saga/sagaTriggerRequestHandler'
import triggerReducer from '../../app/store/serverOverview/triggerReducer'


import incidentReducer from '../../app/store/incident/reducer';
import NotificationRoles from '../../app/store/notificationRoles/reducer';

import billingReducer from '../../app/store/billing/reducer';

import watcherIncidentSaga from '../../app/store/incident/saga/sagaHandler'
import watcherNotificationRolesSaga from '../../app/store/notificationRoles/saga/sagaHandler'
import watcherBillingSaga from '../../app/store/billing/saga/sagaHandler'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  config: config.configReducer,
  servers: serversReducer,
  groups: groupReducer,
  upTime: upTimeReducer,
  serverView: serverOverviewReducer,
  commonReducer: commonReducer,
  statisticReducer: statisticReducer,
  triggerReducer: triggerReducer,
  notificationRolesReducer: NotificationRoles,
  incident:incidentReducer,
  billingReducer:billingReducer


})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(),
  watcherServerSaga(),
  watcherGroupSaga(),
  watcherUpTimeSaga(),
  requestServerDetailHandler(),
  watcherStatisticSaga(),
  watcherServerTriggerSaga(),
  watcherNotificationRolesSaga()
  ,
    watcherIncidentSaga(),
    watcherBillingSaga()
  ])
}
