import styled from '@emotion/styled'
import React, {useState, MouseEvent, useEffect } from 'react'

import {
  getDayArray,
  getDayWeekArray,
  getHourArray,
  getLastMonthData,
  getMonthArray,
  HourObject,
} from '../../../helpers/helperFunctions'
import Tooltip, {tooltipClasses} from '@mui/material/Tooltip'
import {FormattedMessage, useIntl} from 'react-intl'
import useUser from '../../Hooks/useUser'

export  const BootstrapTooltip = styled(({ className, backgroundColor, ...props }: any) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme, backgroundColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor||'#2b2b2b',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor || '#2b2b2b', // Use the provided backgroundColor or default
    fontSize: '12px',
  },
}));

interface IUptimeWidget {
  range: 'day' | 'week' | 'month' | 'year' | 'last_month'
  uptime: Record<string, any>
  mode?: 'table' | 'block'
  isPause?: boolean
  upColor?: string
  downColor?: string
  pauseColor?: string
  maintenanceColor?: string
  customColor?: boolean
  isMaintenance?:boolean
  tooltipbg?:string

}

interface DateTimeProps {
  date: string
  uptime: number | string
  downtime: string[]
  sumDowntime:string

}

export  const DateTimeComponent: React.FC<DateTimeProps> = ({date, uptime, downtime,sumDowntime}) => {
  
  return (
    <div className='fs-7'>
      <p className='text-white mb-2'>{date}</p>
      <hr className=' text-white bg-white m-0 my-2' />
      <p className=' mb-2'>
        <strong className='text-success'>
          <FormattedMessage id='MENU.UPTIME' />
        </strong>{' '}
        : {uptime} %
      </p>
      <p className=' mb-2'>
        <strong className='text-danger'>
          <FormattedMessage id='LABELS.UPTIME_DOWNTIME' />
        </strong>{' '}
        : {sumDowntime} 
        {downtime.length === 0 && 0}
      </p>
      <p className=' mb-2'>
        <strong className='text-danger'>
          <FormattedMessage id='LABELS.INCIDENT' />
        </strong>{' '}
        : <span>{downtime?.[0]}</span>
        {downtime.length - 1 >= 1 && (
          <p className='fw-bold mt-3'>
            <FormattedMessage id='LABELS.MORE.INCIDENT' values={{len: downtime.length - 1}} />
          </p>
        )}
        {downtime.length === 0 && "-"}
      </p>
    </div>
  )
}

const BlockTime = React.forwardRef(function (
  props: HourObject & {
    onMouseEnter?: (event: MouseEvent<HTMLSpanElement>) => void
    isPause: boolean
    upColor?: string
    downColor?: string
    pauseColor?: string
    maintenanceColor?: string
    customColor?: boolean
    isMaintenance?:boolean
  },
  ref: any
) {
  const {
    isActive,
    onMouseEnter,
    downtime,
    isPause,
    customColor,
    downColor,
    upColor,
    maintenanceColor,
    pauseColor,
    isMaintenance,
    ...rest

  } = props

  return (
    <div
      {...rest}
      onMouseEnter={onMouseEnter}
      className={`flex-fill   h-20px  ${
      !customColor?(  isPause
        ? 'bg-secondary'
        : isActive && !!downtime?.length
        ? 'bg-danger'
        : isActive
        ? 'bg-success'
        : 'bg-success opacity-25 '):""
      } `}
      style={{
        borderRadius: '2px',
        ...(customColor
          ? {
              backgroundColor:(isMaintenance&&isActive) ? maintenanceColor: isPause
                ? pauseColor
                : isActive && !!downtime?.length
                ? downColor
                : isActive
                ? upColor
                : 'rgba(0,0,0,0.1)',
            }
          : {}),
      }}
      ref={ref}
    ></div>
  )
})

const UptimeWidget = (props: IUptimeWidget) => {
  const {
    range,
    uptime,
    mode = 'block',
    isPause,
    customColor,
    downColor,
    maintenanceColor,
    pauseColor,
    upColor,
    isMaintenance,
    tooltipbg
    
  } = props
  const [tooltipText, setTooltipText] = useState('')
  const intl = useIntl()
  const {timezone} = useUser()


  const handleBlockHover = (data: string) => {
    setTooltipText(data)
  }
  const [list, setList] = useState<HourObject[]>([])

  useEffect(() => {
    
    if (mode === 'block') {
      if (range === 'day') {
        setList(
          getHourArray(uptime?.created_at * 1000, uptime?.periods?.today?.incidents, intl, timezone)
        )
      }
      if (range === 'week') {
        setList(
          getDayWeekArray(
            uptime?.created_at * 1000,
            uptime?.periods?.this_week?.incidents,
            intl,
            timezone
          )
        )
      }

      if (range === 'month') {
        setList(
          getDayArray(
            uptime?.created_at * 1000,
            uptime?.periods?.this_month?.incidents,
            intl,
            timezone
          )
        )
      }

      if (range === 'year') {
        setList(
          getMonthArray(
            uptime?.created_at * 1000,
            uptime?.periods?.this_year?.incidents,
            intl,
            timezone
          )
        )
      }
    } else {
      if (range === 'day') {
        setList(getHourArray(uptime?.created_at_timestamp * 1000, uptime?.incidents, intl, timezone))
      }
      if (range === 'week') {
        setList(
          getDayWeekArray(uptime?.created_at_timestamp * 1000, uptime?.incidents, intl, timezone)
        )
      }

      if (range === 'month') {
        setList(getDayArray(uptime?.created_at_timestamp * 1000, uptime?.incidents, intl, timezone))
      }

      if (range==="last_month") {
        setList(getLastMonthData(uptime?.created_at_timestamp * 1000, uptime?.incidents, intl, timezone))
        
      }

      if (range === 'year') {
        setList(
          getMonthArray(uptime?.created_at_timestamp * 1000, uptime?.incidents, intl, timezone)
        )
      }
    }
  }, [range, JSON.stringify(uptime),timezone])

  return (
    <div className='d-flex gap-1 position-relative zindex-tooltip w-100'>
      {!!list.length &&
        list.map((uptimeData) => {
          return uptimeData?.isActive && !isPause ? (
            <BootstrapTooltip
              key={uptimeData.date + Math.random()}
              backgroundColor={tooltipbg}
              title={
                <DateTimeComponent
                  date={tooltipText}
                  uptime={uptimeData.uptime}
                  downtime={uptimeData.downtime}
                  sumDowntime={uptimeData.sumDowntime}
                />
              }
              placement='top-start'
            >
              <BlockTime
                key={uptimeData.date + Math.random()}
                {...uptimeData}
                onMouseEnter={() => handleBlockHover(uptimeData.date)}
                isPause={!!isPause}
                upColor={upColor}
                downColor={downColor}
                pauseColor={pauseColor}
                maintenanceColor={maintenanceColor}
                customColor={customColor}
                isMaintenance={isMaintenance}
              />
            </BootstrapTooltip>
          ) : (
            <BlockTime
              key={uptimeData.date + Math.random()}
              {...uptimeData}
              isPause={!!isPause}
              onMouseEnter={() => handleBlockHover(uptimeData.date)}
              upColor={upColor}
              downColor={downColor}
              pauseColor={pauseColor}
              maintenanceColor={maintenanceColor}
              customColor={customColor}
              isMaintenance={isMaintenance}
            />
          )
        })}
    </div>
  )
}

export default React.memo(UptimeWidget)
