import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = 'client/profile'

const patchProfileDetailUpdate = (body:any) => {
  return Http.patch(url,body)
}

const ProfileDetailUpdate = () => {
  return useMutation(patchProfileDetailUpdate)
}

export default ProfileDetailUpdate
