import { useEffect } from 'react';

const useSearchShortcuts = (openModal: () => void, closeModal: () => void) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey ) && event.key === 'k') {
        event.preventDefault();
        openModal();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openModal, closeModal]);
};

export default useSearchShortcuts;
