import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {shallowEqual} from 'react-redux'
import {injectWidgets} from '../../../../helpers/helperFunctions'
import {RootState} from '../../../../setup'
import {stringToBoolean} from '../../../../_metronic/helpers'
import {CheckWidgets} from '../../../modules/auth/core/AccessWidgets'
import {UserModel} from '../../../modules/auth/models/UserModel'
import useDashboardValues, {DashboardTypes} from '../../../modules/context/DashboardCtx'
import IconButton from '../../../modules/global/IconButton'
import classes from '../style.module.scss'

const listOfWidgets = [
  {
    id: 'widget-1',
    title: 'LAST.ACTIVE.TICKETS',
    icon: 'fas fa-ticket-alt',
  },
  {
    id: 'widget-2',
    title: 'RECENT.SESSIONS',
    icon: 'fas fa-laptop',
  },
  {
    id: 'widget-3',
    title: 'LABELS.RESOLVED_INCIDENTS',
    icon: 'fas fa-check-square',
  },
  {
    id: 'widget-4',
    title: 'SERVERS.HIGH.UPTIME',
    icon: 'fas fa-server',
  },
  {
    id: 'widget-5',
    title: 'TOTAL.INCIDENTS',
    icon: 'far fa-chart-bar',
  },
  {
    id: 'widget-6',
    title: 'TOP.MEMORY.DASHBOARD',
    icon: 'far fa-hdd',
  },
  {
    id: 'widget-7',
    title: 'LAST.LOGIN',
    icon: 'fas fa-sign-in-alt',
  },
  {
    id: 'widget-8',
    title: 'TOP.DISK',
    icon: 'far fa-save',
  },
  {
    id: 'widget-9',
    title: 'LABELS.OPEN_INCIDENTS',
    icon: 'fas fa-exclamation-triangle',
  },
  {
    id: 'widget-10',
    title: 'SUM.CREDITS',
    icon: 'far fa-credit-card',
  },
  {
    id: 'widget-11',
    title: 'LABELS.ACCOUNT_BALANCE',
    icon: 'fas fa-file-invoice-dollar',
  },
  {
    id: 'widget-12',
    title: 'INVOICES',
    icon: 'fas fa-file-invoice',
  },
  {
    id: 'widget-13',
    title: 'RECENT.QOUTATIONS',
    icon: 'fa fa-calculator',
  },
  {
    id: 'widget-14',
    title: 'TOP.CPU.DASHBOARD',
    icon: 'fas fa-microchip',
  },
  {
    id: 'widget-15',
    title: 'RECENT.ORDERS',
    icon: 'fas fa-shopping-cart',
  },
  {
    id: 'widget-16',
    title: 'REMAINING.SMS',
    icon: 'fas fa-mobile-alt',
  },
  {
    id: 'widget-17',
    title: 'SERVERS.LEAST.UPTIME',
    icon: 'fas fa-server',
  },
  {
    id: 'widget-18',
    title: 'LABELS.TOTAL.CHECKS.STATUS',
    icon: 'far fa-check-circle',
  },
  {
    id: 'widget-19',
    title: 'LABELS.CHECK.MOST.DOWNTIME',
    icon: 'far fa-check-circle',
  },
  {
    id: 'widget-20',
    title: 'LABELS.SSL_EXPIRING_SOON',
    icon: 'fa-solid fa-lock',
  },
]

const SettingDrawer = () => {
  const [state, dispatch] = useDashboardValues()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const userWidgets = injectWidgets({
    ...user?.widgets,
  })

  const AccessWidgets = CheckWidgets(
    {
      ...userWidgets,
    },
    user
  )

  const clickHandler = (id: string) => {
    if (stringToBoolean(state.widgets?.[id]?.status)) {
      dispatch({
        type: DashboardTypes.DISABLE,
        payload: id,
      })
      return
    }
    dispatch({
      type: DashboardTypes.ENABLE,
      payload: id,
    })
  }

  const resetHand = () => {
    dispatch({
      type: DashboardTypes.RESET,
    })
  }

  const intl = useIntl()
  return (
    <div
      id='kt_help'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='help'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'100%', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_help_toggle'
      data-kt-drawer-close='#kt_help_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header px-6' id='kt_help_header'>
          <h5 className='card-title fw-bold text-dark'>
            {intl.formatMessage({id: 'WIDGET.SETTINGS'})}
          </h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_help_close'
            >
              <IconButton icon='fas fa-times' iconSize='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-0 pt-4' id='kt_help_body'>
          {/* begin::Content */}
          <div
            id='kt_help_scroll'
            className='pb-6 h-100'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
            style={{height:'100% !important'}}
          >
            <div className='d-flex flex-column justify-content-between h-100'>
            <Row
              className='gx-10 gy-4 pt-2'
              style={{maxHeight: '80% !important', overflow: 'auto'}}
            >
              {listOfWidgets?.map((widget, index) => {
                if (!AccessWidgets[widget.id]?.hasAccess) {
                  return
                }
                return (
                  <Col xs={6} key={widget.id + index + '_'}>
                    <div
                      className={`d-flex align-items-center bg-hover-success  text-hover-light p-2  rounded h-100 ${
                        stringToBoolean(state.widgets?.[widget.id]?.status)
                          ? 'bg-success text-white'
                          : 'bg-light-secondary'
                      }`}
                      onClick={() => clickHandler(widget.id)}
                    >
                      {/* begin::Icon */}
                      <div
                        className={`d-flex flex-center w-30px h-30px w-lg-40px h-lg-40px fs-2  flex-shrink-0 rounded  ${widget.icon} `}
                        style={{backgroundColor: 'inherit'}}
                      ></div>
                      {/* end::Icon */}
                      {/* begin::Info */}
                      <div className='d-flex flex-stack flex-grow-1 ms-2 position-relative'>
                        {stringToBoolean(state.widgets?.[widget.id]?.status) && (
                          <span
                            style={{left: '95%'}}
                            className='position-absolute  translate-middle   '
                          >
                            <span className='far fa-check-circle fs-3' />
                          </span>
                        )}

                        {/* begin::Wrapper */}
                        <div className='d-flex flex-column me-3 me-lg-5'>
                          {/* begin::Title */}
                          <span className={` fw-bolder fs-7 ${classes['custom_wdget_drawer']}`}>
                            {intl.formatMessage({id: widget.title})}
                          </span>
                          {/* end::Title */}
                        </div>
                        {/* end::Wrapper */}
                      </div>
                      {/* end::Info */}
                      {/* <div>
                      <span className="badge bg-secondary">New</span>
                      </div> */}
                    </div>
                  </Col>
                )
              })}
            </Row>
            {(!('parent' in user) || !('access' in user)) && (
              <button className='btn btn-lg btn-primary w-100 my-4' onClick={resetHand}>
                {intl.formatMessage({id: 'WIDGET.RESET'})}
              </button>
              )}
            </div>
          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {SettingDrawer}
