import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {getServersLeastUptime} from '../../../services/dashboard'
import {TablesWidget1} from '../../../_metronic/partials/widgets'
import classes from "./style.module.scss"

interface Props {
  onRemove: () => void
}

const ServersLeastUptime = ({onRemove}: Props) => {
  const intl = useIntl()

  const {data: uptimes, isLoading}: any = useQuery(
    ['dashboard-least-uptime'],
    getServersLeastUptime
  )

  const filteredUptime=uptimes?.filter((up:any)=>up?.uptime_percentage!=100)??[]

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <TablesWidget1
        isLoading={isLoading}
        title={intl.formatMessage({id: 'SERVERS.LEAST.UPTIME'})}
        icon={<span className='fas fa-server fs-1' />}
        onClose={onClose}
        dataLength={filteredUptime?.length}
        className={classes['custom_table']}

        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{maxWidth: '10%', width: '10%'}}>
              {intl.formatMessage({id:"LABELS.OS"})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{maxWidth: '35%', width: '35%'}}>
              {intl.formatMessage({id: 'LABELS.LABEL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{maxWidth: '40%', width: '40%'}}>
              {intl.formatMessage({id: 'LABELS.TOTAL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{maxWidth: '15%', width: '15%'}}>
            </th>
          </tr>
        }
      >
        {!!filteredUptime?.length &&
          filteredUptime.map((upt: Record<string, any>, index: number) => (
            <tr key={upt?.device + upt?.uptime_percentage}>
              <td>
                <div className='symbol symbol-40px me-2'>
                  <span className='symbol-label'>
                    <span
                      className={`${upt?.platform == 0 ? 'fab fa-linux ' : 'fab fa-windows '}fs-3`}
                    />
                  </span>
                </div>
              </td>

              <td>
                <span className='text-dark fw-bold  mb-1 fs-6'>
                  <span
                    className='text-truncate d-block text-dark fw-bold mb-1 fs-7'
                    data-tip
                    data-for={'server-least-' + index}
                    style={{maxWidth: '90%'}}
                  >
                    {upt?.device_label}
                  </span>
                  {upt?.device_label?.length >= 10 && (
                    <ReactTooltip id={'server-least-' + index} className='tooltip-wrapper'>
                      {upt?.device_label}
                    </ReactTooltip>
                  )}
                </span>
                <span className='text-muted fw-semobold d-block fs-7'>
                  {upt?.platform == 0 ? 'Linux' : 'Windows'}
                </span>
              </td>
              <td className='p-0'>
                <div className='d-flex flex-column w-100 me-2'>
                  <div className='d-flex flex-stack mb-2'>
                    <span className='text-dark me-2 fs-7 fw-semobold'>
                      {upt?.uptime_percentage}%
                    </span>
                  </div>
                  <div className='progress h-6px w-100'>
                    <div
                      className='progress-bar bg-primary'
                      role='progressbar'
                      style={{width: `${upt?.uptime_percentage}%`}}
                    ></div>
                  </div>
                </div>
              </td>
              <td className='text-end p-0'>
                <Link
                  to={`/servers/${upt?.device}/overview`}
                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                >
                  <span className='fas fa-arrow-right fs-4' />
                </Link>
              </td>
            </tr>
          ))}
      </TablesWidget1>
    </div>
  )
}

export default React.memo(ServersLeastUptime)
