import * as React from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import {useIntl} from 'react-intl'
import IconButton from '../IconButton'

interface IConfirmPopover {
  onConfirm: () => any
  children: React.ReactNode
  text ?:React.ReactNode
}

export default function ConfirmPopover(props: IConfirmPopover) {
  const intl = useIntl()


  const {onConfirm, children ,text=intl.formatMessage({id: 'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SURE.DESCRIPTION'})} = props


  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const onClickConfirm = () => {
    onConfirm()
    handleClose()
  }

  return (
    <>
      <div aria-describedby={id} onClick={handleClick}>
        {children}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <section className='p-2 ' style={{maxWidth:"250px"}}>
          <h6 className='text-center'>
            {text}
          </h6>
          <div className='d-flex gap-2 justify-content-center mt-2'>
            <IconButton iconSize='fs-3' icon='fas fa-times' onClick={handleClose} />

            <IconButton iconSize='fs-3' icon='fas fa-check ' onClick={onClickConfirm} />
          </div>
        </section>
      </Popover>
    </>
  )
}
