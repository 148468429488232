import { call, put, takeEvery } from "redux-saga/effects";
import { getIncidents } from "./requests";
import {
    types
  } from "../actionTypes";
interface Response{
    data: any
  }
  function* requestIncidents(action:any) {
    try {
      const response: Response  = yield call(getIncidents,action.query);
      yield put(
        {
            type: types.GET_INCIDENTS_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_INCIDENTS_ERROR,
          }
      );
    }
  }

  function* watcherIncidentSaga() {
    yield takeEvery(types.GET_INCIDENTS, requestIncidents)
    yield takeEvery(types.GET_INCIDENTS_REFETCH, requestIncidents)
  }
  
  export default watcherIncidentSaga