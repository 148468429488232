import {
    types
  } from "./actionTypes";


  export const getServerDetailAction = (params:any) => ({
    type: types.GET_SERVER,
    params
  });

  export const getServerDetailRefetchAction = (params:any) => ({
    type: types.GET_SERVER_REFETCH,
    params
  });

  
  export const getServerIpsAction = (params:any) => ({
    type: types.GET_SERVER_ip,
    params
  });


  export const getServerUptimAction = (params:any) => ({
    type: types.GET_SERVER_UPTIME,
    params
  });
  
  
  export const getServerIncidentAction = (params:any) => ({
    type: types.GET_SERVER_INCIDENT,
    params
  });

   
  export const reqGetChecksAction = (serverId:any) => ({
    type: types.REQUEST_GET_CHECKS,
    serverId
  });
  

    
  export const reqTestAddNewCheckAction = (params:any) => ({
    type: types.REQUEST_TEST_ADD_NEW_CHECK,
    params
  });
  
  
    
  export const reqAddNewCheckAction = (params:any,id:any) => ({
    type: types.REQUEST_ADD_NEW_CHECK,
    params,id
  });
  
  
    
  export const reqRemoveCheckAction = (serverId:any,checkId:any) => ({
    type: types.REQUEST_REMOVE_CHECK,
    serverId,checkId
  });
  
  
  export const reqPauseCheckAction = (serverId:any,checkId:any,param:any) => ({
    type: types.REQUEST_PAUSE_CHECKS,
    serverId,checkId,param
  });
  
  
  export const reqUnpauseCheckAction = (serverId:any,checkId:any) => ({
    type: types.REQUEST_UNPAUSE_CHECKS,
    serverId,checkId
  });
  
  
  
  export const selectCheckToEditAction = (check:any) => ({
    type: types.SELECT_CHECK_TO_EDIT,
    check
  });
  
  export const requestUpdateCheckAction = (serverId:any,checkId:any,params:any) => ({
    type: types.REQUEST_UPDATE_CHECKS,
    serverId,checkId,params
  });
  
  export const requestCpuLoadAverageGraphAction = (serverId:any,period:string) => ({
    type: types.REQUEST_GRAPH_CPU_LOAD_AVERAGE,
    serverId,period
  });

  export const requestCpuUsageGraphAction = (serverId:any,period:string) => ({
    type: types.REQUEST_GRAPH_CPU_USAGE,
    serverId,period
  });
  
  export const requestDiskUsageGraphAction = (serverId:any,period:string) => ({
    type: types.REQUEST_GRAPH_DISK_USAGE,
    serverId,period
  });
  
  export const requestMemoryUsageGraphAction = (serverId:any,period:string) => ({
    type: types.REQUEST_GRAPH_MEMORY_USAGE,
    serverId,period
  });
  
  export const requestDiskIoGraphAction = (serverId:any,period:string) => ({
    type: types.REQUEST_GRAPH_DISK_IO,
    serverId,period
  });
  
  export const requestNetworkGraphAction = (serverId:any,period:string,port:string) => ({
    type: types.REQUEST_GRAPH_NETWORK,
    serverId,period,port
  });
  
  export const requestCheckGraphAction = (serverId:any,checkId:number,graphName:string,period:string) => ({
    type: types.REQUEST_SERVER_CHECK_GRAPH,period,
    serverId,checkId,graphName
  });



  export const requestServerMetricTriggerAction = (serverId:any) => ({
    type: types.REQUEST_SERVER_TRIGGERS,
    serverId
  });

  
  export const requestServerChecksTriggerAction = (serverId:any) => ({
    type: types.REQUEST_SERVER_CHECKS_TRIGGERS,
    serverId
  });

  
  
  export const requestCreateTriggerAction = (serverId:any,params:any) => ({
    type: types.REQUEST_CREATE_TRIGGERS,
    serverId,params
  });

  
  export const requestDeleteTriggerAction = (serverId:any,triggerId:number) => ({
    type: types.REQUEST_DELETE_TRIGGERS,
    serverId,triggerId
  });

  
  export const requestUpdateTriggerAction = (serverId:any,triggerId:number,params:any) => ({
    type: types.REQUEST_UPDATE_TRIGGERS,
    serverId,triggerId,params
  });

  
  export const requestCreateCheckTriggerAction = (serverId:any,checkId:number,params:any) => ({
    type: types.REQUEST_CREATE_CHECK_TRIGGERS,
    serverId,checkId,params
  });

  export const requestUpdateCheckTriggerAction = (serverId:any,checkId:number,triggerId:number,params:any) => ({
    type: types.REQUEST_UPDATE_CHECK_TRIGGERS,
    serverId,checkId,triggerId,params
  });

  
  export const requestDeleteCheckTriggerAction = (serverId:any,checkId:number,triggerId:number) => ({
    type: types.REQUEST_DELETE_CHECK_TRIGGERS,
    serverId,checkId,triggerId
  });


  export const resetTriggerProps = () => ({
    type: types.RESET_Graph_PROPS,
  });

  export const resetGraphProps = () => ({
    type: types.RESET_Graph_PROPS,
  });

  export const resetServerDetailProps = () => ({
    type: types.RESET_SERVICE_OVERVIEW_PROPS,
  });
  
  