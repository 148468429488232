export const types = {
    GET_GROUPS: 'GET_GROUPS',
    GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
    GET_GROUPS_ERROR: 'GET_GROUPS_ERROR',
    RESET_PROPS: 'RESET_PROPS',
  
    ADD_GROUP: 'ADD_GROUP',
    ADD_GROUP_SUCCESS: 'ADD_GROUP_SUCCESS',
    ADD_GROUP_ERROR: 'ADD_GROUP_ERROR',

    
    UPDATE_GROUP: 'UPDATE_GROUP',
    UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
    UPDATE_GROUP_ERROR: 'UPDATE_GROUP_ERROR',

    
    DELETE_GROUP_REQUEST: 'DELETE_GROUP_REQUEST',
    DELETE_GROUP_REQUEST_SUCCESS: 'DELETE_GROUP_REQUEST_SUCCESS',
    DELETE_GROUP_REQUEST_ERROR: 'DELETE_GROUP_REQUEST_ERROR',

    
    ASSIGN_GROUP_SERVERS_REQUEST: 'ASSIGN_GROUP_SERVERS_REQUEST',
    ASSIGN_GROUP_SERVERS_REQUEST_SUCCESS: 'ASSIGN_GROUP_SERVERS_REQUEST_SUCCESS',
    ASSIGN_GROUP_SERVERS_REQUEST_ERROR: 'ASSIGN_GROUP_SERVERS_REQUEST_ERROR',

    
    UNASSIGN_GROUP_SERVERS_REQUEST: 'UNASSIGN_GROUP_SERVERS_REQUEST',
    UNASSIGN_GROUP_SERVERS_REQUEST_SUCCESS: 'UNASSIGN_GROUP_SERVERS_REQUEST_SUCCESS',
    UNASSIGN_GROUP_SERVERS_REQUEST_ERROR: 'UNASSIGN_GROUP_SERVERS_REQUEST_ERROR',
  }
  