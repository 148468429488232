import { _axios } from "../app/store/core/inceptor";



export const Api = {
    getList: (perPage?: number) => _axios.get(`/keys`, {
        params: {
            ...(!!perPage && { "per_page":perPage }),
        }
    }),
    add: (params: any) => {

        if ('expire_at' in params && params['expire_at'] == '') {
            delete params['expire_at'];
        } else if (typeof  params['expire_at'] === 'string') {
            params['expire_at'] = +params['expire_at'];
        }

        return _axios.post(`/keys`, params)
    },
    delete: (id: number) => _axios.delete(`/keys/${id}`),
};