import React from 'react'
import {Spinner} from 'react-bootstrap'

interface ISpinner {
  isLoading: boolean
  height?: string
  bgClass?:string
}

const WaveSpinner = ({isLoading = false, height = '150px',bgClass="bg-body"}: ISpinner) => {
  return (
    <>
      {isLoading && (
        <div
          className={`text-center d-flex justify-content-center align-items-center ${bgClass}`}
          style={{height}}
        >
          <Spinner variant='primary' animation='grow' />
        </div>
      )}
    </>
  )
}

export default WaveSpinner
