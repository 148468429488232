import React from 'react'
import {useIntl} from 'react-intl'
import ReactTooltip from 'react-tooltip'
import useIsTrial from '../../Hooks/useIsTrial'
import useIsUserExperimentalFree from '../../Hooks/useIsUserExperimentalFree'
import useMainConfig from '../../Hooks/useMainConfig'
import useActionControll from '../../Hooks/useActionControll'

const PaidBadge = ({
  justFree,
  place = 'top',
  margin,
  experimentalFree=false,
  text,
  customNode,
  limit = null
}: {
  justFree?: boolean
  place?: 'top' | 'right' | 'bottom' | 'left'
  margin?: string
  experimentalFree?: boolean
  text?:string
  customNode?: React.ReactNode
  limit?:boolean | null
}) => {
  const isTrial = useIsTrial()
  const {is_free_plan, isPlanFullyFree} = useMainConfig()
  const intel = useIntl()
  const isUserExperimentalFree=useIsUserExperimentalFree()

  const status = justFree ? isPlanFullyFree : is_free_plan
  const id = 'plan.info' + Math.random()
  const experimental = experimentalFree ? isUserExperimentalFree : null
  
  return (
    <>
      {((experimental||limit) ?? status) ? (
        <>
          <div
            data-for={id}
            data-tip
            className={`d-inline ${!margin ? 'mx-2' : margin}`}
            style={{height:!customNode? '20px':"auto"}}
          >
           {customNode? customNode: <span className='far fa-gem fs-2' style={{color: '#FFD700'}} />}
          </div>

          <ReactTooltip id={id} place={place}>
            {text ? text: intel.formatMessage({id: 'PAID.PLAN.INFO'})}
          </ReactTooltip>
        </>
      ) : null}
    </>
  )
}

export default PaidBadge
