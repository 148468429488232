// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface ILoadTableParams {
    page: number
    perPage: number
    sort: string
}

export function loadTableParams({page, perPage, sort}: any) {

    let id='id';
    let desc = true;

    if (sort?.[0]) {
        id = sort[0]?.id ?? '';
        desc = sort[0]?.desc ?? '';
    }

    sort = `${id}.${desc ? "desc" : "asc"}`;

    let data: any = {'query': `per_page=${perPage}&page=${page}&sort=${sort}`, 'headers': {}};

    //all pages
    if (perPage === -1) {
        data['query'] = `sort=${sort}`
        data['headers'] = {'X-Total-Count': true}
    }

    return data;
}