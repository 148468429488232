import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import useUser from './useUser'

type AccessType = {
  api: boolean
  report: boolean
  billing: boolean
  tickets: boolean
  [key: string]: boolean // Add more access keys if needed
}

type ProfileType = {
  parent?: number
  access: AccessType
}

const useAccessControl = (accessName: string, falseHandler?: () => void): boolean => {
  const [hasAccess, setHasAccess] = useState(false)
  const profile = useUser()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!profile.parent || profile.full_access === 1) {
      if (location.pathname?.includes('sub-accounts') && profile.parent) {
        setHasAccess(false)
        falseHandler?.()
        navigate('/403')
      } else {
        // Parent user has full access
        setHasAccess(true)
      }
    } else {
      // Sub account user

      if (location.pathname?.includes('sub-accounts')) {
        setHasAccess(false)

        falseHandler?.()
      }

      if (!!profile.access && !!profile.access?.[accessName]) {
        setHasAccess(true)
      } else {
        setHasAccess(false)

        falseHandler?.()
      }
    }
  }, [accessName, profile])

  return hasAccess
}

export default useAccessControl
