
import { call, put, takeEvery } from 'redux-saga/effects'
import {
  deleteTrigger,
    getServerChecksTriggers,
    getServerMetricTriggers,
    postCreateCheckTrigger,
    postCreateTrigger,
    postDeleteCheckTrigger,
    postUpdateCheckTrigger,
    updateTrigger,

} from './requests';
import { types } from '../actionTypes'

interface Response {
  data: any
}
function* requestGetMetricTriggersHandler(action: any) {
  try {
    const response: Response = yield call(getServerMetricTriggers, action.serverId)
    yield put({
      type: types.REQUEST_SERVER_TRIGGERS_SUCCESS,
      response: response.data
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_SERVER_TRIGGERS_ERROR,
      action: e?.data?.message
    })
  }
}
 
function* requestGetChecksTriggersHandler(action: any) {
    try {
      const response: Response = yield call(getServerChecksTriggers, action.serverId)
      yield put({
        type: types.REQUEST_SERVER_CHECKS_TRIGGERS_SUCCESS,
        response: response.data
      })
    } catch (e: any) {
      yield put({
        type: types.REQUEST_SERVER_CHECKS_TRIGGERS_ERROR,
        action: e?.data?.message
      })
    }
  }

  
function* requestCreateTriggersHandler(action: any) {
  try {
    const response: Response = yield call(postCreateTrigger, action.serverId,action.params)
    yield put({
      type: types.REQUEST_CREATE_TRIGGERS_SUCCESS,
      response: response.data
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_CREATE_TRIGGERS_ERROR,
      action: e
    })
  }
}

  
function* requestDeleteTriggersHandler(action: any) {
  try {
    const response: Response = yield call(deleteTrigger, action.serverId,action.triggerId)
    yield put({
      type: types.REQUEST_DELETE_TRIGGERS_SUCCESS,
      response: response.data
    })
  } catch (e: any) {
    
    yield put({
      type: types.REQUEST_DELETE_TRIGGERS_ERROR,
      action: e
    })
  }
}


function* requestUpdateTriggersHandler(action: any) {
  try {
    const response: Response = yield call(updateTrigger, action.serverId,action.triggerId,action.params)
    yield put({
      type: types.REQUEST_UPDATE_TRIGGERS_SUCCESS,
      response: response.data
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_UPDATE_TRIGGERS_ERROR,
      action: e
    })
  }
}

function* requestCreateCheckTriggersHandler(action: any) {
  try {
    const response: Response = yield call(postCreateCheckTrigger, action.serverId,action.checkId,action.params)
    yield put({
      type: types.REQUEST_CREATE_CHECK_TRIGGERS_SUCCESS,
      response: response.data
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_CREATE_CHECK_TRIGGERS_ERROR,
      action: e
    })
  }
}

function* requestUpdateCheckTriggersHandler(action: any) {
  try {
    const response: Response = yield call(postUpdateCheckTrigger, action.serverId,action.checkId,action.triggerId,action.params)
    yield put({
      type: types.REQUEST_UPDATE_CHECK_TRIGGERS_SUCCESS,
      response: response.data
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_UPDATE_CHECK_TRIGGERS_ERROR,
      action: e
    })
  }
}


function* requestDeleteCheckTriggersHandler(action: any) {
  try {
    const response: Response = yield call(postDeleteCheckTrigger, action.serverId,action.checkId,action.triggerId)
    yield put({
      type: types.REQUEST_DELETE_CHECK_TRIGGERS_SUCCESS,
      response: response.data
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_DELETE_CHECK_TRIGGERS_ERROR,
      action: e
    })
  }
}



function* watcherServerTriggerSaga() {
  yield takeEvery(types.REQUEST_SERVER_TRIGGERS, requestGetMetricTriggersHandler)
  yield takeEvery(types.REQUEST_SERVER_CHECKS_TRIGGERS, requestGetChecksTriggersHandler)
  yield takeEvery(types.REQUEST_CREATE_TRIGGERS, requestCreateTriggersHandler)
  yield takeEvery(types.REQUEST_DELETE_TRIGGERS, requestDeleteTriggersHandler)
  yield takeEvery(types.REQUEST_UPDATE_TRIGGERS, requestUpdateTriggersHandler)
  yield takeEvery(types.REQUEST_CREATE_CHECK_TRIGGERS, requestCreateCheckTriggersHandler)
  yield takeEvery(types.REQUEST_DELETE_CHECK_TRIGGERS, requestDeleteCheckTriggersHandler)
  yield takeEvery(types.REQUEST_UPDATE_CHECK_TRIGGERS, requestUpdateCheckTriggersHandler)


}

export default watcherServerTriggerSaga
