import {
    types
  } from "./actionTypes";

  const initialState: any = {
    pending: false,
    success:false,
    error: null,
    list:[]
  };
  

  // eslint-disable-next-line import/no-anonymous-default-export
  export default (state = initialState, action: any) => {
    switch (action.type) {
      case types.GET_INCIDENTS:
        return {
          ...state,
          pending: true,
        };
        case types.GET_INCIDENTS_REFETCH:
        return {
          ...state,
          pending: false,
        };
      case types.GET_INCIDENTS_SUCCESS:
          
        return {
          ...state,
          pending: false,
          list:action.payload.data,
          full:action.payload,
          error: null
        };
      case types.GET_INCIDENTS_ERROR:
        return {
          ...state,
          pending: false,
           error: action.error,
        };

          case types.RESET_PROPS:
          return {
            ...state,
            pending: false,
            success:false,
             error: null,
          };


      default:
        return {
          ...state,
        };
    }
  };