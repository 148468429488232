import React ,{useEffect} from 'react'
import { PasswordMeterComponent } from '../../../_metronic/assets/ts/components'

const PasswordMeter = ({value}:{value:string}) => {

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
      }, [value])
    
  return (
    <div className='d-flex align-items-center my-3' data-kt-password-meter-control='highlight'>
      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
      <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
    </div>
  )
}

export default PasswordMeter
