import React, {useState, useEffect} from 'react'
import { useIntl } from 'react-intl'
import {useQuery} from 'react-query'
import {getGroups} from '../../../../services/uptime'
import MultipleSelect from './MultipleSelect'

interface IGroupMultipleSelect {
  value: any
  handleChange: (values: any) => void
  size?:string
}

const GroupMultipleSelect = (props: IGroupMultipleSelect) => {
  const intl=useIntl()
  const {value, handleChange,size} = props
  const {data: groupList, isLoading} = useQuery(['groups-list'], getGroups,{
  })
  const [options, setOptions] = useState<any>([])

  useEffect(() => {
    if (groupList?.data?.length) {
      const arrayTemp: any = []
      groupList?.data?.map((group: Record<string, any>) => {
        arrayTemp.push({title: group?.name, value: group?.id})
        if (group?.subgroups?.length) {
          group?.subgroups?.map((subGp: Record<string, any>) => {
            arrayTemp.push({title: subGp?.name, value: subGp?.id,isSub:true})
          })
        }
      })
      arrayTemp.push({title: 'Ungrouped', value: 0})
      setOptions(arrayTemp)
    }
  }, [isLoading])

  return <MultipleSelect isLoading={isLoading} value={value} options={options} onChangeHandler={handleChange}
  size={size}
  placeHolder={intl.formatMessage({id:"LABELS.SELECT.GROUPS"})}
  
  />
}

export default GroupMultipleSelect
