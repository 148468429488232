import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import ReactTooltip from 'react-tooltip'
import {getQoutations} from '../../../services/dashboard'
import {TablesWidget3} from '../../../_metronic/partials/widgets'
import classes from './style.module.scss'

interface Props {
  onRemove: () => void
}

const RecentQoutations = ({onRemove}: Props) => {
  const intl = useIntl()

  const {data: qoutations, isLoading} = useQuery(['dashboard-quotations'], getQoutations)

  const onClose = () => {
    onRemove()
  }
  return (
    <div>
      <TablesWidget3
        onClose={onClose}
        isLoading={isLoading}
        title={intl.formatMessage({id: 'RECENT.QOUTATIONS'})}
        className={classes['custom_table']}
        dataLength={qoutations?.data?.length}
        icon={<span className='fa fa-calculator fs-1' />}
        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '15%'}}>
              ID
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '35%'}}>
              {intl.formatMessage({id: 'LABELS.TITLE'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '25%'}}>
              {intl.formatMessage({id: 'VALID.UNTIL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '15%'}}>
              {intl.formatMessage({id: 'LABELS.TOTAL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '10%'}}></th>
          </tr>
        }
      >
        {!!qoutations?.data?.length &&
          qoutations?.data.map((qt: Record<string, any>, index: number) => (
            <tr key={qt?.id}>
              <td className='text-dark fw-semobold'>{qt?.id}</td>
              <td>
                <div>
                  <span
                    className='text-truncate d-block text-dark fw-semobold mb-1 fs-7'
                    data-tip
                    data-for={'qt-' + index}
                    style={{width: '14ch'}}
                  >
                    {qt?.title}
                  </span>
                  {qt?.title?.length >= 14 && (
                    <ReactTooltip id={'qt-' + index} className='tooltip-wrapper'>
                      {qt?.title}
                    </ReactTooltip>
                  )}
                </div>
              </td>
              <td className='text-dark fw-semobold'>{qt?.valid_until}</td>
              <td className='text-dark fw-semobold'>
                <span className='fas fa-dollar-sign me-1' />
                {qt?.total}
              </td>
              <td className='text-end p-0'>
                <a
                  href={`https://${process.env.REACT_APP_WS_HOST}/client/quotations/${qt?.id}?pdf=1`}
                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                  target={'_blank'}
                >
                  <span className='fas fa-arrow-right fs-4' />
                </a>
              </td>
            </tr>
          ))}
      </TablesWidget3>
    </div>
  )
}
//
export default React.memo(RecentQoutations)
