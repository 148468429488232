import { FC } from "react";
import { Col, Row } from "react-bootstrap";


type Props = { styles?: any, width?: number, height?: number, fill?: string }

export const CopySVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
        </svg>
    );
};

export const ArrowUpSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" />
        </svg>
    );
};

export const NoteTextSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M14,10H19.5L14,4.5V10M5,3H15L21,9V19A2,2 0 0,1 19,21H5C3.89,21 3,20.1 3,19V5C3,3.89 3.89,3 5,3M5,12V14H19V12H5M5,16V18H14V16H5Z" />
        </svg>
    );
};


export const CheckedSVG: FC<Props> = ({ width = 24, height = 24, fill, styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
        </svg>
    );
};

export const CheckedBoldSVG: FC<Props> = ({ width = 24, height = 24, fill, styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
        </svg>
    );
};


export const CloseSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M19,3H16.3H7.7H5A2,2 0 0,0 3,5V7.7V16.4V19A2,2 0 0,0 5,21H7.7H16.4H19A2,2 0 0,0 21,19V16.3V7.7V5A2,2 0 0,0 19,3M15.6,17L12,13.4L8.4,17L7,15.6L10.6,12L7,8.4L8.4,7L12,10.6L15.6,7L17,8.4L13.4,12L17,15.6L15.6,17Z" />
        </svg>
    );
};

export const ExclamationSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M10 3H14V14H10V3M10 21V17H14V21H10Z" />
        </svg>
    );
};


export const ArrowUpDownSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M9,3L5,7H8V14H10V7H13M16,17V10H14V17H11L15,21L19,17H16Z" />
        </svg>
    );
};

export const ArrowUpSmallSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M7.03 9.97H11.03V18.89L13.04 18.92V9.97H17.03L12.03 4.97Z" />
        </svg>
    );
};


export const ArrowDownSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M7.03 13.92H11.03V5L13.04 4.97V13.92H17.03L12.03 18.92Z" />
        </svg>
    );
};

export const ArrowLeftSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M10.05 16.94V12.94H18.97L19 10.93H10.05V6.94L5.05 11.94Z" />
        </svg>
    );
};


export const ArrowRightSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M14 16.94V12.94H5.08L5.05 10.93H14V6.94L19 11.94Z" />
        </svg>
    );
};


export const PlusSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </svg>
    );
};


export const PauseSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M14,19H18V5H14M6,19H10V5H6V19Z" />
        </svg>
    );
};

export const UnPauseSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M8,5.14V19.14L19,12.14L8,5.14Z" />
        </svg>
    );
};


export const RadioBlankSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
        </svg>
    );
};


export const RadioMarkedSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" />
        </svg>
    );
};




export const EditSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M5,3C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19H5V5H12V3H5M17.78,4C17.61,4 17.43,4.07 17.3,4.2L16.08,5.41L18.58,7.91L19.8,6.7C20.06,6.44 20.06,6 19.8,5.75L18.25,4.2C18.12,4.07 17.95,4 17.78,4M15.37,6.12L8,13.5V16H10.5L17.87,8.62L15.37,6.12Z" />
        </svg>
    );
};



export const TrashSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
        </svg>
    );
};


export const UnCheckSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z" />
        </svg>
    );
};


export const CautionSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (


        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
        </svg>
    );
};


export const OpenInNewSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z" />
        </svg>
    );
};


export const ChevronDoubleRightSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M5.59,7.41L7,6L13,12L7,18L5.59,16.59L10.17,12L5.59,7.41M11.59,7.41L13,6L19,12L13,18L11.59,16.59L16.17,12L11.59,7.41Z" />
        </svg>
    );
};


export const ChevronDoubleDownSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M16.59,5.59L18,7L12,13L6,7L7.41,5.59L12,10.17L16.59,5.59M16.59,11.59L18,13L12,19L6,13L7.41,11.59L12,16.17L16.59,11.59Z" />
        </svg>
    );
};

export const TSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M9,7H15V9H13V17H11V9H9V7M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2M12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20A8,8 0 0,0 20,12A8,8 0 0,0 12,4Z" />
        </svg>
    );
};


export const UserSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z" />
        </svg>
    );
};


export const MailSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z" />
        </svg>
    );
};


export const MobileSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z" />
        </svg>
    );
};


export const RefreshSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
        </svg>
    );
};


export const ExpandSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M9.5,13.09L10.91,14.5L6.41,19H10V21H3V14H5V17.59L9.5,13.09M10.91,9.5L9.5,10.91L5,6.41V10H3V3H10V5H6.41L10.91,9.5M14.5,13.09L19,17.59V14H21V21H14V19H17.59L13.09,14.5L14.5,13.09M13.09,9.5L17.59,5H14V3H21V10H19V6.41L14.5,10.91L13.09,9.5Z" />
        </svg>
    );
};


export const MinusSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M20 14H4V10H20" />
        </svg>
    );
};


export const BillingSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (


        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" >


            <g transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                <path d="M 74.635 55.709 c -1.002 0 -1.993 -0.251 -2.896 -0.746 c -1.688 -0.924 -2.845 -2.616 -3.094 -4.527 c -0.143 -1.096 0.63 -2.099 1.726 -2.241 c 1.097 -0.149 2.099 0.63 2.241 1.726 c 0.085 0.657 0.468 1.217 1.048 1.534 c 0.482 0.264 1.032 0.324 1.56 0.17 c 0.524 -0.153 0.958 -0.503 1.221 -0.982 c 0.543 -0.991 0.178 -2.238 -0.813 -2.78 l -3.89 -2.233 c -2.89 -1.581 -3.968 -5.264 -2.367 -8.189 c 1.603 -2.926 5.283 -4.005 8.21 -2.403 c 1.689 0.924 2.847 2.617 3.096 4.527 c 0.143 1.096 -0.63 2.099 -1.726 2.242 c -1.087 0.137 -2.099 -0.63 -2.241 -1.725 c -0.085 -0.658 -0.468 -1.217 -1.049 -1.535 c -0.993 -0.542 -2.238 -0.177 -2.781 0.814 c -0.541 0.99 -0.176 2.237 0.814 2.78 l 3.89 2.233 c 2.889 1.581 3.967 5.264 2.366 8.189 c -0.776 1.417 -2.057 2.447 -3.607 2.901 C 75.779 55.628 75.205 55.709 74.635 55.709 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
                <path d="M 74.66 38.293 c -1.104 0 -2 -0.896 -2 -2 v -2.71 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 2.71 C 76.66 37.397 75.765 38.293 74.66 38.293 z" strokeLinecap="round" />
                <path d="M 74.66 58.417 c -1.104 0 -2 -0.896 -2 -2 v -2.71 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 2.71 C 76.66 57.521 75.765 58.417 74.66 58.417 z" strokeLinecap="round" />
                <path d="M 65.928 90 H 20.04 c -5.918 0 -10.732 -4.814 -10.732 -10.732 V 10.732 C 9.308 4.814 14.122 0 20.04 0 h 45.888 C 71.846 0 76.66 4.814 76.66 10.732 v 14.709 c 0 1.104 -0.896 2 -2 2 s -2 -0.896 -2 -2 V 10.732 C 72.66 7.02 69.64 4 65.928 4 H 20.04 c -3.712 0 -6.732 3.02 -6.732 6.732 v 68.535 c 0 3.712 3.02 6.732 6.732 6.732 h 45.888 c 3.712 0 6.732 -3.021 6.732 -6.732 V 64.559 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 14.709 C 76.66 85.186 71.846 90 65.928 90 z" transform=" matrix(1 0 0 1 0 0) " strokeLinecap="round" />
                <path d="M 60.489 66.087 h -27.42 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 27.42 c 1.104 0 2 0.896 2 2 S 61.594 66.087 60.489 66.087 z" strokeLinecap="round" />
                <path d="M 60.489 54.159 h -27.42 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 27.42 c 1.104 0 2 0.896 2 2 S 61.594 54.159 60.489 54.159 z" strokeLinecap="round" />
                <path d="M 60.489 42.231 h -27.42 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 27.42 c 1.104 0 2 0.896 2 2 S 61.594 42.231 60.489 42.231 z" strokeLinecap="round" />
                <path d="M 60.489 30.303 h -27.42 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 27.42 c 1.104 0 2 0.896 2 2 S 61.594 30.303 60.489 30.303 z" strokeLinecap="round" />
                <path d="M 26.875 66.087 h -1.396 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.396 c 1.104 0 2 0.896 2 2 S 27.979 66.087 26.875 66.087 z" strokeLinecap="round" />
                <path d="M 26.875 54.159 h -1.396 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.396 c 1.104 0 2 0.896 2 2 S 27.979 54.159 26.875 54.159 z" strokeLinecap="round" />
                <path d="M 26.875 42.231 h -1.396 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.396 c 1.104 0 2 0.896 2 2 S 27.979 42.231 26.875 42.231 z" strokeLinecap="round" />
                <path d="M 26.875 30.303 h -1.396 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.396 c 1.104 0 2 0.896 2 2 S 27.979 30.303 26.875 30.303 z" strokeLinecap="round" />
            </g>
        </svg>
    );
};


export const BalanceSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M13 20V8.8C13.5 8.6 14 8.3 14.3 7.9L17.8 9.2L14.9 16C14.4 18 15.9 19 18.4 19S22.5 18 21.9 16L19.3 9.7L20.2 10L20.9 8.1L15 6C15 4.8 14.3 3.6 13 3.1C11.8 2.6 10.5 3.1 9.7 4L3.9 2L3.2 3.8L4.8 4.4L2.1 11C1.6 13 3.1 14 5.6 14S9.7 13 9.1 11L6.6 5.1L9 6C9 7.2 9.7 8.4 11 8.9V20H2V22H22V20H13M19.9 16H16.9L18.4 12.2L19.9 16M7.1 11H4.1L5.6 7.2L7.1 11M11.1 5.7C11.3 5.2 11.9 4.9 12.4 5.1S13.2 5.9 13 6.4 12.2 7.2 11.7 7 10.9 6.2 11.1 5.7Z" />
        </svg>
    );
};


export const ShopIngSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (
        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M17,18C15.89,18 15,18.89 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20C19,18.89 18.1,18 17,18M1,2V4H3L6.6,11.59L5.24,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42A0.25,0.25 0 0,1 7.17,14.75C7.17,14.7 7.18,14.66 7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.58 17.3,11.97L20.88,5.5C20.95,5.34 21,5.17 21,5A1,1 0 0,0 20,4H5.21L4.27,2M7,18C5.89,18 5,18.89 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20C9,18.89 8.1,18 7,18Z" />
        </svg>
    );
};


export const SMSSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M11,17V7H4V17H11M11,3A2,2 0 0,1 13,5V19A2,2 0 0,1 11,21H4C2.89,21 2,20.1 2,19V5A2,2 0 0,1 4,3H11M16.5,3H21.5A1.5,1.5 0 0,1 23,4.5V7.5A1.5,1.5 0 0,1 21.5,9H18L15,12V9L15,4.5A1.5,1.5 0 0,1 16.5,3Z" />
        </svg>
    );
};


export const MoveSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M13,6V11H18V7.75L22.25,12L18,16.25V13H13V18H16.25L12,22.25L7.75,18H11V13H6V16.25L1.75,12L6,7.75V11H11V6H7.75L12,1.75L16.25,6H13Z" />
        </svg>
    );
};


export const EyeSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z" />
        </svg>
    );
};




export const MenuSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
        </svg>
    );
};


export const WarningSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
        </svg>
    );
};



export const DeleteSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
        </svg>
    );
};


export const PauseFillSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M15,16H13V8H15M11,16H9V8H11M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
        </svg>
    );
};

export const TVSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (


        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M21,17H3V5H21M21,3H3A2,2 0 0,0 1,5V17A2,2 0 0,0 3,19H8V21H16V19H21A2,2 0 0,0 23,17V5A2,2 0 0,0 21,3Z" />
        </svg>
    );
};



export const AlertSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
        </svg>
    );
};


export const SettingSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M15.9,18.45C17.25,18.45 18.35,17.35 18.35,16C18.35,14.65 17.25,13.55 15.9,13.55C14.54,13.55 13.45,14.65 13.45,16C13.45,17.35 14.54,18.45 15.9,18.45M21.1,16.68L22.58,17.84C22.71,17.95 22.75,18.13 22.66,18.29L21.26,20.71C21.17,20.86 21,20.92 20.83,20.86L19.09,20.16C18.73,20.44 18.33,20.67 17.91,20.85L17.64,22.7C17.62,22.87 17.47,23 17.3,23H14.5C14.32,23 14.18,22.87 14.15,22.7L13.89,20.85C13.46,20.67 13.07,20.44 12.71,20.16L10.96,20.86C10.81,20.92 10.62,20.86 10.54,20.71L9.14,18.29C9.05,18.13 9.09,17.95 9.22,17.84L10.7,16.68L10.65,16L10.7,15.31L9.22,14.16C9.09,14.05 9.05,13.86 9.14,13.71L10.54,11.29C10.62,11.13 10.81,11.07 10.96,11.13L12.71,11.84C13.07,11.56 13.46,11.32 13.89,11.15L14.15,9.29C14.18,9.13 14.32,9 14.5,9H17.3C17.47,9 17.62,9.13 17.64,9.29L17.91,11.15C18.33,11.32 18.73,11.56 19.09,11.84L20.83,11.13C21,11.07 21.17,11.13 21.26,11.29L22.66,13.71C22.75,13.86 22.71,14.05 22.58,14.16L21.1,15.31L21.15,16L21.1,16.68M6.69,8.07C7.56,8.07 8.26,7.37 8.26,6.5C8.26,5.63 7.56,4.92 6.69,4.92A1.58,1.58 0 0,0 5.11,6.5C5.11,7.37 5.82,8.07 6.69,8.07M10.03,6.94L11,7.68C11.07,7.75 11.09,7.87 11.03,7.97L10.13,9.53C10.08,9.63 9.96,9.67 9.86,9.63L8.74,9.18L8,9.62L7.81,10.81C7.79,10.92 7.7,11 7.59,11H5.79C5.67,11 5.58,10.92 5.56,10.81L5.4,9.62L4.64,9.18L3.5,9.63C3.41,9.67 3.3,9.63 3.24,9.53L2.34,7.97C2.28,7.87 2.31,7.75 2.39,7.68L3.34,6.94L3.31,6.5L3.34,6.06L2.39,5.32C2.31,5.25 2.28,5.13 2.34,5.03L3.24,3.47C3.3,3.37 3.41,3.33 3.5,3.37L4.63,3.82L5.4,3.38L5.56,2.19C5.58,2.08 5.67,2 5.79,2H7.59C7.7,2 7.79,2.08 7.81,2.19L8,3.38L8.74,3.82L9.86,3.37C9.96,3.33 10.08,3.37 10.13,3.47L11.03,5.03C11.09,5.13 11.07,5.25 11,5.32L10.03,6.06L10.06,6.5L10.03,6.94Z" />
        </svg>
    );
};


export const WrenchSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M19.94 17.94C19.96 17.79 19.97 17.65 19.97 17.5S19.96 17.2 19.94 17.05L20.91 16.32C21 16.25 21.03 16.13 20.97 16.03L20.05 14.47C20 14.37 19.86 14.33 19.76 14.37L18.61 14.82C18.37 14.65 18.12 14.5 17.83 14.38L17.66 13.19C17.64 13.08 17.54 13 17.43 13H15.58C15.46 13 15.36 13.08 15.34 13.19L15.17 14.38C14.88 14.5 14.63 14.65 14.39 14.82L13.24 14.37C13.14 14.33 13 14.37 12.96 14.47L12.03 16.03C11.97 16.13 12 16.25 12.09 16.32L13.06 17.05C13.05 17.2 13.03 17.35 13.03 17.5S13.05 17.79 13.06 17.94L12.09 18.68C12 18.75 11.97 18.87 12.03 18.97L12.96 20.53C13 20.63 13.14 20.66 13.24 20.63L14.39 20.17C14.63 20.35 14.88 20.5 15.17 20.62L15.34 21.81C15.36 21.92 15.46 22 15.58 22H17.43C17.54 22 17.64 21.92 17.66 21.81L17.83 20.62C18.12 20.5 18.37 20.35 18.61 20.17L19.76 20.63C19.86 20.66 20 20.63 20.05 20.53L20.97 18.97C21.03 18.87 21 18.75 20.91 18.68L19.94 17.94M16.5 19C15.67 19 15 18.33 15 17.5S15.67 16 16.5 16 18 16.67 18 17.5 17.33 19 16.5 19M9 2C10.8 2.6 12 4.3 12 6.2C12 8.2 10.8 9.9 9 10.5V21.5C9 21.8 8.8 22 8.5 22H6.5C6.2 22 6 21.8 6 21.4V10.4C4.2 9.8 3 8.1 3 6.2S4.2 2.6 6 2V5.7H9V2Z" />
        </svg>
    );
};


export const ChartSVG: FC<Props> = ({ width = 24, height = 24, fill = "#000", styles }) => {
    return (

        <svg style={{ ...styles, width, height, verticalAlign: "middle" }} viewBox="0 0 24 24">
            <path fill={fill} d="M17.45,15.18L22,7.31V19L22,21H2V3H4V15.54L9.5,6L16,9.78L20.24,2.45L21.97,3.45L16.74,12.5L10.23,8.75L4.31,19H6.57L10.96,11.44L17.45,15.18Z" />
        </svg>
    );
};




const SVGViewer = () => {


    return (

        <Row>
            <Col lg={1} xs={3} >
                <div className="p-2">
                    <div>
                        <CopySVG />
                    </div>
                    <div className="px-2" >CopySVG</div>
                </div>

            </Col>
        </Row>
    )

}