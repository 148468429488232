import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormattedMessage, useIntl } from "react-intl";
import { notify } from "../../../../../helpers";
import { IAddApiKey } from "../../../client/setting/SettingsModel";
import { Api } from "../../../../../services/Api";
import classes from "./ApiKeyAdd.module.css";

const AddApiSchema = Yup.lazy(() => Yup.object().shape({
  title: Yup.string().required().min(3).max(50)
}));

interface IApiAdd {
  onReloadApis?: any
}

const ApiAdd = ({onReloadApis}: IApiAdd) => {
  const intl = useIntl();
  const initValues: IAddApiKey = { title: "", expire_at: "" };
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: AddApiSchema,
    onSubmit: (values) => {
      setLoading(true);

      Api.add(values)
        .then((response) => {
          
          formik.resetForm();

          setLoading(false);

          onReloadApis();

          notify({
            type: "success",
            message: intl.formatMessage(
              {
                id: "NOTIFICATIONS.ITEM_ADDED",
              },
              { name: values.title }
            ),
          });
        })
        .catch(({ data }) => {
          setLoading(false);

          let messages = [];

          for (let i in data) {
            messages.push(data[i]);
          }

          notify({ type: "error", message: messages.join("\n") });
        });
    },
  });

  useEffect(() => {
    const validation_messages = Object(intl?.messages?.VALIDATORS);
    Yup.setLocale({ ...validation_messages });
  }, [])

  return (
    <div className="card mb-5 mb-xl-10">
      <form onSubmit={formik.handleSubmit} noValidate className="form">
        <div className="card-body border-top p-9">
          <div className="row mb-6">
            <label className="col-lg-4 col-form-label required fw-bold fs-6">
              <FormattedMessage id="LABELS.TITLE" />
            </label>

            <div className="col-lg-8 fv-row">
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                {...formik.getFieldProps("title")}
              />
              {formik.touched.title && formik.errors.title && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.title}</div>
                </div>
              )}
            </div>
          </div>

          <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-bold fs-6">
              <FormattedMessage id="ACCOUNT.APIS.LABELS.EXPIRE" />
            </label>

            <div className="col-lg-8 fv-row">
              <select
                className="form-select form-select-solid form-select-lg"
                {...formik.getFieldProps("expire_at")}
              >
                <option value="86400">1 Day</option>
                <option value="604800">1 Week</option>
                <option value="2592000">1 Month</option>
                <option value="31449600">1 Year</option>
                <option value="62899200">2 Years</option>
                <option value="">Never</option>
              </select>
            </div>
          </div>

          <button
            type="submit"
            className={`btn btn-primary ${classes.button}`}
            disabled={loading}
          >
            {!loading && (
              <FormattedMessage id="ACCOUNT.APIS.BUTTONS.GENERATE" />
            )}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                <FormattedMessage id="ACTIONS.WAIT" />{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>

        <div className="card-footer d-flex justify-content-end py-6 px-9"></div>
      </form>
    </div>
  );
};

export default ApiAdd;
