import React, { FC } from 'react'
import { Spinner } from 'react-bootstrap'

const MiniSpinner:FC<{animation: "border" | "grow",variant:string,style?:any}> = 
({animation="border",variant="light",style={verticalAlign:'middle',width:'20px',height:'20px'}}) => {
  return (
    <Spinner animation={animation} variant={variant} style={{...style}}/>
  )
}

export default MiniSpinner