import * as React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CircularProgress, {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress'

interface ICircularProgressMui {
  size?: number
  value: number
}

export default function CircularProgressMui(props: ICircularProgressMui) {
  const {size, value: PropsValue} = props
  const [value, setValue] = React.useState(PropsValue??0)

  React.useEffect(() => {
    setValue(PropsValue)
  }, [PropsValue])

  return (
    <Box sx={{position: 'relative'}}>
      <CircularProgress
        variant='determinate'
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={size}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant='determinate'
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',

            color:value<=30 ? "#f1416c":value>30&&value<=80? "#ffc700" :  '#50cd89',
          },
        }}
        size={size}
        thickness={4}
        {...props}
        value={value}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='caption' component='div' className='text-white fs-7'>{`${Math.round(
          value
        )}%`}</Typography>
      </Box>
    </Box>
  )
}
