/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import clsx from 'clsx'
import {handleOpenFeedback} from '../../../../app/store/commonAction'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {isHasAccess} from '../../../../helpers/isHasAccess'
import useIsDigitalOceanUser from '../../../../app/Hooks/useIsDigitalOceanUser'
import {useNavigate} from 'react-router-dom'
import {useQuery} from 'react-query'
import {getDownIncidents} from '../../../../services/incidentList'
import {useSocket} from '../../../../socket/client/client'
import useKeyboardShortcuts from '../../../../app/Hooks/useKeyboardShortcuts'
import useCustomizedDashboardValues from '../../../../app/modules/context/CustomizedDashboardCtx'
interface IAsideMenuMain {
  xClicked:boolean
}

export function AsideMenuMain({xClicked}:IAsideMenuMain) {
  const intl = useIntl()
  const {data: incidents, refetch} = useQuery(['down-incidents-count'], getDownIncidents, {
    cacheTime: 0,
    staleTime: 0,
    keepPreviousData: true,
  })

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const isDigitalOcean = useIsDigitalOceanUser()
  const navigate = useNavigate()

  const defaultDash = localStorage.getItem('defaultDash')
  const dashboardLocation = !!defaultDash ? `/customized-dashboard/${defaultDash}` : '/dashboard'

  const incidentHandler = (e: any) => {
    refetch()
  }

  useSocket({
    type: 'CLIENT_BROWSER_NOTIFICATION',
    callBack: incidentHandler,
  })

  return (
    <>
      <AsideMenuItem
        to={dashboardLocation}
        icon='/media/icons/duotune/general/gen008.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
        activeShortcut={xClicked}
        keyId={1}
      />

      <AsideMenuItem
        to='/new'
        title={intl.formatMessage({id: 'MENU.NEW.MONITORING'})}
        icon='/media/icons/duotune/general/gen035.svg'
        fontIcon='bi-person'
        activeShortcut={xClicked}
        keyId={2}
      />
      <AsideMenuItem
        to='/incidents'
        icon='/media/icons/duotune/general/gen044.svg'
        title={
          <section className='d-flex align-items-center gap-2'>
            {intl.formatMessage({id: 'MENU.INCIDENTS'})}{' '}
            {!!incidents?.data?.count && (
              <span className='badge badge-danger text-white '>{incidents?.data?.count}</span>
            )}
          </section>
        }
        activeShortcut={xClicked}
        keyId={3}
        fontIcon='bi-app-indicator'
        // hasAccess={isHasAccess(null,user)}
      />
      <AsideMenuItem
        to='/servers'
        icon='/media/icons/duotune/general/gen019.svg'
        title={intl.formatMessage({id: 'MENU.SERVERS'})}
        activeShortcut={xClicked}
        keyId={4}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='/metrics'
        icon='/media/icons/duotune/graphs/gra002.svg'
        title={intl.formatMessage({id: 'LABELS.METRICS'})}
        fontIcon='bi-app-indicator'
        keyId={5}
      />
      <AsideMenuItem
        to='/uptime'
        icon='/media/icons/duotune/general/gen013.svg'
        title={intl.formatMessage({id: 'LABELS.UPTIME'})}
        activeShortcut={xClicked}
        keyId={6}
        fontIcon='bi-app-indicator'
        // hasAccess={isHasAccess(null,user)}
      />
      <AsideMenuItem
        to='/ssl'
        icon='/media/icons/duotune/general/gen047.svg'
        title={intl.formatMessage({id: 'LABELS.CREATE.SSL.TITLE'})}
        activeShortcut={xClicked}
        keyId={7}
        fontIcon='bi-app-indicator'
        // hasAccess={isHasAccess(null,user)}
      />
      <AsideMenuItem
        to='/trigger-management'
        icon='/media/icons/duotune/coding/cod009.svg'
        title={intl.formatMessage({id: 'LABELS.TRIGGER.MANAGEMENT'})}
        activeShortcut={xClicked}
        keyId={8}
        fontIcon='bi-app-indicator'
        // hasAccess={isHasAccess(null,user)}
      
      />
      <AsideMenuItem
        to='/notificationRoles'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.NOTIFICATION_ROLES'})}
        activeShortcut={xClicked}
        keyId={9}
        fontIcon='bi-app-indicator'
        hasAccess={isHasAccess('notification_roles', user)}
      />
      <AsideMenuItem
        to='/public-status-page'
        icon='/media/icons/duotune/medicine/med001.svg'
        title={intl.formatMessage({id: 'PUBLIC.STATUS'})}
        activeShortcut={xClicked}
        keyId={'0'}
        fontIcon='bi-app-indicator'
        hasAccess={isHasAccess('status_pages', user)}
      />
      <AsideMenuItem
        to='/maintenance'
        icon='/media/icons/duotune/general/gen014.svg'
        title={intl.formatMessage({id: 'MAINTENANCE.TITLE'})}
        activeShortcut={xClicked}
        fontIcon='bi-app-indicator'
        hasAccess={isHasAccess('maintenance_schedule', user)}
      />
      {/* <AsideMenuItem
        to='/reports'
        icon='/media/icons/duotune/files/fil001.svg'
        title={intl.formatMessage({id: 'MENU.REPORTS'})}
        activeShortcut={xClicked}
        fontIcon='bi-app-indicator'
        hasAccess={isHasAccess('report_email', user)}
      /> */}
      <AsideMenuItemWithSub
        to='/reports'
        icon='/media/icons/duotune/files/fil001.svg'
        title={intl.formatMessage({id: 'LABELS.REPORTS'})}
        fontIcon='bi-app-indicator'
        // hasAccess={isHasAccess(null,user)}
        children={
          <>
            <AsideMenuItem
              to='/reports'
              icon='/media/icons/duotune/communication/com002.svg'
              title={intl.formatMessage({id: 'MENU.REPORTS'})}
              activeShortcut={xClicked}
              fontIcon='bi-app-indicator'
              hasAccess={isHasAccess('report_email', user)}
              isChild
            />
            <AsideMenuItem
              to='/reports/uptime'
              icon='/media/icons/duotune/general/gen005.svg'
              title={intl.formatMessage({id: 'LABELS.UPTIME_REPORTS'})}
              activeShortcut={xClicked}
              fontIcon='bi-app-indicator'
              isChild
              // hasAccess={isHasAccess(null,user)}
            />
         
          </>
        }
      />
      <AsideMenuItem
        to='/account'
        title={intl.formatMessage({id: 'MENU.ACCOUNT'})}
        icon='/media/icons/duotune/communication/com006.svg'
        activeShortcut={xClicked}
        fontIcon='bi-person'
      />
      <AsideMenuItem
        to={'/billing'}
        icon='/media/icons/duotune/finance/fin007.svg'
        title={intl.formatMessage({id: 'MENU.SIDE_BILLING'})}
        activeShortcut={xClicked}
        fontIcon='bi-app-indicator'
        hasAccess={isHasAccess('billing', user)}
        handLerFunc={() => {
          if (isDigitalOcean) {
            window.open('https://cloud.digitalocean.com/add-ons', '_blank')
          } else {
            navigate('/billing')
          }
        }}
      />
      <AsideMenuItem
        to='/tickets'
        icon='/media/icons/duotune/general/gen011.svg'
        title={intl.formatMessage({id: 'MENU.TICKETS'})}
        activeShortcut={xClicked}
        fontIcon='bi-app-indicator'
        hasAccess={isHasAccess('tickets', user)}
      />
    </>
  )
}
