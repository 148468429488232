/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, getProfile, getToken} from '../core/_requests'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {notify} from '../../../../helpers'
import {useIntl} from 'react-intl'
import * as configRedux from '../../config/redux/ConfigRedux'
import * as authMain from '../../auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {getMainServiceAction} from '../../../store/billing/actions'
import Bugsnag from '@bugsnag/js'
import Swal from 'sweetalert2'
import OAuths from './OAuths'
import { useLocalStorage } from '../../../Hooks/useLocalStorage'
import { Global } from '../../../../services/Global'
import { getDashboards } from '../../../../services/customizedDashboard'

const initialValues = {
  loginName: '',
  password: '',
  rememberMe: false,
  no_redirect: 'true',
}

type LocationState = {
  from: string
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const [loginNameValue, setloginNameValue] = useState<String>('')
  const dispatch = useDispatch()
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const stateUrl = searchParams.get('state')
  const zapierUrl = searchParams.get('zapier')
  const cliUrl = searchParams.get('cli_login')
  const location = useLocation()
  const locationState = location.state as LocationState
  const [zapierValue, setZapierValue] = useLocalStorage<any>('zapier-integration', {})
  const [cliValue] = useLocalStorage<any>('cli-integration', {})


  useEffect(() => {
    if (stateUrl === 'sendLink') {
      Swal.fire({
        icon: 'success',
        text: intl.formatMessage({id: 'FORGET.PASS.MSG'}),
        confirmButtonColor: '#06d2dd',
        customClass: {
          // Define your custom CSS class here
          popup: 'my-sweet-alert-bg',
        },
      })
    }
  }, [])

  useEffect(() => {
    if (stateUrl === 'sendPass') {
      Swal.fire({
        icon: 'success',
        text: intl.formatMessage({id: 'LABELS.RESET.PW.TEXT'}),
        confirmButtonColor: '#06d2dd',
        customClass: {
          // Define your custom CSS class here
          popup: 'my-sweet-alert-bg',
        },
      })
    }
  }, [])

  const ROUTER_POSTFIX = process.env.REACT_APP_ROUTER_POSTFIX || '/'

  const checkDefaultAndReturnId = (items:any) => {
    const item = items.find((item: any) => item.default === true || item.default === 1);
    if (!!item) {
      localStorage.setItem('defaultDash', item.id)
    }
    return item ? item.id : false;
  }

  const getProfileHandler = async () => {
    try {
      const profile = await getProfile()
      const customizedDashboards = await getDashboards()
      
      dispatch(authMain.actions.setUser(profile?.data))
      dispatch(
        authMain.actions.setWizard({
          completedSteps: profile?.data?.wizard
            ? JSON.parse(profile?.data?.wizard)?.completedSteps
            : {
                email_confirmation: false,
                monitoring: false,
                sub_account: false,
                notification_roles: false,
                status_page: false,
                report_email: false,
                complete_profile: false,
                upgrade_plan: false,
                mobile_app: false,
              },
          // numberOfStepsCompleted: profile?.data?.wizard
          //   ? JSON.parse(profile?.data?.wizard)?.numberOfStepsCompleted
            // : [],
        })
      )
      if (profile?.data) {
        setCurrentUser(profile?.data)
        dispatch(getMainServiceAction())
        Bugsnag.addMetadata('userInformation', {
          id: profile.data.id,
          username: profile.data.username,
          email: profile.data.email,
        })
      }
      const dashId = checkDefaultAndReturnId(customizedDashboards)
      const dashboardLocation = await !!dashId ? `/customized-dashboard/${dashId}` : '/dashboard'
      if (!!zapierUrl || !!zapierValue.params) {        
        navigate(`/zapier${location.search || zapierValue.params}`,{state:{access:zapierUrl}})
        
      } else if (!!cliUrl || !!cliValue.params){
        navigate(`/cli${location.search || cliValue.params}`,{state:{access:cliUrl}})

      } else if (profile?.data?.getting_started) {
        // const url = ROUTER_POSTFIX + '/new'
        // window.location.href = url
        navigate(locationState.from ?? '/wizard')
      } else {
        // const url = ROUTER_POSTFIX
        // window.location.href = url
        navigate(locationState.from ?? dashboardLocation)
      }
    } catch (err: any) {
      const error = err.response && (err.response || err.message)
      let data = error?.data
      console.log('data error ', data)
      setLoading(false)
    }
  }
 
  const loginSchema = Yup.object().shape({
    loginName: loginNameValue.includes('@')
      ? Yup.string()
          .email('Wrong email format')
          .min(3, 'Minimum 3 symbols')
          .max(50, 'Maximum 50 symbols')
          .required('Email/username is required')
      : Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols'),
    password: Yup.string()
      .min(8, 'Minimum 8 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        const response = await login(values.loginName, values.password, values.rememberMe)
        const token =response?.data.token||response?.data
        dispatch(authMain.actions.login(token))
        if (response?.data?.status === '2fa_required') {
          navigate(`/auth/2fa?rememberMe=${!!values.rememberMe}`)
          setLoading(false)
        } else if (response?.data?.status === 'unconfirmed_email_contact') {
          setStatus(intl.formatMessage({id: 'LABELS.UNCONFIRM.EMAIL.CONTACT'}))
          setLoading(false)
          return
        } else {
         await getProfileHandler()
         const configDate= await Global.getConfig()
         dispatch(configRedux.configActions.setConfig(configDate.data))
        
        }
      } catch (err: any) {
        setLoading(false)
        saveAuth(undefined)
        
        setSubmitting(false)
        const error = err.response && (err.response || err.message)
        let data = error?.data
        if (data?.provider!=='digitalocean' &&data?.provider!=='null'&&data?.provider!==null ) {
            setStatus(intl.formatMessage({id:"LABELS.LOGIN.FAILD.PROVIDER"},{provider:data?.provider}))
        } 
        else {
          setStatus(intl.formatMessage({id:"LABELS.LOGIN.FAILD"}))
        }
        if (!data) {
          return notify({
            type: 'error',
            message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
          })
        }
        let messages = []

        for (let i in data) {
          messages.push(
            <>
              {data[i]}
              <br />
            </>
          )
        }
        notify({type: 'error', message: <>{messages?.[0]}</>})
      }
    },
  })

  useEffect(() => {
    setloginNameValue(formik.values.loginName)
  }, [formik.values.loginName])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to Xitoring</h1>
      </div>
    
      {/* begin::Heading */}
      <OAuths />
      
      <div className='separator separator-content my-10'>
        <span className='w-125px text-dark fw-semibold fs-7'>
          {intl.formatMessage({id: 'OR.EMAIL'})}
        </span>
      </div>

      {formik.status && (
        <div className='mb-lg-8 alert alert-danger text-white'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'AUTH.INPUT.USERNAME'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'USERNAME.PLACEHOLDER'})}
          {...formik.getFieldProps('loginName')}
          className={clsx(
            'form-control bg-transparen',
            {'is-invalid': formik.touched.loginName && formik.errors.loginName},
            {
              'is-valid':
                formik.touched.loginName && !formik.errors.loginName && formik.values.loginName,
            }
          )}
          type='email'
          name='loginName'
          value={formik.values.loginName}
          onChange={formik.handleChange}
          autoComplete='off'
        />
        {formik.touched.loginName && formik.errors.loginName && (
          <div className='fv-plugins-message-container'>
            <span className=' text-danger'>{formik.errors.loginName}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row '>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
            </label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparen',
            {
              'is-invalid':
                formik.touched.password && formik.errors.password && formik.values.password,
            },
            {
              'is-valid':
                formik.touched.password && !formik.errors.password && formik.values.password,
            }
          )}
          name='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          placeholder={intl.formatMessage({id: 'PASSWORD.PLACEHOLDER'})}
        />
        {formik.values.password && formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex justify-content-between align-items-center mt-4 mb-10'>
        <div className=' '>
          <input
            className='form-check-input'
            type='checkbox'
            name='rememberMe'
            // value={formik.values.rememberMe}
            onChange={formik.handleChange}
            id='flexCheckChecked'
          />
          <label className='form-check-label ms-2' htmlFor='flexCheckChecked'>
            {intl.formatMessage({id: 'REMEMBER.ME'})}
          </label>
        </div>
        <a
          href={`${process.env.REACT_APP_API_URL_NOT_VERSION}/forget`}
          className='link-primary fs-6 fw-bolder'
        >
          {intl.formatMessage({id: 'FORGOT.PASSWORD'})}
        </a>
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'LOGS.TABS.LOGIN.TITLE'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'PLEASE.WAIT'})}

              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        <div className='text-center text-dark text-uppercase  mb-5'>
          {intl.formatMessage({id: 'AUTH.GENERAL.OR'})}
        </div>
        {/* end::Separator */}
        {/* begin::Link */}

        <a
          href={`${process.env.REACT_APP_API_URL_NOT_VERSION}/register`}
          className=' fs-5  btn btn-lg btn-primary w-100'
        >
          {intl.formatMessage({id: 'REGISTER'})}
        </a>
        {/* end::Link */}
        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
