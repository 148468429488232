import React, {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {notify} from '../../../../helpers'
import CopyToClipBoard from '../../../../helpers/CopyToClipBoard'
import { convertToDateFormat } from '../../../../helpers/timeHelper'
import IconButton from '../../../modules/global/IconButton'
import ConfirmModal from '../../../modules/widgets/components/ConfirmModal'

interface Props {
  isApiTokens?: boolean
  [key: string]: any
}

export const ApiKeyItem = ({
  expire_at,
  expires_at,
  created_at,
  token,
  title,
  id,
  onDelete,
  isApiTokens,
}: Props) => {
  const createdDate = convertToDateFormat(created_at)
  const expiredDate =convertToDateFormat(expire_at??expires_at)
  const [display, setDisplay] = useState<boolean>(false)
  const intl = useIntl()

  return (
    <div className='card card-bordered border border-2 mt-4'>
      <div
        className={`card-header px-4 ribbon ribbon-end ribbon-clip min-h-100 ${
          isApiTokens ? 'border-0' : ''
        }`}
      >
        <div className='ribbon-label text-dark border-dark'>
          {expire_at||expires_at ? (
            expiredDate
          ) : (
            <FormattedMessage id='ACCOUNT.APIS.LABELS.NEVER' />
          )}
          <span
            className='ribbon-inner border-dark bg-secondary'
            style={{boxShadow: 'none'}}
          ></span>
        </div>

        <div className='card-title py-2 d-flex flex-column gap-2'>
          <div className='fs-6 fw-bold d-flex gap-4 align-items-center'>
            {title}
            {isApiTokens && (
              <IconButton
                icon='far fas-trash'
                iconSize='fs-2'
                onClick={() => {
                  setDisplay(true)
                }}
              />
            )}
          </div>

          <div className='text-muted fs-7'>
            <FormattedMessage id='LABELS.CREATED' /> :{' '}
            <span title={created_at}>{createdDate || '-'}</span>
          </div>
        </div>
      </div>
      {!isApiTokens && (
        <div className='card-body p-4 d-flex flex-column align-items-end gap-4'>
          <div className='w-100 d-flex flex-wrap gap-3  align-items-center justify-content-between'>
            <span className=' pe-1 fw-bold'>
              <FormattedMessage id='ACCOUNT.APIS.LABELS.KEY' /> : {token}
            </span>

            <div className='d-flex gap-3'>
              <CopyToClipBoard
                text={token}
                onCopy={() => {
                  notify({
                    type: 'success',
                    message: intl.formatMessage({id: 'NOTIFICATIONS.COPIED'}),
                  })
                }}
              >
                <IconButton icon='far fa-copy' iconSize='fs-2' />
              </CopyToClipBoard>

              <IconButton
                icon='fas fa-trash'
                iconSize='fs-2'
                onClick={() => {
                  setDisplay(true)
                }}
              />
            </div>
          </div>
        </div>
      )}
      <ConfirmModal
        title={intl.formatMessage({id: 'ALERT.SURE'})}
        description={intl.formatMessage({id: 'ALERT.DELETE_MESSAGE'}, {name: title})}
        display={display}
        handleClose={() => setDisplay(false)}
        handleConfirm={() => {
          onDelete()
          setDisplay(false)
        }}
      />
    </div>
  )
}
