import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  styled,
} from '@mui/material'
import {convertByte} from '../../../modules/widgets/components/ChartAssistanr'
import {FormattedMessage, useIntl} from 'react-intl'
import {sortObjectByTotal} from '../../../../helpers/helperFunctions'

interface Props {
  data: Record<string, any>
}

const StyledTableCell = styled(TableCell)(({theme}) => ({
  border: 'none',
  borderRight: `2px solid  var(--kt-table-border-color)`,
  '&:last-child': {
    borderRight: 0,
  },
}))

const BandWidthTable = (props: Props) => {
  const {data} = props
  const intl = useIntl()
  const sortData = sortObjectByTotal(data ?? {})

  return (
    <div className='card p-5'>
      <h3 className='card-title align-items-start  flex-row justify-content-between mb-6'>
        <span className='card-label fw-bold fs-1 mb-1'>
          {intl.formatMessage({id: 'LABELS.BANDWIDTH'})}
        </span>
      </h3>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                border: '1px solid var(--kt-table-border-color)',
              }}
            >
              <StyledTableCell rowSpan={2}>Interface</StyledTableCell>
              <StyledTableCell colSpan={3} align='center'>
                Today
              </StyledTableCell>
              <StyledTableCell colSpan={3} align='center'>
                This Week
              </StyledTableCell>
              <StyledTableCell colSpan={3} align='center'>
                This Month
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{
                border: '1px solid var(--kt-table-border-color)',
              }}
            >
              <StyledTableCell align='center'>In</StyledTableCell>
              <StyledTableCell align='center'>Out</StyledTableCell>
              <StyledTableCell align='center'>Total</StyledTableCell>
              <StyledTableCell align='center'>In</StyledTableCell>
              <StyledTableCell align='center'>Out</StyledTableCell>
              <StyledTableCell align='center'>Total</StyledTableCell>
              <StyledTableCell align='center'>In</StyledTableCell>
              <StyledTableCell align='center'>Out</StyledTableCell>
              <StyledTableCell align='center'>Total</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(sortData)?.length ? (
              Object.entries(sortData).map(([interfaceName, record]) => (
                <TableRow
                  sx={{
                    border: '1px solid var(--kt-table-border-color)',

                    backgroundColor: 'var(--kt-card-bg)',
                  }}
                  key={interfaceName}
                >
                  <StyledTableCell>{interfaceName}</StyledTableCell>
                  <StyledTableCell align='center'>{convertByte(record.today.in)}</StyledTableCell>
                  <StyledTableCell align='center'>
                    {convertByte(record.today.total)}
                  </StyledTableCell>
                  <StyledTableCell align='center'>{convertByte(record.today.out)}</StyledTableCell>
                  <StyledTableCell align='center'>
                    {convertByte(record.this_week.in)}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    {convertByte(record.this_week.out)}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    {convertByte(record.this_week.total)}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    {convertByte(record.this_month.in)}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    {convertByte(record.this_month.out)}
                  </StyledTableCell>
                  <StyledTableCell align='center'>
                    {convertByte(record.this_month.total)}
                  </StyledTableCell>
                </TableRow>
              ))
            ) : (
              <TableRow
                sx={{
                  backgroundColor: 'var(--kt-card-bg)',
                  border: '1px solid var(--kt-table-border-color)',
                }}
              >
                <TableCell colSpan={12}>
                  <h6 className='p-5 text-center m-0'>
                    <FormattedMessage id='NO_DATA_TABLE' />
                  </h6>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default BandWidthTable
