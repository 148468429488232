import Http from '../core/inceptor'
import {useMutation} from 'react-query'

const url = 'client/profile'

const getProfileDetail = (params:any) => {
  return Http.get(url)
}

const ProfileDetail = () => {
  return useMutation(getProfileDetail)
}

export default ProfileDetail
