import { operatorList } from './../../../../helpers/triggers';
import { convertStringServices, removeHttpPrefix, timestampToDate, timestampToFormattedDate } from "../../../../helpers/helperFunctions";
import { convertByte } from "./ChartAssistanr";
import cronstrue from 'cronstrue';

export const TYPES = {
    LOAD_AVERAGE: 1,
    CPU_USAGE: 2,
    MEMORY_USAGE: 3,
    DISK_USAGE: 4,
    DISK_IO: 5,
    NETWORK: 6,
    PING: 20,
    HTTP: 21,
    DNS: 22,
    FTP: 23,
    SMTP: 24,
    POP3: 25,
    IMAP: 26,
    Heartbeat:30,
    NGINX:111,
    APACHE:112,
    FPM:113,
    REDIS:114,
    KEYDB:115,
    SUPERVISOR:116,
    DOCKER:117,
    MYSQL:118,
    SSL: 27,

    MONGODB:119,
    HAPROXY:120,
    SERVICES:121,
    IIS:123,
    POSTGRESQL:122,
    VARNISH:124,
    TCP:28,
    UDP:29,
    DISK_HEALTH:125,
    RABBITMQ:126,
    BANDWIDTH:7,
    CouchDB:127
}

export const TYPES_TITLE = (type: number) => {
    return {
        1: "Load Average",
        2: "CPU Usage",
        3: "Memory Usage",
        4: "Disk Usage",
        5: "Disk I/O",
        6: "Network",
        20: "Ping",
        21: "Http(s)",
        22: "DNS",
        23: "FTP",
        24: "SMTP",
        25: "POP3",
        26: "IMAP",
        30:"Heartbeat",
        111:"Nginx",
        112:"Apache",
        113:"PHP-FPM",
        114:"Redis",
        115:"KeyDB",
        116:"Supervisor",
        117:"Docker",
        118:"MySQL",
        119:"MongoDB",
        120:"HAproxy",
        27:"SSL",
        121:"Services",
        123:"IIS",
        122:"PostgreSQL",
        124:"Varnish",
        28:"TCP",
        29:"UDP",
        125:"Disk Health",
        126:"RabbitMQ",
        7:"Bandwidth",
        127:"CouchDB"
    }[type]
}

export const checksForSelect=[
  {
    title:"Ping",value:20
  },
  {
    title:"Http(s)",value:21
  },
  {
    title:"DNS",value:22
  },
  {
    title:"FTP",value:23
  },
  {
    title:"SMTP",value:24
  },
  {
    title:"POP3",value:25
  },
  {
    title:"IMAP",value:26
  },
  {
    title:"Heartbeat",value:30
  },
  {
    title:"SSL",value:27
  },
  {
    title:"TCP",value:28
  },
  {
    title:"UDP",value:29
  },
]

export const metricForSelect=[
  {
    title:"Load Average",value:1
  },
  {
    title:"CPU Usage",value:2
  },
  {
    title:"Memory Usage",value:3
  },
  {
    title:"Disk Usage",value:4
  },
  {
    title:"Disk I/O",value:5
  },
  {
    title:"Network",value:6
  },
  
]

export const integrationsTypeStrForSelect = [

  {
      title: "Nginx",
      value: 'Nginx'
  },
  {
    title: "Netstat",
    value: 'Netstat'
},
  {
      title: "Apache",
      value: 'Apache'
  },
  {
      title: "PHP-FPM",
      value: 'Fpm'
  },
  {
      title: "Redis",
      value: 'Redis'
  },
  {
      title: "KeyDB",
      value: 'Keydb'
  },
  {
      title: "Supervisor",
      value: 'Supervisor'
  },
  {
      title: "Docker",
      value: 'Docker'
  },
  {
      title: "MySQL",
      value: 'Mysql'
  },
  {
      title: "MongoDB",
      value: 'Mongo'
  },
  {
      title: "HAproxy",
      value: 'HAProxy'
  },
  // {
  //     title: "Services",
  //     value: 'Services'
  // },
  {
    title: "PostgreSQL",
    value: 'Postgresql'
  },
  {
    title: "IIS",
    value: 'IIS'
  },
  {
    title: "RabbitMQ",
    value: 'RabbitMQ'
  },
  {
    title:"Disk health",
    value:"Disk_Health"
  },
  {
    title:"Varnish",
    value:"Varnish"
  },
  {
    title:"CouchDB",
    value:"CouchDB"
  }
];

export const integrationsForSelect = [

  {
      title: "Nginx",
      value: 111
  },
  {
      title: "Apache",
      value: 112
  },
  {
      title: "PHP-FPM",
      value: 113
  },
  {
      title: "Redis",
      value: 114
  },
  {
      title: "KeyDB",
      value: 115
  },
  {
      title: "Supervisor",
      value: 116
  },
  {
      title: "Docker",
      value: 117
  },
  {
      title: "MySQL",
      value: 118
  },
  {
      title: "MongoDB",
      value: 119
  },
  {
      title: "HAproxy",
      value: 120
  },
  {
      title: "Services",
      value: 121
  },
  {
    title: "PostgreSQL",
    value: 122
  },
  {
    title: "IIS",
    value: 123
  },
  {
    title: "Disk Health",
    value: 125
  },
  {title: "Varnish",
    value: 124
  },
  {title: "RabbitMQ",
    value: 126
  },
  {title: "Bandwidth",
  value: 7
  },
  {title: "CouchDB",
    value: 127
  }
];

export const moduleTypeToHumanReadable = (value: number):string => {
    var types = [];

    types[TYPES.PING] = 'ping';
    types[TYPES.HTTP] = 'http(s)';
    types[TYPES.DNS] = 'dns';
    types[TYPES.FTP] = 'ftp';
    types[TYPES.SMTP] = 'smtp';
    types[TYPES.POP3] = 'pop3';
    types[TYPES.IMAP] = 'imap';
    types[TYPES.Heartbeat] = 'Heartbeat';
    types[TYPES.SSL] = 'SSL';
    types[TYPES.UDP] = 'UDP';
    types[TYPES.TCP] = 'TCP';

    return types[value];
}


export const handleError=(e:any)=>{
    let error=""
    if(e.response?.data){
    Object.keys(e.response.data).forEach((errorItem:string)=>{
      error +=e.response.data[errorItem]+" "
    })
    }else{
        error="Something went wrong"
    }

    return error;
}

 


export const handleErrorWithStatusCode = (e: any) => {
    let error: any = {}
    error.message = ''
    error.statusCode = e.response?.status
    if (e.response?.data) {
        Object.keys(e.response.data).forEach((errorItem: string) => {
            if (typeof e.response.data[errorItem] === 'object') {
                error.message += e.response.data[errorItem][0] + " "

            } else {
                error.message += e.response.data[errorItem] + " "

            }
        })
    } else {
        error.message = "Something went wrong"
    }

    return error;
}


export const findDeviceStatus = (e: any, isSuspended: boolean) => {
    return e?.is_under_maintenance ? 'STATUS.UNDER_MAINTENANCE' :
        e?.is_paused ? 'STATUS.PAUSED' :
            (0 === e?.statistics?.length || 0 === e?.statistics?.last_update) ? 'STATUS.EMPTY_DATA' :
                isSuspended ? 'STATUS.OUTDATED' :
                    'STATUS.NORMAL';
}


export const diskIoToHumanReadable=(value:any)=>{
    if (typeof value == 'undefined') {
        return value;
    }

    value = parseInt(value);

    if (value == 0) {
        return value + 'KBps';
    }

    return byteToHumanReadable(value)+ 'ps';

}

export const byteToHumanReadable =(value:any)=>{
    if (typeof value != 'undefined') {

        value = parseInt(value);

        if (value == 0) {
            return value;
        }

        var i = Math.floor(Math.log(value) / Math.log(1024)),
            sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        return parseFloat((value / Math.pow(1024, i)).toFixed?.(2)) * 1 + ' ' + sizes[i];
    }

    return value;
}
export const createCustomIncidentMessage = (details: any,intl:any) => {
    let type = details["type"] ? details["type"] : "";
    let name = details["name"] ? details["name"] : "";


    type = parseInt(type);

    switch (type) {
        case TYPES.PING:
        case TYPES.HTTP:
        case TYPES.DNS:
        case TYPES.FTP:
        case TYPES.SMTP:
        case TYPES.IMAP:
        case TYPES.POP3:
            let module_host = "";

            if (details.hasOwnProperty("module_host")) {
                module_host = details["module_host"];
            }

            let module_name: string = moduleTypeToHumanReadable(type);


            return intl.formatMessage({ id: 'MODULE_INCIDENT_MANUALLY' }, {
                type: module_name.toUpperCase(),
                host:module_host?`- (${module_host})`:"" ,

            })

        case TYPES.LOAD_AVERAGE:
            return intl.formatMessage({ id: 'CPU_LOAD_AVERAGE_INCIDENT_MANUALLY' }, {
                name: name,
            })

        case TYPES.CPU_USAGE:
            return intl.formatMessage({ id: 'CPU_USAGE_INCIDENT_MANUALLY' })

        case TYPES.DISK_USAGE:
            return intl.formatMessage({ id: 'DISK_USAGE_INCIDENT_MANUALLY' }, {
                name: name,
            })

        case TYPES.MEMORY_USAGE:
            return intl.formatMessage({ id: 'memory_USAGE_INCIDENT_MANUALLY' }, {
                name: name,
            })

        case TYPES.NETWORK:
            let traffic_type = details["traffic_type"]
                ? details["traffic_type"]
                : "";

            if (traffic_type !== 'total') {
                traffic_type = traffic_type === "in" ? "receiving" : "sending";
            }

            if (name === 'total') {
                return intl.formatMessage({ id: 'TOTAL_NETWORK_INCIDENT_MANUALLY' }, {
                    traffic_type: traffic_type,
                })
            }

            return intl.formatMessage({ id: 'NETWORK_INCIDENT_MANUALLY' }, {
                name: name,
                traffic_type: traffic_type,
            })

        case TYPES.DISK_IO:
            return intl.formatMessage({ id: 'DISK_IO_INCIDENT_MANUALLY' }, {
                name: name,
            })

        default:
            return type;
    }
};

export const humanText = (text: string ,intl:any) => {
    switch (text) {
      case 'req_per_sec':
        return intl.formatMessage({id: 'LABELS.REQ.PER.SEC'})
      case 'bytes_per_sec':
        return intl.formatMessage({id: 'LABELS.BYTES.PER.SEC'})

      case 'load_1':
        return intl.formatMessage({id: 'lABEL.LOAD.1'})
      case 'load_5':
        return intl.formatMessage({id: 'lABEL.LOAD.5'})
      case 'load_15':
        return intl.formatMessage({id: 'lABEL.LOAD.15'})

      case 'bytes_per_req':
        return intl.formatMessage({id: 'LABELS.BYTES.PER.REQ'})

      case 'duration_per_req':
        return intl.formatMessage({id: 'LABELS.DURATION.PER.REQ'})

      case 'connected_clients':
        return intl.formatMessage({id: 'LABELS.KEYDB.CONNECTED_CLIENTS'})

      case 'rejected_conns':
        return intl.formatMessage({id: 'LABELS.TRIGGER.rejected_conns'})

      case 'used_memory':
        return intl.formatMessage({id: 'LABELS.KEYDB.USED_MEMORY'})

      case 'used_cpu_user':
        return intl.formatMessage({id: 'LABELS.KEYDB.USED_CPU_USER'})

      case 'r_per_sec':
        return intl.formatMessage({id: 'LABELS.TRIGGER.r_per_sec'})
      case 'w_per_sec':
        return intl.formatMessage({id: 'LABELS.TRIGGER.w_per_sec'})
      case 'total_keys':
        return intl.formatMessage({id: 'LABELS.KEYDB.TOTAL_KEYS'})
      case 'current_connections':
        return intl.formatMessage({id: 'LABELS.CURRENT_CONNECTIONS'})
      case 'bytes_received_persec':
        return intl.formatMessage({id: 'LABELS.BYTES_RECEIVED_PERSEC'})
      case 'bytes_sent_persec':
        return intl.formatMessage({id: 'LABELS.BYTES_SENT_PERSEC'})
      case 'bytes_total_persec':
        return intl.formatMessage({id: 'LABELS.BYTES_TOTAL_PERSEC'})
      case 'delete_requests_persec':
        return intl.formatMessage({id: 'LABELS.DELETE_REQUESTS_PERSEC'})
      case 'get_requests_persec':
        return intl.formatMessage({id: 'LABELS.GET_REQUESTS_PERSEC'})
      case 'head_requests_persec':
        return intl.formatMessage({id: 'LABELS.HEAD_REQUESTS_PERSEC'})
      case 'options_requests_persec':
        return intl.formatMessage({id: 'LABELS.OPTIONS_REQUESTS_PERSEC'})
      case 'post_requests_persec':
        return intl.formatMessage({id: 'LABELS.POST_REQUESTS_PERSEC'})
      case 'put_requests_persec':
        return intl.formatMessage({id: 'LABELS.PUT_REQUESTS_PERSEC'})
      case 'total_method_requests_persec':
        return intl.formatMessage({id: 'LABELS.TOTAL_METHOD_REQUESTS_PERSEC'})
      case '0':
        return 'DB 0'
      case '1':
        return 'DB 1'
      case '2':
        return 'DB 2'
      case '3':
        return 'DB 3'
      case '4':
        return 'DB 4'
      case '5':
        return 'DB 5'
      case 'active_processes':
        return intl.formatMessage({id: 'LABELS.FPM.ACTIVE.PROCESSES'})
      case 'idle_processes':
        return intl.formatMessage({id: 'LABELS.FPM.IDLE_PROCESSES'})
      case 'request_memory':
        return intl.formatMessage({id: 'LABELS.FPM.TRIGGER.REQUEST_MEMORY'})
      case 'request_cpu':
        return intl.formatMessage({id: 'LABELS.FPM.TRIGGER.REQUEST_CPU'})
      case 'listen_queue':
        return intl.formatMessage({id: 'LABELS.FPM.LISTEN_QUEUE'})
      case 'up_containers_count':
        return intl.formatMessage({id: 'LABELS.TRIGGER.up_containers_count'})
      case 'cpu':
        return intl.formatMessage({id: 'PROPERTIES.CPU'})
      case 'memory':
            return intl.formatMessage({id: 'LABELS.MEMORY'})
      case 'part_proc':
                return intl.formatMessage({id: 'LABELS.TRIGGER.part_proc'})
      case "part_containercpu":
        return intl.formatMessage({id: 'LABELS.part_containercpu'})
        case "part_containermemory" :
        return intl.formatMessage({id: 'LABELS.part_containermemory'})
        case "up_containers_count" :
          return intl.formatMessage({id: 'LABELS.TRIGGER.up_containers_count'})
          case "part_proc":
          return intl.formatMessage({id: 'LABELS.TRIGGER.part_proc'})

      case "part_name":
        return intl.formatMessage({id:"LABELS.PART.NAME"})
      case 'active_connections':
          return intl.formatMessage({id: 'LABELS.ACTIVE.CONNECTIONS'});
      case 'idle_connections':
          return intl.formatMessage({id: 'LABELS.IDLE_CONNECTIONS'});
      case 'cache_hit':
          return intl.formatMessage({id: 'LABELS.CACHE_HIT'});
      case 'total_disk_space':
          return intl.formatMessage({id: 'LABELS.TOTAL_DISK_SPACE'});
      case 'database_deadlocks':
          return intl.formatMessage({id: 'LABELS.DATABASE_DEADLOCKS'});
      case 'active_queries':
          return intl.formatMessage({id: 'LABELS.ACTIVE_QUERIES'});
      case 'waiting_queries':
          return intl.formatMessage({id: 'LABELS.WAITING_QUERIES'});
      case 'committed_transactions':
          return intl.formatMessage({id: 'LABELS.COMMITTED_TRANSACTIONS'});
      case 'rollback_transactions':
          return intl.formatMessage({id: 'LABELS.ROLLBACK_TRANSACTIONS'});
      case 'read_disk_blocks':
          return intl.formatMessage({id: 'LABELS.READ_DISK_BLOCKS'});
      case 'rows_returned':
          return intl.formatMessage({id: 'LABELS.ROWS_RETURNED'});
      case 'rows_selected':
          return intl.formatMessage({id: 'LABELS.ROWS_SELECTED'});
      case 'rows_inserted':
          return intl.formatMessage({id: 'LABELS.ROWS_INSERTED'});
      case 'rows_updated':
          return intl.formatMessage({id: 'LABELS.ROWS_UPDATED'});
      case 'rows_deleted':
          return intl.formatMessage({id: 'LABELS.ROWS_DELETED'});
      default:
        return text
    }
  }

 
  
 export  const convertPartToString = (obj: Record<string,any>): string => {
    const { name, part } = obj;
  
    let nameWithoutPrefix=''

    if (name) {
        // Remove "part_" prefix from the name
         nameWithoutPrefix = name.replace(/^part_/, "");
    }

  
    let description=""
    if (part) {
         // Extract parts from the 'part' string
    const parts = part.split(/[\[\]]/).filter(Boolean);
  
    // Construct the description from the parts
     description = parts.reverse().join(" of ");
    }
 
  
    // Combine the description with the name
    return `${description} in ${nameWithoutPrefix}`;
  };

export const createIncidentMessages = function (details: any,intl:any) {
    let type = details["type"] ? details["type"] : "";
    let name = details["name"] ? details["name"] : "";
    let unit = details["unit"] ? details["unit"] : "";
    let value = details["value"] ? details["value"] : "";




 


    type = parseInt(type);

    switch (type) {
        case TYPES.Heartbeat:
           return moduleTypeToHumanReadable(type)
        case TYPES.PING:
        case TYPES.HTTP:
        case TYPES.DNS:
        case TYPES.FTP:
        case TYPES.SMTP:
        case TYPES.IMAP:
        case TYPES.POP3:
          case TYPES.SSL:
          case TYPES.UDP:
          case TYPES.TCP:

            let module_host = details["module_host"];

            let module_name: string = moduleTypeToHumanReadable(type);

            return `${module_name.toUpperCase()} (${module_host})`;

        case TYPES.LOAD_AVERAGE:
            return intl.formatMessage({ id: 'CPU_LOAD_AVERAGE_INCIDENT' }, {
                name: name,
                value: value,
            })

        case TYPES.CPU_USAGE:
            return intl.formatMessage({ id: 'CPU_USAGE_INCIDENT' }, {
                value:value,//(value && value>0 )?value.toFixed(2):value,
            })

        case TYPES.DISK_USAGE:
            value =
                unit == "%" ? value + "%" : convertByte(value);

            if (name == "used") {
                return intl.formatMessage({ id: 'TOTAL_DISK_USAGE_incident' }, {
                    name: name,
                    value: value,
                })
            }

            return intl.formatMessage({ id: 'DISK_USAGE_INCIDENT' }, {
                name: name,
                value: value,
            })

        case TYPES.MEMORY_USAGE:
            value =
                unit == "%" ? value + "%" : convertByte(value);

            return intl.formatMessage({ id: 'memory_USAGE_INCIDENT' }, {
                name: name,
                value: value,
            })

        case TYPES.NETWORK:
          const splittedType: string = details?.traffic_type?.split("_")
          const netType: string = splittedType?.[0]
          let traffic_type: string = splittedType?.[1]??splittedType?.[0]??""

            const notBytesUnit=details?.unit==="kb"?intl.formatMessage({id:"LABELS.KILO.PACKETS"}):details?.unit==="mb"?intl.formatMessage({id:"LABELS.MEGA.PACKETS"}):intl.formatMessage({id:"LABELS.PACKETS"})

            if (netType==='packets') {
              return intl.formatMessage({ id: 'LABELS.NETWORK.PACKET.INCIDENT' }, { name: name,traffic: traffic_type, value: value,unit:notBytesUnit })
            }else if(netType==='dropped'){
              return intl.formatMessage({ id: 'LABELS.NETWORK.DROPPED.PACKET.INCIDENT' }, { name: name,traffic: traffic_type, value: value,unit:notBytesUnit })

            }else{
            value = convertByte(value);

              if (traffic_type != 'total') {
                traffic_type = traffic_type === "in" ? "Receiving" : "Sending";
            }

            if (name == 'total') {
                return intl.formatMessage({ id: 'TOTAL_NETWORK_INCIDENT' }, { traffic_type: traffic_type, value: value })
            }

            return intl.formatMessage({ id: 'NETWORK_INCIDENT' }, {
                name: name,
                traffic_type: traffic_type,
                value: value,
            })
            }

          

            

        case TYPES.DISK_IO:
            value = convertByte(value);

            return intl.formatMessage({ id: 'DISK_IO_INCIDENT' }, {
                name: name,
                value: value,
            })
        case TYPES.NGINX:
            
             return intl.formatMessage({ id: 'LABELS.NGINX.INCIDENT' }, {
                name: name,
            })

        case TYPES.FPM:
            
            return intl.formatMessage({ id: 'LABELS.PHP.FPM.INCIDENT' }, {
                name:humanText(name,intl),
               })
        case TYPES.REDIS:
            
            return intl.formatMessage({ id: 'LABELS.REDIS.INCIDENT' }, {
                name:humanText(name,intl),
                })
        case TYPES.KEYDB:
            
            return intl.formatMessage({ id: 'LABELS.KEYDB.INCIDENT' }, {
                name:humanText(name,intl),
                })
        case TYPES.SUPERVISOR:
            
            return intl.formatMessage({ id: 'LABELS.SUPERVISOR.INCIDENT' }, {
                part:humanText(details?.trigger?.part,intl),
                name:humanText(name,intl),
                })
        case TYPES.DOCKER:
            
            return intl.formatMessage({ id: 'LABELS.DOCKER.INCIDENT' }, {
              name:humanText(name,intl),
              // part: humanText(details?.trigger?.part, intl),
              // operator: intl.formatMessage({ id: details?.trigger?.operator }),
              value: details?.value,
              unit: unit
              })  

        case TYPES.MYSQL:
            
            return intl.formatMessage({ id: 'LABELS.MYSQL.INCIDENT' }, {
                 name:humanText(name,intl),
                 })
         case TYPES.MONGODB:
            
             return intl.formatMessage({ id: 'LABELS.MONGODB.INCIDENT' }, {
                       name:humanText(name,intl),
                       })

          case TYPES.HAPROXY:
            
             return intl.formatMessage({ id: 'LABELS.HAPROXY.INCIDENT' }, {
                      name:humanText(name,intl),
                  })
        case TYPES.APACHE:
            
            return intl.formatMessage({ id: 'LABELS.APACHE.INCIDENT' }, {
                    name:humanText(name,intl)
                 })
        case TYPES.SERVICES :
          const selectedServices=convertStringServices(value)
          const servicesOfIncident=selectedServices?.filter((service)=>{
                 if (details?.trigger?.operator==="neq") {
                        return  service?.status?.toLowerCase()!==details?.trigger?.value?.toLowerCase()
  
                 }else{
                       return service?.status?.toLowerCase()===details?.trigger?.value?.toLowerCase()
                    }
                })

          return servicesOfIncident?.length===1? intl.formatMessage({ id: 'LABELS.SERVICES.INCIDENT' }, {
            name:servicesOfIncident?.[0]?.service
         }): intl.formatMessage({ id: 'LABELS.SERVICES.INCIDENT.MORE' }, {
          name:servicesOfIncident?.[0]?.service,
          len:(servicesOfIncident?.length ??0) - 1
       })

       case TYPES.IIS:
            
        return intl.formatMessage({ id: 'LABELS.IIS.INCIDENT' }, {
                  name:humanText(name,intl),
                  })

       case TYPES.POSTGRESQL:
            
        return intl.formatMessage({ id: 'LABELS.POSTGRESQL.INCIDENT' }, {
                  name:humanText(name,intl),
                  })

      case TYPES.DISK_HEALTH:

       if (details?.name==="part_health") {
        return intl.formatMessage({ id: 'LABELS.DISK.HEALTH.PART.INCIDENT' }, {
          disk:details?.trigger?.part,
          })    
       } else {
        return intl.formatMessage({ id: 'LABELS.DISK.HEALTH.ALL.INCIDENT' })    
       }
            
       case TYPES.RABBITMQ:

        if (details?.trigger?.part) {
         return intl.formatMessage({ id: 'LABELS.RABBITMQ.PART.INCIDENT' }, {
           part:convertPartToString(details?.trigger),
           })    
        } else {
         return intl.formatMessage({ id: 'LABELS.RABBITMQ.INCIDENT',name:name })    
        }       

      case TYPES.VARNISH:
            
        return intl.formatMessage({ id: 'LABELS.VARNISH.INCIDENT' }, {
                   name:humanText(name,intl),
                 })
      case TYPES.BANDWIDTH:
        return intl.formatMessage({ id: 'LABELS.BANDWIDTH.INCIDENT' }, {
          name:humanText(name,intl),
        })
                 case TYPES.CouchDB:

               
                   return intl.formatMessage({ id: 'LABELS.COUCHDB.PART.INCIDENT' }, {
                     part:convertPartToString(details?.trigger),
                     })    
                  
        default:
            return type;
    }
};


export const generateCheckIncidentCause = (details: any,intl:any) => {

    const { type, subject } = details;

    switch (parseInt(type)) {

        case TYPES.PING:
            return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.PING.packet_loss' });

         case TYPES.UDP:
              if (subject === "response_time_exceeded") {
                return intl.formatMessage({ id: 'LABELS.CAUSE.RESPONSE.TIME' });
              }
              if (subject === "connection_error") {
                return intl.formatMessage({ id: 'LABELS.INCIDENTS.CAUSE.connection_error' });
              }
              if (subject === 'response_mismatch') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.HTTP.response_mismatch' });
            }
              return subject;

           case TYPES.TCP:
                if (subject === "response_time_exceeded") {
                  return intl.formatMessage({ id: 'LABELS.CAUSE.RESPONSE.TIME' });
                }

                if (subject === "connection_error") {
                  return intl.formatMessage({ id: 'LABELS.INCIDENTS.CAUSE.connection_error' });
                }
                if (subject === 'response_mismatch') {
                  return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.HTTP.response_mismatch' });
              }
                return subject;

        case TYPES.HTTP:

          if (subject === "response_time_exceeded") {
            return intl.formatMessage({ id: 'LABELS.CAUSE.RESPONSE.TIME' });
          }
          
            if (subject === 'host_not_resolve') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.HTTP.host_not_resolve' });
            }

            if (subject === 'ssl_certificate_error') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.HTTP.ssl_certificate_error' });
            }

            if (subject === 'response_mismatch') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.HTTP.response_mismatch' });
            }

            if (subject === 'too_many_redirects') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.HTTP.too_many_redirects' });
            }

            if (subject === 'empty_response') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.HTTP.empty_response' });
            }

            if (subject === 'response_code_mismatch') {
              return intl.formatMessage({ id: 'LABELS.RESPONSE.CODE.MISMATCH' })
            }

            //4xx or 5xx errors
            if (details.hasOwnProperty('status_code') && !details.status_code.startsWith("2")) {

                let text = subject.replace('_', ' ');

                text = text.toLowerCase().replace(/\b[a-z]/g, function (letter: string) {
                    return letter.toUpperCase();
                });

                return text;
            }

            

            return subject;

        case TYPES.DNS:

            if (subject === 'no_answer') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.DNS.no_answer' });
            }

            if (subject === 'timeout') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.DNS.timeout' });
            }

            if (subject === 'answer_mismatch') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.DNS.answer_mismatch' });
            }

            if (subject ==="nxdomain") {
              return intl.formatMessage({id:"LABELS.CAUSE.NXDOMAIN"})
            }

            return subject;

        case TYPES.FTP:
            return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.FTP.timeout' });

        case TYPES.SMTP:

            if (subject === 'authentication_error') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.SMTP.authentication_failed' });
            }

            if (subject === 'timeout') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.SMTP.timeout' });
            }

            if (subject === 'invalid_host') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.SMTP.invalid_host' });
            }

            return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.SMTP.global' });

        case TYPES.POP3:

            if (subject === 'timeout') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.POP3.timeout' });
            }

            if (subject === 'invalid_host') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.POP3.invalid_host' });
            }

            return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.POP3.global' });

        case TYPES.IMAP:

            if (subject === 'timeout') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.IMAP.timeout' });
            }

            if (subject === 'invalid_host') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.IMAP.invalid_host' });
            }

            if (subject === 'ssl_error') {
                return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.IMAP.ssl_error' });
            }

            return intl.formatMessage({ id: 'CHECK_INCIDENT_CAUSE.IMAP.global' });
        case TYPES.Heartbeat :
          const heartbeatSubject= details?.root_cause?.subject ??subject
          
          if (heartbeatSubject === 'integration') {
            return intl.formatMessage({ id: 'LABELS.HEARTBEAT.INTEGRATION.CAUSE' });
            
          }
          if (heartbeatSubject === 'no_end_hit') {
            return intl.formatMessage({ id: 'LABELS.HEARTBEAT.NO.END.HIT' });
            
          }
          if (heartbeatSubject === 'execution_time') {
            return intl.formatMessage({ id: 'LABELS.HEARTBEAT.EXE.TIME.TOOK' },{time:details?.root_cause?.execution_time});
            
          }
          if (heartbeatSubject === 'condition_failed') {
            return intl.formatMessage({ id: 'LABELS.HEARTBEAT.PAYLOAD.FAILD' });
            
          }

          if (heartbeatSubject === 'key_not_in_payload') {
            return intl.formatMessage({ id: 'LABELS.HEARTBEAT.PAYLOAD.KEY.WRONG' });
            
          }
          if (heartbeatSubject === 'empty_payload') {
            return intl.formatMessage({ id: 'LABELS.HEARTBEAT.PAYLOAD.EMPTY' });
            
          }
          if (heartbeatSubject === 'payload_value_not_float') {
            return intl.formatMessage({ id: 'LABELS.HEARTBEAT.PAYLOAD.VALUE.NUMBER' });
            
          }
          if (heartbeatSubject === 'corrupt_payload') {
            return intl.formatMessage({ id: 'LABELS.HEARTBEAT.PAYLOAD.CORRUPT' });
            
          }
          return intl.formatMessage({id:"CHECK_INCIDENT_CAUSE.HEARTBEAT"},{date:timestampToFormattedDate(details?.module_response)})

          default :
          return details?.module_response
    }
};

export const createCustomIncidentMessageWithIntegrations = (details: any,intl:any) => {
    let type = details['type'] ? details['type'] : ''
    let name = details['name'] ? humanText(details['name'],intl) : ''

    type = parseInt(type)

    switch (type) {
      case TYPES.PING:
      case TYPES.HTTP:
      case TYPES.DNS:
      case TYPES.FTP:
      case TYPES.SMTP:
      case TYPES.IMAP:
      case TYPES.POP3:
      case TYPES.Heartbeat:
        let module_host = ''

        if (details.hasOwnProperty('module_host')) {
          module_host = details['module_host']
        }

        let module_name: string = moduleTypeToHumanReadable(type)

        return intl.formatMessage(
          {id: 'MODULE_INCIDENT_MANUALLY'},
          {
            type: module_name.toUpperCase(),
            host:module_host?`- (${module_host})`:"" ,
          }
        )

      case TYPES.LOAD_AVERAGE:
        return intl.formatMessage(
          {id: 'CPU_LOAD_AVERAGE_INCIDENT_MANUALLY'},
          {
            name: name,
          }
        )

      case TYPES.CPU_USAGE:
        return intl.formatMessage({id: 'CPU_USAGE_INCIDENT_MANUALLY'})

      case TYPES.DISK_USAGE:
        return intl.formatMessage(
          {id: 'DISK_USAGE_INCIDENT_MANUALLY'},
          {
            name: name,
          }
        )

      case TYPES.MEMORY_USAGE:
        return intl.formatMessage(
          {id: 'memory_USAGE_INCIDENT_MANUALLY'},
          {
            name: name,
          }
        )

      case TYPES.NETWORK:
        const splittedType: string = details?.traffic_type?.split("_")
        const netType: string = splittedType?.[0]
        let traffic_type: string = splittedType?.[1]??splittedType?.[0]??""
        if (netType==='packets') {
          return intl.formatMessage({ id: 'LABELS.NETWORK.PACKET.RESOLVE.DELETED' }, { name: name,traffic: traffic_type})
        }else if(netType==='dropped'){
          return intl.formatMessage({ id: 'LABELS.NETWORK.DROPPED.PACKET.RESOLVE.DELETED' }, { name: name,traffic: traffic_type})

        }else{


        if (traffic_type !== 'total') {
          traffic_type = traffic_type === 'in' ? 'receiving' : 'sending'
        }

        if (name === 'total') {
          return intl.formatMessage(
            {id: 'TOTAL_NETWORK_INCIDENT_MANUALLY'},
            {
              traffic_type: traffic_type,
            }
          )
        }
      }
        return intl.formatMessage(
          {id: 'NETWORK_INCIDENT_MANUALLY'},
          {
            name: name,
            traffic_type: traffic_type,
          }
        )

      case TYPES.DISK_IO:
        return intl.formatMessage(
          {id: 'DISK_IO_INCIDENT_MANUALLY'},
          {
            name: name,
          }
        )

      case TYPES.NGINX:
        return intl.formatMessage(
          {id: 'LABELS.NGINX.CAUSE.MANUAL'},
          {
            name: name,
          }
        )
      case TYPES.APACHE:
        return intl.formatMessage(
          {id: 'LABELS.APACHE.CAUSE.MANUAL'},
          {
            name: name,
          }
        )
      case TYPES.FPM:
        return intl.formatMessage(
          {id: 'LABELS.FPM.CAUSE.MANUAL'},
          {
            name: name,
          }
        )
      case TYPES.REDIS:
        return intl.formatMessage(
          {id: 'LABELS.REDIS.CAUSE.MANUAL'},
          {
            name: name,
          }
        )
      case TYPES.KEYDB:
        return intl.formatMessage(
          {id: 'LABELS.KEYDB.CAUSE.MANUAL'},
          {
            name: name,
          }
        )
      case TYPES.SUPERVISOR:
        return intl.formatMessage(
          {id: 'LABELS.SUPERVISOR.CAUSE.MANUAL'},
          {
            name: details?.trigger?.data?.['part'],
          }
        )
      case TYPES.DOCKER:
        return intl.formatMessage(
          {id: 'LABELS.DOCKER.CAUSE.MANUAL'},
          {
            name: name,
          }
        )
      case TYPES.MYSQL:
        return intl.formatMessage(
          {id: 'LABELS.MYSQL.CAUSE.MANUAL'},
          {
            name: name,
          }
        )
        case TYPES.MONGODB:
          return intl.formatMessage(
            {id: 'LABELS.MONGODB.CAUSE.MANUAL'},
            {
              name: name,
            }
          )
          case TYPES.HAPROXY:
            return intl.formatMessage(
              {id: 'LABELS.HAPROXY.CAUSE.MANUAL'},
              {
                name: name,
              }
            )
            case TYPES.SERVICES:
            return intl.formatMessage(
              {id: 'LABELS.SERVICES.CAUSE.MANUAL'},
             
            )
         case TYPES.POSTGRESQL:
          return intl.formatMessage(
                {id: 'LABELS.POSTGRESQL.CAUSE.MANUAL'},
                {
                  name: name,
                }
            )
        case TYPES.VARNISH:
           return intl.formatMessage(
                {id: 'LABELS.VARNISH.CAUSE.MANUAL'},
                {
                  name: name,
                 }
            )
        case TYPES.DISK_HEALTH:
          if (details?.trigger?.data?.name !== 'part_health') {
            return intl.formatMessage(
              {id: 'LABELS.DISK.CAUSE.MANUAL'}, )

          } else {
            return intl.formatMessage(
              {id: 'LABELS.DISK.CAUSE.PART.MANUAL'},
              {
                part:details?.trigger?.data?.part,
               }
          )
          }
        case TYPES.RABBITMQ :
          if (details?.trigger?.data?.part) {
            return intl.formatMessage(
              {id: 'LABELS.RABBITMQ.CAUSE.PART.MANUAL'},
              {
                part:convertPartToString(details?.trigger?.data),
               }
          )
          }else{
            return intl.formatMessage(
              {id: 'LABELS.RABBITMQ.CAUSE.MANUAL'},
              {
                name: name,
               }
          )
          }

        case TYPES.BANDWIDTH:
          return intl.formatMessage(
            {id: 'LABELS.BANDWIDTH.CAUSE.MANUAL'},
            {
              name,
             }
          )
          case TYPES.CouchDB :
  
              return intl.formatMessage(
                {id: 'LABELS.COUCHDB.CAUSE.PART.MANUAL'},
                {
                  part:convertPartToString(details?.trigger?.data),
                 }
            )
             
      default:
        return type
    }
  }



export const createMetricMessageWithIntegrations = function (
  details: any,
  resolve_mode: boolean,
  intl: any
) {
  let incident_details

  if (resolve_mode && details.resolver['details'].hasOwnProperty('trigger')) {
    incident_details = JSON.parse(JSON.stringify(details.resolver['details'] ?? {}))
  } else {
    incident_details = JSON.parse(JSON.stringify(details['details'] ?? {}))
  }

  let type = incident_details.type

  type = parseInt(type)

  if (resolve_mode) {
    if (details?.resolver?.details?.is_manual) {
      return createCustomIncidentMessageWithIntegrations(details.resolver.details, intl)
    }
    if (details?.resolver?.details?.module_response === 'Resolved manually') {
      return intl.formatMessage({id: 'LABELS.INCIDENT.RESOLVED.MANUALLY'})
    }
    switch (incident_details?.trigger?.operator) {
      case 'gt':
        incident_details.trigger.operator = 'lt'
        break
      case 'lt':
        incident_details.trigger.operator = 'gt'
        break
      case 'eq':
        incident_details.trigger.operator = 'neq'
        break
      case 'neq':
        incident_details.trigger.operator = 'eq'
        break
    }
  }

  switch (type) {
    case TYPES.CPU_USAGE:
      return intl.formatMessage(
        {id: 'CPU_USAGE_TRIGGER_HUMAN_READABLE'},
        {
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )
    case TYPES.MEMORY_USAGE:
      return intl.formatMessage(
        {id: 'MEMORY_USAGE_TRIGGER_HUMAN_READABLE'},
        {
          segment: incident_details.trigger.name,
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
          unit: incident_details.trigger.unit.toUpperCase(),
        }
      )

    case TYPES.DISK_USAGE:
      return intl.formatMessage(
        {id: 'DISK_USAGE_TRIGGER_HUMAN_READABLE'},
        {
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
          unit: incident_details.trigger.unit.toUpperCase(),
        }
      )

    case TYPES.LOAD_AVERAGE:
      return intl.formatMessage(
        {id: 'CPU_LOAD_AVERAGE_TRIGGER_HUMAN_READABLE'},
        {
          segment: incident_details.trigger.name,
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )

    case TYPES.DISK_IO:
      return intl.formatMessage(
        {id: 'DISK_IO_TRIGGER_HUMAN_READABLE'},
        {
          segment: incident_details.trigger.name,
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
          unit: incident_details.trigger.unit.toUpperCase(),
        }
      )

    case TYPES.NETWORK:
      let unit = ''
      const splittedType: string = incident_details?.trigger?.traffic_type?.split("_")
      const netType: string = splittedType?.[0]
      let traffic_type: string = splittedType?.[1]??splittedType?.[0]??""
      const notBytesUnit=incident_details?.trigger?.unit==="kb"?intl.formatMessage({id:"LABELS.KILO.PACKETS"}):incident_details?.trigger?.unit==="mb"?intl.formatMessage({id:"LABELS.MEGA.PACKETS"}):intl.formatMessage({id:"LABELS.PACKETS"})

      switch (incident_details.trigger.unit) {
        case 'kb':
          unit = 'Kb'
          break
        case 'mb':
          unit = 'Mb'
          break
        case 'gb':
          unit = 'Gb'
          break
      }

      if (netType==='packets') {
        return intl.formatMessage({ id: 'LABELS.NETWORK.PACKET.CAUSE' }, 
        {unit:notBytesUnit, traffic: traffic_type
        ,op: intl.formatMessage({id: incident_details.trigger.operator}),
         value: incident_details.trigger.value,
        name:incident_details.trigger.name })
      }else if(netType==='dropped'){
        return intl.formatMessage({ id: 'LABELS.NETWORK.DROPPED.PACKET.CAUSE' }, 
        {unit:notBytesUnit, traffic: traffic_type
        ,op: intl.formatMessage({id: incident_details.trigger.operator}),
         value: incident_details.trigger.value,
        name:incident_details.trigger.name })
      }
      else{
      return intl.formatMessage(
        {id: 'NETWORK_TRIGGER_HUMAN_READABLE'},
        {
          segment: incident_details.trigger.name,
          traffic_type: incident_details.trigger.traffic_type,
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
          unit: incident_details.trigger.unit.toUpperCase(),
        }
      )
    }

    case TYPES.NGINX:
      return intl.formatMessage(
        {id: 'LABELS.NGINX.CAUSE'},
        {
          name: incident_details.trigger.name,
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )

    case TYPES.APACHE:
      return intl.formatMessage(
        {id: 'LABELS.APACHE.CAUSE'},
        {
          name: humanText(incident_details.trigger.name, intl),
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )

    case TYPES.FPM:
      return intl.formatMessage(
        {id: 'LABELS.FPM.CAUSE'},
        {
          name: humanText(incident_details.trigger.name, intl),
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )
    case TYPES.REDIS:
      return intl.formatMessage(
        {id: 'LABELS.REDIS.CAUSE'},
        {
          name: humanText(incident_details.trigger.name, intl),
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )
    case TYPES.KEYDB:
      return intl.formatMessage(
        {id: 'LABELS.KEYDB.CAUSE'},
        {
          name: humanText(incident_details.trigger.name, intl),
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )
    case TYPES.SUPERVISOR:
      return intl.formatMessage(
        {id: 'LABELS.SUPERVISOR.CAUSE'},
        {
          name: humanText(incident_details.trigger.part, intl),
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )
    case TYPES.DOCKER:
      return intl.formatMessage(
        {id: 'LABELS.DOCKER.CAUSE'},
        {
          name: humanText(incident_details.trigger.name, intl),
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )
    case TYPES.MYSQL:
      return intl.formatMessage(
        {id: 'LABELS.MYSQL.CAUSE'},
        {
          name: humanText(incident_details.trigger.name, intl),
          operator: intl.formatMessage({id: incident_details.trigger.operator}),
          value: incident_details.trigger.value,
        }
      )
    case TYPES.MONGODB:
      return intl.formatMessage(
        {id: 'LABELS.MONGODB.CAUSE'},
        {
          name: humanText(incident_details?.trigger?.name, intl),
          operator: intl.formatMessage({id: incident_details?.trigger?.operator}),
          value: incident_details?.trigger?.value,
        }
      )

    case TYPES.IIS:
      return intl.formatMessage(
        {id: 'LABELS.IIS.CAUSE'},
        {
          name: humanText(incident_details?.trigger?.name, intl),
          operator: intl.formatMessage({id: incident_details?.trigger?.operator}),
          value: incident_details?.trigger?.value,
        }
      )
    case TYPES.HAPROXY:
      return intl.formatMessage(
        {id: 'LABELS.HAPROXY.CAUSE'},
        {
          name: humanText(incident_details?.trigger?.name, intl),
          operator: intl.formatMessage({id: incident_details?.trigger?.operator}),
          value: incident_details?.trigger?.value,
        }
      )
    case TYPES.SERVICES:
      return intl.formatMessage(
        {id: 'LABELS.SERVICES.CAUSE'},
        {
          operator: intl.formatMessage({id: incident_details?.trigger?.operator})?.toLowerCase(),
          value: incident_details?.trigger?.value,
        }
      )
    case TYPES.POSTGRESQL:
      return intl.formatMessage(
        {id: 'LABELS.POSTGRESQL.CAUSE'},
        {
          name: humanText(incident_details?.trigger?.name, intl),
          operator: intl.formatMessage({id: incident_details?.trigger?.operator}),
          value: incident_details?.trigger?.value,
        }
      )

    case TYPES.DISK_HEALTH:
      if (incident_details?.name !== 'part_health') {
        return intl.formatMessage(
          {id: 'LABELS.CAUSE.ALL.DISKS'},
          {
            operator: intl
              .formatMessage({id: incident_details?.trigger?.operator})
              ?.toLowerCase?.(),
            status: intl
              .formatMessage({
                id:
                  incident_details?.trigger?.value === '1'
                    ? 'LABELS.HEALTHY'
                    : 'LABELS.NOT.HEALTHY',
              })
              ?.toLowerCase?.(),
          }
        )
      } else {
        return intl.formatMessage(
          {id: 'LABELS.CAUSE.PART.DISKS'},
          {
            operator: intl
              .formatMessage({id: incident_details?.trigger?.operator})
              ?.toLowerCase?.(),
            status: intl
              .formatMessage({
                id:
                  incident_details?.trigger?.value === '1'
                    ? 'LABELS.HEALTHY'
                    : 'LABELS.NOT.HEALTHY',
              })
              ?.toLowerCase?.(),
            disk: incident_details?.trigger?.part,
          }
        )
      }
    case TYPES.VARNISH:
      return intl.formatMessage(
        {id: 'LABELS.VARNISH.CAUSE'},
        {
          name: humanText(incident_details?.trigger?.name, intl),
          operator: intl.formatMessage({id: incident_details?.trigger?.operator}),
          value: incident_details?.trigger?.value,
        }
      )

    case TYPES.RABBITMQ:

    if (incident_details?.trigger?.part) {
      return intl.formatMessage({id:"LABELS.RABBITMQ.CAUSE.PART"},{
        part:convertPartToString(incident_details?.trigger),
        operator: intl.formatMessage({id: incident_details?.trigger?.operator})?.toLowerCase?.(),
        value: incident_details?.trigger?.value,
      })
    }else{
      return intl.formatMessage({id:"LABELS.RABBITMQ.CAUSE"},{
        name:humanText(incident_details?.trigger?.name, intl),
        operator: intl.formatMessage({id: incident_details?.trigger?.operator})?.toLowerCase?.(),
        value: incident_details?.trigger?.value,
      })
    }

    case TYPES.BANDWIDTH:
      return intl.formatMessage({id:"LABELS.BANDWIDTH.CAUSE"},{
        name:humanText(incident_details?.trigger?.name, intl),
        op: intl.formatMessage({id: incident_details?.trigger?.operator})?.toLowerCase?.(),
        value: incident_details?.trigger?.value,
        unit:incident_details.trigger.unit.toUpperCase(),
        period:incident_details?.trigger?.period?.replace("_"," "),
        traffic:incident_details?.trigger?.traffic_type
      })
    case TYPES.CouchDB:

      
        return intl.formatMessage({id:"LABELS.COUCHDB.CAUSE.PART"},{
          part:convertPartToString(incident_details?.trigger),
          operator: intl.formatMessage({id: incident_details?.trigger?.operator})?.toLowerCase?.(),
          value: incident_details?.trigger?.value,
        })
      
  }
}



  export const generateCheckResolveIncidentCause = (item: Record<string,any>,intl:any) => {
    const {details}=item

    const { type, subject } = details;

    if (item?.resolver?.details?.is_manual) {
     return createCustomIncidentMessage(details,intl) 
    }

    if (item?.resolver?.details?.module_response==="Resolved manually") {
      return intl.formatMessage({ id: 'LABELS.INCIDENT.RESOLVED.MANUALLY' });
    }

    switch (parseInt(type)) {

        case TYPES.PING:
            return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.PING.packet_loss' });

            case TYPES.UDP:
              if (subject === "response_time_exceeded") {
                return intl.formatMessage({ id: 'LABELS.CAUSE.RESPONSE.TIME.RESOLVE' });
              }
              if (subject === "connection_error") {
                return intl.formatMessage({ id: 'LABELS.INCIDENTS.RESOLVE.CAUSE.connection_error' });
              }
              if (subject === 'response_mismatch') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.HTTP.response_mismatch' });
            }
              return subject;

           case TYPES.TCP:
                if (subject === "response_time_exceeded") {
                  return intl.formatMessage({ id: 'LABELS.CAUSE.RESPONSE.TIME.RESOLVE' });
                }

                if (subject === "connection_error") {
                  return intl.formatMessage({ id: 'LABELS.INCIDENTS.RESOLVE.CAUSE.connection_error' });
                }
                if (subject === 'response_mismatch') {
                  return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.HTTP.response_mismatch' });
              }
                return subject;

        case TYPES.HTTP:

            if (subject === 'host_not_resolve') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.HTTP.host_not_resolve' });
            }

            if (subject === 'ssl_certificate_error') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.HTTP.ssl_certificate_error' });
            }

            if (subject === 'response_mismatch') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.HTTP.response_mismatch' });
            }

            if (subject === 'too_many_redirects') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.HTTP.too_many_redirects' });
            }

            if (subject === 'empty_response') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.HTTP.empty_response' });
            }
            if (subject === "response_time_exceeded") {
              return intl.formatMessage({ id: 'LABELS.CAUSE.RESPONSE.TIME.RESOLVE' });
            }

            if (subject === "timeout") {
              return intl.formatMessage({ id: 'LABELS.CAUSE.TIMEOUT.RESOLVE' });
            }
            if (subject === 'response_code_mismatch') {
              return intl.formatMessage({ id: 'LABELS.RESPONSE.CODE.RESOLVE' })
            }

            //4xx or 5xx errors
            if (details.hasOwnProperty('status_code') && !details.status_code.startsWith("2")) {

                let text = subject.replace('_', ' ');

                text = text.toLowerCase().replace(/\b[a-z]/g, function (letter: string) {
                    return letter.toUpperCase();
                });

                return text;
            }

            return subject;

        case TYPES.DNS:

            if (subject === 'no_answer') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.DNS.no_answer' });
            }

            if (subject === 'timeout') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.DNS.timeout' });
            }

            if (subject === 'answer_mismatch') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.DNS.answer_mismatch' });
            }

            return subject;

        case TYPES.FTP:
            return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.FTP.timeout' });

        case TYPES.SMTP:

            if (subject === 'authentication_error') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.SMTP.authentication_failed' });
            }

            if (subject === 'timeout') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.SMTP.timeout' });
            }

            if (subject === 'invalid_host') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.SMTP.invalid_host' });
            }

            return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.SMTP.global' });

        case TYPES.POP3:

            if (subject === 'timeout') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.POP3.timeout' });
            }

            if (subject === 'invalid_host') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.POP3.invalid_host' });
            }

            return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.POP3.global' });

        case TYPES.IMAP:

            if (subject === 'timeout') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.IMAP.timeout' });
            }

            if (subject === 'invalid_host') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.IMAP.invalid_host' });
            }

            if (subject === 'ssl_error') {
                return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.IMAP.ssl_error' });
            }

            return intl.formatMessage({ id: 'RESOLVE_INCIDENT_CAUSE.IMAP.global' });
        case TYPES.Heartbeat :
          return intl.formatMessage({id:"RESOLVE_INCIDENT_CAUSE.HEARTBEAT"},{date:timestampToFormattedDate(item?.resolver?.details?.module_response)})


          default :
          return details?.module_response
    }
};

export const removeUnderlineSecondWord=(text:string)=> {
  return text.replace(/_/g, ' ').replace(/\bshould\b/gi, '');
}

export const addCheckSummary = (values: any,intl:any,bulkAdd:any) => {
  
  const Type = values?.data?.ssl ? "https" : "http"

  // const host =removeHttpPrefix(values?.data?.host)
  const port =
    values?.data?.port &&
    values?.data?.port!=='80' &&
    values?.data?.port!=='443' ? (
     ":"+values?.data?.port
    ) : (
      ""
    )

  // const ssl = values?.data?.ssl ? <span className=''> (ssl)</span> : <></>
  const path = values?.data?.url ? values?.data?.url : ""

  const formattedLogins = bulkAdd.map((item:any) => `${Type}://${removeHttpPrefix(item.host)}${port}${item.url}`);

  const result = formattedLogins.join(" - ")


  switch (parseInt(values.type)) {
    case TYPES.PING:
      return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.PING' }, {
        host:values.data.host,
        trigger: values.trigger.value,
        fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",    
      });
      
    case TYPES.HTTP:
        if (!!values.trigger.header_condition_enabled) {
          return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.HTTP_CONTAINTS' }, {
            host:result,
            response_time: values.trigger.response_time,
            fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",       
            response_code: values.trigger.response_code == "custom" ? values.trigger.response_code_custom : (values.trigger.response_code || 1)+"xx",
            header_condition: removeUnderlineSecondWord(values.trigger.header_condition),
            header_expected_responses: values.trigger.header_expected_responses[0].text,
            returns: values.trigger.response_code_operator == "eq"  ? intl.formatMessage({ id: 'LABELS.RETURNS' }) :intl.formatMessage({ id: 'LABELS.DONT_RETURNS' }) 
          });    
        } else {
          return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.HTTP' }, {
            host:result,
            response_time: values.trigger.response_time,
            fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",       
            response_code: values.trigger.response_code == "custom" ? values.trigger.response_code_custom : (values.trigger.response_code || 1)+"xx",
            returns: values.trigger.response_code_operator =="eq" ? "returns" : "don't returns"
          });      
        } 

      case TYPES.DNS:
      return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.DNS' }, {
        host: values.data.host,
        query:values.data.value ,
        record_type:values.data.record_type ,
        expected_result: values.trigger.expected_result,    
        fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",        
      });
      
      case TYPES.FTP:
      return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FTP' }, {
        host: values.data.host,
        fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",        
      });
      
      case TYPES.SMTP:
      return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.SMTP' }, {
        host: values.data.host,
        port: values.data.port ? `port "${values.data.port}"` : "",
        fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",           
      });
      
      case TYPES.POP3:
      return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.POP3' }, {
        host: values.data.host,
        port: values.data.port ? `port "${values.data.port}"` : "",
        fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",        
      });
      
      case TYPES.IMAP:
      return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.IMAP' }, {
        host: values.data.host,
        port: values.data.port ? `port "${values.data.port}"` : "",
        fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",       
      });
      
      case TYPES.Heartbeat :
        const ftHeartbeat=values.trigger.fault_tolerance === 1
        ? intl.formatMessage({ id: 'LABELS.EVERY.MIN' })
        : values.trigger.fault_tolerance === 5 || values.trigger.fault_tolerance === 30
        ? intl.formatMessage({ id: 'LABELS.EVERY.MINS' }, { min: values.trigger.fault_tolerance })
        : values.trigger.fault_tolerance === 1440
        ? intl.formatMessage({ id: 'LABELS.DAILY' })
        : values.trigger.fault_tolerance === 10080
        ? intl.formatMessage({ id: 'LABELS.WEEKLY' })
        : intl.formatMessage({ id: 'LABELS.EVERY.h' }, { hour: values.trigger.fault_tolerance / 60 })
        let cronHeart
        try {
           cronHeart=cronstrue.toString(values?.data?.cron)
        } catch (error) {
          
        }
       
        const isHasExe = values?.data?.mode==="interval-based-2"||values?.data?.mode==="cron-based-2"
        const text= isHasExe ? intl.formatMessage({ id: 'LABELS.ADD.CHECK.SUMMARY.HEARTBEAT.EXE.TIME' },   {
          ft:values?.data?.isCronJob ? cronHeart: ftHeartbeat,
          execution_time:values?.data?.executionTime_human
        }) : intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.HEARTBEAT' },   {
        fault_tolerance:values?.data?.isCronJob ? cronHeart: ftHeartbeat
      })

      return text

      case TYPES.TCP :
        case TYPES.UDP :
        if (!!values?.trigger?.condition_enabled && !!values?.data?.value) {
          return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.TCP.VALUE.CONDITION' },   {
            host:values.data.host,
                response_time: values.trigger.response_time,
                fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",
                port:values?.data?.port,
                condition:removeUnderlineSecondWord(values?.trigger?.condition),
                condition_responses:values?.trigger?.expected_responses?.map((ob:any)=>ob?.text)?.join?.(", "),
                value:values?.data?.value
          });
        }
        if (!!values?.trigger?.condition_enabled) {
          return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.TCP.CONDITION' },   {
            host:values.data.host,
                response_time: values.trigger.response_time,
                fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",
                port:values?.data?.port,
                condition:removeUnderlineSecondWord(values?.trigger?.condition),
                condition_responses:values?.trigger?.expected_responses?.map((ob:any)=>ob?.text)?.join?.(", ")
          });
        }
        if (!!values?.data?.value) {
          return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.TCP.VALUE' },   {
            host:values.data.host,
                response_time: values.trigger.response_time,
                fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",
                port:values?.data?.port,
                value:values?.data?.value
          });
        }
      return intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.TCP' },   {
        host:values.data.host,
            response_time: values.trigger.response_time,
            fault_tolerance: !!values.trigger.fault_tolerance ? intl.formatMessage({ id: 'ADD_CHECK_SUMMARY.FAULT_TOLERANCE'},{tolerance:values.trigger.fault_tolerance } ) : "",
            port:values?.data?.port  
      });
        
    default:
      return values.type
  }
}

export const extractPath = (url:string) => {
  const urlObj = new URL(url);
  const fullPath = urlObj.pathname;
  const pathAfterUI = fullPath.replace('/ui', '');
  return pathAfterUI;
}

export const additionalDataLabel = (option: any,type:any) => {
  switch (type) {
    case 'Servers':
      return `${option.label} (${option.id})`
    case 'Uptime Checks':
      return `(${moduleTypeToHumanReadable(option.type)?.toUpperCase()}) - ${option?.label}`
    case 'Status Pages':
      return option.title
    case 'Notification roles':
      return option.name
    case 'Maintenances':
      if (option.description?.length > 12) {
        return option.description?.slice(0, 12) + '...'
      } else {
        return option.description
      }
    case 'SSL monitoring':
      return `(${moduleTypeToHumanReadable(option.type)?.toUpperCase()}) ${option?.label}`
    case 'Custom dashboards':
      return `${option.name} - ${option?.id}`
    case 'Incidents':
      return `(${TYPES_TITLE(option?.details?.type)}) - ${option?.details?.name}`
    default:
      return null
      break
  }
}
