/* eslint-disable import/no-anonymous-default-export */
import {
  types
} from "./actionTypes";

const initialState: any = {
  pending: false,
  success: false,
  error: null,
  list: [],
  pendingUnassignServerToGroup: false,
  successUnassignServerToGroup: false,
  errorUnassignServerToGroup: null,
  pendingAssignServerToGroup: false,
  successAssignServerToGroup: false,
  errorAssignServerToGroup: null,
  successUpdateGroup:false
};


export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_GROUPS:
      return {
        ...state,
        pending: true,
      };
    case types.GET_GROUPS_SUCCESS:

      return {
        ...state,
        pending: false,
        list: action.payload.data,
        error: null
      };
    case types.GET_GROUPS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case types.ADD_GROUP:
      return {
        ...state,
        pendingAddGroup: true,
        successAddGroup: false,
        errorAddGroup: null


      };
    case types.ADD_GROUP_SUCCESS:
      return {
        ...state,
        pendingAddGroup: false,
        successAddGroup: true,
        errorAddGroup: null
      };
    case types.ADD_GROUP_ERROR:
      return {
        ...state,
        pendingAddGroup: false,
        errorAddGroup: action.error,
      };


    case types.UPDATE_GROUP:
      return {
        ...state,
        pendingUpdateGroup: true,
        successUpdateGroup: false,
        errorUpdateGroup: null


      };
    case types.UPDATE_GROUP_SUCCESS:
      return {
        ...state,
        pendingUpdateGroup: false,
        successUpdateGroup: true,
      };
    case types.UPDATE_GROUP_ERROR:
      return {
        ...state,
        pendingUpdateGroup: false,
        errorUpdateGroup: action.error,
      };


    case types.DELETE_GROUP_REQUEST:
      return {
        ...state,
        pendingDeleteGroup: true,
        successDeleteGroup: false,
        errorDeleteGroup: null


      };
    case types.DELETE_GROUP_REQUEST_SUCCESS:
      return {
        ...state,
        pendingDeleteGroup: false,
        successDeleteGroup: true,
      };
    case types.DELETE_GROUP_REQUEST_ERROR:
      return {
        ...state,
        pendingDeleteGroup: false,
        errorDeleteGroup: action.error,
      };


    case types.ASSIGN_GROUP_SERVERS_REQUEST:
      return {
        ...state,
        pendingAssignServerToGroup: true,
        successAssignServerToGroup: false,
        errorAssignServerToGroup: null


      };
    case types.ASSIGN_GROUP_SERVERS_REQUEST_SUCCESS:
      return {
        ...state,
        pendingAssignServerToGroup: false,
        successAssignServerToGroup: true,
      };
    case types.ASSIGN_GROUP_SERVERS_REQUEST_ERROR:
      return {
        ...state,
        pendingAssignServerToGroup: false,
        errorAssignServerToGroup: action.error,
      };



    case types.UNASSIGN_GROUP_SERVERS_REQUEST:
      return {
        ...state,
        pendingUnassignServerToGroup: true,
        successUnassignServerToGroup: false,
        errorUnassignServerToGroup: null

      };
    case types.UNASSIGN_GROUP_SERVERS_REQUEST_SUCCESS:
      return {
        ...state,
        pendingUnassignServerToGroup: false,
        successUnassignServerToGroup: true,
      };
    case types.UNASSIGN_GROUP_SERVERS_REQUEST_ERROR:
      return {
        ...state,
        pendingUnassignServerToGroup: false,
        errorUnassignServerToGroup: action.error,
      };

    case types.RESET_PROPS:
      return {
        ...state,
        pendingUpdateGroup: false,
        success: false,
        error: null,
        successUpdateGroup: false,
        errorUpdateGroup: null,
        pendingDeleteGroup: false,
        successDeleteGroup: false,
        errorDeleteGroup: null,
        pendingUnassignServerToGroup: false,
        successUnassignServerToGroup: false,
        errorUnassignServerToGroup: null,
        pendingAssignServerToGroup: false,
        successAssignServerToGroup: false,
        errorAssignServerToGroup: null
      };


    default:
      return {
        ...state,
      };
  }
};