import {Dialog, DialogActions, DialogContent, DialogTitle, Paper} from '@mui/material'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import classes from './PaidModal.module.scss'
export interface SimpleDialogProps {
  open: boolean
  onClose: () => void
  title?: string
}

const PaidModal = (props: SimpleDialogProps) => {
  const {onClose, open, title} = props

  const navigate = useNavigate()

  const handleClose = () => {
    onClose()
  }

  const handleUpgrade = () => {
    navigate('/billing')
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperComponent={Paper}
      PaperProps={{className: classes['custom-bg']}}
    >
      <DialogTitle>
        <h3 className='text-dark fs-4 fw-bold'>{title}</h3>
      </DialogTitle>
      <DialogContent className='text-dark'>
        <FormattedMessage id='LIMIT.MODAL.BODY'/>
      </DialogContent>
      <DialogActions>
        <button className='btn btn-secondary ' onClick={onClose}>
          <FormattedMessage id='ACTIONS.CANCEL' />
        </button>
        <button className='btn btn-primary d-flex gap-2 align-items-center' onClick={handleUpgrade}>
          <span className='far fa-gem fs-2' />

          <FormattedMessage id='LABELS.UPGRADE_SERVICE' />
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default PaidModal
