import React, {ReactNode, useEffect, useMemo} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import useAccessControl from '../Hooks/useAccessControl'
import useIsDigitalOceanUser from '../Hooks/useIsDigitalOceanUser'
import useMainConfig from '../Hooks/useMainConfig'
import useUser from '../Hooks/useUser'

const accessNames: {[key: string]: any} = {
  logs: 'report',
  billing: 'billing',
  tickets: 'tickets',
  notificationRoles: 'notification_roles',
  reports: 'report_email',
  maintenance: 'maintenance_schedule',
  actionRecommendation: 'action_recommendation',
  'public-status-page': 'status_pages',
}

const forbiddenContactRoute = ['sub-accounts', 'wizard',"preferrence"]

const AccessRoute = ({children}: {children: ReactNode}) => {
  const navigate = useNavigate()
  const isDigitalOcean=useIsDigitalOceanUser()
  const user = useUser()
  const mainConfig=useMainConfig()
  const location = useLocation()
  const purePath: string = location?.pathname?.split('/')?.[1]
  const hasAcc = useAccessControl(accessNames?.[purePath], () => {
    if (purePath in accessNames && !hasAcc && purePath !== '403') {
      navigate('/403')
    }
  })

  useEffect(() => {
    if (!user?.full_access&&user?.parent && user?.access&& forbiddenContactRoute.some((route) => location.pathname.includes(route))) {
      navigate('/403')
    }
    if (isDigitalOcean && purePath=="billing") {
      window.open('https://cloud.digitalocean.com/add-ons', '_self')
    }
    if (mainConfig?.suspend_bool && purePath!=="billing") {
      navigate('/billing')
      
    }
  }, [location.pathname,mainConfig?.suspend_bool])

  return <>{children}</>
}

export default AccessRoute
