import React from 'react'
import {getLayout, setLayoutintoLocalStorage} from '../../_metronic/layout/core'


//data-kt-aside-minimize=
const useAsideMinimize = () => {
  const toggleAside = async () => {
    const config = await getLayout()
    setLayoutintoLocalStorage({
      ...config,
      aside: {
        ...config.aside,
        minimized: !config.aside.minimized,
      },
    })
  }

  return {toggleAside}
}

export default useAsideMinimize
