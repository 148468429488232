import { IntlFormatters, IntlShape } from "react-intl";
import moment from 'moment';
import { calculatePercentageOfDay, calculatePercentageOfHour, calculatePercentageOfMonth, calculateTimeDifference, calculateTimestampDifference } from "./timeHelper";
import { convertPartToString } from "../app/modules/widgets/components/Common";
import dayjs from 'dayjs';

export function createStringFromArray(arr: (string | number)[]): string {
  if (!Array.isArray(arr)) {
    return "";
  }
  if (arr.some((item) => typeof item !== "string" && typeof item !== "number")) {
    return "";
  }
  const result = arr.map((item, index) => `DB ${index} : ${item}`).join(" ,  ");
  return result;
}



  export function fixUnits(str: string): string {
    const num = parseFloat(str);
    if (isNaN(num)) {
      return str;
    }
    if (str.endsWith("G")) {
      return `${num} GB`;
    } else if (str.endsWith("M")) {
      return `${num} MB`;
    } else if (str.endsWith("K")) {
      return `${num} KB`;
    } else {
      return str
    }
  }

  export function microsecondsToMilliseconds(microseconds: number): string | undefined {
    if (typeof microseconds !== "number" || isNaN(microseconds)) {
      return "";
    }
    const ms = microseconds / 1000;
    return `${ms.toFixed(2)} ms`;
  }
  
  
export function removeKeysFromObject(obj: Record<string, any>, keys: string[]): Record<string, any> {
  if (!obj) {
    return {}; // Return an empty object if obj is undefined
  }
  const newObj: Record<string, any> = { ...obj }; // Create a shallow copy of the object

  keys.forEach((key) => {
    if (newObj.hasOwnProperty(key)) {
      delete newObj[key]; // Remove the key from the object
    }
  });

  return newObj;
}

export const validateNumber = (input: string): boolean => {
  const trimmedInput = input.trim();
  return !isNaN(Number(trimmedInput));
};

export function removeHttpPrefix(input: string): string {
  try {
    if (!input) {
      return ''
    }
    
    return input.replace(/^(https?:\/\/)/, '');
  } catch (error) {
    console.error('An error occurred while removing the HTTP prefix:', error);
    // You can choose to handle the error in a specific way or throw it further
    // throw error;
    return '';
  }
}


export function arrayDifference(arr1: number[], arr2: number[]): number[] {
  // Check if either argument is not an array
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return []
  }

  // Create a new array to store the difference
  const difference: number[] = [];

  // Iterate over the first array
  for (const num of arr1) {
    // Check if the element is not present in the second array
    if (!arr2.includes(num)) {
      difference.push(num);
    }
  }

  return difference;
}

export function removeObjectKeys<T extends object, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const clonedObj = { ...obj }; // Create a shallow copy of the original object
  
  keys.forEach((key) => {
    delete clonedObj[key]; // Delete the specified key from the cloned object
  });
  
  return clonedObj};
export function isCurrentTimeGreaterThanTimestamp(timestamp: number): boolean {
  const currentTime = Date.now();
  const differenceInSeconds = (currentTime - (timestamp*1000)) / 1000;
  const differenceInMinutes = differenceInSeconds / 60;

  return differenceInMinutes > 5;
}

export const getNumberOfDaysInCurrentMonth = (): number => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-based, so add 1

  // Calculate the number of days in the month
  const numberOfDays = new Date(year, month, 0).getDate();

  return numberOfDays;
};

 export interface HourObject {
  date: string;
  isActive: boolean;
  downtime:any[]
  uptime:string|number
  sumDowntime:string

}

export const getHourArray = (createdAt: number, incidents: Record<string, any>[], intl: IntlShape, tz: string): HourObject[] => {
  const currentDate = moment().tz(tz);
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const currentDay = currentDate.date();

  const hoursArray: HourObject[] = [];

  for (let i = 0; i < 24; i++) {
    const hourStart = moment().tz(tz).startOf('day').hour(i);
    const hourEnd = moment().tz(tz).startOf('day').hour(i).minute(59).second(59);
    const isActive = createdAt <= hourEnd.valueOf() && hourStart.valueOf() <= moment().tz(tz).valueOf();

    const incidentsOnHour = incidents?.filter((inc) => {
      const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
      const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();

      return incidentStartTime <= hourEnd.valueOf() && incidentEndTime > hourStart.valueOf();
    });

    let Downtimes: any[] = [];

    let uptimePercent = 100;
    let sumDowntime = 0;
    if (!!incidentsOnHour?.length) {
      Downtimes = incidentsOnHour?.map((inc) => {
        const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
        const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();

        let startOfHour = hourStart.valueOf() ;
        let endOfHour = hourEnd.valueOf()+1000;

        // if (hourStart.isSame(moment().tz(tz), 'hour')) {
        //   endOfHour = moment().tz(tz).valueOf(); // Set dayEnd to the current time for today
        // }

        const adjustedStartTime = Math.max(incidentStartTime, startOfHour);
        const adjustedEndTime = Math.min(incidentEndTime, endOfHour);

        let diff = calculateTimestampDifference(adjustedEndTime, adjustedStartTime);
        uptimePercent = uptimePercent - calculatePercentageOfHour(adjustedEndTime - adjustedStartTime,endOfHour-startOfHour);

        sumDowntime = sumDowntime + Math.abs(adjustedEndTime - adjustedStartTime);

        let text = diff + " " + intl.formatMessage({ id: "DESCRIPTIONS.UPTIME_INCIDENT" }, {
          down: moment(adjustedStartTime).tz(tz).format('YYYY-MM-DD hh:mm:ss a'),
          up: moment(adjustedEndTime).tz(tz).format('YYYY-MM-DD hh:mm:ss a'),
          type: ""
        });
        return text;
      });
    }

    const date = `${hourStart.format('MMM DD')} ${i.toString().padStart(2, '0')}:00 to ${i.toString().padStart(2, '0')}:59`;
    // const date = `${hourStart.format('MMM DD')} - ${hourStart.tz(tz).get("hour")}:${hourStart.tz(tz).get("minutes")} to 
    // ${hourEnd.tz(tz).get("hour")}:${hourStart.isSame(moment().tz(tz), 'hour')? moment().tz(tz).get("minutes") : hourEnd.tz(tz).get("minutes")}`;

    hoursArray.push({
      date,
      isActive,
      downtime: Downtimes,
      uptime: uptimePercent?.toFixed(2),
      sumDowntime: calculateTimestampDifference(sumDowntime, 0)
    });
  }

  return hoursArray;
};

export function calculateTimePercentage(hours: number, minutes: number, seconds: number): number {
  const totalSecondsInDay = 24 * 60 * 60;
  const totalSeconds = hours * 60 * 60 + minutes * 60 + seconds;
  const percentage = (totalSeconds / totalSecondsInDay) * 100;

  return percentage;
}


export function getThisWeekDates(): Date[] {
  const today = new Date();
  const currentDay = today.getDay();
  const diff = today.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // Adjust for Sunday if necessary
  const startOfWeek = new Date(today.setDate(diff));

  const dates: Date[] = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(startOfWeek);
    date.setDate(startOfWeek.getDate() + i);
    dates.push(date);
  }

  return dates;
}

export const getDayWeekArray = (
  createdAt: number,
  incidents: Record<string, any>[],
  intl: IntlShape,
  tz: string
): HourObject[] => {
  const currentDate = moment().tz(tz);
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  const currentDay = currentDate.date();

  const startOfWeek = currentDate.clone().startOf('week');
  const endOfWeek = currentDate.clone().endOf('week');

  const dayArray: HourObject[] = [];

  let dayStart = startOfWeek.clone();

  while (dayStart.isSameOrBefore(endOfWeek)) {
    const dayEnd = dayStart.clone().endOf('day');
    const isActive =
      createdAt <= dayEnd.valueOf() && dayStart.valueOf() <= moment().tz(tz).valueOf();

    const incidentsOnDay = incidents?.filter(
      (inc) =>
        moment(inc?.down_timestamp * 1000).tz(tz).isSameOrBefore(dayEnd) &&
        moment(inc?.up_timestamp * 1000).tz(tz).isAfter(dayStart)
    );

    let downtime: any[] = [];
    let uptimePercent = 100;
    let sumDowntime = 0;

    if (!!incidentsOnDay?.length) {
      downtime = incidentsOnDay?.map((inc) => {
        const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
        const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();

        const startOfDay = dayStart.valueOf() 
        let endOfDay = dayEnd.valueOf()+1000
        // if (dayStart.isSame(moment().tz(tz), 'day')) {
        //   endOfDay = moment().tz(tz).valueOf(); // Set dayEnd to the current time for today
        // }

        const adjustedStartTime = Math.max(incidentStartTime, startOfDay);
        const adjustedEndTime = Math.min(incidentEndTime, endOfDay);

        const diff = calculateTimestampDifference(adjustedEndTime, adjustedStartTime);
        uptimePercent -= calculatePercentageOfDay(adjustedEndTime - adjustedStartTime,endOfDay-startOfDay);
        sumDowntime = sumDowntime + Math.abs(adjustedEndTime - adjustedStartTime);

        const text = `${diff} ${intl.formatMessage(
          { id: 'DESCRIPTIONS.UPTIME_INCIDENT' },
          {
            down: moment(adjustedStartTime).tz(tz).format('YYYY-MM-DD hh:mm:ss a'),
            up: moment(adjustedEndTime).tz(tz).format('YYYY-MM-DD hh:mm:ss a'),
            type: '',
          }
        )}`;
        return text;
      });
    }

    const date = dayStart.format('dddd, MMM DD');

    dayArray.push({
      date,
      isActive,
      downtime,
      uptime: uptimePercent?.toFixed(3),
      sumDowntime: calculateTimestampDifference(sumDowntime, 0)
    });

    dayStart.add(1, 'day');
  }

  return dayArray;
};



export const getLastMonthData = (createdAt: number, incidents: Record<string, any>[], intl: IntlShape, tz: string): HourObject[] => {
  const currentDate = moment().tz(tz);
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();

  // Calculate the previous month's details
  const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const lastMonthYear = lastMonth === 11 ? currentYear - 1 : currentYear;

  const startOfLastMonth = moment().tz(tz).year(lastMonthYear).month(lastMonth).date(1);
  const endOfLastMonth = moment().tz(tz).year(lastMonthYear).month(lastMonth).endOf('month');

  const lastMonthArray: HourObject[] = [];

  for (let i = startOfLastMonth.date(); i <= endOfLastMonth.date(); i++) {
    const dayStart = moment().tz(tz).year(lastMonthYear).month(lastMonth).date(i).hour(0).minute(0).second(0);
    const dayEnd = moment().tz(tz).year(lastMonthYear).month(lastMonth).date(i).hour(23).minute(59).second(59);
    const isActive = createdAt <= dayEnd.valueOf() && dayStart.valueOf() <= moment().tz(tz).valueOf();

    const incidentsOnDay = incidents?.filter((inc) => {
      const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
      const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();
      
      return incidentStartTime <= dayEnd.valueOf() && incidentEndTime > dayStart.valueOf();
    });

    let Downtimes: any[] = [];
    let uptimePercent = 100;
    let sumDowntime = 0;

    if (!!incidentsOnDay?.length) {
      Downtimes = incidentsOnDay?.map((inc) => {
        const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
        const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();

        const startOfDay = dayStart.valueOf() 
        let endOfDay = dayEnd.valueOf()+1000

        // if (dayStart.isSame(moment().tz(tz), 'day')) {
        //   endOfDay = moment().tz(tz).valueOf(); // Set dayEnd to the current time for today
        // }

        const adjustedStartTime = Math.max(incidentStartTime, startOfDay);
        const adjustedEndTime = Math.min(incidentEndTime, endOfDay);

        const diff = calculateTimestampDifference(adjustedEndTime, adjustedStartTime);
        uptimePercent = uptimePercent - calculatePercentageOfDay(adjustedEndTime - adjustedStartTime,endOfDay-startOfDay);
        sumDowntime = sumDowntime + Math.abs(adjustedEndTime - adjustedStartTime);

        const text = `${diff} ${intl.formatMessage(
          { id: 'DESCRIPTIONS.UPTIME_INCIDENT' },
          {
            down: moment(adjustedStartTime).tz(tz).format('YYYY-MM-DD hh:mm:ss a'),
            up: moment(adjustedEndTime).tz(tz).format('YYYY-MM-DD hh:mm:ss a'),
            type: '',
          }
        )}`;
        return text;
      });
    }

    const date = `${dayStart.format('MMMM')} ${i}`;

    lastMonthArray.push({
      date,
      isActive,
      downtime: Downtimes,
      uptime: uptimePercent.toFixed(3),
      sumDowntime: calculateTimestampDifference(sumDowntime, 0)
    });
  }

  return lastMonthArray;
};




export const getDayArray = (createdAt: number, incidents: Record<string, any>[], intl: IntlShape,tz:string): HourObject[] => {
  const currentDate = moment().tz(tz);
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();
  
  const startOfMonth = moment().tz(tz).year(currentYear).month(currentMonth).date(1);
  const endOfMonth = moment().tz(tz).year(currentYear).month(currentMonth).endOf('month');

  const dayArray: HourObject[] = [];

  for (let i = startOfMonth.date(); i <= endOfMonth.date(); i++) {
    const dayStart = moment().tz(tz).year(currentYear).month(currentMonth).date(i).hour(0).minute(0).second(0);
    const dayEnd = moment().tz(tz).year(currentYear).month(currentMonth).date(i).hour(23).minute(59).second(59);
    const isActive = createdAt <= dayEnd.valueOf() && dayStart.valueOf() <= moment().tz(tz).valueOf();
    const incidentsOnDay = incidents?.filter((inc) => {
      const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
      const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();
      const startOfDay = dayStart.valueOf();
      const endOfDay = dayEnd.valueOf();
    
      const adjustedStartTime = Math.max(incidentStartTime, startOfDay);
      const adjustedEndTime = Math.min(incidentEndTime, endOfDay);
    
      return adjustedStartTime < adjustedEndTime;
      // (inc?.down_timestamp * 1000) <= dayEnd.valueOf() && (inc?.up_timestamp * 1000) > dayStart.valueOf()
    });

    let Downtimes: any[] = [];
    let uptimePercent = 100;
    let sumDowntime=0

    if (!!incidentsOnDay?.length) {
      Downtimes = incidentsOnDay?.map((inc) => {
        const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
        const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();
    
        // Adjust start and end times if the incident spans multiple days
        const startOfDay = dayStart.valueOf();
        let endOfDay = dayEnd.valueOf()+1000;

        // if (dayStart.isSame(moment().tz(tz), 'day')) {
        //   endOfDay = moment().tz(tz).valueOf(); // Set dayEnd to the current time for today
        // }
    
        const adjustedStartTime = Math.max(incidentStartTime, startOfDay);
        const adjustedEndTime = Math.min(incidentEndTime, endOfDay);
    
        let diff = calculateTimestampDifference(adjustedEndTime, adjustedStartTime);
        uptimePercent = uptimePercent - calculatePercentageOfDay(adjustedEndTime - adjustedStartTime,endOfDay-startOfDay);
        sumDowntime = sumDowntime + Math.abs(adjustedEndTime - adjustedStartTime);
    
        // Use adjusted start and end times for formatting in the specified timezone
        let text = diff + " " + intl.formatMessage({ id: "DESCRIPTIONS.UPTIME_INCIDENT" }, {
          down: moment(adjustedStartTime).tz(tz).format('YYYY-MM-DD hh:mm a'),
          up: moment(adjustedEndTime).tz(tz).format('YYYY-MM-DD hh:mm a'),
          type: ""
        });
        return text;
      });
    }

    const date = `${dayStart.format('MMMM')} ${i}`;

    dayArray.push({
      date,
      isActive,
      downtime: Downtimes,
      uptime: uptimePercent.toFixed(3),
      sumDowntime:calculateTimestampDifference(sumDowntime, 0)

    });
  }

  return dayArray;
};





export const getMonthArray = (createdAt: number, incidents: Record<string, any>[], intl: IntlShape, tz: string): HourObject[] => {
  const currentDate = moment().tz(tz);
  const currentYear = currentDate.year();

  const startOfYear = moment().tz(tz).year(currentYear).month(0).date(1);
  const endOfYear = moment().tz(tz).year(currentYear).month(11).endOf('month');

  const monthArray: HourObject[] = [];

  for (let i = startOfYear.month(); i <= endOfYear.month(); i++) {
    const monthStart = moment().tz(tz).year(currentYear).month(i).date(1).hour(0).minute(0).second(0);
    const monthEnd = moment().tz(tz).year(currentYear).month(i).endOf('month');
    const isActive = createdAt <= monthEnd.valueOf() && monthStart.valueOf() <= moment().tz(tz).valueOf();

 
    const incidentsOnDay = incidents?.filter((inc) => {
      const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
      const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();
      
      return incidentStartTime <= monthEnd.valueOf() && incidentEndTime > monthStart.valueOf();
    });

    let Downtimes: any[] = [];
    let uptimePercent = 100;
    let sumDowntime = 0;

    if (!!incidentsOnDay?.length) {
      Downtimes = incidentsOnDay?.map((inc) => {
        const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
        const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();

        const startOfMonth = monthStart.valueOf() ;
        let endOfMonth= monthEnd.valueOf() ;

        // if (monthStart.isSame(moment().tz(tz), 'month')) {
       
        //   endOfMonth = moment().tz(tz).valueOf(); // Set monthEnd to the current time for today
        // }

        const adjustedStartTime = Math.max(incidentStartTime, startOfMonth);
        const adjustedEndTime = Math.min(incidentEndTime, endOfMonth);
       
        
        const diff = calculateTimestampDifference(adjustedEndTime, adjustedStartTime);
        uptimePercent = uptimePercent - calculatePercentageOfMonth(adjustedEndTime - adjustedStartTime,endOfMonth-startOfMonth);
        sumDowntime = sumDowntime + Math.abs(adjustedEndTime - adjustedStartTime);

        const text = `${diff} ${intl.formatMessage(
          { id: 'DESCRIPTIONS.UPTIME_INCIDENT' },
          {
            down: moment(adjustedStartTime).tz(tz).format('YYYY-MM-DD hh:mm a'),
            up: moment(adjustedEndTime).tz(tz).format('YYYY-MM-DD hh:mm a'),
            type: '',
          }
        )}`;
        return text;
      });
    }

    const date = `${monthStart.format('MMMM')} ${currentYear}`;

    monthArray.push({
      date,
      isActive,
      downtime: Downtimes,
      uptime: uptimePercent.toFixed(3),
      sumDowntime: calculateTimestampDifference(sumDowntime, 0)
    });
  }

  return monthArray;
};


export  function calculatePercentageOf90Days(downtime: number,total:number): number {
  // const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  const percentage = (downtime / total) * 100;

  return +percentage.toFixed(3);
}

export const get90DaysArray = (incidents: Record<string, any>[], tz: string): {longestDowntimeIn90:number,totalDowntime:number,data: { name: string, data: { day: string, detail: string }[] }[]} => {
  const currentDate = moment().tz(tz);
  const currentYear = currentDate.year();
  const currentMonth = currentDate.month();

  const endOfRange = moment().tz(tz);

  const resultArray: { name: string, data: { day: string, detail: any ,dayStart:any,dayEnd:any }[]}[] = [];
 let longestDowntimeIn90=0
 let totalDowntime=0
  for (let i = 0; i < 90; i++) {
    const dayStart = moment().tz(tz).year(currentYear).month(currentMonth).date(endOfRange.date() - i).hour(0).minute(0).second(0);
    const dayEnd = moment().tz(tz).year(currentYear).month(currentMonth).date(endOfRange.date() - i).hour(23).minute(59).second(59);

    const incidentsOnDay = incidents?.filter((inc) => {
      const incidentStartTime = inc?.down_timestamp * 1000;
      const incidentEndTime = inc?.up_timestamp * 1000;
    
      const startOfDay = dayStart.valueOf();
      const endOfDay = dayEnd.valueOf() + 1000;
    
      const adjustedStartTime = Math.max(incidentStartTime, startOfDay);
      const adjustedEndTime = Math.min(incidentEndTime, endOfDay);
    
      return adjustedStartTime < adjustedEndTime;
    });
    
    let Downtimes:any[] = [];
    
    let sumDowntime = 0;

    if (!!incidentsOnDay?.length) {
      Downtimes= incidentsOnDay?.map((inc) => {
        const incidentStartTime = moment(inc?.down_timestamp * 1000).tz(tz).valueOf();
        const incidentEndTime = moment(inc?.up_timestamp * 1000).tz(tz).valueOf();
  
        const startOfDay = dayStart.valueOf();
        let endOfDay = dayEnd.valueOf() + 1000;
  
        const adjustedStartTime = Math.max(incidentStartTime, startOfDay);
        const adjustedEndTime = Math.min(incidentEndTime, endOfDay);
  
        let diff = calculateTimestampDifference(adjustedEndTime, adjustedStartTime);
       
  
        sumDowntime = sumDowntime + Math.abs(adjustedEndTime - adjustedStartTime);
        let text = diff + ` was down from ${moment(adjustedStartTime).tz(tz).format('YYYY-MM-DD hh:mm:ss a')} to ${moment(adjustedEndTime).tz(tz).format('YYYY-MM-DD hh:mm:ss a')}`;
        return text;
      });
    }

    const monthName = dayStart.format('MMM');
    const date = `${dayStart.format('DD')}`;

    let monthObject = resultArray.find(month => month.name === monthName);

    if (!monthObject) {
      monthObject = { name: monthName, data: [] };
      resultArray.unshift(monthObject);
    }
    longestDowntimeIn90=sumDowntime>longestDowntimeIn90? sumDowntime:longestDowntimeIn90
    totalDowntime=totalDowntime+sumDowntime
    monthObject.data.unshift({
      day: date,
      detail:{
        incidents:Downtimes,
        sumDowntime:calculateTimestampDifference(sumDowntime, 0,false),
        sumDowntime_unix:sumDowntime,
       
      },
      dayStart:dayStart.unix()*1000,
      dayEnd:dayEnd.unix()*1000
    });

  }


  return {data:resultArray,longestDowntimeIn90,totalDowntime};
};




export const returnTypeUptimeWidget=(type:string)=>{
  switch (type) {
    case "this_year":
      
      return "year"
  
      case "this_month":
      
      return "month"

      case "this_week":
      
        return "week"
        case "today":
      
        return "day"
    default:
      return type
  }
}


export const recipientsConvert=(recipients:Record<string,any>)=>{

  const convertedObj:any={
    sms:{},
    email:{},
    call:{},
    notification:{}
  }
  
   
      const mergedArrayEmail = [...(recipients?.dynamic?.email?.clients?.map?.(Number)??[]), ...(recipients?.dynamic?.email?.contacts?.map?.(Number)??[])];
      convertedObj.email.recipients=mergedArrayEmail
    

      const mergedArraySMS = [...(recipients?.dynamic?.sms?.clients?.map?.(Number)??[]), ...(recipients?.dynamic?.sms?.contacts?.map?.(Number)??[])];
      convertedObj.sms.recipients=mergedArraySMS

      const mergedArrayCall = [...(recipients?.dynamic?.call?.clients?.map?.(Number)??[]), ...(recipients?.dynamic?.call?.contacts?.map?.(Number)??[])];
      convertedObj.call.recipients=mergedArrayCall

      const mergedNotification= [...(recipients?.dynamic?.notification?.clients?.map?.(Number)??[]), ...(recipients?.dynamic?.notification?.contacts?.map?.(Number)??[])];
      convertedObj.notification.recipients=mergedNotification
    
  


    const smsArray = recipients?.static?.filter?.((obj:any) => !!obj.sms )??[];
    const smsResult = smsArray?.map?.((obj:any) => ({...obj, name: obj.name, sms: obj.sms }))??[];
    convertedObj.sms.customRecipients=smsResult
  
    
    const emailArray = recipients?.static?.filter?.((obj:any) => !!obj.email )??[];
    const emailResult = emailArray?.map?.((obj:any) => ({ ...obj,name: obj.name, email: obj.email }))??[];
    convertedObj.email.customRecipients=emailResult

  

  return convertedObj
}
export const injectWidgets=(data:Record<string,any>)=>{

  const cloneConfig:Record<string,any>={...data}
  const widgets:Record<string,any>={...data.widgets}
  let columns:Record<string,any>={...data.columns}

  if (!('widget-18' in widgets)) {
    widgets['widget-18']={id: 'widget-18', status: true, hasAccess: true}
    columns={
     ...columns,
     'column-1':{
      id:'column-1',
      widgetsIds:[ ...columns['column-1'].widgetsIds,'widget-18' ]
     }
    }
  }

  if (!('widget-19' in widgets)) {
    widgets['widget-19']={id: 'widget-19', status: true, hasAccess: true}
    columns={
     ...columns,
     'column-1':{
      id:'column-1',
      widgetsIds:[ ...columns['column-1'].widgetsIds,'widget-19' ]
     }
    }
  }
  if (!('widget-20' in widgets)) {
    widgets['widget-20']={id: 'widget-20', status: true, hasAccess: true}
    columns={
     ...columns,
     'column-1':{
      id:'column-1',
      widgetsIds:[ ...columns['column-1'].widgetsIds,'widget-20' ]
     }
    }
  }
return {
  ...cloneConfig,
  widgets,
  columns
}
}




export function convertByteByUnit(value: any, unit?: string) {
  if (typeof value !== 'undefined') {
    value = parseInt(value);

    if (isNaN(value) || value === 0) {
      return value;
    }

    let base = 1024;
    let sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    if (unit === 'gb') {
      return (value / Math.pow(base, 3)).toFixed(3) + ' ' + sizes[3];
    } else if (unit === 'mb') {
      return (value / Math.pow(base, 2)).toFixed(3) + ' ' + sizes[2];
    } else if (unit === 'kb') {
      return (value / base).toFixed(2) + ' ' + sizes[1];
    }

    const i = Math.floor(Math.log(value) / Math.log(base));
    return parseFloat((value / Math.pow(base, i)).toFixed(2)) + ' ' + sizes[i];
  }

  return value;
}


export function booleanToNumber(value: boolean): number {
  return value ? 1 : 0;
}


export function getTimeDifference(dateString: string): string {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return 'Invalid date';
  }

  const now = new Date();

  const timeDifferenceInMilliseconds = now.getTime() - date.getTime();

  const seconds = Math.floor(timeDifferenceInMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const months = Math.floor(days / 30);  // Approximation for months

  if (months > 0) {
    return `${months} ${months === 1 ? 'month' : 'months'} ago`;
  } else if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'} ago`;
  } else if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
  } else {
    return 'just now';
  }
}
function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatExpirationDate(value:string) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}
export function convertMBToGB(valueInMB: number) {
  if (typeof valueInMB !== 'number' || isNaN(valueInMB)) {
    return valueInMB;
  }

  const valueInGB = valueInMB / 1024;

  if (valueInGB < 1) {
    return `${valueInMB.toFixed(1)} MB`;
  } else {
    return `${valueInGB.toFixed(2)} GB`;
  }
}



export function removePartFromString(originalString: string, partToRemove: string | RegExp,replaceValue:string = ''): string {
  // Use the replace function to replace the partToRemove with an empty string
  const modifiedString = originalString?.replace?.(partToRemove, replaceValue);

  return modifiedString;
}
export function timestampToDate(timestamp: number): Date {
  // Multiply by 1000 to convert from seconds to milliseconds
  const milliseconds = timestamp * 1000;
  return new Date(milliseconds);
}

export function timestampToFormattedDate(timestamp: number, timezone?: string): string {
  const date = new Date(timestamp * 1000);

  // If timezone is provided, calculate time based on the timezone
  if (timezone) {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // Use 24-hour format
    };
    const formattedDate = date.toLocaleString('en-US', options);
    const [datePart, timePart] = formattedDate.split(', ');
    const [month, day, year] = datePart.split('/');
    return `${year}-${month}-${day} ${timePart}`;
  }

  // If no timezone is provided, use local time
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}





export function extractDNVariables(dnString: string): { [key: string]: string } {
  const regexPattern = /(\w+)=([^,]+)/g;
  const extractedVariables: { [key: string]: string } = {};

  let match;
  while ((match = regexPattern.exec(dnString)) !== null) {
    const key = match[1];
    const value = match[2];
    extractedVariables[key] = value;
  }

  return extractedVariables;
}

export const integrationIdWithIcon: any = {
  "24":"/media/icons/xmatters.svg",
  "23":"/media/icons/zapier.svg",
  "22":"/media/icons/whatsapp.svg",
  "5":"/media/icons/webhook.svg",
  "21":"/media/icons/telegram.svg",
  "19":"/media/icons/teams.svg",
  "12":"/media/icons/splunk.svg",
  "11":"/media/icons/spike.svg",
  "2":"/media/icons/mobile.svg",
  "3":"/media/icons/slack.svg",
  "10":"/media/icons/pushover.svg",
  "9":"/media/icons/pushbullet.svg",
  "20":"/media/icons/bell.svg",
  "8":"/media/icons/pager.svg",
  "13":"/media/icons/pagerduty.svg",
  "14":"/media/icons/opsgenie.svg",
  "15":"/media/icons/ntfy.svg",
  "7":"/media/icons/mattermost.svg",
  "17":"/media/icons/gotify.svg",
  "18":"/media/icons/google-chat.svg",
  "16":"/media/icons/discord.svg",
  "1":"/media/icons/envelope.svg",
  "6":"/media/icons/call.svg",
}
export function convertTimeString(input: string): string {
  if (!input) {
    return "";
  }

  // Regular expressions for each unit
  const daysRegex = /(\d+)\s*days?/i;
  const hoursRegex = /(\d+)\s*hours?/i;
  const minutesRegex = /(\d+)\s*minutes?/i;
  const secondsRegex = /(\d+)\s*seconds?/i;

  // Function to extract unit value or return 0 if not present
  const extractUnit = (regex: RegExp): number => {
    const match = input.match(regex);
    return match ? parseInt(match[1], 10) : 0;
  };

  // Extract values for each unit
  const days = extractUnit(daysRegex);
  const hours = extractUnit(hoursRegex);
  const minutes = extractUnit(minutesRegex);
  const seconds = extractUnit(secondsRegex);

  // Construct the result string with non-zero units
  const resultArray = [];
  if (days > 0) resultArray.push(`${days}D`);
  if (hours > 0) resultArray.push(`${hours}H`);
  if (minutes > 0) resultArray.push(`${minutes}M`);
  if (seconds > 0) resultArray.push(`${seconds}S`);

  // Join the non-zero units into the final result string
  const result = resultArray.join(' ');

  return result;
}

export const convertMillisecondsToString = (milliseconds: number): string => {
  const units = {
    milliseconds: 1,
    seconds: 1000,
    minutes: 60 * 1000,
    hours: 60 * 60 * 1000,
  };

  let remainingMilliseconds = milliseconds;
  const result: string[] = [];

  const addUnit = (value: number, unit: string) => {
    if (value > 0) {
      result.push(`${value.toFixed(0)} ${unit}`);
    }
  };

  addUnit(Math.floor(remainingMilliseconds / units.hours), 'hours,');
  remainingMilliseconds %= units.hours;

  addUnit(Math.floor(remainingMilliseconds / units.minutes), 'minutes,');
  remainingMilliseconds %= units.minutes;

  addUnit(Math.floor(remainingMilliseconds / units.seconds), 'seconds');

  return result.join(' ');
};


export const returnSubjectTransalte = (subject: string) => {
  switch (subject) {
    case 'packet_loss':
      return 'CHECK_INCIDENT_CAUSE.PING.packet_loss'
    case 'response_mismatch':
      return 'CHECK_INCIDENT_CAUSE.HTTP.response_mismatch'
    case 'host_not_resolve':
      return 'CHECK_INCIDENT_CAUSE.HTTP.host_not_resolve'
    case 'too_many_redirects':
      return 'CHECK_INCIDENT_CAUSE.HTTP.too_many_redirects'
    case 'empty_response':
      return 'CHECK_INCIDENT_CAUSE.HTTP.empty_response'
    case 'no_answer':
      return 'CHECK_INCIDENT_CAUSE.DNS.no_answer'
    // case 'timeout':
    //   return 'CHECK_INCIDENT_CAUSE.DNS.timeout'
    case 'answer_mismatch':
      return 'CHECK_INCIDENT_CAUSE.DNS.answer_mismatch'
    case 'authentication_error':
      return 'CHECK_INCIDENT_CAUSE.SMTP.authentication_failed'
    case 'invalid_host':
      return 'CHECK_INCIDENT_CAUSE.SMTP.invalid_host'
    case 'ssl_error':
      return 'CHECK_INCIDENT_CAUSE.IMAP.ssl_error'
    case 'ssl_certificate_error':
      return 'CHECK_INCIDENT_CAUSE.HTTP.ssl_certificate_error'
    case 'response_time_exceeded':
      return 'LABELS.RESPONSE.TIME.EXCEEDED'
    case 'heartbeat_did_not_hit':
      return 'LABELS.HEARTBEAT.DID.NOT.HIT'
    case "response_code_mismatch":
      return "LABELS.RESPONSE.CODE.MISMATCH"
    case "bad_request":
    return "LABELS.BAD.REQUEST"
    case "smtp_error":
      return "LABELS.SMTP.ERROR"
    case "connection_error":
      return "LABELS.INCIDENTS.CAUSE.connection_error"
    case "integration":
        return "LABELS.HEARTBEAT.INTEGRATION.CAUSE"
    case "no_hit":
         return 'LABELS.HEARTBEAT.DID.NOT.HIT'
    case "no_end_hit":
         return 'LABELS.HEARTBEAT.NO.END.HIT'    
    case "execution_time":
          return 'LABELS.HEARTBEAT.EXE.TOOKS'  
    case "condition_failed":
          return 'LABELS.HEARTBEAT.PAYLOAD.FAILD'  
    case "key_not_in_payload":
            return 'LABELS.HEARTBEAT.PAYLOAD.KEY.WRONG'
    case "empty_payload":
              return 'LABELS.HEARTBEAT.PAYLOAD.EMPTY'
    case "payload_value_not_float":
        return 'LABELS.HEARTBEAT.PAYLOAD.VALUE.NUMBER'      
    case "corrupt_payload":
        return 'LABELS.HEARTBEAT.PAYLOAD.CORRUPT'  
    default:
      return subject
  }
}


export function secondsToMinutes(seconds: number | string): string | null {
  const parsedSeconds = typeof seconds === 'string' ? parseFloat(seconds) : seconds;

  if (isNaN(parsedSeconds) || !isFinite(parsedSeconds)) {
    
    return "";
  }

  const minutes = parsedSeconds / 60;
  const pluralSuffix = minutes === 1 ? 'min' : 'mins';

  return `${minutes} ${pluralSuffix}`;
}

export function convertStringServices(input: string): { service: string; status: string }[] | null {
  try {
    const parsedArray = JSON.parse(input);

    if (!Array.isArray(parsedArray)) {
      
      return [];
    }

    let idCounter = 1;
    const resultArray = parsedArray.map((value: string) => {
      const match = value.match(/^(.*): (.*)$/);

      if (match && match.length === 3) {
        return {
          id: idCounter++,
          service: match[1],
          status: match[2],
        };
      } else {
     
        return [];
      }
    });

    return resultArray.filter((item) => item !== null) as { id:number,service: string; status: string }[];
  } catch (error) {
   
    return [];
  }
}

export const convertTimezone = (date:string,tz:string,format:string) => {
  const formattedDate = moment.utc(date).tz(tz).format(format);
  
    return formattedDate
} 

export function sortArrayByCreatedAt(originalArray: Record<string, any>[],reverse:boolean=false): Record<string, any>[] {
  return [...originalArray].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);

    return reverse ?dateB.getTime() - dateA.getTime() :  dateA.getTime() - dateB.getTime();
  });
}

export const getDatePeriod = (period: string, tz: string) => {
  const currentDate = moment().tz(tz);
  let startDate, endDate;

  switch (period) {
    case 'today':
      startDate = moment().startOf('day').tz(tz);
      startDate.set({
        date: currentDate.date(),
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      });
      endDate = currentDate;
      break;
    case 'this_week':
      startDate = moment().startOf('isoWeek')
      endDate = currentDate;
      break;
    case 'this_month':
      startDate = moment().startOf('month');
      endDate = currentDate;     
      break;
    case 'last_month':
      startDate = moment().subtract(1, 'months').startOf('month');
      endDate = moment().subtract(1, 'months').endOf('month').tz(tz);
      break;
    case 'this_year':
      startDate = moment().startOf('year');
      endDate = currentDate;
      break;
    default:
      startDate = moment().startOf('month').tz(tz);
      endDate = currentDate;
      break;
  }

  return {
    startDate: startDate.format('DD MMM HH:mm'),
    endDate: endDate.format('DD MMM HH:mm')
  };
};

interface IntervalMessages {
  [key: number]: { id: string; params: { [key: string]: string } };
}

export const  getRepeatMessage = (selectedValue: number, intl: any): string => {
  const intervalMessages: IntervalMessages = {
      0: { id: 'LABELS.NONE', params: {} },
      300: { id: 'REPEAT.EACH.MIN', params: { min: '5' } },
      600: { id: 'REPEAT.EACH.MIN', params: { min: '10' } },
      1800: { id: 'REPEAT.EACH.MIN', params: { min: '30' } },
      3600: { id: 'REPEAT.EACH.HOUR', params: { hour: '1' } },
      7200: { id: 'REPEAT.EACH.HOURS', params: { hour: '2' } },
      10800: { id: 'REPEAT.EACH.HOURS', params: { hour: '3' } },
      21600: { id: 'REPEAT.EACH.HOURS', params: { hour: '6' } },
      43200: { id: 'REPEAT.EACH.HOURS', params: { hour: '12' } },
  };

  return intervalMessages[selectedValue]? intl.formatMessage({ id: intervalMessages[selectedValue].id }, intervalMessages[selectedValue].params) :selectedValue;
};
const humanText = (text: string, intl: IntlFormatters) => {
  switch (text) {
    case 'req_per_sec':
      return intl.formatMessage({id: 'LABELS.REQ.PER.SEC'})
    case 'bytes_per_sec':
      return intl.formatMessage({id: 'LABELS.BYTES.PER.SEC'})

    case 'load_1':
      return intl.formatMessage({id: 'lABEL.LOAD.1'})
    case 'load_5':
      return intl.formatMessage({id: 'lABEL.LOAD.5'})
    case 'load_15':
      return intl.formatMessage({id: 'lABEL.LOAD.15'})

    case 'bytes_per_req':
      return intl.formatMessage({id: 'LABELS.BYTES.PER.REQ'})

    case 'duration_per_req':
      return intl.formatMessage({id: 'LABELS.DURATION.PER.REQ'})

    case 'connected_clients':
      return intl.formatMessage({id: 'LABELS.KEYDB.CONNECTED_CLIENTS'})

    case 'rejected_conns':
      return intl.formatMessage({id: 'LABELS.TRIGGER.rejected_conns'})

    case 'used_memory':
      return intl.formatMessage({id: 'LABELS.KEYDB.USED_MEMORY'})

    case 'used_cpu_user':
      return intl.formatMessage({id: 'LABELS.KEYDB.USED_CPU_USER'})

    case 'r_per_sec':
      return intl.formatMessage({id: 'LABELS.TRIGGER.r_per_sec'})
    case 'w_per_sec':
      return intl.formatMessage({id: 'LABELS.TRIGGER.w_per_sec'})
    case 'total_keys':
      return intl.formatMessage({id: 'LABELS.KEYDB.TOTAL_KEYS'})

    case '0':
      return 'DB 0'
    case '1':
      return 'DB 1'
    case '2':
      return 'DB 2'
    case '3':
      return 'DB 3'
    case '4':
      return 'DB 4'
    case '5':
      return 'DB 5'
    case 'active_processes':
      return intl.formatMessage({id: 'LABELS.FPM.ACTIVE.PROCESSES'})
    case 'idle_processes':
      return intl.formatMessage({id: 'LABELS.FPM.IDLE_PROCESSES'})
    case 'request_memory':
      return intl.formatMessage({id: 'LABELS.FPM.TRIGGER.REQUEST_MEMORY'})
    case 'request_cpu':
      return intl.formatMessage({id: 'LABELS.FPM.TRIGGER.REQUEST_CPU'})
    case 'listen_queue':
      return intl.formatMessage({id: 'LABELS.FPM.LISTEN_QUEUE'})
    case 'conn_current':
      return intl.formatMessage({id: 'LABELS.CURRENT.CONNECTIONS'})
    case 'conn_active':
      return intl.formatMessage({id: 'LABELS.ACTIVE.CONNECTIONS'})
    case 'active_sess':
      return intl.formatMessage({id: 'LABELS.ACTIVE.SESSIONS'})
    case 'part_containermemory':
      return intl.formatMessage({id: 'LABELS.part_containermemory'})
    case 'part_containercpu':
      return intl.formatMessage({id: 'LABELS.part_containercpu'})
    case 'up_containers_count':
      return intl.formatMessage({id: 'LABELS.TRIGGER.up_containers_count'})
    case 'part_qcur':
      return intl.formatMessage({id: 'LABELS.QUEUED'})
    case 'part_scur':
      return intl.formatMessage({id: 'LABELS.SESSIONS'})
    case 'part_bin':
      return intl.formatMessage({id: 'LABELS.RQ.BYTE'})
    case 'part_bout':
      return intl.formatMessage({id: 'LABELS.RSP.BYTE'})
    case 'part_req_rate':
      return intl.formatMessage({id: 'LABELS.RQS'})
    case 'part_hrsp_2xx':
      return intl.formatMessage({id: 'LABELS.2XX'})
    case 'part_hrsp_4xx':
      return intl.formatMessage({id: 'LABELS.4XX'})
    case 'part_hrsp_5xx':
      return intl.formatMessage({id: 'LABELS.5XX'})
    case 'part_lastsess':
      return intl.formatMessage({id: 'LABELS.LAST.SESSIONS'})
    case 'part_rate':
      return intl.formatMessage({id: 'LABELS.RATE'})
    case 'part_conn_rate':
      return intl.formatMessage({id: 'LABELS.CONNECTION.RATE'})
    case 'requests':
      return intl.formatMessage({id: 'LABELS.REQUESTS'})
    case 'active':
      return intl.formatMessage({id: 'ECOMMERCE.COMMON.ACTIVE'})
    case 'accepted':
      return intl.formatMessage({id: 'LABELS.ACCEPTED'})
    case 'handled':
      return intl.formatMessage({id: 'LABELS.HANDLED'})
    case 'reading':
      return intl.formatMessage({id: 'LABELS.READING'})
    case 'writing':
      return intl.formatMessage({id: 'LABELS.WRITING'})
    case 'waiting':
      return intl.formatMessage({id: 'LABELS.WAITING'})
    case 'cpu':
      return intl.formatMessage({id: 'PROPERTIES.CPU'})
    case 'memory':
      return intl.formatMessage({id: 'LABELS.MEMORY'})
    case 'conn_current':
      return intl.formatMessage({id: 'LABELS.CURRENT.CONNECTIONS'})
    case 'conn_active':
      return intl.formatMessage({id: 'LABELS.ACTIVE.CONNECTIONS'})
    case 'active_sess':
      return intl.formatMessage({id: 'LABELS.ACTIVE.SESSIONS'})
    case 'insert':
      return `${intl.formatMessage({id: 'LABELS.INSERT'})} ${intl.formatMessage({
        id: 'LABELS.PER.SECOND',
      })}`
    case 'query':
      return `${intl.formatMessage({id: 'LABELS.QUERY'})} ${intl.formatMessage({
        id: 'LABELS.PER.SECOND',
      })}`
    case 'update':
      return `${intl.formatMessage({id: 'LABELS.UPDATE'})} ${intl.formatMessage({
        id: 'LABELS.PER.SECOND',
      })}`
    case 'delete':
      return `${intl.formatMessage({id: 'ACTIONS.DELETE'})} ${intl.formatMessage({
        id: 'LABELS.PER.SECOND',
      })}`
    case 'command':
      return `${intl.formatMessage({id: 'LABELS.SERVER_COMMAND'})} ${intl.formatMessage({
        id: 'LABELS.PER.SECOND',
      })}`
    case 'getmore':
      return `${intl.formatMessage({id: 'LABELS.GETMORE'})} ${intl.formatMessage({
        id: 'LABELS.PER.SECOND',
      })}`
    case 'active_connections':
      return intl.formatMessage({id: 'LABELS.ACTIVE.CONNECTIONS'})
    case 'idle_connections':
      return intl.formatMessage({id: 'LABELS.IDLE_CONNECTIONS'})
    case 'cache_hit':
      return intl.formatMessage({id: 'LABELS.CACHE_HIT'})
    case 'total_disk_space':
      return intl.formatMessage({id: 'LABELS.TOTAL_DISK_SPACE'})
    case 'database_deadlocks':
      return intl.formatMessage({id: 'LABELS.DATABASE_DEADLOCKS'})
    case 'active_queries':
      return intl.formatMessage({id: 'LABELS.ACTIVE_QUERIES'})
    case 'waiting_queries':
      return intl.formatMessage({id: 'LABELS.WAITING_QUERIES'})
    case 'committed_transactions':
      return intl.formatMessage({id: 'LABELS.COMMITTED_TRANSACTIONS'})
    case 'rollback_transactions':
      return intl.formatMessage({id: 'LABELS.ROLLBACK_TRANSACTIONS'})
    case 'read_disk_blocks':
      return intl.formatMessage({id: 'LABELS.READ_DISK_BLOCKS'})
    case 'rows_returned':
      return intl.formatMessage({id: 'LABELS.ROWS_RETURNED'})
    case 'rows_selected':
      return intl.formatMessage({id: 'LABELS.ROWS_SELECTED'})
    case 'rows_inserted':
      return intl.formatMessage({id: 'LABELS.ROWS_INSERTED'})
    case 'rows_updated':
      return intl.formatMessage({id: 'LABELS.ROWS_UPDATED'})
    case 'rows_deleted':
      return intl.formatMessage({id: 'LABELS.ROWS_DELETED'})
    case 'current_connections':
      return intl.formatMessage({id: 'LABELS.CURRENT_CONNECTIONS'})
    case 'bytes_received_persec':
      return intl.formatMessage({id: 'LABELS.BYTES_RECEIVED_PERSEC'})
    case 'bytes_sent_persec':
      return intl.formatMessage({id: 'LABELS.BYTES_SENT_PERSEC'})
    case 'bytes_total_persec':
      return intl.formatMessage({id: 'LABELS.BYTES_TOTAL_PERSEC'})
    case 'delete_requests_persec':
      return intl.formatMessage({id: 'LABELS.DELETE_REQUESTS_PERSEC'})
    case 'get_requests_persec':
      return intl.formatMessage({id: 'LABELS.GET_REQUESTS_PERSEC'})
    case 'head_requests_persec':
      return intl.formatMessage({id: 'LABELS.HEAD_REQUESTS_PERSEC'})
    case 'options_requests_persec':
      return intl.formatMessage({id: 'LABELS.OPTIONS_REQUESTS_PERSEC'})
    case 'post_requests_persec':
      return intl.formatMessage({id: 'LABELS.POST_REQUESTS_PERSEC'})
    case 'put_requests_persec':
      return intl.formatMessage({id: 'LABELS.PUT_REQUESTS_PERSEC'})
    case 'total_method_requests_persec':
      return intl.formatMessage({id: 'LABELS.TOTAL_METHOD_REQUESTS_PERSEC'})
    case 'sess_conn':
      return intl.formatMessage({id: 'LABELS.SESSION.CONNECTION.PER.SECOND'})
    case 'sess_queued':
      return intl.formatMessage({id: 'LABELS.SESSION.QUEUED.PER.SECOND'})
    case 'sess_dropped':
      return intl.formatMessage({id: 'LABELS.SESSION.DROPPED.PER.SECOND'})
    case 'client_req':
      return intl.formatMessage({id: 'LABELS.CLIENT.REQUEST.PER.SECOND'})
    case 'cache_hit':
      return intl.formatMessage({id: 'LABELS.CACHE_HIT.PER.SECOND'})
    case 'cache_miss':
      return intl.formatMessage({id: 'LABELS.CACHE.MISS.PER.SECOND'})
    case 'cache_hit_rate':
      return intl.formatMessage({id: 'LABELS.CACHE.HIT.RATE'})
    case 'backend_busy':
      return intl.formatMessage({id: 'LABELS.BACKEND.BUSY.PER.SECOND'})
    case 'backend_conn':
      return intl.formatMessage({id: 'LABELS.BACKEND.CONNECTION.PER.SECOND'})
    case 'backend_fail':
      return intl.formatMessage({id: 'LABELS.BACKEND.FAIL.PER.SECOND'})
    case 'backend_req':
      return intl.formatMessage({id: 'LABELS.BACKEND.REQUEST.PER.SECOND'})
    case 'backend_toolate':
      return intl.formatMessage({id: 'LABELS.BACKEND.TOOLATE.PER.SECOND'})
    case 'backend_recycle':
      return intl.formatMessage({id: 'LABELS.BACKEND.RECYCLE.PER.SECOND'})
    case 'backend_reuse':
      return intl.formatMessage({id: 'LABELS.BACKEND.REUSE.PER.SECOND'})
    case 'backend_unhealthy':
      return intl.formatMessage({id: 'LABELS.BACKEND.UNHEALTHY.PER.SECOND'})
    case 'cache_hitpass':
      return intl.formatMessage({id: 'LABELS.CACHE.HIT.PASS.PER.SECOND'})
    case 'esi_errors':
      return intl.formatMessage({id: 'LABELS.ESI.ERRORS.PER.SECOND'})
    case 'esi_warnings':
      return intl.formatMessage({id: 'LABELS.ESI.WARNINGS.PER.SECOND'})
    case 'n_expired':
      return intl.formatMessage({id: 'LABELS.N.EXPIRED.PER.SECOND'})
    case 'n_lru_nuked':
      return intl.formatMessage({id: 'LABELS.N.LRU.NUKED.PER.SECOND'})
    case 'sent_octets':
      return intl.formatMessage({id: 'LABELS.SENT.OCTETS'})

    case 'received_octets':
      return intl.formatMessage({id: 'LABELS.SENT.OCTETS'})

    case 'part_queues':
      return intl.formatMessage({id: 'LABELS.QUEUES'})
    case 'part_overview':
      return intl.formatMessage({id: 'LABELS.OVERVIEW'})
    case 'part_nodes':
      return intl.formatMessage({id: 'LABLES.NODES'})
    case 'part_exchanges':
      return intl.formatMessage({id: 'LABELS.EXCHANGES'})
    default:
      return text
  }
}

export const triggerToString = (data: Record<string, any>, type: number, intl: IntlFormatters) => {
  const metrics = [1, 2, 3, 4, 5, 6, 111, 112, 113, 114, 115,118,119,122,123,124]
  const typesWithNoResponse=[23,24,25,26,27]
  if (!data) {
    return ''
  }
  let name = humanText(data?.name ?? '', intl) || ''
  let operator = data?.operator?intl.formatMessage({id: data?.operator||""})?.toLowerCase():""
  let unit = data?.unit?.toUpperCase() ?? ''
  let value = data?.value
  let FT = data?.fault_tolerance

  if (metrics.includes(type)) {
    if (type === 1) {
      name = name === '1min' ? 'Load1' : name === '5min' ? 'Load5' : 'Load15'
    }

    if (type===6 && data?.net_type==='Packets') {
      return intl.formatMessage({id:"LABELS.NETWORK.PACKET.TRIGGER"},{
        name,value,op:operator,ft:FT,traffic:data?.traffic_type,
        unit:data?.unit==="kb"?intl.formatMessage({id:"LABELS.KILO.PACKETS"}):data?.unit==="mb"?intl.formatMessage({id:"LABELS.MEGA.PACKETS"}):intl.formatMessage({id:"LABELS.PACKETS"})
      })
    }
    if (type===6 && data?.net_type==='Dropped') {
      return intl.formatMessage({id:"LABELS.NETWORK.DROPPED.PACKET.TRIGGER"},{
        name,value,op:operator,ft:FT,traffic:data?.traffic_type,
        unit:data?.unit==="kb"?intl.formatMessage({id:"LABELS.KILO.PACKETS"}):data?.unit==="mb"?intl.formatMessage({id:"LABELS.MEGA.PACKETS"}):intl.formatMessage({id:"LABELS.PACKETS"})
      })
    }
    if (type===6 && data?.net_type==='Byte') {
      return intl.formatMessage({id:"LABELS.NETWORK.TRIGGER"},{
        name,value,op:operator,ft:FT,traffic:data?.traffic_type,
        unit
      })
    }
    return `${name} ${operator} ${value}${unit} ${intl.formatMessage(
      {id: 'LABELS.FOR.MINS'},
      {ft: FT}
    )} `
  }

  if (typesWithNoResponse.includes(type)) {
    return intl.formatMessage({id: 'LABELS.NOT.RESPONSE.TRIGGER'}, {ft: FT})
    
  }

  if (type === 116) {
    return intl.formatMessage({id: 'LABELS.SUPERVISOR.TRIGGER'}, {part: data?.part, value, ft: FT,op:operator})
  }

  if (type === 20) {
    return intl.formatMessage({id: 'LABELS.PING.TRIGGER'}, {ft: FT, value})
  }

  if (type===120) {
    return intl.formatMessage(
      {id: 'LABELS.HAPROXY.PART.TRIGGER'},
      {part: data?.part, value, ft: FT, name, op: operator}
    )
  }
  if (type===121) {
    if (data?.part?.length>1) {
      return intl.formatMessage(
        {id: 'LABELS.SERVICES.TRIGGER'},
        {len: data?.part?.length-1, value, ft: FT, name:data?.part[0], op: operator}
      )
    }else{
      return intl.formatMessage(
        {id: 'LABELS.SERVICE.TRIGGER'},
        { value, ft: FT, name:data?.part[0], op: operator}
      )
    }

  }
  if (type === 117) {
    if (data?.part) {
      return intl.formatMessage(
        {id: 'LABELS.DOCKER.PART.TRIGGER'},
        {part: data?.part, value, ft: FT, name, op: operator}
      )
    }else{
      return `${name} ${operator} ${value} ${intl.formatMessage(
        {id: 'LABELS.FOR.MINS'},
        {ft: FT}
      )} `
    }
  }

  if (type===126) {
    
    if (data?.part) {
      return intl.formatMessage(
        {id: 'LABELS.RABBITMQ.TRIGGER.PART'},
        {
          value,
          ft:FT,
          part: convertPartToString(data),
          op: operator
        }
      )
    }else{
      return intl.formatMessage(
        {id: 'LABELS.RABBITMQ.TRIGGER'},
        {
          value,
          ft:FT,
          name,
          op: operator
        }
      )
    }

  }

  if(type===127){
    return intl.formatMessage(
      {id: 'LABELS.COUCHDB.TRIGGER.PART'},
      {
        value,
        ft:FT,
        part: convertPartToString(data),
        op: operator
      }
    )
  }
  
  if (type===125) {
    
    if (data?.part && data?.part !=="all") {
      return intl.formatMessage(
        {id: 'LABELS.DISK.PART.TRIGGER'},
        {
          value:intl.formatMessage({id:value==="1"?"LABELS.HEALTHY":"LABELS.NOT.HEALTHY"}).toLowerCase(),
          ft:FT,
          part: data?.part,
          op: operator
        }
      )
    }else{
      return intl.formatMessage(
        {id: 'LABELS.DISK.ALL.TRIGGER'},
        {
          value:intl.formatMessage({id:value==="1"?"LABELS.HEALTHY":"LABELS.NOT.HEALTHY"}).toLowerCase(),
          ft:FT,
          op: operator
        }
      )
    }
  }

  if (type === 21) {    
    let isHeaderAndBodyEnable = data?.condition_enabled && data?.header_condition_enabled
    let isJustBodyEnable = data?.condition_enabled && !data?.header_condition_enabled
    let isJustHeaderEnable = !data?.condition_enabled && data?.header_condition_enabled

    const headerItem = data?.header_expected_responses?.map?.((item:any) => `"${item.text}"`);
    const bodyItem = data?.expected_responses?.map?.((item:any) => `"${item.text}"`);

    return intl.formatMessage(
      {
        id: isHeaderAndBodyEnable
          ? 'LABELS.HTTP.BODY.HEADER.TRIGGER'
          : isJustBodyEnable
          ? 'LABELS.HTTP.BODY.TRIGGER'
          : isJustHeaderEnable
          ? 'LABELS.HTTP.HEADER.TRIGGER'
          : 'LABELS.HTTP.TRIGGER',
      },
      {
        ft: FT,
        rt: data?.response_time,
        rc: data?.response_code == "custom" ? data?.response_code_custom : `${data?.response_code}xx`,
        operator: data?.response_code_operator === 'neq' ? intl.formatMessage({id:'LABELS.NOT'}) : '',
        header: removePartFromString(data?.header_condition,/_/g, ' ') ,
        headerItem: !!data?.header_expected_responses?.[0]?.text && `(${headerItem.join(' , ')})`,
        body:removePartFromString(data?.condition,/_/g, ' '),
        bodyItem: !!data?.expected_responses?.[0]?.text && `(${bodyItem.join(' , ')})`,
      }
    )
  }

  if (type === 28 || type===29) {
    let isTimeAndConditionEnable = data?.condition_enabled && data?.response_time
    let isJustConditionEnable = data?.condition_enabled 
    let isJustTimeEnable = data?.response_time

    return intl.formatMessage(
      {
        id: isTimeAndConditionEnable
          ? 'LABELS.TCP.CONDITION.TIME.TRIGGER'
          : isJustConditionEnable
          ? 'LABELS.TCP.CONDITION.TRIGGER'
          : isJustTimeEnable
          ? 'LABELS.TCP.TIME.TRIGGER'
          : 'LABELS.TCP.TRIGGER',
      },
      {
        ft: FT,
      }
    )
  }

  if (type === 22) {
    return intl.formatMessage({id: 'LABELS.DNS.TRIGGER'}, {result: data?.expected_result, ft: FT})
  }

  if (type === 30) {
    return intl.formatMessage({id: 'LABELS.HEARTBEAT.TRIGGER'}, {ft: FT})
  }

  if (type==7) {
    return intl.formatMessage({id: 'LABELS.BANDWIDTH.TRIGGER'}, {
      value,
      ft:FT,
      name,
      op: operator,
      unit,
      traffic:data?.traffic_type,
      period:data?.period?.replace("_",' '),
    })
    
  }

  return ''
}

export const convertRabbitPartsToObject = (str: string): any => {
  const parts = str.split(/[\[\]]/).filter(Boolean);

  const obj: any = {};
  parts.forEach((part, index) => {
    obj[`part_l${index + 1}`] = part;
  });

  return obj;
}

export const convertCouchDBPartstoObject= (str: string): any => {
  const parts = str.split(/[\[\]]/).filter(Boolean);
  parts.shift()

  const obj: any = {};
  parts.forEach((part, index) => {
    obj[`part_l${index + 1}`] = part;
  });

  return obj;
}

export const extractNamefromParts= (str: string): any => {
  const parts = str.split(/[\[\]]/).filter(Boolean);
 const name= parts.shift()

  

  return name
}


export const convertPartsToObject = (str: string): any => {
  const parts = str.split(/[\[\]]/).filter(Boolean);

  const obj: any = {};
  parts.forEach((part, index) => {
    obj[`part_l${index + 1}`] = part;
  });

  return obj;
}

interface FlattenedObject {
  [key: string]: any;
}

export const flattenObject = (obj: { [key: string]: any }, parentPath: string = ''): FlattenedObject => {
  let result: FlattenedObject = {};

  Object.keys(obj).forEach(key => {
    const fullPath = parentPath ? `${parentPath}.${key}` : key;
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item:any, index:number) => {
        const arrayFullPath = `${fullPath}[${index}]`;
        if (typeof item === 'object' && item !== null) {
          result = { ...result, ...flattenObject(item, arrayFullPath) };
        } else {
          result[arrayFullPath] = item;
        }
      });
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      result = { ...result, ...flattenObject(obj[key], fullPath) };
    } else {
      result[fullPath] = String(obj[key]);
    }
  });

  return result;
};

export function formatDuration(milliseconds: number): string {
  const SECONDS_IN_A_YEAR = 365 * 24 * 60 * 60;
  const SECONDS_IN_A_MONTH = 30 * 24 * 60 * 60; // Approximation
  const SECONDS_IN_A_DAY = 24 * 60 * 60;
  const SECONDS_IN_AN_HOUR = 60 * 60;
  const SECONDS_IN_A_MINUTE = 60;

  let seconds = Math.floor(milliseconds / 1000);

  const years = Math.floor(seconds / SECONDS_IN_A_YEAR);
  seconds %= SECONDS_IN_A_YEAR;
  const months = Math.floor(seconds / SECONDS_IN_A_MONTH);
  seconds %= SECONDS_IN_A_MONTH;
  const days = Math.floor(seconds / SECONDS_IN_A_DAY);
  seconds %= SECONDS_IN_A_DAY;
  const hours = Math.floor(seconds / SECONDS_IN_AN_HOUR);
  seconds %= SECONDS_IN_AN_HOUR;
  const minutes = Math.floor(seconds / SECONDS_IN_A_MINUTE);
  seconds %= SECONDS_IN_A_MINUTE;

  const parts: string[] = [];
  if (years > 0) parts.push(`${years} year${years > 1 ? 's' : ''}`);
  if (months > 0) parts.push(`${months} month${months > 1 ? 's' : ''}`);
  if (days > 0) parts.push(`${days} day${days > 1 ? 's' : ''}`);
  if (hours > 0) parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  if (minutes > 0) parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  if (seconds > 0) parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);

  return parts.join(' ');
}

export const triggersValidateData = (triggers: any[]) => {
  if (!triggers) {
    return []
  }
  return triggers?.map?.((trigger) => ({
    ...trigger,
    data:{
      ...trigger?.data,
      fault_tolerance_up: trigger?.data?.fault_tolerance_up ?? '0',
      confirm_node_count: trigger?.confirm_node_count ?? '2',
      up_confirm_node_count: trigger?.up_confirm_node_count ?? '1',

    }
  }))
}


export const calculateSumNodes = (selectedIds: number[],regionsData:any) => {
  let sum = 0
  for (const region of regionsData) {
    if (selectedIds.includes(region.id)) {
      sum += region?.nodes?.length || 0
    }
  }
  return sum
}
export const urlGenerator = (check: any) => {
  const Type = check?.data?.ssl ? "https" : "http"

  const host = removeHttpPrefix(check?.data?.host)
  const port =
    check?.data?.port &&
      check?.data?.port !== '80' &&
      check?.data?.port !== '443' ? (
      `:${check?.data?.port}`
    ) : (
      ''
    )

  // const ssl = check?.data?.ssl ? <span className=''> (ssl)</span> : <></>
  const path = check?.data?.url ? check?.data?.url : ''
 
  return `${Type}://${host}${port}${path}`
}
// Function to sort an object with the 'total' key last
export function sortObjectByTotal(obj: Record<string,any>): Record<string,any> {
  const sortedObj: Record<string,any> = {};
  const keys = Object.keys(obj).sort((a, b) => {
    if (a === "total") return 1; // Move 'total' to the end
    if (b === "total") return -1; // Move 'total' to the end
    return a.localeCompare(b); // Default alphabetical sort
  });

  keys.forEach(key => {
    const value = obj[key];
    sortedObj[key] = (typeof value === "object" && value !== null && !Array.isArray(value))
      ? sortObjectByTotal(value)
      : value;
  });

  return sortedObj;
}

export const filterNetworkData = (data: any, keysToKeep: string[]) => {
  const filterKeys = (section: any) => {
    return Object.keys(section || {})
      .filter(key => keysToKeep.includes(key))
      .reduce((obj:any, key) => {
        obj[key] = section[key];
        return obj;
      }, {});
  };

  return {
    ...data,
    data_sources: {
      average: filterKeys(data?.data_sources?.average),
      min: filterKeys(data?.data_sources?.min),
      max: filterKeys(data?.data_sources?.max),
    },
  };
}

export const regexForLink=/^(?!^\.)(http[s]?\:\/\/)?((\w+)\.)?(([\w-]+)?)(\.[\w-]+)?(([\w-]+)?)(\.[\w-]+)?(([\w-]+)?)(\.[\w-]+)?(([\w-]+)?)(\.[\w-]+){1,2}$/
export const findFavoriteRole=(roles:any[])=>{

  if (!roles?.length) {
     return []
  }
  const favRole=roles?.find((ro:Record<string,any>)=>!!ro?.default)

  if (favRole) {
  return favRole?.id ? [favRole?.id] : []
    
  } else {
  return roles?.[0]?.id ? [roles?.[0]?.id] : []
    
  }


}




type DayData = {
  day: string;
  dayStart: number;
  dayEnd: number;
};

type MonthData = {
  name: string;
  data: DayData[];
};

export function generateMonthsWithDays(): MonthData[] {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const currentYear = dayjs().year();
  const result: MonthData[] = months.map((month, index) => {
    const totalDays = dayjs(`${currentYear}-${index + 1}-01`).daysInMonth();
    const days: DayData[] = [];

    for (let day = 1; day <= totalDays; day++) {
      const dayString = dayjs(`${currentYear}-${index + 1}-${day}`).format('DD');
      const dayStart = dayjs(`${currentYear}-${index + 1}-${day}`).startOf('day').valueOf();
      const dayEnd = dayjs(`${currentYear}-${index + 1}-${day}`).endOf('day').valueOf();
      days.push({
        day: dayString,
        dayStart,
        dayEnd,
      });
    }

    return {
      name: month,
      data: days,
    };
  });

  return result;
}


export function convertSelectedDaysBinaryToArray(input: string): number[] {
  const selectedDays: number[] = [];
  
  for (let i = 0; i < input?.length; i++) {
    if (input[i] === '1') {
      // Add 1 to i because days are 1-indexed (Monday is 1, Sunday is 7)
      selectedDays.push(i + 1);
    }
  }
  
  return selectedDays;
}
export const smartConvertHour = (hours: number) => {
  const totalDays = Math.floor(hours / 24);
  const totalMonths = Math.floor(totalDays / 30);
  const totalYears = Math.floor(totalDays / 365);

  const remainingDaysAfterYears = totalDays % 365;
  const remainingMonths = Math.floor(remainingDaysAfterYears / 30);
  const remainingDays = remainingDaysAfterYears % 30;
  const remainingHours = Math.floor(hours % 24);

  if (totalYears > 0) {
    return `${totalYears} year(s), ${remainingMonths} month(s), ${remainingDays} day(s) and ${remainingHours} hour(s)`;
  } else if (totalMonths > 0) {
    return `${totalMonths} month(s), ${remainingDays} day(s), and ${remainingHours} hour(s)`;
  } else if (totalDays > 0) {
    return `${totalDays} day(s) and ${remainingHours} hour(s)`;
  } else {
    return `${Math.floor(hours)} hour(s)`;
  }
};
/**
 * Converts an array of strings to an array of unique numbers.
 * 
 * @param {any[]} array - The input array containing string representations of numbers.
 * @returns {number[]} A unique array of numbers derived from the input strings.
 *                     If the input array is null or undefined, it returns an empty array.
 */
 export const uniqueArrayNumber = (array: any[]): number[] => {
  if (!array) {
    return [];
  }
  return Array.from(new Set(array.map(Number)));
};


export function generateCurlHeartbeat(data:Record<string,any>) {
  
  let method =''
  let url = data?.heartbeat_url;
  
  if (data?.state) {
    url=url+`?state=${data?.state}`
  }
  const postData = {
      data:{
         [data?.payload?.key]:data?.payload?.value,
      }
  };
  
  if (data?.payload?.key) {
     method ='-X POST'
  }

  let curlCommand = `curl ${method} ${url} `;

  if (data?.payload?.key) {
    curlCommand=curlCommand + `-H "Content-Type: application/json" -d '${JSON.stringify(postData)}'`
  }

  return curlCommand.trim();
}

// @TODO remove this after library update https://hsndev.atlassian.net/browse/XR-1058
export const isUtcOrEquivalent = (timezone?: string) => {
  const utcZones = [
    'UTC',
    'Europe/Dublin',
    'Atlantic/Canary',
    'Africa/Casablanca',
    'Europe/Lisbon',
    'Europe/London'
  ];
  
  return utcZones.includes(timezone || '');
}
