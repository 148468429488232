import {types} from './actionTypes'

export const getOrdersPendingCountAction = () => ({
  type: types.GET_ORDERS_PENDING_COUNT,
  
})


export const getClientPeriodsAction = () => ({
  type: types.GET_CLIENT_PERIODS,
  
})

export const getInvoicesUnpaidCountAction = () => ({
  type: types.GET_INVOICES_UNPAID_COUNT,
  
})

export const getSMSCreditsAvailableAction = () => ({
  type: types.GET_SMS_CREDITS_AVAILABLE,
  
})


export const getMainServiceAction = () => ({
  type: types.GET_MAIN_SERVICE,
  
})


export const getPaidPlanPricingOptionAction = () => ({
  type: types.GET_PAID_PLAN_PRICING_OPTIONS,

  
})


export const getSMSPlanPricingOptionAction = () => ({
  type: types.GET_SMS_PLAN_PRICING_OPTIONS,

  
})



export const resetBillingProps = () => ({
  type: types.RESET_BILLING_PROPS,
})


export const setMainState=(main:Record<string,any>)=>{
return {
  type: types.SET_MAIN_SERVICE,
  payload:main

}
}

export const increaseSmsCredit=(qty:number)=>{
  return {
    type: types.SET_INCREASE_SMS_CREDITS_AVAILABLE,
    payload:qty
  
  }
  }

export const incrementOption=(opt:string)=>{
return{
  type:types.INCREMENT_OPTION,
  payload:opt
}
}

export const decrementOption=(opt:string)=>{
  return{
    type:types.DECREMENT_OPTION,
    payload:opt
  }
  }