export const types = {
    GET_SERVERS: 'GET_SERVERS',
    GET_SERVERS_SUCCESS: 'GET_SERVERS_SUCCESS',
    GET_SERVERS_ERROR: 'GET_SERVERS_ERROR',
    RESET_SERVER_PROPS: 'RESET_SERVER_PROPS',
  
    GET_AGENTKEY: 'GET_AGENTKEY',
    GET_AGENTKEY_SUCCESS: 'GET_AGENTKEY_SUCCESS',
    GET_AGENTKEY_ERROR: 'GET_AGENTKEY_ERROR',
    
    GET_NOTIFICATIONROLES: 'GET_NOTIFICATIONROLES',
    GET_NOTIFICATIONROLES_SUCCESS: 'GET_NOTIFICATIONROLES_SUCCESS',
    GET_NOTIFICATIONROLES_ERROR: 'GET_NOTIFICATIONROLES_ERROR',

    
    UPDATE_SERVER_REQUEST: 'UPDATE_SERVER_REQUEST',
    UPDATE_SERVER_REQUEST_SUCCESS: 'UPDATE_SERVER_REQUEST_SUCCESS',
    UPDATE_SERVER_REQUEST_ERROR: 'UPDATE_SERVER_REQUEST_ERROR',
        
    DELETE_SERVER_REQUEST: 'DELETE_SERVER_REQUEST',
    DELETE_SERVER_REQUEST_SUCCESS: 'DELETE_SERVER_REQUEST_SUCCESS',
    DELETE_SERVER_REQUEST_ERROR: 'DELETE_SERVER_REQUEST_ERROR',

    
    MOVE_SERVER_REQUEST: 'MOVE_SERVER_REQUEST',
    MOVE_SERVER_REQUEST_SUCCESS: 'MOVE_SERVER_REQUEST_SUCCESS',
    MOVE_SERVER_REQUEST_ERROR: 'MOVE_SERVER_REQUEST_ERROR',

    
    PAUSE_SERVER_REQUEST: 'PAUSE_SERVER_REQUEST',
    PAUSE_SERVER_REQUEST_SUCCESS: 'PAUSE_SERVER_REQUEST_SUCCESS',
    PAUSE_SERVER_REQUEST_ERROR: 'PAUSE_SERVER_REQUEST_ERROR',
    GET_SERVERS_REFETCH:"GET_SERVERS_REFETCH"
  }
  