export const setIconBrowser = (name: string) => {
  switch (name) {
    case 'Chrome':
      return 'fab fa-chrome'
    case 'Firefox':
      return 'fab fa-firefox-browser'
    case 'Safari':
      return 'fab fa-safari'

    case 'Edge':
      return 'fab fa-edge'

    case 'Opera':
      return 'fab fa-opera'
    default:
      return 'fab fa-chrome'
  }
}
