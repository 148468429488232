import React from 'react'
import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'

import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {Provider} from 'react-redux'
import store, {persistor} from './setup/redux/Store'
import {PersistGate} from 'redux-persist/integration/react'
import './mainStyle.css'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {ThemeModeProvider} from './_metronic/partials'
import {BrowserRouter} from 'react-router-dom'

Bugsnag.start({
  apiKey: 'fc76741bdef1e80a82a3fdc938881417',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_SW_VER ? process.env.REACT_APP_SW_VER : '',
  releaseStage: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : 'dev',
  metadata: {
    userInformation: {
      id: 'Guest',
      email: 'Guest',
      username: 'Guest',
    },
  },
  onError: function (event: any) {
    event.setUser(
      event._metadata?.userInformation?.id,
      event?._metadata?.userInformation.email,
      event._metadata?.userInformation?.username
    )
  },
})
const ErrorBoundary: any = Bugsnag.getPlugin('react')?.createErrorBoundary(React)

//serviceWorkerRegistration.register();

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retryOnMount:false
    },
  },
})
const ROUTER_POSTFIX = process.env.REACT_APP_ROUTER_POSTFIX || '/'

const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          {/* <AuthProvider>
          <AppRoutes />
        </AuthProvider> */}
          <Provider store={store}>
            <PersistGate
              persistor={persistor}
              // loading={<div>Loading...</div>}
            >
              <ThemeModeProvider>
                <AuthProvider>
                  <BrowserRouter basename={ROUTER_POSTFIX}>
                    <AppRoutes />
                  </BrowserRouter>
                </AuthProvider>
              </ThemeModeProvider>
            </PersistGate>
          </Provider>
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  )
}
serviceWorkerRegistration.unregister()
