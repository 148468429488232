import React, { FC } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import PaidBadge from '../../global/PaidBadge'


const ConfirmModal: FC<{
    display: boolean,
    waiting?: boolean,
    title: string,
    description: string,
    confirmText?:string
    id?: string,
    handleClose: () => void,
    handleConfirm?: () => void,
    paid?:boolean
}> =
    ({ display, handleClose, handleConfirm, title = "", description = "", waiting = false, id,confirmText,paid=false }) => {
    const intl = useIntl()

        return (
            <Modal show={display}  onHide={handleClose}  id={id ? id : ""} style={{zIndex:50000}} centered>
                <Modal.Body>
                    <div className='text-center ' >
                        <h2 className='mb-4'>{title}</h2>
                        <p>{description}</p>
                    </div>
                    <div className='d-flex justify-content-center mt-5'>
                        <Button
                            variant={handleConfirm?'secondary':'primary'}
                            onClick={handleClose}
                            size='sm'
                            className='me-2 w-100px' >
                           {handleConfirm ?intl.formatMessage({ id: "ACTIONS.CANCEL" }):intl.formatMessage({ id: "ACTIONS.OK" })}
                        </Button>
                      {handleConfirm &&  <Button
                            variant='primary'
                            className='me-2 w-100px d-flex  align-items-center justify-content-center'
                            size='sm'

                            onClick={handleConfirm}
                            disabled={waiting}
                            type="button"
                        >
                            
                        
                            {waiting ? <div> <Spinner size='sm'  animation="grow" /> </div> :<>
                            {confirmText?confirmText:intl.formatMessage({ id: "ACTIONS.CONFIRM" })}
                            </>}
                            {paid&& <PaidBadge/>}

                            
                        </Button>}


                    </div>
                </Modal.Body>

            </Modal>
        )
    }

export default ConfirmModal