import Http, {_axios} from '../app/store/core/inceptor'

export const getUptimeChecks = (id: string) => Http.get(`/checks/${id}/uptime`)

export const getModules = (id: string) => Http.get(`servers/${id}/checks`)

export const getGraph = ({
  id,
  moduleId,
  filter,
  name,
  since,
  until,
}: {
  id: string
  moduleId: string
  filter: string
  name: string
  since?: string
  until?: string
}) =>
  Http.get(`/servers/${id}/checks/${moduleId}/graphs/${name}`, {
    params:
     (filter === 'custom')
        ? {start: since, end: until}
        : {
            start: filter,
          },
  })

export const getDevice = (id: string) => Http.get(`/servers/${id}`)

export const getGroups = () => Http.get('/groups')

export const getTriggers = (id: string) => Http.get(`/servers/${id}/checks/triggers?per_page=100`)

export const getIncidents = (
  id: string,
  status: string,
  since: string,
  until: string,
  type: string,
  page: number|string,
  per_page: number|string
) =>
  Http.get(`/servers/${id}/incidents`, {
    params: {
      status,
      ...(!!type && {type}),
      ...(!!since && {since}),
      ...(!!until && {until}),
      ...(!!page && {page}),
      ...(!!per_page && {per_page}),
    },
  })

export const uptimeTest = (params: any) => Http.post('/checks/test', params)

export const updateCheck = (data: Record<string, any>, id: string) =>
  Http.put(`/checks/${id}`, data)

export const deleteCheck = (id: string) => Http.delete(`/checks/${id}`)
export const deleteServerCheck = (id: string, checkId: string) =>
  Http.delete(`/servers/${id}/checks/${checkId}`)

export const getCheckIncident = (
  id: string,
  status: string,
  since: any,
  until: any,
  type: string,
  page: number|string,
  per_page: number|string
) => {
  return Http.get(`/check/${id}/incidents`, {
    params: {
      status,
      ...(!!type && {type}),
      ...(!!since && {since}),
      ...(!!until && {until}),
      ...(!!page && {page}),
      ...(!!per_page && {per_page}),
    },
  })
}

export const getResultLog = (id: string) => Http.get(`/checks/${id}/log-results`)

export const getCheckDetail = (id: string) => Http.get(`/check/${id}`)

export const getUptimeGraph = (checkId: string, filter: string) =>
  Http.get(`/check/${checkId}/uptime-graph?range=${filter}`)

export const getServerUptime = (id: string) => Http.get(`/servers/${id}/checks/uptime`)
export const getServerUptimePingHttp = (id: string) =>
  Http.get(`/servers/${id}/checks/uptime?type=20,21`)

export const getUptimeCheckByModule = (id: number) => Http.get(`/check/${id}/uptime`)

export const updateGenericsCheck = (data: Record<string, any>, id: number) =>
  Http.put(`/check/${id}`, data)

export const bulkCheckApi = (data: {operation: string; pause_until: any; ids: number[]}) => {
  return Http.post('/check/bulk', data)
}

export const getUptimeList = (params: any) => {
  const paramsSend: any = {}

  if (params.period) paramsSend.period = params.period
  if (params.group_id) paramsSend.group_id = params.group_id
  if (params.type) paramsSend.type = params.type
  if (params.server) paramsSend.server = params.server
  if (params.check) paramsSend.check = params.check
  if (params.status) paramsSend.status = params.status

  return Http.get('/uptime', {
    params: paramsSend,
  })
}
export const getUptimeReport = (params: any) => {
  const paramsSend: any = {}

  if (params.group_id) paramsSend.group_id = params.group_id
  if (params.start_report) paramsSend.start_report = params.start_report
  if (params.end_report) paramsSend.end_report = params.end_report
  if (params.period) paramsSend.period = params.period

  return Http.get('/uptime/report', {
    params: paramsSend,
  })
}
export const getUptimeReportPDF = (params: any) => {
  const paramsSend: any = {}

  if (params.group_id) paramsSend.group_id = params.group_id
  if (params.start_report) paramsSend.start_report = params.start_report
  if (params.end_report) paramsSend.end_report = params.end_report
  if (params.period) paramsSend.period = params.period
  if (params.pdf) paramsSend.pdf = params.pdf

  return _axios.get('/uptime/report', {
    params: paramsSend,
    responseType:"blob",
    // headers:{
    //   'Accept': '*/*',
    // }
  })
}

export const getSSLList = (params: any) => {
  const paramsSend: any = {}

  if (params.period) paramsSend.period = params.period
  if (params.group_id) paramsSend.group_id = params.group_id

  return Http.get('/uptime/ssl', {
    params: paramsSend,
  })
}
 
export const updateGenericsCheckMutate=({data,id}:{data:Record<string,any>,id:number})=>Http.put(`/check/${id}`,data)

export const getListSSLCheck=()=>Http.get(`/check/ssl`,{params:{sort:"ssl_expire"}})

export const importUptimeCompetitors = (name: string, key: string) =>
  Http.post(`/import/${name}`, {key})