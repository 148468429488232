/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {FormattedMessage, useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {ToastContainer} from 'react-toastify'
import {notify} from '../../../helpers'
import {IFeedback} from '../client/setting/SettingsModel'
import {Ticket} from '../../../services/Ticket'
import {Modal} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {handleOpenFeedback} from '../../store/commonAction'
import MuiSelect from '../global/UI/MuiSelect'
import { MenuItem } from '@mui/material'
import MuiTextField from '../global/UI/MuiTextField'

const subjectMaxLength = 78
const BugSubjectPrefix = '[Bug] feedback - '
const FeatureSubjectPrefix = '[Feature request] feedback - '

const feedbackFormValidationSchema = Yup.lazy(() =>
  Yup.object().shape({
    type: Yup.string().required(),
    subject: Yup.string()
      .when(['type'], (type, schema) => {
        return type === 'bug'
          ? schema.max(subjectMaxLength - BugSubjectPrefix.length)
          : schema.max(subjectMaxLength - FeatureSubjectPrefix.length)
      })
      .required(),
    body: Yup.string().required(),
  })
)

const RequestFeedback = () => {
  const intl = useIntl()
  const [deparmentId, setDepartmentId] = useState(0)
  const [priority, setPriority] = useState(0)
  const [loading, setLoading] = useState(false)
  const display = useSelector<RootState>((state) => state.commonReducer.feedbackOpen) as boolean
  const dispatch = useDispatch()

  const formik = useFormik<IFeedback>({
    initialValues: {type: 'bug', subject: '', body: ''},
    validationSchema: feedbackFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true)

      let params = {...values}

      if (params.type === 'bug') {
        params.subject = BugSubjectPrefix + params.subject
      } else {
        params.subject = FeatureSubjectPrefix + params.subject
      }

      params.department_id = deparmentId
      params.priority = priority

      Ticket.add(params)
        .then((response: any) => {
          setLoading(false)

          notify({
            type: 'success',
            message: intl.formatMessage({id: 'NOTIFICATIONS.FEEDBACK_SENT'}),
          })

          //close modal
          const closeBtn = window.document.getElementById('close-feedback-btn')!
          closeBtn.click()

          formik.resetForm()
        })
        .catch((err: any) => {
          const error = err.response && (err.response || err.message)
          let data = error?.data
          setLoading(false)
          if (!data) {
            return notify({
              type: 'error',
              message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
            })
          }
          let messages = []

          for (let i in data) {
            messages.push(
              <>
                {data[i]}
                <br />
              </>
            )
          }
          notify({type: 'error', message: <>{messages}</>})
        })
    },
  })

  useEffect(() => {
    //localize validation messages
    const validation_messages = Object(intl?.messages?.VALIDATORS)
    Yup.setLocale({...validation_messages})

    //get default department id
    Ticket.getDepartments().then((response: any) => {
      const departments = response.data.data
      for (let i in departments) {
        if (departments[i].default === 1) {
          setDepartmentId(departments[i].id)
        }
      }
    })

    //get low priority id
    Ticket.getPriorities().then((response: any) => {
      setPriority(+Object.keys(response.data.data)[0])
    })
  }, [])

  return (
    <>
      <Modal show={display} size='lg' centered>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id='FEEDBACK.TITLE' />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage id='FEEDBACK.DESCRIPTION' />
          </p>
          <form onSubmit={formik.handleSubmit} id='kt_feedback' className='form' noValidate>
            <div className='d-flex flex-column'>
              <div className='row mb-1'>
                <div className='col-lg-12'>
                  <div className='fv-row mb-0 mt-4'>
                    <label htmlFor='type' className='form-label fs-6 fw-bolder mb-3'>
                      <FormattedMessage id='LABELS.TYPE' />
                    </label>
                    <MuiSelect
                      // className='form-control form-control-lg form-control-solid'
                      id='type'
                      {...formik.getFieldProps('type')}
                    >
                      <MenuItem value='bug'>
                        {intl.formatMessage({
                          id: 'FEEDBACK.TYPE_OPTIONS.BUG',
                        })}
                      </MenuItem>
                      <MenuItem value='feture_request'>
                        {intl.formatMessage({
                          id: 'FEEDBACK.TYPE_OPTIONS.REQUEST_FEATURE',
                        })}
                      </MenuItem>
                    </MuiSelect>
                    {formik.touched.type && formik.errors.type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.type}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='fv-row mb-0 mt-4'>
                    <label htmlFor='subject' className='form-label required fs-6 fw-bolder mb-3'>
                      <FormattedMessage id='LABELS.TITLE' />
                    </label>
                    <MuiTextField
                      type='text'
                      // className='form-control form-control-lg form-control-solid '
                      id='subject'
                      {...formik.getFieldProps('subject')}
                    />
                    {formik.touched.subject && formik.errors.subject && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.subject}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-12'>
                  <div className='fv-row mb-0 mt-4'>
                    <label htmlFor='body' className='form-label required fs-6 fw-bolder mb-3'>
                      <FormattedMessage id='LABELS.MESSAGE' />
                    </label>
                    <MuiTextField
                      multiline
                      rows={5}
                      inputProps={{ style: { resize: "both" } }}
                      // className='form-control form-control-lg form-control-solid '
                      id='body'
                      {...formik.getFieldProps('body')}
                    />
                    {formik.touched.body && formik.errors.body && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.body}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <button
                type='reset'
                className='btn btn-light me-3'
                id='close-feedback-btn'
                data-bs-dismiss='modal'
                onClick={() => dispatch(handleOpenFeedback(false))}
              >
                <FormattedMessage id='ACTIONS.CANCEL' />
              </button>
              <button type='submit' className='btn btn-primary me-2 px-6'>
                {!loading && <FormattedMessage id='ACTIONS.SUBMTT' />}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    <FormattedMessage id='ACTIONS.WAIT' />{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>


    </>
  )
}

export {RequestFeedback}
