import React, {useEffect, useState} from 'react'
import {getSessions} from '../../../../../services/Sessions'
import {useTable, useExpanded} from 'react-table'
import {FormattedMessage, useIntl} from 'react-intl'
import SessionDeleteIcon from './RemoveSession'
import ViewSession from './ViewSession'
import Flags from '../../../global/UI/Flags'

const Sessions = () => {
  const [sessionData, setSessionData] = useState<[]>([])
  const [reload,setReload]=useState<boolean>(false)
  const [error, setError] = useState<any>()

  const intl = useIntl()

  useEffect(() => {
    getSessions().then((res) => {
      const filterData=res?.data?.filter?.((sess:any)=>sess?.hide==0)
      setSessionData(filterData??[])
    })
  }, [reload])

  /* --------------------------------- columns -------------------------------- */
  const columns = React.useMemo(
    () => [
      {
        Header: () => null,
        id: 'expander',
        Cell: ({row}: any) => (
          <span
            className={
              row.isExpanded
                ? 'fas fa-angle-double-down collapse-icon'
                : 'fas fa-angle-double-right collapse-icon'
            }
          ></span>
        ),
      },
      {
        Header: <FormattedMessage id='LABELS.NAME' />,
        accessor: 'user_name',
        id: '0',
      },
      {
        Header: <FormattedMessage id='LABELS.LOCATION' />,
        accessor: 'country_iso',
        id: '1',
        width:200,
        Cell: ({row}: any) => {
          return (
            <Flags country={row.original?.country_iso}/>
           
          )
        },
      },
      {
        Header: <FormattedMessage id='LABELS.LAST.ACTIVITY' />,
        accessor: 'last_activity_readable',
        id: '2',
      },
      {
        Header: () => null,
        accessor: 'data',
      },
      {
        Header: () => null,
        accessor: 'id',
      },
      {
        Header: () => null,
        id: 'remove',
        Cell: ({row}: any) => {
          return (
            <SessionDeleteIcon
              id={row.original?.no}
              name={row.original.user_name}
              className='text-dark fs-4'
              onSuccessDelete={() => {setReload(pre=>!pre)}}
            />
          )
        },
      },
    ],
    []
  )
  /* ------------------------------------ . ----------------------------------- */
  const hiddenColumns = ['data', 'id']
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: {expanded},
  } = useTable(
    {
      columns,
      data: sessionData,
      initialState: {hiddenColumns},
      
    },
    useExpanded
  )
  return (
    <div style={{maxHeight:"400px",overflow:"auto"}}>
      <table className='table table-striped bg-white gy-5 gs-7' {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup: any) => (
            <tr className='fw-bold fs-6 text-gray-800 px-7' {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {!error &&
            rows.map((row: any, i: number) => {
              prepareRow(row)

              return (
                <React.Fragment key={row.getRowProps().key}>
                  <tr {...row.getToggleRowExpandedProps()}>
                    {row.cells.map((cell: any) => {
                      return (
                        <td {...cell.getCellProps()} className=''>
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        <ViewSession
                          platform={row.original.platform}
                          country={row.original.country}
                          city={row.original.city}
                          ip={row.original.ip}
                          browser={row.original.browser}
                          last_activity={row.original.last_activity}
                        />
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              )
            })}
          {!error && rows.length == 0 && (
            <tr>
              <td role='cell' colSpan={5} className='text-center'>
                <FormattedMessage id='ACCOUNT.SESSION.EMPTY' />
              </td>
            </tr>
          )}
          {error && (
            <tr>
              <td role='cell' colSpan={5} className='text-center'>
                {error}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <br />
    </div>
  )
}

export default Sessions
