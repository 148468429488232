import React from 'react'

interface Props {
  title: string|React.ReactNode
  value: React.ReactNode
  noTitleWidth?:boolean
  fs?:"fs-1"|'fs-2'|"fs-3"|"fs-4"|"fs-5"|"fs-6"|"fs-7"
  classCustom?:string
  minWidth?:string
}

const TitleAndValues = ({title,classCustom='', value="-",fs,noTitleWidth=false,minWidth="min-w-150px "}: Props) => {
  return (
   

    <p className={`d-flex  ${fs??""} ${classCustom} align-items-center`}>
      <strong className={`font-weight-bold ${noTitleWidth?"":minWidth}  me-2 d-block text-nowrap`}>{title} : </strong>
      <span> {value ||(value==0 ?0:  "-")}</span>
    </p>
  )
}

export default TitleAndValues
