import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import moment from 'moment'
import {TablesWidget2} from '../../../_metronic/partials/widgets'
import LongTitle from '../../modules/global/UI/LongTitle'

import classes from './style.module.scss'
import {getChecksMostDowntime} from '../../../services/dashboard'
import ChecksStatusArrow from '../../modules/global/ChecksStatusArrow'
import ChecksStatusBox from '../../modules/global/ChecksStatusBox'

interface Props {
  onRemove: () => void
}

const ChecksMostDowntime = ({onRemove}: Props) => {
  const intl = useIntl()

  const {data: checks, isLoading}: any = useQuery(
    ['dashboard-checks-most-downtime'],
    getChecksMostDowntime,{
      cacheTime:0,
      staleTime:0
    }
  )
  const onClose = () => {
    onRemove()
  }

const filterCheckByDowntime=checks?.filter((ch:Record<string,any>)=>!!ch?.downtime_human_readable)??[]


  return (
    <div>
      <TablesWidget2
        className={classes['custom_table']}
        isLoading={isLoading}
        title={intl.formatMessage({id: 'LABELS.CHECK.MOST.DOWNTIME'})}
        onClose={onClose}
        icon={<span className='far fa-check-circle fs-1' />}
        dataLength={filterCheckByDowntime?.length}
        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '15%'}}>
              {intl.formatMessage({id: 'CHECK'})}
            </th>
            <th className='bg-transparent fw-bold fs-7  p-2 px-1' style={{minWidth: '30%',width:"30%"}}>
              {intl.formatMessage({id: 'LABELS.LABEL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '30%', width: '30%'}}>
              {intl.formatMessage({id: 'LABELS.UPTIME_DOWNTIME'})}
            </th>

            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '10%'}}></th>
          </tr>
        }
      >
        {!!filterCheckByDowntime?.length
          ? filterCheckByDowntime?.map((data: Record<string, any>, index: number) => (
              <tr key={data?.check?.id}>
                <td className='pe-0'>
                  <ChecksStatusBox
                    type={data?.check?.type}
                    status={data?.check?.status}
                    is_paused={data?.check?.is_paused}
                    size={'symbol-50px'}
                    font={'8px'}
                  />
                </td>
                <td className=''>
                  <span className='text-dark fw-bold mb-1 fs-6'>
                    <LongTitle title={data?.check?.label ?? data?.device_object?.label} id={'ch-down-i-d' + index} maxWidth/>
                  </span>
                  <span className='text-muted fw-semobold fs-7'>
                    <LongTitle
                      className='text-muted'
                      title={data?.check?.data?.host ?? '-'}
                      id={'ch-down-h-i-d' + index}
                      maxWidth
                    />
                  </span>
                </td>
                <td className='px-1 fs-8'>
                  <LongTitle title={data?.downtime_human_readable??"-"}  id={data?.check?.id+"dd"} maxWidth/>
                  {/* <span className='d-flex ps-2'>
                    <ChecksStatusArrow
                      is_paused={data?.check?.is_paused}
                      status={data?.check?.status}
                      is_under_maintenance={data?.check?.is_under_maintenance}
                    />
                  </span> */}
                </td>

                <td className='text-end'>
                  <Link
                    to={data?.check?.type===27 ? `/ssl/${data?.device}?module=${data?.check?.id}`: `/uptime/${data?.device}?module=${data?.check?.id}`}
                    className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                  >
                    <span className='fas fa-arrow-right fs-4' />
                  </Link>
                </td>
              </tr>
            ))
          : null}
      </TablesWidget2>
    </div>
  )
}

export default ChecksMostDowntime
