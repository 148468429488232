import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {Box, DialogActions, DialogContent, DialogTitle, useMediaQuery} from '@mui/material'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const CustomDialog = ({open, onClose, props, title, children, footer}: any) => {
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      {...props}
      sx={{zIndex: !isMobile && 20}}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: !isMobile ? '500px' : '',
          height: 'inherit',
          bgcolor: 'var(--kt-modal-bg)',
        }}
      >
        {isMobile ? (
          <AppBar sx={{position: 'relative'}}>
            <Toolbar>
              <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ml: 2, flex: 1}} variant='h6' component='h2'>
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
        ) : (
          <>
            <DialogTitle
              sx={{p: 2.75, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
            >
              <Typography variant='h6' fontWeight='bold'>{title}</Typography>
              {/* <IconButton edge='start' color='inherit' onClick={onClose} aria-label='close'>
                <CloseIcon />
              </IconButton> */}
              <button
                type='button'
                className='btn-close'
                aria-label='Close'
                onClick={onClose}
              ></button>{' '}
            </DialogTitle>
            <Divider sx={{borderColor: 'var(--kt-modal-header-border-color)', opacity: '1'}} />
          </>
        )}
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2.75,
            overflowY: 'auto',
            maxHeight: !isMobile ? '65vh' : '85vh',
          }}
        >
          {children}
        </DialogContent>{' '}
        {!!footer && (
          <Box sx={{mt: isMobile ? 'auto' : ''}}>
            <Divider sx={{borderColor: 'var(--kt-modal-header-border-color)', opacity: '1'}} />
            <DialogActions
              sx={{display: 'flex', justifyContent: 'end', py: 2, px: 2.75, gap: 0.5}}
            >
              {footer}
            </DialogActions>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}
export default CustomDialog
