import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {getIncidents} from '../../../services/dashboard'
import {ChartsWidget1} from '../../../_metronic/partials/widgets'
import classes from './style.module.scss'

function getFirstDayOfMonth(year: number, month: number) {
  return +new Date(year, month, 1)
}

interface Props {
  onRemove: () => void
}

const TotalIncident = ({onRemove}: Props) => {
  const intl = useIntl()

  const {data, isLoading}: any = useQuery(['dashboard-total-incidents'], getIncidents)

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <ChartsWidget1
        onClose={onClose}
        title={intl.formatMessage({id: 'TOTAL.INCIDENTS'})}
        icon={<span className='far fa-chart-bar fs-1' />}
        className={classes['custom_table']}

        isLoading={isLoading}
        series={[
          {
            name: 'Current',
            data: [
              data?.data?.today,
              data?.data?.this_week,
              data?.data?.this_month,
              data?.data?.this_year,
            ],
          },
          {
            name: 'Last',
            data: [
              data?.data?.yesterday,
              data?.data?.last_week,
              data?.data?.last_month,
              data?.data?.last_year,
            ],
          },
        ]}
      />
    </div>
  )
}

export default React.memo(TotalIncident)
