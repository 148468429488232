import React, {FC, useEffect, useState, useCallback} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {Button, ButtonGroup, Col, Form, Row} from 'react-bootstrap'
import MetaHead from '../../modules/global/MetaHead/MetaHead'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import './style.scss'
import {
  getModuleCountAction,
  getUptimeAction,
  getUptimeReFetchAction,
} from '../../store/upTimeStore/actions'
import {RootState} from '../../../setup'
import {getGroupsAction} from '../../store/groupStore/actions'
import CustomValuesHook from '../../Hooks/customValuesHook'
import {IGroup} from '../../store/groupStore/types'
import CustomMultiSelectDrp from '../../../helpers/CustomMultiSelectDrp'
import UpTimeTable from './UpTimeTable'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import WarnAlert from '../../modules/global/WarnAlert'

import TouringHelper from '../../../helpers/TouringHelper'
import GroupsubgroupDropdown from '../../../helpers/CustomMultiSelectDrp/GroupsubgroupDropdown'
import useActionControll from '../../Hooks/useActionControll'
import PaidModal from '../../modules/global/PaidModal'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import BulkActions from './UpTimeTable/BulkActions'
import {notify} from '../../../helpers'
import {checksForSelect} from '../../modules/widgets/components/Common'
import MultipleSelect from '../../modules/global/UI/MultipleSelect'
import {FormControlLabel, MenuItem, Select, Switch} from '@mui/material'
import {BootstrapInput} from '../../modules/global/UI/MUIInput'
import GroupMultipleSelect from '../../modules/global/UI/GroupMultipleSelect'
import {useQuery} from 'react-query'
import {getUptimeList} from '../../../services/uptime'
import FilterPopup from '../../modules/global/UI/FilterPopup'
import IconButtonGroup from '../../modules/global/IconButtonGroup'
import {useLocalStorage} from '../../Hooks/useLocalStorage'
import PageContainer from '../../modules/global/UI/PageContainer'

const typeList = [
  {text: 'PING', value: 20},
  {text: 'HTTP(S)', value: 21},
  {text: 'DNS', value: 22},
  {text: 'FTP', value: 23},
  {text: 'SMTP', value: 24},
  {text: 'POP3', value: 25},
  {text: 'IMAP', value: 26},
  {text: 'Heartbeat', value: 30},
  {text: 'SSL', value: 27},
  {text: 'TCP', value: 28},
  {text: 'UDP', value: 29},
]

const layoutView = [
  {
    name: 'block',
    icon: <span className='fas fa-th-large fs-3' />,
  },
  {
    name: 'list',
    icon: <span className='fas fa-align-justify fs-3' />,
  },
]

const breadCrumbs: Array<PageLink> = [
  {
    title: 'Xitoring',
    path: '/',
    isSeparator: false,
    isActive: false,
  },
]
const UpTime: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const count: any = useSelector<RootState>((state) => state.upTime.count) as object
  const [searchParams] = useSearchParams()
  const statusFromUrl = searchParams.get('status')

  const [relateToServer, setRelatetoServer] = useState(false)
  const {actionFunc, isLimitModal, closeLimitModal} = useActionControll('module')
  const [selectedChecks, setSelectedChecks] = useState<any[]>([])
  const [selectedOp, setSelectedOp] = useState('pause')
  const [openModal, setOpenModal] = useState(false)
  const [isBulkEnable, setIsBulkEnable] = useState(false)

  const [checkTypes, setCheckTypes] = useState<any[]>([])
  const [selectedGp, setSelectedGp] = useState<any[]>([])
  const [statusCheck, setStatusCheck] = useState<string>(statusFromUrl ?? 'all')
  const [period, setPeriod] = useState<string>('this_month')
  const [modeValueFromStorage, setModeValueFromStorage] = useLocalStorage<string>(
    'uptime-view',
    'block'
  )
  const [selectedMode, setSelectedMode] = useState<string>(modeValueFromStorage ?? 'block')
  const {
    data: uptime,
    refetch,
    isLoading,
  }: any = useQuery(
    ['uptime', checkTypes, selectedGp, statusCheck, period, relateToServer],
    () =>
      getUptimeList({
        period,
        group_id: selectedGp?.map((gp) => gp.value)?.join(','),
        type: checkTypes?.map((gp) => gp.value)?.join(','),
        server: relateToServer ? 1 : 0,
        status: statusCheck,
      }),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  )

  const onSelectCheck = useCallback((checks: any[]) => {
    setSelectedChecks(checks)
  }, [])
  const navigate = useNavigate()

  const handleEnableBulk = useCallback((bulkStatus: boolean) => {
    setIsBulkEnable(bulkStatus)
  }, [])

  const handleSelectOp = (operator: string) => {
    setSelectedOp(operator)
  }

  const clearSelected = () => {
    setSelectedChecks([])
  }

  const onOpenConfirmModal = () => {
    if (!selectedChecks?.length) {
      notify({type: 'error', message: intl.formatMessage({id: 'LABELS.ERROR.BULK.CHECKS'})})
      return
    }
    setOpenModal(true)
  }

  useEffect(() => {
    dispatch(getModuleCountAction())
  }, [])

  useEffect(() => {
    if (statusFromUrl) {
      setStatusCheck(statusFromUrl)
    }
  }, [statusFromUrl])

  
  const main: any = useSelector<RootState>(
    ({billingReducer}) => billingReducer.mainService,
    shallowEqual
  ) as any

  const handleCheckType = (dataCheckType: any[]) => {
    setCheckTypes(dataCheckType)
  }

  const handleSelectMode = (name: string) => {
    setSelectedMode(name)
    setModeValueFromStorage(name)
  }

  const activeFiltersCount =
  checkTypes?.length +
  selectedGp?.length +
  (statusCheck !== 'all' ? 1 : 0) +
  (period!=="this_month"?1:0) +
  (relateToServer ? 1 : 0); 

  const onClear=()=>{
    setCheckTypes([])
    setSelectedGp([])
    setRelatetoServer(false)
    setStatusCheck("all")
    setPeriod("this_month")
  }

  return (
    <>
      <MetaHead title={`${intl.formatMessage({id: 'PAGE.TITLES.UPTIME'})} - Xitoring`} />
      <PageTitle breadcrumbs={breadCrumbs}>
        {intl.formatMessage({id: 'PAGE.TITLES.UPTIME'})}
      </PageTitle>
      <div className='main-uptime-page-container h-100'>
      
        {main?.is_suspended && (
          <WarnAlert
            text={intl.formatMessage({id: 'service_suspended'})}
            name='suspendserive'
            isWarning={true}
          />
        )}

        <PageContainer>
          <section className='d-flex gap-4 align-items-center justify-content-between flex-wrap'>

          
          <div className='d-flex gap-2 align-items-center  '>
            <FilterPopup activeFilters={activeFiltersCount} onClear={onClear}>
              <Form.Group className=''>
                <Form.Label className='fs-7'>
                  {' '}
                  {intl.formatMessage({id: 'LABELS.FILTER.BY.TYPE'})}
                </Form.Label>
                <MultipleSelect
                  value={checkTypes}
                  size="small"
                  options={checksForSelect?.filter((check)=>check.title!=="SSL")}
                  onChangeHandler={handleCheckType}
                  placeHolder={intl.formatMessage({id: 'LABELS.SELECT.TYPE'})}
                />
              </Form.Group>
              <Form.Group className=''>
                <Form.Label className='fs-7'>
                  {intl.formatMessage({id: 'LABELS.FILTER.BY.PERIOD'})}
                </Form.Label>
                <Select
                  // input={<BootstrapInput />}
                  fullWidth
                  size='small'
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                  sx={{
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--kt-input-border-color) !important',
                      borderWidth: '1px !important',
                    },
                  }}
                  MenuProps={{
                    sx: {
                      '& .MuiPaper-elevation': {
                        backgroundColor: 'var(--kt-page-bg)',
                      },
                      "& li[aria-selected='true']": {
                        backgroundColor: 'var(--kt-input-border-color)',
                      },
                    },
                  }}
                >
                  <MenuItem value={'today'}>
                    {intl.formatMessage({id: 'PROPERTY.UPTIME_TODAY'})}
                  </MenuItem>
                  <MenuItem value={'this_week'}>
                    {intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_WEEK'})}
                  </MenuItem>
                  <MenuItem value={'this_month'}>
                    {intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_MONTH'})}
                  </MenuItem>
                  <MenuItem value={'last_month'}>
                    {intl.formatMessage({id: 'LABELS.LAST.MONTH'})}
                  </MenuItem>
                  <MenuItem value={'this_year'}>
                    {intl.formatMessage({id: 'PROPERTY.UPTIME_THIS_YEAR'})}
                  </MenuItem>
                </Select>
              </Form.Group>
              <Form.Group className=''>
                <Form.Label className='fs-7'>
                  {intl.formatMessage({id: 'LABELS.FILTER.BY.STATUS'})}
                </Form.Label>
                <Select
                  // input={<BootstrapInput />}
                  value={statusCheck}
                  size='small'
                  fullWidth
                  onChange={(e) => setStatusCheck(e.target.value)}
                  sx={{
                    '&.Mui-focused fieldset': {
                      borderColor: 'var(--kt-input-border-color) !important',
                      borderWidth: '1px !important',
                    },
                  }}
                  MenuProps={{
                    sx: {
                      '& .MuiPaper-elevation': {
                        backgroundColor: 'var(--kt-page-bg)',
                      },
                      "& li[aria-selected='true']": {
                        backgroundColor: 'var(--kt-input-border-color)',
                      },
                    },
                  }}
                >
                  <MenuItem value={'all'}>
                    {intl.formatMessage({id: 'ECOMMERCE.COMMON.ALL'})}
                  </MenuItem>
                  <MenuItem value={'up'}>{intl.formatMessage({id: 'UP'})}</MenuItem>
                  <MenuItem value={'down'}>{intl.formatMessage({id: 'DOWN'})}</MenuItem>
                  <MenuItem value={'pause'}>{intl.formatMessage({id: 'PAUSE'})}</MenuItem>
                  <MenuItem value={'maintenance'}>
                    {intl.formatMessage({id: 'MENU.MAINTENANCE'})}
                  </MenuItem>
                </Select>
              </Form.Group>
              <Form.Group className=''>
                <Form.Label className='fs-7'>
                  {intl.formatMessage({id: 'LABELS.FILTER.BY.GROUP'})}
                </Form.Label>
                <GroupMultipleSelect
                  size='small'
                  value={selectedGp}
                  handleChange={(values) => setSelectedGp(values)}
                />
              </Form.Group>
              <FormControlLabel
                control={
                  <Switch
                    checked={relateToServer}
                    size='small'
                    onChange={(e) => {
                      setRelatetoServer(e.target.checked)
                    }}
                  />
                }
                className='m-0 d-flex justify-content-between'
                labelPlacement='start'
                label={
                  <span className='fs-7'>
                    {intl.formatMessage({id: 'LABELS.RELATED.TO.SERVERS'})}
                  </span>
                }
              />
            </FilterPopup>
            <IconButtonGroup
              iconBtnList={layoutView}
              selected={selectedMode}
              className=''
              onChange={handleSelectMode}
            />
          </div>
          <div className='d-flex gap-2'>
            <BulkActions
              handleEnableBulk={handleEnableBulk}
              handleSelectOp={handleSelectOp}
              selectedOp={selectedOp}
              onOpenConfirmModal={onOpenConfirmModal}
              clearSelected={clearSelected}
            />
            <Link to={'/new?mode=add-check'}>
              <Button size='sm'>{intl.formatMessage({id: 'ACTIONS.UPTIME_ADD_NEW_CHECK'})}</Button>
            </Link>
          </div>
          </section>
          <div className='mt-5 table-container'>
            <UpTimeTable
              isBulkEnable={isBulkEnable}
              onSelectCheck={onSelectCheck}
              openModal={openModal}
              selectedChecks={selectedChecks}
              selectedOp={selectedOp}
              setOpenModal={setOpenModal}
              types={typeList}
              period={period}
              uptime={uptime}
              refetch={refetch}
              isLoading={isLoading}
              selectedMode={selectedMode}
            />
          </div>
        </PageContainer>
      </div>
      <TouringHelper />
      <PaidModal
        title={intl.formatMessage({id: 'UPTIME.PAGE.LIMIT'})}
        open={isLimitModal}
        onClose={closeLimitModal}
      />
    </>
  )
}

export default UpTime
