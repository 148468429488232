import React, {useState} from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import PaidBadge from '../../../modules/global/PaidBadge'
import AddFormApi from './AddFormApi'
import ApiTokenList from './ApiTokenList'
import classes from './style.module.scss'
import XitogentKeysList from './XitogentKeysList'

const ApiAccess = () => {
  const [key, setKey] = useState<string>('xitogent')
  const intl = useIntl()
  return (
    <Tabs
      id='controlled-tab-example'
      activeKey={key}
      onSelect={(k: any) => setKey(k)}
      className={`mb-3 ${classes['custom-tab-edit-api']}`}
    >
      <Tab
        eventKey='xitogent'
        title={<div>{intl.formatMessage({id: 'XITOGENT.TOKENS.TITlE'})}</div>}
        className='p-4'
      >
        <div>
        <AddFormApi />
        <XitogentKeysList />
        </div>
        
      </Tab>
      <Tab
        eventKey='api'
        title={
          <div>
            {intl.formatMessage({id: 'API.TOKENS.TITlE'})} <PaidBadge />
          </div>
        }
        className='p-4'
      >
        <div className='alert alert-secondary border-0 d-flex align-items-center p-5 mb-10'>
          <span className='fas fa-info-circle fs-1 me-4 text-dark'></span>

          <div className='d-flex flex-column text-dark'>
            <h5 className='mb-2'>{intl.formatMessage({id: 'HEADER.INFO.API.TOKENS'})}</h5>
            <span className='fs-7'>{intl.formatMessage({id: 'TEXT.INFO.API.TOKENS'})}</span>
            <a href='https://app.xitoring.com/docs/' target='_blank'>
              {intl.formatMessage({id: 'TEXT.INFO.API.DOC'})}
            </a>
          </div>
        </div>
        <AddFormApi isApiTokens />
        <ApiTokenList />
      </Tab>
    </Tabs>
  )
}

export default ApiAccess
