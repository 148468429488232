/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { notify } from '../../../../../../helpers'
import { RootState } from '../../../../../../setup'
import IconButton from '../../../../../modules/global/IconButton'
import useMainConfig from '../../../../../Hooks/useMainConfig'
import ConfirmModal from '../../../../../modules/widgets/components/ConfirmModal/ConfirmModal'
import { TrashSVG } from '../../../../../modules/widgets/components/SVGIcons'
import {  reqGetChecksAction, reqRemoveCheckAction, resetServerDetailProps } from '../../../../../store/serverOverview/actions'

const RemoveCheck: FC<{ _item: any, _type: string,refetch?:()=>void }> = ({ _item, _type,refetch }) => {
    const success: boolean = useSelector<RootState>((state) => state.serverView.removeCheckSuccess) as boolean
    const waiting: boolean = useSelector<RootState>((state) => state.serverView.pendingRemoveCheck) as boolean
    const {reFetchMain}=useMainConfig()

    const [display, setDisplay] = useState(false)
    const[selectedItemToDelete,setSelectedItemToDelete]=useState()
    const dispatch = useDispatch();
    const params = useParams();
    const intl = useIntl()

    useEffect(() => {
        if (success) {
            if (selectedItemToDelete===_item) {
                reFetchMain()
                refetch?.()
                 notify({
                type: "success",
                message: intl.formatMessage(
                    { id: "NOTIFICATIONS.SUCCESS_DELETE_CHECK" }, { type: String(_item?.type_human_readable)?.toUpperCase(), host: _item?.data?.host?`(${_item?.data?.host})`:``}
                ),
            })
            dispatch(resetServerDetailProps())
            dispatch(reqGetChecksAction(params.id))
            }
           


        }
    }, [success])

    return (
        <>
          
            <IconButton icon='fas fa-trash' onClick={() => {setDisplay(true);setSelectedItemToDelete(_item)}} />
            <ConfirmModal
                display={display}
                title={intl.formatMessage({ id: "NOTIFICATIONS.CONFIRM_MODAL_TITLE_ARE_YOU_SURE" })}
                description={intl.formatMessage({ id: "NOTIFICATIONS.CONFIRM_DELETE_CHECK" }, { type: String(_item?.type_human_readable)?.toUpperCase(), host: _item?.data?.host?`(${_item?.data?.host})`:``})}
                handleClose={() => setDisplay(false)}
                handleConfirm={() => dispatch(reqRemoveCheckAction(params.id, _item.id))}
                waiting={waiting}
                />
        </>
    )
}

export default RemoveCheck