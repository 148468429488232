import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import ReactTooltip from 'react-tooltip'
import {setIconBrowser} from '../../../helpers/setIconBrowser'
import {getLastLogin, getSessions} from '../../../services/dashboard'
import {TablesWidget3} from '../../../_metronic/partials/widgets'
import Flags from '../../modules/global/UI/Flags'
import classes from './style.module.scss'

interface Props {
  onRemove: () => void
}

const LastLogin = ({onRemove}: Props) => {
  const intl = useIntl()

  const {data: lastData, isLoading} = useQuery(['dashboard-last-login'], getLastLogin,{
    staleTime:0,
    cacheTime:0
  })

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <TablesWidget3
        onClose={onClose}
        isLoading={isLoading}
        title={intl.formatMessage({id: 'LAST.LOGIN'})}
        dataLength={lastData?.data?.length}
        className={classes['custom_table']}
        icon={<span className='fas fa-sign-in-alt fs-1' />}
        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '35%', width: '35%'}}>
              {intl.formatMessage({id: 'LABELS.NAME'})}
            </th>
            <th
              className='bg-transparent fw-bold fs-7 p-2 px-1'
              style={{minWidth: '35%', width: '35%'}}
            >
              {intl.formatMessage({id: 'ACCOUNT.SESSION.IP'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '15%', width: '15%'}}>
              {intl.formatMessage({id: 'LABELS.PLATFORM'})}
            </th>
            <th
              className='bg-transparent fw-bold text-end fs-7 p-2 px-1'
              style={{minWidth: '15%', width: '15%'}}
            >
              {intl.formatMessage({id: 'LABELS.BROWSER'})}
            </th>
          </tr>
        }
      >
        {!!lastData?.data?.length &&
          lastData?.data?.map((login: Record<string, any>, index: number) => (
            <tr key={login?.id}>
              <td style={{whiteSpace: 'nowrap'}} className='text-dark fw-semobold mb-1 fs-6'>
                <span
                  className='text-truncate fs-7 d-block mb-2'
                  data-tip
                  data-for={'ip-n-' + index + login?.login_name}
                >
                  {login?.login_name}
                </span>
                <div
                  className='text-muted text-truncate '
                  data-tip
                  data-for={'ip-n-' + index + login?.created_at}
                  style={{fontSize: '11px'}}
                >
                  {login?.created_at}
                </div>
                <ReactTooltip id={'ip-n-' + index + login?.created_at} className='tooltip-wrapper'>
                  {login?.created_at}
                </ReactTooltip>
                <ReactTooltip id={'ip-n-' + index + login?.login_name} className='tooltip-wrapper'>
                  {login?.login_name}
                </ReactTooltip>
              </td>
              <td className='text-dark fw-semobold px-1' >
                <span
                  className='text-truncate d-block  mb-1'
                  data-tip
                  data-for={'ip-l-' + index}
                  style={{fontSize: '11px'}}
                >
                  {login?.ip}
                </span>
                <div className='text-truncate text-muted' style={{fontSize: '11px'}}>
                <Flags country={login?.country_code} className="me-2"/>
                

                  {login?.country}
                </div>
                <ReactTooltip id={'ip-l-' + index} className='tooltip-wrapper'>
                  {login?.ip}
                </ReactTooltip>
              </td>
              <td className='text-dark fw-semobold fs-7 text-muted'>
                <div className='symbol symbol-35px me-2'>
                  <span className='symbol-label fs-3 bg-light-primary'>
                    <span
                      className={`${
                        login?.platform === 'OS X'
                          ? 'fab fa-apple'
                          : login?.platform === 'Windows'
                          ? 'fab fa-windows'
                          : 'fab fa-linux'
                      }`}
                    />
                  </span>
                </div>
              </td>
              <td className='text-end text-dark fw-bold fs-6 pe-0'>
                <div className='symbol symbol-35px me-2'>
                  <span className='symbol-label fs-3 bg-light-primary'>
                    <span className={`${setIconBrowser(login?.browser)}`} />
                  </span>
                </div>
              </td>
            </tr>
          ))}
      </TablesWidget3>
    </div>
  )
}

export default React.memo(LastLogin)
