import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {getListSSLCheck} from '../../../services/uptime'
import {Link} from 'react-router-dom'
import {TablesWidget2, TablesWidget5} from '../../../_metronic/partials/widgets'
import classes from './style.module.scss'
import LongTitle from '../../modules/global/UI/LongTitle'
import ChecksStatusBox from '../../modules/global/ChecksStatusBox'
import ProgressDate from '../SSL/ProgressDate'
import moment from 'moment'
import ChecksStatusArrow from '../../modules/global/ChecksStatusArrow'

interface Props {
  onRemove: () => void
}

const SslExpiringSoon = ({onRemove}: Props) => {
  const intl = useIntl()

  const onClose = () => {
    onRemove()
  }

  const {data, refetch, isLoading}: any = useQuery(['list-ssl-expiring'], () => getListSSLCheck(), {
    cacheTime: 0,
    staleTime: 0,
  })
  const filterList = data?.filter((obj:any) => obj.is_paused === false).slice(0, 5);

  const progressValues = (startTimeStamp: number, endTime: number) => {
    const currentDate = moment()
    const startDate = moment.unix(startTimeStamp)
    const endDate = moment(endTime, 'YYYY-MM-DD HH:mm:ss')

    const remainingDays = endDate.diff(currentDate, 'days')

    const totalDays = endDate.diff(startDate, 'days')
    const currentDays = currentDate.diff(startDate, 'days')
    const label = `${remainingDays}/${totalDays}`

    const progressWidth = 100 - (currentDays / totalDays) * 100
    return {label, progressWidth}
  }

  return (
    <div>
      <TablesWidget2
        // onClose={onClose}
        isLoading={isLoading}
        dataLength={filterList?.length}
        onClose={onClose}
        title={intl.formatMessage({id: 'LABELS.SSL_EXPIRING_SOON'})}
        className={classes['custom_table']}
        icon={<span className='fa-solid fa-lock' />}
        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '15%'}}>
              {intl.formatMessage({id: 'LABELS.STATUS'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '30%'}}>
              {intl.formatMessage({id: 'LABELS.SERVER_HOST'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '30%'}}>
              {intl.formatMessage({id: 'LABELS.REMAINING'})}
            </th>
            <th className='bg-transparent fw-bold text-end fs-7 p-2 px-1' style={{minWidth: '15%'}}></th>
          </tr>
        }
      >
        {!!filterList?.length &&
          filterList.map((item: Record<string, any>, index: number) => (
            <tr key={item?.id}>
              <td className='pe-0'>
                <ChecksStatusBox
                  type={item?.type}
                  status={item?.status}
                  is_paused={item?.is_paused}
                  size={'symbol-50px'}
                  font={'8px'}
                />
                {/* <ChecksStatusArrow
                  is_paused={item?.is_paused}
                  status={item?.status}
                  is_under_maintenance={item?.is_under_maintenance}
                /> */}
              </td>
              <td className=''>
                <span className='text-dark fw-bold mb-1 fs-6'>
                  <LongTitle title={item?.data?.host} id={'ch-down-i-d' + index} />
                </span>
              </td>
              {/* <td className=''>
                <ProgressDate
                  startTimeStamp={item?.ssl_data?.start}
                  endTime={item?.ssl_expire}
                  place='left'
                />
              </td> */}
              <td className='p-0'>
                {item?.ssl_data?.start ? (
                  <div className='d-flex flex-column w-100 me-2'>
                    <div className='d-flex flex-stack mb-2'>
                      <span className='text-dark me-2 fs-7 fw-semobold'>
                        {progressValues(item?.ssl_data?.start, item?.ssl_expire).label}
                      </span>
                    </div>
                    <div className='progress h-6px w-100'>
                      <div
                        className='progress-bar bg-primary'
                        role='progressbar'
                        style={{
                          width: `${
                            progressValues(item?.ssl_data?.start, item?.ssl_expire).progressWidth
                          }%`,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <span style={{fontSize: '10px'}}>
                    {intl.formatMessage({id: 'LABELS.NO_DATA_TO_DISPLAY'})}
                  </span>
                )}
              </td>
              <td className='text-end'>
                <Link
                  to={`/ssl/${item?.device_id}?module=${item?.id}`}
                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                >
                  <span className='fas fa-arrow-right fs-4' />
                </Link>
              </td>
            </tr>
          ))}
      </TablesWidget2>
    </div>
  )
}

export default React.memo(SslExpiringSoon)
