import React from 'react'
import IconButton from '../../global/IconButton'

interface IAccountCard {
  children?: any
  title: any
  tooltip?: any
  onAdd?: any
  changeIcon?: boolean
  canAdd?: any
  onChangeIcon?: any
  className?: string
  noBorder?: boolean
  rightNode?: React.ReactNode
}

function AccountCard({
  children,
  title,
  tooltip,
  onAdd = null,
  changeIcon = false,
  canAdd = () => {
    return true
  },
  className,
  noBorder,
  onChangeIcon = null,
  rightNode,
}: IAccountCard) {
  return (
    <div className='card card-custom '>
      <div className={`card-header ${noBorder ? 'border-0' : ''} px-4 `}>
        <span className='card-title fw-bold fs-2 w-75'>
          {title}{' '}
          {tooltip && <span className='fas fa-info-circle ms-3 fs-4' data-tip={tooltip}></span>}
        </span>
        <div className='card-toolbar '>
          {canAdd() && !changeIcon && (
            <IconButton onClick={onAdd} icon='fas fa-plus  fs-1x' tooltip='add new' />
          )}
          {rightNode && <div>{rightNode}</div>}
          {changeIcon && (
            <button type='button' onClick={onChangeIcon} className='btn btn-sm btn-light'>
              <i className='fas fa-minus text-primary fs-1' data-tip='cancel'></i>
            </button>
          )}
        </div>
      </div>
      <div className={`card-body ${className}`}>{children}</div>
    </div>
  )
}

export default AccountCard
