
import { _axios } from "../app/store/core/inceptor";
import { loadTableParams } from "../helpers";


export const Ticket = {
  getDepartments: () => _axios.get(`/tickets/departments`),
  getStatuses: () => _axios.get(`/tickets/statuses`),
  getPriorities: () => _axios.get(`/tickets/priorities`),
  add: (params: any) => {
    let formData = new FormData();

    for (const i in params) {
      if (i === "file") {
        for (const j in params[i]) {
          if ("name" in params[i][j]) {
            formData.append(`file[${j}]`, params[i][j]);
          }
        }
      } else {
        formData.append(i, params[i]);
      }
    }

    return _axios.post(`/tickets`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        enctype: "multipart/form-data",
      },
    });
  },
  getList: (params: any) => {
    let { query, headers } = loadTableParams(params);

    if ("status" in params && params["status"] !== "") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query = `${query}&status=${params["status"]}`;
    }

    // return axios.get(`/tickets?${query}`, {
    //   headers: { ...headers },
    // });
    return _axios.get(`/tickets?${query}`, {
      headers: { ...headers },
    });
  },
  profile: (id: number) => _axios.get(`/tickets/${id}`),
  getReplies: (id: number, params: any) => {
    let { query, headers } = loadTableParams(params);

    return _axios.get(`/tickets/${id}/replies?${query}`, {
      headers: { ...headers },
    });
  },
  reply: (id: number, params: any) => {
    let formData = new FormData();

    for (const i in params) {
      if (i === "file") {
        for (const j in params[i]) {
          if ("name" in params[i][j]) {
            formData.append(`file[${j}]`, params[i][j]);
          }
        }
      } else {
        formData.append(i, params[i]);
      }
    }

    return _axios.post(`/tickets/${id}/replies`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        enctype: "multipart/form-data",
      },
    });
  },
};
