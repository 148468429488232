import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import store from '../setup/redux/Store'
declare global {
  interface Window {
    io: any
    Echo: Echo
    pusher: Pusher
  }
}
window.pusher = new Pusher(process.env.REACT_APP_SOCKET_KEY || '')
// @TODO we dont need this on production because on samesite cookies are sent by browser automatically, 
Pusher.Runtime.createXHR = function () {
  var xhr: any = new XMLHttpRequest()
  xhr.withCredentials = true
  return xhr
}




const getFromStore = () => {
  const token = store.getState()?.auth?.accessToken
  return {
    token: `Bearer ${token ?? ''}`,
  }
}

export function createSocketConnection() {
    if (!window.Echo) {
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.REACT_APP_SOCKET_KEY,
            wsHost: process.env.REACT_APP_WS_HOST,
            wssHost: process.env.REACT_APP_WS_HOST,
            enabledTransports: ['ws', 'wss'],
            wssPort:process.env.REACT_APP_WSS_PORT,
            wsPort:process.env.REACT_APP_WS_PORT,
            forceTLS: false,
            disableStats: true,
            authEndpoint: process.env.REACT_APP_API_URL_NOT_VERSION + `/broadcasting/auth`,
            auth: {
              headers: {
                Accept: 'application/json, text/plain, */*',
                'Authorization': getFromStore()?.token,
              }
            }
        });
    }
}
