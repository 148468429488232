import Http from '../app/store/core/inceptor'

export const getApiTokens = () => {
  return Http.get('/tokens/api')
}

export const createApiTokens = (data: Record<string, any>) => {
  return Http.post('/tokens/api/create', data)
}

export const removeAPlToken = (id: number) => {
  return Http.delete(`/tokens/api/${id}`)
}
