import React from 'react'
import useMainConfig from './useMainConfig'

const useIsTrial: () => boolean = () => {
  const {is_free_plan, is_in_trial} = useMainConfig()

  return is_free_plan && is_in_trial
}

export default useIsTrial
