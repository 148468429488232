/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useRef, useEffect} from 'react'
import {Col, Row, Stack} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import Column from './draggable/Column'
import {DragDropContext, DropResult, OnDragEndResponder} from '@hello-pangea/dnd'
import {SettingToggler} from './settings/SettingToggler'
import useDashboardValues, {DashboardTypes, initialData} from '../../modules/context/DashboardCtx'
import {editDashboardWidgets} from '../../../services/dashboard'
import TouringHelper from '../../../helpers/TouringHelper'
import MetaHead from '../../modules/global/MetaHead/MetaHead'

import DashboardManagement from '../customizedDashboard/DashboardManagement'

const DashboardPage: FC = () => {
  const [state, dispatch] = useDashboardValues()
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      editDashboardWidgets(state)
    }
  }, [state])

  const OnDragEnd = (result: DropResult) => {
    const {source, destination, draggableId} = result

    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    const start = state.columns[source.droppableId]
    const finish = state.columns[destination.droppableId]

    if (start === finish) {
      const newWidgetIds = Array.from(start.widgetsIds)
      newWidgetIds.splice(source.index, 1)
      newWidgetIds.splice(destination.index, 0, draggableId)

      const newColumn = {
        ...start,
        widgetsIds: newWidgetIds,
      }

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      }

      dispatch({
        type: DashboardTypes.RE_ORDER,
        payload: newState,
      })
      return
    }

    //move to another list
    const startWidgetIds = Array.from(start.widgetsIds)
    startWidgetIds.splice(source.index, 1)

    const newStart = {
      ...start,
      widgetsIds: startWidgetIds,
    }

    const finishWidgetsIds = Array.from(finish.widgetsIds)
    finishWidgetsIds.splice(destination.index, 0, draggableId)
    const newFinish = {
      ...finish,
      widgetsIds: finishWidgetsIds,
    }

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }
    dispatch({
      type: DashboardTypes.RE_ORDER,
      payload: newState,
    })
  }

  const intl = useIntl()
  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Xitoring',
      path: '',
      isSeparator: false,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardManagement />

      <SettingToggler />
      <DragDropContext onDragEnd={OnDragEnd}>
        <Row className='overflow-hidden gy-2'>
          {state?.columnOrder?.map((columnId: string) => {
            const column = state.columns[columnId]
            const widgets = column.widgetsIds.map((widgetId: string) => state.widgets[widgetId])
            return (
              <Col xs={12} md={4} key={column.id}>
                <Column column={column} widgets={widgets} />
              </Col>
            )
          })}
        </Row>
      </DragDropContext>
      <TouringHelper />
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()

  return (
    <>
    <MetaHead title={`${intl.formatMessage({id: 'MENU.DASHBOARD'})} - Xitoring`} key="dashboard-page"/>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>

      <DashboardPage />
    </>
  )
}

export default DashboardWrapper
