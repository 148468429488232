import React from 'react'
import {FormattedMessage} from 'react-intl'
import {DashboardType} from '../../modules/context/CustomizedDashboardCtx'
import DashboardItemForm from './DashboardItemForm'

const DashboardMangementForm = ({
  customizedDashboards,
}: {
  customizedDashboards: DashboardType[]
}) => {
  return (
    <ul className='mt-4' style={{listStyleType: 'disc'}}>
      {!!customizedDashboards?.length ? (
        customizedDashboards?.map((dashboard) => {
          return (
            <li key={dashboard.id}>
              <DashboardItemForm dashboard={dashboard} />
            </li>
          )
        })
      ) : (
        <p className='text-center fs-7'>
          <FormattedMessage id='NO_DATA_TABLE' />
        </p>
      )}
    </ul>
  )
}

export default React.memo(DashboardMangementForm)
