
import { Accordion } from "react-bootstrap";
import {
    types
} from "./actionTypes";

const initialState: any = {
    pending: false,
    error: null,
    success: false,
    pendingGetChecksTrigger: false,
    errorGetChecksTrigger: null,
    triggerListData: {},
    checkTriggerListData: {},
    errorCreateTrigger: null,
    pendingCreateTrigger: false,
    successCreateTrigger: false,
    errorDeleteTrigger: null,
    pendingDeleteTrigger: false,
    successDeleteTrigger: false,
    errorUpdateTrigger: null,
    pendingUpdateTrigger: false,
    successUpdateTrigger: false,
    errorCreateCheckTrigger: null,
    pendingCreateCheckTrigger: false,
    successCreateCheckTrigger: false,
    errorUpdateCheckTrigger: null,
    pendingUpdateCheckTrigger: false,
    successUpdateCheckTrigger: false,
    errorDeleteCheckTrigger: null,
    pendingDeleteCheckTrigger: false,
    successDeleteCheckTrigger: false,
};


// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
    switch (action.type) {


        case types.REQUEST_SERVER_TRIGGERS:
            return {
                ...state,
                error: null,
                pending: true,
                success: false,
                triggerListData: []

            };
        case types.REQUEST_SERVER_TRIGGERS_SUCCESS:

            return {
                ...state,
                pending: false,
                success: true,
                triggerListData: action.response
            };
        case types.REQUEST_SERVER_TRIGGERS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error,

            };

        case types.REQUEST_SERVER_CHECKS_TRIGGERS:
            return {
                ...state,
                errorGetChecksTrigger: null,
                checkTriggerListData: []

            };

        case types.REQUEST_SERVER_CHECKS_TRIGGERS_SUCCESS:

            return {
                ...state,
                pendingGetChecksTrigger: false,
                checkTriggerListData: action.response
            };
        case types.REQUEST_SERVER_CHECKS_TRIGGERS_ERROR:
            return {
                ...state,
                pendingGetChecksTrigger: false,
                errorGetChecksTrigger: action.error,

            };

        case types.REQUEST_CREATE_TRIGGERS:
            return {
                ...state,
                errorCreateTrigger: null,
                pendingCreateTrigger: true,

                successCreateTrigger: false,

            };

        case types.REQUEST_CREATE_TRIGGERS_SUCCESS:

            return {
                ...state,
                pendingCreateTrigger: false,
                successCreateTrigger: true,

            };
        case types.REQUEST_CREATE_TRIGGERS_ERROR:
            return {
                ...state,
                pendingCreateTrigger: false,
                errorCreateTrigger: action.action.response.data.message,

            };

            
        case types.REQUEST_DELETE_TRIGGERS:
            return {
                ...state,
                errorDeleteTrigger: null,
                pendingDeleteTrigger: true,
                successDeleteTrigger: false,

            };

        case types.REQUEST_DELETE_TRIGGERS_SUCCESS:

            return {
                ...state,
                pendingDeleteTrigger: false,
                successDeleteTrigger: true,

            };
        case types.REQUEST_DELETE_TRIGGERS_ERROR:
            
            return {
                ...state,
                pendingDeleteTrigger: false,
                errorDeleteTrigger: action.action.response.data.message,

            };

     
            case types.REQUEST_UPDATE_TRIGGERS:
                return {
                    ...state,
                    errorUpdateTrigger: null,
                    pendingUpdateTrigger: true,
                    successUpdateTrigger: false,
    
                };
    
            case types.REQUEST_UPDATE_TRIGGERS_SUCCESS:
    
                return {
                    ...state,
                    pendingUpdateTrigger: false,
                    successUpdateTrigger: true,
    
                };
            case types.REQUEST_UPDATE_TRIGGERS_ERROR:
                
                return {
                    ...state,
                    pendingUpdateTrigger: false,
                    errorUpdateTrigger: action.action.response?.data?.message?
                    action.action.response?.data?.message:action.action?.response?.data?.type?
                    action.action.response?.data?.type[0]:'Error has occurred',
    
                };
    
    
                case types.REQUEST_CREATE_CHECK_TRIGGERS:
                    return {
                        ...state,
                        errorCreateCheckTrigger: null,
                        pendingCreateCheckTrigger: true,
                        successCreateCheckTrigger: false,
        
                    };
        
                case types.REQUEST_CREATE_CHECK_TRIGGERS_SUCCESS:
        
                    return {
                        ...state,
                        pendingCreateCheckTrigger: false,
                        successCreateCheckTrigger: true,
        
                    };
                case types.REQUEST_CREATE_CHECK_TRIGGERS_ERROR:
                    
                    return {
                        ...state,
                        pendingCreateCheckTrigger: false,
                        errorCreateCheckTrigger: action.action.response.data.message,
        
                    };
        
    
                    case types.REQUEST_UPDATE_CHECK_TRIGGERS:
                        return {
                            ...state,
                            errorUpdateCheckTrigger: null,
                            pendingUpdateCheckTrigger: true,
                            successUpdateCheckTrigger: false,
            
                        };
            
                    case types.REQUEST_UPDATE_CHECK_TRIGGERS_SUCCESS:
            
                        return {
                            ...state,
                            pendingUpdateCheckTrigger: false,
                            successUpdateCheckTrigger: true,
            
                        };
                    case types.REQUEST_UPDATE_CHECK_TRIGGERS_ERROR:
                        
                        return {
                            ...state,
                            pendingUpdateCheckTrigger: false,
                            errorUpdateCheckTrigger: action.action.response.data.message,
            
                        };
            

                        case types.REQUEST_DELETE_CHECK_TRIGGERS:
                            return {
                                ...state,
                                errorDeleteCheckTrigger: null,
                                pendingDeleteCheckTrigger: true,
                                successDeleteCheckTrigger: false,
                
                            };
                
                        case types.REQUEST_DELETE_CHECK_TRIGGERS_SUCCESS:
                
                            return {
                                ...state,
                                pendingDeleteCheckTrigger: false,
                                successDeleteCheckTrigger: true,
                
                            };
                        case types.REQUEST_DELETE_CHECK_TRIGGERS_ERROR:
                            
                            return {
                                ...state,
                                pendingDeleteCheckTrigger: false,
                                errorDeleteCheckTrigger: action.action.response.data.message,
                
                            };
                
    

        case types.RESET_Graph_PROPS:
            return {
                ...state,
                pending: false,
                error: null,
                success: false,
                errorCreateTrigger: null,
                pendingCreateTrigger: false,
                successCreateTrigger: false,
                errorDeleteTrigger: null,
                pendingDeleteTrigger: false,
                successDeleteTrigger: false,
                errorUpdateTrigger: null,
                pendingUpdateTrigger: false,
                successUpdateTrigger: false,
                errorCreateCheckTrigger: null,
                pendingCreateCheckTrigger: false,
                successCreateCheckTrigger: false,
                errorUpdateCheckTrigger: null,
                pendingUpdateCheckTrigger: false,
                successUpdateCheckTrigger: false,
                errorDeleteCheckTrigger: null,
                pendingDeleteCheckTrigger: false,
                successDeleteCheckTrigger: false,

            };



        default:
            return {
                ...state,
            };
    }
};