
import  { _axios } from '../../core/inceptor'

export const getMainSettings = () => _axios.get<any>('/services/main')
export const getSelectedNotificationRoleRequest = (id:number) => _axios.get(`/notifications/roles/${id}` )
export const testNotificationRoleRequest = (params:any) => _axios.post<any>('/notifications/roles/test',params)
export const addNewNotificationRoleRequest = (params:any) => _axios.post<any>('/notifications/roles',params)
export const editNotificationRoleRequest = (params:any,id:number) => _axios.put(`/notifications/roles/${id}`,params )
export const deleteNotificationRoleRequest = (id:number) => _axios.delete(`/notifications/roles/${id}` )


export const editNotificationRoleRequestObj = (data:{params:any,id:number}) => _axios.put(`/notifications/roles/${data.id}`,data.params )



