import {Checkbox, Divider, List, ListItemButton} from '@mui/material'
import React, {Fragment} from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {getUptimeList} from '../../../../services/uptime'
import WaveSpinner from '../../../modules/global/WaveSpinner'
import {moduleTypeToHumanReadable} from '../../../modules/widgets/components/Common'
import {IGroup} from '../../../store/groupStore/types'

interface ICheckSelect {
  onSelect: (data: Record<string, any>) => void
  moduleIds: number[]
}

const CheckSelect = (props: ICheckSelect) => {
  const {onSelect, moduleIds} = props
  const {data: groupLists, isLoading}: any = useQuery(
    ['uptime'],
    () => getUptimeList({type: '20,21'}),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  )
  const intl = useIntl()
  return (
    <div
      style={{
        // maxHeight: '400px',
        overflowY: 'auto',
      }}
      className='pe-2'
    >
      <h3 className='mb-6 text-center'>{intl.formatMessage({id: 'LABELS.SELECT.CHECKS'})}</h3>
      {isLoading && <WaveSpinner isLoading />}

      {!!groupLists &&
        Object?.keys(groupLists ?? {})?.map((groupName: string) => {
          const groupDevices = groupLists?.[groupName]?.devices
          const groupSub = groupLists?.[groupName]?.subgroups
          return (
            <Fragment key={groupName}>
              <Divider>
                <h5 className='m-0 fw-bold'>{groupName}</h5>
              </Divider>
              <List component='nav' className='mb-4' sx={{"& .Mui-selected":{
                backgroundColor:"var(--kt-primary) !important"
              }}}>
                {Object?.keys(groupDevices ?? {})?.map((deviceName: any, index) => {
                  const checks = Object.values(groupDevices[deviceName])
                  return (
                    <div className='mt-2 rounded border border-1' key={deviceName + index}>
                      <strong className='text-center p-1 bg-secondary d-block'>{deviceName}</strong>
                      <section className='p-1'>
                        {checks?.map((check: any) => {
                          return (
                            <ListItemButton
                              selected={moduleIds?.includes(check.id)}
                              onClick={(event) =>
                                onSelect({
                                  title: `${moduleTypeToHumanReadable(check.type)?.toUpperCase()} ${
                                    check?.host
                                  }`,
                                  type_human_readable: check.type ===20?"ping":"http",
                                  name: 'check',
                                  deviceName,
                                  label:check?.label,
                                  id:check?.id,
                                  type:check?.type,
                                  device_id:check?.device_id
                                })
                              }
                              key={check.id}
                              className='d-flex align-items-center fs-7 justify-content-between p-2 rounded mt-1'
                            >
                              <div>
                                <strong className='text-uppercase me-3'>
                                  {moduleTypeToHumanReadable(check.type)}
                                </strong>
                                <strong className='fs-7'>{check?.host}</strong>
                              </div>

                              {/* <Checkbox checked={moduleIds?.includes(check.id)} /> */}
                            </ListItemButton>
                          )
                        })}
                      </section>
                    </div>
                  )
                })}
              </List>
              {!!groupSub &&
                Object?.keys(groupSub ?? {})?.map((subGrName) => {
                  const subGroupDevices = groupSub?.[subGrName]?.devices

                  return (
                    <Fragment key={groupSub}>
                      <Divider>
                        <h5 className='m-0 fw-bold'>{subGrName}</h5>
                      </Divider>
                      <List component='nav' className='mb-4' sx={{"& .Mui-selected":{
                backgroundColor:"var(--kt-primary) !important"
              }}}>
                        {Object?.keys(subGroupDevices ?? {})?.map(
                          (deviceSubName: any, subIndex) => {
                            const checksSubs = Object.values(subGroupDevices[deviceSubName])
                            return (
                              <div
                                className='mt-2 rounded border border-1'
                                key={subIndex + deviceSubName}
                              >
                                <strong className='text-center p-1 bg-secondary d-block'>
                                  {deviceSubName}
                                </strong>
                                <section className='p-1'>
                                  {checksSubs?.map((checksub: any) => {
                                    return (
                                      <ListItemButton
                                        selected={moduleIds?.includes(checksub.id)}
                                        onClick={(event) =>
                                          onSelect({
                                            ...checksub,

                                            title: `${moduleTypeToHumanReadable(
                                              checksub.type
                                            )?.toUpperCase()} ${checksub?.host}`,
                                            type_human_readable: checksub.type ===20?"ping":"http"
                                            ,
                                            name: 'check',
                                          })
                                        }
                                        className='d-flex align-items-center justify-content-between p-0 px-1 rounded mt-1'
                                        key={checksub.id}
                                      >
                                        <div>
                                          <strong className='text-uppercase me-3'>
                                            {moduleTypeToHumanReadable(checksub.type)}
                                          </strong>
                                          <strong className='fs-7'>{checksub?.host}</strong>
                                        </div>

                                        {/* <Checkbox
                                          checked={moduleIds?.includes(checksub.id)}
                                        /> */}
                                      </ListItemButton>
                                    )
                                  })}
                                </section>
                              </div>
                            )
                          }
                        )}
                      </List>
                    </Fragment>
                  )
                })}
            </Fragment>
          )
        })}
    </div>
  )
}

export default CheckSelect
