/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import TwoFactorAuth from './components/TwoFactorAuth'
import {ToastContainer} from 'react-toastify'
import './style.scss'

const AuthLayout = () => {
  

  return (
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 py-10 order-2 order-lg-1 card h-100 border-0'>
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div className='w-100 w-lg-500px p-8'>
              <Outlet />
             
            <div className='d-flex fw-semibold text-primary fs-base justify-content-between mt-5'>
              <a href='https://xitoring.com/docs/' className='  text-center flex-fill' target='_blank'>
                Documentation
              </a>
              <a href='https://xitoring.com/faq/' className='  text-center flex-fill' target='_blank'>
                FAQ
              </a>
              <a href='https://xitoring.com/contact/' className=' text-center flex-fill' target='_blank'>
                Contact Us
              </a>
            </div>
         
            </div>
          </div>
          
        </div>
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{backgroundColor: '#1f1f1f',backgroundImage:`url(${toAbsoluteUrl('/media/icons/bg.svg')})`}}
        >
          <div className='d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100'>
            <a href='#' className='mb-0 mb-lg-14'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/images/logos/logo_white.png')}
                className='h-60px h-lg-150px'
              />
            </a>
          </div>
        </div>

        {/* end::Wrapper */}
      </div>
      {/* end::Content */}

      {/* <ToastContainer
        position='bottom-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='2fa' element={<TwoFactorAuth />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
