import React, {useState} from 'react'

import useMainConfig from './useMainConfig'
import useIsTrial from './useIsTrial'
import {Options} from '../modules/auth/models/MainConfig'

const useActionControll = (limit: keyof Options) => {
  const {is_free_plan, options} = useMainConfig()
  const isTrial = useIsTrial()

  const [isLimitModal, setIsLimitModal] = useState<boolean>(false)

  const hasUsedProperty = (option: any): option is { used: number, quantity: number | string } => {
    return option && 'used' in option && 'quantity' in option;
  };

  const actionFunc = (cb: () => any | void) => {
    //if paid user call back function triggerd
    // if (!isTrial && !is_free_plan) {
    //   cb()
    //   return
    // }

    let isLimitted = false;
    if (options?.[limit]) {
      const option = options[limit];
      if (hasUsedProperty(option)) {
        isLimitted = option.quantity == -1 ? false : option.used >= option.quantity;
      }
    }
    if (isLimitted) {
      setIsLimitModal(true)
    } else {
      cb()
    }
  }

  const closeLimitModal = () => setIsLimitModal(false)

  return {actionFunc, isLimitModal, closeLimitModal}
}

export default useActionControll
