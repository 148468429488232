import Http from '../app/store/core/inceptor'

export const getLastLogin = () =>
  Http.get('/login/logs', {
    params: {
      per_page: '5',
    },
  })

export const getSessions = () => Http.get('/client/sessions?length=5')

export const getTopCpu = () => Http.get('/servers/top-cpu-usage')
export const getTopMemory = () => Http.get('/servers/top-memory-usage')
export const getLastTickets = () => Http.get('/tickets?per_page=5')
export const getSumCredits = () => Http.get('/credits/sum')
export const getDiskIo = () => Http.get('/servers/top-disk-io')
export const getQoutations = () => Http.get('/quotations?per_page=5')
export const getOrders = () => Http.get('/orders?per_page=5')
export const getResolvedIncidents = () => Http.get('/incidents?status=resolved&per_page=5')
export const getOpenIncidents = () => Http.get('/incidents?status=open&per_page=5')
export const getServersUptime = () => Http.get('/servers/top-uptime')
export const getServersLeastUptime = () => Http.get('/servers/top-uptime?reverse=1')
export const getChecksMostDowntime = () => Http.get('/servers/top-uptime?reverse=1&only_check=1')

export const getSMS = () => Http.get('/sms-credits/available')

export const getinvoiceCount = () => Http.get('/invoices/unpaid/count')

export const getInvoices = () => Http.get('/invoices?per_page=5&paid_status=unpaid')

export const getIncidents = () => Http.get('/incidents?count_only=true&auto_range=true')

export const editDashboardWidgets = (data: Record<string, any>) => {
  return Http.put('/client/profile', {widgets: data})
}

export const editTimezone = (data: string) => {
  return Http.put('/client/profile', {timezone: data})
}


export const editWizardAPI = (data: Record<string,any>) => {
  return Http.put('/client/profile', {wizard: data})
}

export const totalChecks=()=>Http.get("/check/widgets")