import React, { Component, ErrorInfo } from 'react';
import { Button } from 'react-bootstrap';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  onReport?: (error: Error, errorInfo: ErrorInfo) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorCount: number;
}

class MyErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorCount: 0 };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error:', error, errorInfo);
    const { onError, onReport } = this.props;
    if (onError) {
      onError(error, errorInfo);
    }
    if (onReport) {
      onReport(error, errorInfo);
    }
    this.setState({ errorCount: this.state.errorCount + 1 });
  }

  handleRefresh = () => {
    window.location.reload();
  };



  render() {
    const { hasError, errorCount } = this.state;
    if (hasError) {
      return (
        <div className='d-flex justify-content-center align-items-center flex-column mt-10 gap-8'>
          <h1>Something went wrong.</h1>
          {/* <p>Error count: {errorCount}</p> */}
          <Button onClick={this.handleRefresh}>Refresh</Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default  MyErrorBoundary;
