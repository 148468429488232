import React, { FC } from 'react'
import { Col, Dropdown, Row } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import IconButton from '../../../../modules/global/IconButton'
import { convertByte } from '../../../../modules/widgets/components/ChartAssistanr'
import { diskIoToHumanReadable } from '../../../../modules/widgets/components/Common'
import { NoteTextSVG } from '../../../../modules/widgets/components/SVGIcons'

const DeviceDiskIO: FC<{data: any}> = ({data}) => {
  const intl = useIntl()

  return (
    <Dropdown>
      <Dropdown.Toggle variant='Secondary' className='group-item-menu'>
      <IconButton icon='fas fa-list-alt'/>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data && Object.keys(data)?.length > 0 ? (
          <div>
            <Col xs={12}>
              <Row>
                <Col xs={3} className='text-center'>
                  {intl.formatMessage({id: 'LABELS.PARTITION'})}
                </Col>
                <Col xs={3} className='text-center'>
                  {intl.formatMessage({id: 'LABELS.READ'})}
                </Col>
                <Col xs={3} className='text-center'>
                  {intl.formatMessage({id: 'LABELS.WRITE'})}
                </Col>
                <Col xs={3} className='text-center'>
                  {intl.formatMessage({id: 'LABELS.TOTAL'})}
                </Col>
              </Row>
              <hr className='mt0 mb0' />
            </Col>
            {data &&
              Object.keys(data).map((item: string, idx: number) => (
                <Col xs={12} key={idx} className='data-item'>
                  <Row>
                    <Col xs={3} className='text-center'>
                      {item}
                    </Col>
                    <Col xs={3} className='cmd-container'>
                      {diskIoToHumanReadable(data[item].read)}
                    </Col>
                    <Col xs={3} className='text-center'>
                      {diskIoToHumanReadable(data[item].write)}
                    </Col>
                    <Col xs={3} className='text-center'>
                      {diskIoToHumanReadable(data[item].total)}
                    </Col>
                  </Row>
                </Col>
              ))}
          </div>
        ) : (
          <div>{intl.formatMessage({id: 'DESCRIPTIONS.NO_RECORDS'})}</div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DeviceDiskIO
