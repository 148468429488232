import { call, put, takeEvery } from 'redux-saga/effects'
import {
  geServerGraph,

    getNetworkGraph,

    getServerCheckGraph,

} from './requests';
import { types } from '../actionTypes'

interface Response {
  data: any
}
function* requestCpuUsageGraphHandler(action: any) {
  try {
    const response: Response = yield call(geServerGraph, action.serverId,"cpu-usage",action.period)
    yield put({
      type: types.REQUEST_GRAPH_CPU_USAGE_SUCCESS,
      response: response.data
    })
  } catch (e: any) {
    yield put({
      type: types.REQUEST_GRAPH_CPU_USAGE_ERROR,
      action: e?.data?.message
    })
  }
}
  
function* requestCpuLoadAverageGraphHandler(action: any) {
    try {
      const response: Response = yield call(geServerGraph, action.serverId,"cpu-load-average",action.period)
      yield put({
        type: types.REQUEST_GRAPH_CPU_LOAD_AVERAGE_SUCCESS,
        response: response.data
      })
    } catch (e: any) {
      yield put({
        type: types.REQUEST_GRAPH_CPU_LOAD_AVERAGE_ERROR,
        action: e?.data?.message
      })
    }
  }

function* requestDiskUsageGraphHandler(action: any) {
    try {
      const response: Response = yield call(geServerGraph, action.serverId,"disk-usage",action.period)
      yield put({
        type: types.REQUEST_GRAPH_DISK_USAGE_SUCCESS,
        response: response.data
      })
    } catch (e: any) {
      yield put({
        type: types.REQUEST_GRAPH_DISK_USAGE_ERROR,
        action: e?.data?.message
      })
    }
  }

function* requestMemoryUsageGraphHandler(action: any) {
    try {
      const response: Response = yield call(geServerGraph, action.serverId,"memory-usage",action.period)
      yield put({
        type: types.REQUEST_GRAPH_MEMORY_USAGE_SUCCESS,
        response: response.data
      })
    } catch (e: any) {
      yield put({
        type: types.REQUEST_GRAPH_MEMORY_USAGE_ERROR,
        action: e?.data?.message
      })
    }
  }

function* requestNetworkGraphHandler(action: any) {
    try {
      const response: Response = yield call(getNetworkGraph, action.serverId,action.period,action.port)
      yield put({
        type: types.REQUEST_GRAPH_NETWORK_SUCCESS,
        response: response.data
      })
    } catch (e: any) {
      yield put({
        type: types.REQUEST_GRAPH_NETWORK_ERROR,
        action: e?.data?.message
      })
    }
  }
  
function* requestDiskIOGraphHandler(action: any) {
    try {
      const response: Response = yield call(geServerGraph, action.serverId,"disk-io",action.period)
      yield put({
        type: types.REQUEST_GRAPH_DISK_IO_SUCCESS,
        response: response.data
      })
    } catch (e: any) {
      yield put({
        type: types.REQUEST_GRAPH_DISK_IO_ERROR,
        action: e?.data?.message
      })
    }
  }

  function* requestCheckGraphHandler(action: any) {
    try {
      const response: Response = yield call(getServerCheckGraph, action.serverId,action.checkId,action.graphName,action.period)
      yield put({
        type: types.REQUEST_SERVER_CHECK_GRAPH_SUCCESS,
        response: response.data
      })
    } catch (e: any) {
      yield put({
        type: types.REQUEST_SERVER_CHECK_GRAPH_ERROR,
        action: e?.data?.message
      })
    }
  }

function* watcherStatisticSaga() {
  yield takeEvery(types.REQUEST_GRAPH_CPU_USAGE, requestCpuUsageGraphHandler)
  yield takeEvery(types.REQUEST_GRAPH_CPU_LOAD_AVERAGE, requestCpuLoadAverageGraphHandler)
  yield takeEvery(types.REQUEST_GRAPH_DISK_USAGE, requestDiskUsageGraphHandler)
  yield takeEvery(types.REQUEST_GRAPH_MEMORY_USAGE, requestMemoryUsageGraphHandler)
  yield takeEvery(types.REQUEST_GRAPH_NETWORK, requestNetworkGraphHandler)
  yield takeEvery(types.REQUEST_GRAPH_DISK_IO, requestDiskIOGraphHandler)
  yield takeEvery(types.REQUEST_SERVER_CHECK_GRAPH, requestCheckGraphHandler)




}

export default watcherStatisticSaga
