import { useEffect, useState } from "react";
import { Profile } from "../../../services/Profile";
import { RootState } from "../../../setup";
import {UserModel} from "../auth/models/UserModel";
import {shallowEqual, useSelector} from "react-redux";
import classes from "./ClientAvatar.module.css";

interface IClientAvatar {
  id?: number;
  avatarClassName?: string;
  wrapperClassName?: string;
  srcFromParent?:string
}

const ClientAvatar = ({
  id,
  wrapperClassName,
  avatarClassName,
  srcFromParent
}: IClientAvatar) => {
  const [src, setSrc] = useState<any>();
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

  useEffect(() => {
    if (id) {
      Profile.getClientAvatar(id).then(async(response) => {
        setSrc(await URL.createObjectURL(response.data));
     });
    }
    
  }, [id]);

  return (
    <div className={wrapperClassName}>
      <img  src={ id?src??srcFromParent :srcFromParent??user?.avatar} className={`${classes.avatar} ${avatarClassName || " "}`} />
    </div>
  );
};

export default ClientAvatar;
