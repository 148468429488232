import React, {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {notify} from '../../../../helpers'
import {Api} from '../../../../services/Api'
import {useSocket} from '../../../../socket/apiKey/apiKey'
import WaveSpinner from '../../../modules/global/WaveSpinner'
import {ApiKeyItem} from './ApiKeyItem'

const XitogentKeysList = () => {
  const [perPage, setPerPage] = useState(3)

  const {
    data: response,
    refetch,
    isLoading,
  } = useQuery(['api-access-keys', perPage], () => Api.getList(perPage), {keepPreviousData: true})

  const intl = useIntl()

  const reloadListHandler = () => {
    refetch()
  }

  

  const loadMore = () => {
    setPerPage(perPage + 3)
  }

  useSocket({
    type: 'API_KEY_CREATED',
    callBack: reloadListHandler,
  })

  useSocket({
    type: 'API_KEY_DELETED',
    callBack: reloadListHandler,
  })

  const deleteApiHandler = (id: number, title: string) => {
    Api.delete(id).then((response) => {
      notify({
        type: 'success',
        message: intl.formatMessage({id: 'NOTIFICATIONS.ITEM_DELETED'}, {name: title}),
      })

      refetch()
    })
  }

  if (isLoading) {
    return <WaveSpinner isLoading={isLoading} />
  }

  return (
    <div className='mt-6'>
      {!!response?.data?.data.length ? (
        response?.data?.data.map((item: any) => {
          return (
            <ApiKeyItem
              key={item.id}
              {...item}
              token={item.key}
              onDelete={() => deleteApiHandler(item.id, item.title)}
            />
          )
        })
      ) : (
        <p className='text-center'>
          <FormattedMessage id='ACCOUNT.APIS.EMPTY' />
        </p>
      )}

      {perPage < response?.data?.total && (
        <button className={`btn btn-primary btn-sm w-100 mt-4`} onClick={loadMore}>
          <FormattedMessage id='ACTIONS.LOAD_MORE' />
        </button>
      )}
    </div>
  )
}

export default XitogentKeysList
