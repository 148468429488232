
import React from 'react'
import useCssVariable from '../../../Hooks/useCssVariable'
import UptimeDevice from './UptimeDevice'

interface IUptimeGroup {
  groupData: Record<string, any>
  range: string
  isBulkEnable: boolean
  onSelectCheck: (checks: any[]) => void
  selectedChecks: any[]
}

const UptimeGroup = (props: IUptimeGroup) => {
  const {groupData, range, isBulkEnable, onSelectCheck, selectedChecks} = props
  const borderColor = useCssVariable("--kt-page-bg")
  return (
    <section
      className='mb-4 rounded overflow-hidden '
      style={{border:`2px solid ${borderColor}`}}
    >
      <h3 className='p-3 bg-secondary text-dark w-100 text-center'>{groupData?.name}</h3>
      <div className='p-3'>
        {!!groupData?.dev?.length &&
          groupData?.dev?.map((device: any, index: number) => {
            return (
              <UptimeDevice
                device={device}
                range={range}
                key={device?.id + device?.name + '-'}
                isBulkEnable={isBulkEnable}
                onSelectCheck={onSelectCheck}
                selectedChecks={selectedChecks}
              />
            )
          })}
        {!!groupData?.sub?.length &&
          groupData?.sub?.map((subgroup: any, index:number) => {
           return <UptimeGroup
              range={range}
              isBulkEnable={isBulkEnable}
              groupData={subgroup}
              key={subgroup?.name + index}
              onSelectCheck={onSelectCheck}
              selectedChecks={selectedChecks}
            />
          })}
      </div>
    </section>
  )
}

export default UptimeGroup
