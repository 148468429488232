// add page location name to array below to show "Take a tour " button on it's helper modal
export const availableSteps= ['dashboard','uptime','incidents','servers','notificationRoles','billing']
// if you want to add a feature step, add it to the <FIRST> element of the array
export const dashboardSteps = [
  {
    target: '.step1',
    disableBeacon: true,
    content: 'Here you can add or manage dashboard widgets',
  },
]
export const uptimeSteps = [
  {
    target: '.step1',
    disableBeacon: true,
    content: 'A global filter that helps you to see individual Checks or those related to Servers',
  },
  {
    target: '.step2',
    disableBeacon: true,
    content: 'Additional filter that helps you to see Checks in specific group or by specific type',
  },
]
export const incidentsSteps = [
  {
    target: '.step1',
    disableBeacon: true,
    content: 'Here you manage to see incidents for specific group or specific check types',
  },
]
export const serversSteps = [
  {
    target: '.step1',
    disableBeacon: true,
    content: 'Here you can manage group and subgroups to organize your Servers and Checks',
  },
]

export const notificationRolesSteps = [
  {
    target: '.step1',
    disableBeacon: true,
    content:
      'Here you can create new notification roles, they can be used when creating new uptime checks or adding servers.',
  },
  {
    target: '.step2',
    disableBeacon: true,
    content: 'Default notification role or additionals that you later created are modified here',
  },
]
export const billingSteps = [
  // these comments are for testing - adding a new feature
  // TODO delete this
  // TODO delete featurestep1 class name on components
  // {
  //   target: '.featurestep1',
  //   disableBeacon: true,
  //   content: 'this is a new added feature',
  // },
  {
    target: '.step1',
    disableBeacon: true,
    content:
      'Here you can manage your subscription plan, upgrade, downgrade, or purchase extra SMS',
  },
  {
    target: '.step2',
    disableBeacon: true,
    content:
      'Here you can manage your credit cards, make sure to have an alternative credit card to avoid service interruption',
  },
  {
    target: '.step3',
    disableBeacon: true,
    content:
      'All invoices, whether paid or unpaid are shown here, you can see the PDF and also make a payment',
  },
  {
    target: '.step4',
    disableBeacon: true,
    content: 'Orders are created automatically when you Upgrade or Downgrade your service.',
  },
  {
    target: '.step5',
    disableBeacon: true,
    content:
      'When Xitoring issues a credit as a refund or gift on your account, it will be shown here',
  },
]
