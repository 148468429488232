import React, {Dispatch, useContext, useReducer} from 'react'

export const initialData = {
  widgets: {
    'widget-1': {id: 'widget-1', status: true,hasAccess:true},
    'widget-2': {id: 'widget-2', status: true,hasAccess:true},
    'widget-3': {id: 'widget-3', status: true,hasAccess:true},
    'widget-4': {id: 'widget-4', status: true,hasAccess:true},
    'widget-5': {id: 'widget-5', status: true,hasAccess:true},
    'widget-6': {id: 'widget-6', status: true,hasAccess:true},
    'widget-7': {id: 'widget-7', status: true,hasAccess:true},
    'widget-8': {id: 'widget-8', status: true,hasAccess:true},
    'widget-9': {id: 'widget-9', status: true,hasAccess:true},
    'widget-10': {id: 'widget-10', status: false,hasAccess:true},
    'widget-11': {id: 'widget-11', status: false,hasAccess:true},
    'widget-12': {id: 'widget-12', status: true,hasAccess:true},
    'widget-13': {id: 'widget-13', status: false,hasAccess:true},
    'widget-14': {id: 'widget-14', status: true,hasAccess:true},
    'widget-15': {id: 'widget-15', status: false,hasAccess:true},
    'widget-16': {id: 'widget-16', status: true,hasAccess:true},
    'widget-17': {id: 'widget-17', status: true,hasAccess:true},
    'widget-18': {id: 'widget-18', status: true,hasAccess:true},
    'widget-19': {id: 'widget-19', status: true,hasAccess:true},
    'widget-20': {id: 'widget-20', status: true,hasAccess:true},
  },
  columns: {
    'column-1': {
      id: 'column-1',
      widgetsIds: ['widget-1', 'widget-2', 'widget-3', 'widget-4', 'widget-5'],
    },
    'column-2': {
      id: 'column-2',
      widgetsIds: ['widget-6', 'widget-7', 'widget-8', 'widget-9', 'widget-10','widget-19','widget-20'],
    },
    'column-3': {
      id: 'column-3',
      widgetsIds: [
        'widget-11',
        'widget-12',
        'widget-13',
        'widget-14',
        'widget-15',
        'widget-16',
        'widget-17',
        'widget-18',       
      ],
    },
  },

  // Facilitate reordering of the columns
  columnOrder: ['column-1', 'column-2', 'column-3'],
}

const DashboardContext = React.createContext<any>(initialData)

/* ========================================================================== */
/*                                ACTION TYPES                                */
/* ========================================================================== */

export const DashboardTypes = {
  RE_ORDER: 'RE_ORDER',
  DISABLE: 'DISABLE',
  ENABLE: 'ENABLE',
  RESET: 'RESET',
  INITIAL: 'INITIAL',
}

/* ========================================================================== */
/*                                   REDUCER                                  */
/* ========================================================================== */

const DashboardReducer = (state: any, {type, payload}: any) => {
  switch (type) {
    case DashboardTypes.RE_ORDER:
      return {
        ...payload,
      }

    case DashboardTypes.DISABLE: {
      let newWidgets = {...state.widgets}
      const oldWidget = {...newWidgets[payload]}
      oldWidget.status = false
      newWidgets = {...newWidgets, [payload]: oldWidget}
      return {
        ...state,
        widgets: newWidgets,
      }
    }
    case DashboardTypes.ENABLE: {
      let newWidgets = {...state.widgets}
      const oldWidget = {...newWidgets[payload]}
      oldWidget.status = true
      newWidgets = {...newWidgets, [payload]: oldWidget}
      return {
        ...state,
        widgets: newWidgets,
      }
    }

    case DashboardTypes.INITIAL: {
      return payload
    }

    case DashboardTypes.RESET: 
      return {...initialData}
    

    default:
      return state
  }
}

/* ========================================================================== */
/*                           CUSTOM HOOK FOR CONTEXT                          */
/* ========================================================================== */

const useDashboardValues = () => {
  const context = useContext(DashboardContext)

  if (!context) {
    throw new Error('useDashboardValues must be used within a DashboardProvider ')
  }
  return context
}

/* ========================================================================== */
/*                              CONTEXT PROVIDER                              */
/* ========================================================================== */

export const DashboardProvider = ({children}: any) => {
  const [state, dispatch] = useReducer(DashboardReducer, initialData)

  return <DashboardContext.Provider value={[state, dispatch]}>{children}</DashboardContext.Provider>
}

export default useDashboardValues

/* ---------------------------------- help ---------------------------------- */

// const [state, dispatch] = useDashboardValues();

/* ------------------------------------ - ----------------------------------- */
