export const types = {
    GET_ORDERS_PENDING_COUNT: 'GET_ORDERS_PENDING_COUNT',
    GET_ORDERS_PENDING_COUNT_SUCCESS: 'GET_ORDERS_PENDING_COUNT_SUCCESS',
    GET_ORDERS_PENDING_COUNT_ERROR: 'GET_ORDERS_PENDING_COUNT_ERROR',

    GET_CLIENT_PERIODS: 'GET_CLIENT_PERIODS',
    GET_CLIENT_PERIODS_SUCCESS: 'GET_CLIENT_PERIODS_SUCCESS',
    GET_CLIENT_PERIODS_ERROR: 'GET_CLIENT_PERIODS_ERROR',

    
    GET_INVOICES_UNPAID_COUNT: 'GET_INVOICES_UNPAID_COUNT',
    GET_INVOICES_UNPAID_COUNT_SUCCESS: 'GET_INVOICES_UNPAID_COUNT_SUCCESS',
    GET_INVOICES_UNPAID_COUNT_ERROR: 'GET_INVOICES_UNPAID_COUNT_ERROR',

    
    GET_SMS_CREDITS_AVAILABLE: 'GET_SMS_CREDITS_AVAILABLE',
    GET_SMS_CREDITS_AVAILABLE_SUCCESS: 'GET_SMS_CREDITS_AVAILABLE_SUCCESS',
    GET_SMS_CREDITS_AVAILABLE_ERROR: 'GET_SMS_CREDITS_AVAILABLE_ERROR',
    SET_INCREASE_SMS_CREDITS_AVAILABLE:' SET_INCREASE_SMS_CREDITS_AVAILABLE',

    
    GET_MAIN_SERVICE: 'GET_MAIN_SERVICE',
    GET_MAIN_SERVICE_SUCCESS: 'GET_MAIN_SERVICE_SUCCESS',
    GET_MAIN_SERVICE_ERROR: 'GET_MAIN_SERVICE_ERROR',
    SET_MAIN_SERVICE:"SET_MAIN_SERVICE",

    
    GET_PAID_PLAN_PRICING_OPTIONS: 'GET_PAID_PLAN_PRICING_OPTIONS',
    GET_PAID_PLAN_PRICING_OPTIONS_SUCCESS: 'GET_PAID_PLAN_PRICING_OPTIONS_SUCCESS',
    GET_PAID_PLAN_PRICING_OPTIONS_ERROR: 'GET_PAID_PLAN_PRICING_OPTIONS_ERROR',

    
    GET_SMS_PLAN_PRICING_OPTIONS: 'GET_SMS_PLAN_PRICING_OPTIONS',
    GET_SMS_PLAN_PRICING_OPTIONS_SUCCESS: 'GET_SMS_PLAN_PRICING_OPTIONS_SUCCESS',
    GET_SMS_PLAN_PRICING_OPTIONS_ERROR: 'GET_SMS_PLAN_PRICING_OPTIONS_ERROR',

    RESET_BILLING_PROPS: 'RESET_BILLING_PROPS',

    INCREMENT_OPTION:"INCREMENT_OPTION",
    DECREMENT_OPTION:"DECREMENT_OPTION"
  

  }
  