import React from 'react'
import useCssVariable from '../../../Hooks/useCssVariable'
import TableGridUptime from '../../../modules/global/TableGridUptime'

interface IUptimeDevice {
  device: Record<string, any>
  range: string
  isBulkEnable:boolean
  onSelectCheck: (checks: any[]) => void
  selectedChecks:any[]


}

const UptimeDevice = (props: IUptimeDevice) => {
  const {device, range,isBulkEnable,onSelectCheck,selectedChecks} = props
  const bgColor = useCssVariable('--xitoring-gray-light-color')
  return (
    <div className='mb-2 rounded overflow-hidden'>
      <h6 className='text-center  py-2 m-0' style={{backgroundColor: bgColor}}>
        {device?.name}
      </h6>
      <TableGridUptime rows={device?.devices} range={range} isBulkEnable={isBulkEnable} onSelectCheck={onSelectCheck} selectedChecks={selectedChecks} />
    </div>
  )
}

export default React.memo(UptimeDevice)
