import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {useNavigate} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {getOrders} from '../../../services/dashboard'
import {TablesWidget5} from '../../../_metronic/partials/widgets'
import classes from "./style.module.scss"

interface Props {
  onRemove: () => void
}

const RecentOrders = ({onRemove}:Props) => {
  const intl = useIntl()

  const {data: orders, isLoading} = useQuery(['dashboard-orders'], getOrders)
  const onClose = () => {
    onRemove()
  }
  const navigate = useNavigate()

  return (
    <div>
      <TablesWidget5
        onClose={onClose}
        isLoading={isLoading}
        dataLength={orders?.data?.length}

        title={intl.formatMessage({id: 'RECENT.ORDERS'})}
        className={classes['custom_table']}

        icon={<span className='fas fa-shopping-cart fs-1' />}
        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '20%', width:"20%"}}>
              Code
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '25%', width:"25%"}}>
              {intl.formatMessage({id: 'OPENED'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '25%', width:"25%"}}>
              {intl.formatMessage({id: 'UPDATED'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '15%', width:"15%"}}>
              {intl.formatMessage({id: 'COST'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '15%', width:"15%"}}>
              {intl.formatMessage({id: 'LABELS.STATUS'})}
            </th>
          </tr>
        }
      >
        {!!orders?.data?.length &&
          orders?.data.map((order: Record<string, any>, index: number) => (
            <tr key={order?.id} onClick={() => navigate('/billing')} style={{cursor: 'pointer'}}>
              <td className='text-dark fw-semobold fs-7'>{order?.code}</td>
              <td>
                <div>
                  <span
                    className='text-truncate d-block text-dark fw-semobold fs-7 w-65px w-lg-75'
                    data-tip
                    data-for={'order-o-' + index}
                    style={{width:"9ch"}}
                  >
                    {order?.created_at}
                  </span>
                  {order?.created_at?.length >= 10 && (
                    <ReactTooltip id={'order-o-' + index} className='tooltip-wrapper'>
                      {order?.created_at}
                    </ReactTooltip>
                  )}
                </div>
              </td>
              <td>
                <div>
                  <span
                    className='text-truncate d-block text-dark fw-semobold fs-7 w-65px w-lg-75'
                    data-tip
                    data-for={'order-u-' + index}
                    style={{width:"9ch"}}
                  >
                    {order?.updated_at}
                  </span>
                  {order?.updated_at?.length >= 10 && (
                    <ReactTooltip id={'order-u-' + index} className='tooltip-wrapper'>
                      {order?.updated_at}
                    </ReactTooltip>
                  )}
                </div>
              </td>
              <td className=' '>
                <div className='d-flex align-items-center text-dark fw-semobold'>
                  <span className='fas fa-dollar-sign me-1' />
                  {order?.cost}
                </div>
              </td>
              <td className=''>
                <span
                  className={`badge ${
                    order?.status_name == 'Completed'
                      ? 'text-success'
                      : order?.status_name == 'Pending'
                      ? 'text-warning'
                      : 'text-danger'
                  }`}
                >
                  {order?.status_name == 'Completed' ? (
                    <span className='far fa-check-circle fs-1' />
                  ) : order?.status_name == 'Pending' ? (
                    <span className='far fa-clock fs-1' />
                  ) : (
                    <span className='far fa-times-circle fs-1' />
                  )}
                </span>
              </td>
            </tr>
          ))}
      </TablesWidget5>
    </div>
  )
}

export default React.memo(RecentOrders)
