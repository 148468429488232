import { useMediaQuery } from '@mui/material';
import React from 'react';

interface IPageContainer {
  children: React.ReactNode;
  className?: string;
  center?: boolean;
}

const PageContainer = (props: IPageContainer) => {
  const { children, className, center, ...rest } = props;
  const isMobile = useMediaQuery('(max-width:600px)')

  return (
    <section
      className={`card shadow p-4 ${
        center ? 'd-flex justify-content-center align-items-center' : ''
      } ${className}`}
      style={{
        minHeight: isMobile ? 'calc(100% - 60px)' : '100%',
      }}
      {...rest}
    >
      {children}
    </section>
  );
};

export default PageContainer;
