import React, {useRef, useEffect, useState} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'
import {Popper,Box} from '@mui/material'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  const [isAsideMinimized, setIsAsideMinimized] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const closePopper = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    // Function to check if the attribute is set to "on"
    const checkAsideMinimize = () => {
      const isMinimized = document.body.getAttribute('data-kt-aside-minimize') === 'on'
      setIsAsideMinimized(isMinimized)
    }

    // Initial check
    checkAsideMinimize()

    // Watch for attribute changes when component mounts
    const observer = new MutationObserver(checkAsideMinimize)
    observer.observe(document.body, {attributes: true, attributeFilter: ['data-kt-aside-minimize']})

    // Cleanup
    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
        closePopper()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return !isAsideMinimized ? (
    <div
      className={clsx('menu-item', {'here show': pathname.includes(to)}, 'menu-accordion')}
      data-kt-menu-trigger='click'
    >
      <span className='menu-link'>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        <span className='menu-title'>{title}</span>
        <span className='menu-arrow'></span>
      </span>
      <div className={clsx('menu-sub menu-sub-accordion', {'menu-active-bg': isActive})}>
        {children}
      </div>
    </div>
  ) : (
    <>
      <div
        className={clsx('menu-item ', {'here show': pathname.includes(to)})}
        aria-describedby={id}
        onClick={handleClick}
        style={{width:"85px"}}
        key="dropdown-menu-mini"
      >
        <span className='menu-link'>
          {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )}
          {icon && aside.menuIcon === 'svg' && (
            <span className='menu-icon'>
              <KTSVG path={icon} className='svg-icon-2' />
            </span>
          )}
          {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        </span>
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='right-start'
        style={{
          zIndex: 100000,
        }}
        ref={popperRef}
      >
        <Box
        sx={{
          backgroundColor: '#2b2b2b',
          "& .menu-link":{
            padding:"10px !important"
          }
        }}
        className=' aside-dark rounded overflow-hidden' >
          <div className='aside-menu'>
            <div className='menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500'>
              <div
                className={clsx('menu-sub menu-sub-accordion d-block', {
                  'menu-active-bg': isActive,
                })}
              >
                {children}
              </div>
            </div>
          </div>
        </Box>
      </Popper>
    </>
  )
}

export {AsideMenuItemWithSub}
