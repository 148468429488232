import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {getOpenIncidents} from '../../../services/dashboard'
import {TablesWidget2} from '../../../_metronic/partials/widgets'
import LongTitle from '../../modules/global/UI/LongTitle'
import {
  createCustomIncidentMessageWithIntegrations,
  createIncidentMessages,
  createMetricMessageWithIntegrations,
  generateCheckIncidentCause,
  moduleTypeToHumanReadable,
  TYPES_TITLE,
} from '../../modules/widgets/components/Common'
import classes from './style.module.scss'

interface Props {
  onRemove: () => void
}

const OpenIncidents = ({onRemove}: Props) => {
  const intl = useIntl()

  const {data: Incidents, isLoading} = useQuery(['dashboard-open-incidents'], getOpenIncidents)

  const onClose = () => {
    onRemove()
  }

  const returnTypeDisplayer = (model: any) => {
    const type = TYPES_TITLE(model.details.type)
    let resultModel: any = {cause: '', value: '', title: type, resolver: {}}
    if (model.module_id === 0) {
      resultModel.cause = createMetricMessageWithIntegrations(model, false, intl)
      resultModel.value = createIncidentMessages(model.details, intl)
    }

    if (model.resolved_id > 0) {
      if (model.resolver.module_id === 0) {
        resultModel.resolver.cause = createMetricMessageWithIntegrations(model, true, intl)
      }
      if (model.resolver.details.value) {
        resultModel.resolver.value = createIncidentMessages(model.resolver.details, intl)
      }
      if (model.resolver.details.is_manual) {
        resultModel.resolver.manual_message = createCustomIncidentMessageWithIntegrations(
          model.resolver.details,
          intl
        )
      }
    }

    return resultModel
  }

  return (
    <div>
      <TablesWidget2
        className={classes['custom_table']}
        isLoading={isLoading}
        title={intl.formatMessage({id: 'LABELS.OPEN_INCIDENTS'})}
        onClose={onClose}
        dataLength={Incidents?.data?.length}
        icon={<span className='fas fa-exclamation-triangle fs-1' />}
        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '15%'}}>
              {intl.formatMessage({id: 'CHECK'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '30%'}}>
              {intl.formatMessage({id: 'LABELS.SERVER_HOST'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '20%'}}>
              {intl.formatMessage({id: 'LABELS.DATE'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '25%'}}>
              {intl.formatMessage({id: 'DURATION'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '10%'}}></th>
          </tr>
        }
      >
        {!!Incidents?.data?.length
          ? Incidents?.data?.map((incident: Record<string, any>, index: number) => {
              return (
                <tr key={incident?.id}>
                  <td className='pe-0'>
                    <div className='symbol symbol-50px me-2 text-uppercase  '>
                      <span
                        className='symbol-label text-white bg-primary fw-bold'
                        
                      >
                        <span
                          style={{
                          fontSize:'9px',

                          }}
                        >
                          {incident?.type_human_readable?.split('_')?.[0]}
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className='text-dark fw-bold  mb-1 ' style={{fontSize: '11px'}}>
                      <LongTitle title={incident?.module?.label ?? incident?.device_object?.label} id={'open-i-d' + index} />
                    </span>
                    <span className='text-muted fw-semobold d-block' style={{fontSize: '11px'}}>
                      {incident?.module_id === 0 && returnTypeDisplayer(incident).cause}
                      {incident?.module_id > 0 &&
                        generateCheckIncidentCause(incident.details, intl)}
                    </span>
                  </td>
                  <td className='px-1'>
                    <span>
                      <span>
                        <span
                          className='text-truncate d-block fs-7 text-dark'
                          data-tip
                          data-for={'create-op-' + index}
                          style={{width: '6ch'}}
                        >
                          {incident?.created_at_human_readable}
                        </span>
                        <ReactTooltip id={'create-op-' + index} className='tooltip-wrapper'>
                          {incident?.created_at_human_readable}
                        </ReactTooltip>
                      </span>
                    </span>
                  </td>
                  <td className='text-end'>
                    <span className='text-muted fw-semobold'>
                      <span
                        className='text-truncate d-block fs-7 text-dark'
                        data-tip
                        data-for={'open-l-' + index}
                        style={{width: '6ch'}}
                      >
                        {incident?.downtime_human_readable}
                      </span>
                      <ReactTooltip id={'open-l-' + index} className='tooltip-wrapper'>
                        {incident?.downtime_human_readable}
                      </ReactTooltip>
                    </span>
                  </td>
                  <td className='text-end'>
                    <Link
                      to={`/incidents/${incident.id}`}
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <span className='fas fa-arrow-right fs-4' />
                    </Link>
                  </td>
                </tr>
              )
            })
          : null}
      </TablesWidget2>
    </div>
  )
}

export default React.memo(OpenIncidents)
