import * as React from 'react'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import useCssVariable from '../../../Hooks/useCssVariable'

interface IDatePickerMui {
  onChange: (value: dayjs.Dayjs, keyboardInputValue?: string | undefined) => void
  label?: React.ReactNode
  placeholder?: string
  size?: 'small' | 'medium'
  height?: string
  value: any
  needMin?:boolean
  format?: string
  withoutHour?:boolean
}

export default function SimpleDatePickerMui(props: IDatePickerMui) {
  const {onChange, label, value, placeholder = '', size = 'medium', height,needMin=true, format='YYYY-MM-DD hh:mm A'} = props
  const borderColor = useCssVariable('--kt-input-border-color')

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        inputFormat={format}
        disableFuture={true}
        views={['day', 'month']}
        renderInput={(params) => (
          <TextField
            size={size}
            {...params}
            fullWidth
            placeholder={placeholder}
            sx={{
              '& .MuiOutlinedInput-root': {
                ...(height ? {height: height} : {}),
                '& fieldset': {
                  borderColor: borderColor,
                  borderWidth: '1px',
                },
                '&:hover fieldset': {
                  borderColor: borderColor,
                  borderWidth: '1px',
                },
                '&.Mui-focused fieldset': {
                  borderColor: borderColor,
                  borderWidth: '1px',
                },
              },
            }}
          />
        )}
        value={value}
        onChange={(newValue: any) => {
          onChange(newValue)
        }}
        // minDate={needMin?dayjs():undefined}
        // minTime={needMin?dayjs():undefined}
      />
    </LocalizationProvider>
  )
}
