import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import ReactTooltip from 'react-tooltip'
import {setIconBrowser} from '../../../helpers/setIconBrowser'
import {getSessions} from '../../../services/dashboard'
import {TablesWidget3} from '../../../_metronic/partials/widgets'
import Flags from '../../modules/global/UI/Flags'
import classes from "./style.module.scss"

interface Props {
  onRemove: () => void
}

const RecentSessions = ({onRemove}:Props) => {
  const intl = useIntl()

  const {data: lastSessions,isLoading} = useQuery(['dashboard-sessions'], getSessions)

  const onClose = () => {
    onRemove()
  }

  const filtered=lastSessions?.data?.filter?.((sess:any)=>sess?.hide==0)??[]

  return (
    <div>
      <TablesWidget3
        onClose={onClose}
        isLoading={isLoading}
        dataLength={filtered?.length}

        title={intl.formatMessage({id: 'RECENT.SESSIONS'})}
        className={classes['custom_table']}

        icon={<span className='fas fa-laptop  fs-1' />}
        thead={
          <tr>
          <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '20%', width: '20%'}}>
            {intl.formatMessage({id: 'LABELS.NAME'})}
          </th>
          <th
            className='bg-transparent fw-bold fs-7 p-2 px-1 '
            style={{minWidth: '50%', width: '50%'}}
          >
            {intl.formatMessage({id: 'ACCOUNT.SESSION.IP'})}
          </th>
          <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '15%', width: '15%'}}>
            {intl.formatMessage({id: 'LABELS.PLATFORM'})}
          </th>
          <th
            className='bg-transparent fw-bold text-end fs-7 p-2 px-1'
            style={{minWidth: '15%', width: '15%'}}
          >
            {intl.formatMessage({id: 'LABELS.BROWSER'})}
          </th>
        </tr>
        }
      >
        {!!filtered?.length &&
          filtered?.map((session: Record<string, any>, index: number) => (
            <tr key={session?.no}>
              <td style={{whiteSpace: 'nowrap'}} className='text-dark fw-semobold  fs-7 '>
                <span className='mb-1 d-block'>
                {session?.user_name}

                </span>
                <div
                  className='text-muted text-truncate '
                  data-tip
                  data-for={'ip-n-' + index + session?.last_activity_readable}
                  style={{fontSize: '11px', width: '11ch'}}
                >
                  {session?.last_activity_readable}
                  
                </div>
                <ReactTooltip id={'ip-n-' + index + session?.last_activity_readable} className='tooltip-wrapper'>
                  {session?.last_activity_readable}
                </ReactTooltip>
              </td>
              <td className='text-dark fw-semobold fs-7 px-1'>
                <span
                  className='text-truncate d-block mb-1' 
                  data-tip
                  data-for={'ip-' + index}
                  style={{fontSize: '11px'}}

                >
                  {session?.ip}
                </span>
                <div className=' text-muted' style={{fontSize: '11px'}}>
                  
                  <Flags country={session?.country_iso} className="me-2"/>
                  {session?.country}
                </div>
                <ReactTooltip id={'ip-' + index} className='tooltip-wrapper'>
                  {session?.ip}
                </ReactTooltip>
              </td>
              <td className='text-dark fw-semobold fs-7 '>
              <div className='symbol symbol-35px me-2'>
                  <span className='symbol-label fs-3 bg-light-primary'>
                    <span
                      className={`${
                        session?.platform === 'OS X'
                          ? 'fab fa-apple'
                          : session?.platform === 'Windows'
                          ? 'fab fa-windows'
                          : 'fab fa-linux'
                      }`}
                    />
                  </span>
                </div>
              </td>
              <td className='text-end text-dark fw-bold fs-6 '>
                <div className='symbol symbol-35px me-2'>
                  <span className='symbol-label fs-3 bg-light-primary'>
                    <span className={`${setIconBrowser(session?.browser)}`} />
                  </span>
                </div>
              </td>
            </tr>
          ))}
      </TablesWidget3>
    </div>
  )
}

export default React.memo(RecentSessions)
