
import  { _axios } from "../../core/inceptor";
import { IGroup } from "../../groupStore/types";

export const getServers = () => _axios.get<IGroup>('/groups?append=devices')
export const getAgentKey = () => _axios.get<any>('/keys/xitogent')
export const getNotificationRoles = () => _axios.get<any>('/notifications/roles?per_page=1000')
export const updateServerRequest = (id:number,params:any) => _axios.put(`/servers/${id}`,params)
export const deleteServerRequest = (id:number) => _axios.delete(`/servers/${id}`)

// export const deleteServerRequest = (id:number) => Http.delete(`https://app-dev0.xitoring.com/client/devices/${id}/delete`)


