import React, {FC} from 'react'
import {useIntl} from 'react-intl'

const SettingToggler: FC = () => {
  const intl = useIntl()

  return (
    <button
      id='kt_help_toggle'
      className='step1 engage-help-toggle bg-hover-white text-hover-primary btn btn-flex h-50px bg-primary text-white btn-active-color-gray-900 shadow-sm px-5 rounded-end-0 '
      title={intl.formatMessage({id: 'WIDGET.SETTINGS'})}
      data-bs-toggle='tooltip'
      data-bs-placement='left'
      data-bs-dismiss='click'
      data-bs-trigger='hover'
      style={{
        position: 'fixed',
        right: '0',
        top: '30%',
        zIndex: 10,
      }}
    >
      <span className='fa fa-cog fs-3 ' />
    </button>
  )
}

export {SettingToggler}
