import {listen} from "../socketHook";
import {createSocketConnection} from '../setup';
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../setup";

type Options = {
    type: 'CONTACT_CREATED' | 'CONTACT_UPDATED' | 'CONTACT_DELETED';
    callBack: (payload: any) => void;
};

export const useSocket = ({ type, callBack }: Options) => {
    const { user} = useSelector<RootState, any>(({auth}) => auth, shallowEqual);
    const channel = 'access' in user ? `contact.${user.id}` : `client.${user.id}`;

    createSocketConnection();

    switch (type) {
        case 'CONTACT_CREATED': {
            return listen(channel, 'ContactCreated', callBack);
        }
        case 'CONTACT_UPDATED': {
            return listen(channel, 'ContactUpdated', callBack);
        }
        case 'CONTACT_DELETED': {
            return listen(channel, 'ContactDeleted', callBack);
        }
    }
};