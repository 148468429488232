
import  { _axios } from '../../core/inceptor'

export const getSMSCreditsAvailableRequest = () => _axios.get<any>(`/sms-credits/available`)
export const getInvoicesUnpaidCountRequest = () => _axios.get<any>(`/invoices/unpaid/count`)
export const getClientPeriodsRequest = () => _axios.get<any>(`/periods`)
export const getOrderPendingCountRequest = () => _axios.get<any>(`/orders/pending/count`)
export const getMainServiceRequest = () => _axios.get<any>(`/services/main`)
export const getPlanPricing = (code:string) => _axios.get<any>(`/plans/${code}/pricing`)






