import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {getinvoiceCount, getInvoices} from '../../../services/dashboard'
import {ListsWidget6} from '../../../_metronic/partials/widgets'

interface Props {
  onRemove: () => void
}


const Invoices = ({onRemove}:Props) => {
  const intl = useIntl()
  const {data: invoicesCount} = useQuery(['dashboard-invoices-count'], getinvoiceCount)

  const {data: invoicesList, isLoading} = useQuery(['dashboard-invoices'], getInvoices)

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <ListsWidget6
        onClose={onClose}
        isLoading={isLoading}
        title={intl.formatMessage({id: 'INVOICES'})}
        className=''
        desc={`Total : ${String(invoicesCount)}`}
        icon={<span className='fas fa-file-invoice fs-1' />}
        bg={'bg-light-warning'}
        keyGetter={{
          title: 'code',
          description: 'due',
          value: 'amount_unpaid',
          unit: '$ ',
        }}
        listData={invoicesList?.data}
      />
    </div>
  )
}

export default  React.memo(Invoices)
