import {types} from './actionTypes'

const initialState: any = {
  pending: false,
  success: false,
  error: null,
  list: [],
  orderPendingCount: 0,
  invoicesUnpaidCount: 0,
  smsCreditsAvailable: 0,
  clientPeriod: [],
  mainService: {},
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ORDERS_PENDING_COUNT:
      return {
        ...state,
        pendingGetOrderPendingCount: true,
        orderPendingCount: null,
      }
    case types.GET_ORDERS_PENDING_COUNT_SUCCESS:
      return {
        ...state,
        pendingGetOrderPendingCount: false,
        orderPendingCount: action.payload,
        errorGetOrderPendingCount: null,
      }
    case types.GET_ORDERS_PENDING_COUNT_ERROR:
      return {
        ...state,
        pendingGetOrderPendingCount: false,
        errorGetOrderPendingCount: action.error,
      }

    case types.GET_CLIENT_PERIODS:
      return {
        ...state,
        pendingGetPeriods: true,
        orderPendingCount: null,
        clientPeriod: [],
      }
    case types.GET_CLIENT_PERIODS_SUCCESS:
      return {
        ...state,
        pendingGetPeriods: false,
        clientPeriod: action.payload.data,
        errorGetPeriods: null,
      }
    case types.GET_CLIENT_PERIODS_ERROR:
      return {
        ...state,
        pendingGetPeriods: false,
        errorGetPeriods: action.error,
      }

    case types.GET_INVOICES_UNPAID_COUNT:
      return {
        ...state,
        pendingGetInvoicesUnpaidCount: true,
        // orderPendingCount: null
      }
    case types.GET_INVOICES_UNPAID_COUNT_SUCCESS:
      return {
        ...state,
        pendingGetInvoicesUnpaidCount: false,
        invoicesUnpaidCount: action.payload,
        errorGetInvoicesUnpaidCount: null,
      }
    case types.GET_INVOICES_UNPAID_COUNT_ERROR:
      return {
        ...state,
        pendingGetInvoicesUnpaidCount: false,
        errorGetInvoicesUnpaidCount: action.error,
      }

    case types.GET_SMS_CREDITS_AVAILABLE:
      return {
        ...state,
        pendingGetSMSCreditsAvailable: true,
        smsCreditsAvailable: null,
      }
    case types.GET_SMS_CREDITS_AVAILABLE_SUCCESS:
      return {
        ...state,
        pendingGetSMSCreditsAvailable: false,
        smsCreditsAvailable: action.payload,
        errorGetSMSCreditsAvailable: null,
      }
    case types.GET_SMS_CREDITS_AVAILABLE_ERROR:
      return {
        ...state,
        pendingGetSMSCreditsAvailable: false,
        errorGetSMSCreditsAvailable: action.error,
      }

    case types.GET_MAIN_SERVICE:
      return {
        ...state,
        pendingGetMainService: true,
        mainService: null,
        errorGetMainService: null,
      }
    case types.GET_MAIN_SERVICE_SUCCESS:
      return {
        ...state,
        pendingGetMainService: false,
        mainService: action.payload,
      }
    case types.GET_MAIN_SERVICE_ERROR:
      return {
        ...state,
        pendingGetMainService: false,
        errorGetMainService: action.error,
      }

    case types.GET_PAID_PLAN_PRICING_OPTIONS:
      return {
        ...state,
        pendingGetPaidPlanOptionPricing: true,
        PlanOptionPricing: null,
      }
    case types.GET_PAID_PLAN_PRICING_OPTIONS_SUCCESS:
      return {
        ...state,
        pendingGetPaidPlanOptionPricing: false,
        paidPlanOptionPricing: action.payload,
        errorGetPaidPlanOptionPricing: null,
      }
    case types.GET_PAID_PLAN_PRICING_OPTIONS_ERROR:
      return {
        ...state,
        pendingGetPaidPlanOptionPricing: false,
        errorGetPaidPlanOptionPricing: action.error,
      }

    case types.GET_SMS_PLAN_PRICING_OPTIONS:
      return {
        ...state,
        pendingGetSMSPlanOptionPricing: true,
        PlanOptionPricing: null,
      }
    case types.GET_SMS_PLAN_PRICING_OPTIONS_SUCCESS:
      return {
        ...state,
        pendingGetSMSPlanOptionPricing: false,
        smsPlanOptionPricing: action.payload,
        errorGetSMSPlanOptionPricing: null,
      }
    case types.GET_SMS_PLAN_PRICING_OPTIONS_ERROR:
      return {
        ...state,
        pendingGetSMSPlanOptionPricing: false,
        errorGetSMSPlanOptionPricing: action.error,
      }

    case types.RESET_BILLING_PROPS:
      return {
        ...state,
        pendingGetOrderPendingCount: false,
        success: false,
        errorGetOrderPendingCount: null,
      }

    case types.SET_MAIN_SERVICE:
      return {
        ...state,
        mainService: action.payload,
      }

    case types.SET_INCREASE_SMS_CREDITS_AVAILABLE:
      return {
        ...state,
        smsCreditsAvailable: state.smsCreditsAvailable + parseInt(action.payload),
      }

    case types.INCREMENT_OPTION:{
      let cloneMainOptions = state.mainService?.options
      let findendOptions=cloneMainOptions[action.payload]
      let updatedOption={
        ...findendOptions,
        used:findendOptions.used + 1
      }
      return {
        ...state,
        mainService:{
          ...state.mainService,
          options:{
            ...cloneMainOptions,
            [action.payload]:updatedOption
          }
        }
      }}
      case types.DECREMENT_OPTION:{
        let cloneMainOptions = state.mainService?.options
        let findendOptions=cloneMainOptions[action.payload]
        let updatedOption={
          ...findendOptions,
          used:findendOptions.used - 1
        }
        return {
          ...state,
          mainService:{
            ...state.mainService,
            options:{
              ...cloneMainOptions,
              [action.payload]:updatedOption
            }
          }
        }}

    default:
      return {
        ...state,
      }
  }
}
