import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { notify } from "../../../../../helpers";
import { removeSessionReq } from "../../../../../services/Sessions";
import ConfirmModal from "../../../widgets/components/ConfirmModal";
// import ConfirmModal from "../../../widgets/components/ConfirmModal";

interface ISessionDeleteIcon {
  id: string;
  name: string;
  className?: string;
  onSuccessDelete: () => void;
  clickableArea?: string;
}

const SessionDeleteIcon = ({
  id,
  name,
  className,
  onSuccessDelete,
  clickableArea = "icon",
}: ISessionDeleteIcon) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);

  const deleteContactHandler = () => {
    removeSessionReq(id)
      .then(() => {
        notify({
          type: "success",
          message: intl.formatMessage(
            { id: "NOTIFICATIONS.ITEM_DELETED" },
            { name }
          ),
        });
        onSuccessDelete();
        setOpen(false);
      })
      .catch((err) => {
        const error = err.response && (err.response || err.message);
        const data = error?.data || intl.formatMessage({ id: "ERROR.UNSPECIFIEDERROR" });

        const messages = Object.values(data).map((message, index) => (
          <>
            {message}
            <br />
          </>
        ));

        notify({ type: "error", message: messages });
      });
  };

  const handleIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <div>
      {clickableArea === "icon" ? (
        <i
          className={`fa fa-trash text-hover-primary cursor-pointer ${className}`}
          onClick={handleIconClick}
        ></i>
      ) : (
        <a className={className} onClick={() => setOpen(true)}>
          <FormattedMessage id="ACTIONS.REMOVE" />
          <i className="fa fa-trash"></i>
        </a>
      )}

      <ConfirmModal
        display={open}
        handleClose={() => setOpen(false)}
        handleConfirm={deleteContactHandler}
        title={intl.formatMessage({ id: "ALERT.SURE" })}
        description={intl.formatMessage({ id: "ALERT.DELETE_MESSAGE" }, { name })}
      />
    </div>
  );
};

export default SessionDeleteIcon;
