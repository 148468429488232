import React, {useState} from 'react'
import {useIntl} from 'react-intl'
import IconButton from '../../../modules/global/IconButton'
import {IWidgetType} from './reducer'

interface Props {
  widget: IWidgetType
  onClickRemove: (data: IWidgetType) => void
  onEditLabel: (widget: IWidgetType, label: string) => void
}

const SelectedItem = (props: Props) => {
  const {widget, onClickRemove, onEditLabel} = props
  const {formatMessage} = useIntl()
  const initialLabel = ` ${widget?.parentTitle ? widget?.parentTitle : ''} ${
    widget?.title && widget?.parentTitle
      ? '(' + widget?.title + ')'
      : widget?.title
      ? widget?.title
      : ''
  } ${widget?.label}`
  const initialCustomLabel = widget?.customLabel
  const displayLabel = initialCustomLabel ?? initialLabel
  const baseHost=process.env.REACT_APP_API_URL_NOT_VERSION
  const isServer = !!widget?.serverId

  const linkToView = isServer
    ? `/ui/servers/${widget?.serverId}/overview`
    : `/ui/uptime/${widget?.device_id}?module=${widget?.id}`

  const [editMode, setEditMode] = useState(false)
  const [customlabel, setCustomLabel] = useState(displayLabel)
  const [error, setError] = useState('')

  const onSaveLabel = () => {
    const regex = /^[a-zA-Z0-9\s\-\+.\(\)]*$/
    if (!customlabel) {
      setError(formatMessage({id:"CUSTOM.LABEL.VALIDATION.ERR"}))
      return
    }

    if (!regex.test(customlabel)) {
      setError(formatMessage({id:"CUSTOM.LABEL.VALIDATION.ERR"}))
      return
    } else {
      setError('')
    }
    setEditMode(false)
    setError('')
    onEditLabel(widget, customlabel)
  }

  const onClickEdit = () => {
    setEditMode(true)
  }

  const cancelHand = () => {
    setEditMode(false)
    setError('')
    setCustomLabel(displayLabel)
  }

  return (
    <div className='d-flex justify-content-between align-items-center '>
      <div className='d-flex gap-3'>
        <section>
          {editMode ? (
            <>
            <input
              value={customlabel}
              onChange={(e) => setCustomLabel(e.target.value)}
              className={`  bg-transparent border ${
                error ? 'border-danger' : 'border-dark'
              }  px-1 fs-7 rounded text-dark `}
              autoFocus
              style={{width: displayLabel?.length + 3 + 'ch'}}
            />
           {error&& <p className='text-danger fs-7 m-0 mt-1'>
              {error}
            </p>}

           </>
          ) : (
            <span className={`fs-7 `}>{customlabel}</span>
          )}
        </section>
      </div>
      <section>
        {editMode ? (
          <>
            <IconButton iconSize='fs-3' icon='fas fa-times' className='' onClick={cancelHand} />
            <IconButton iconSize='fs-3' icon='fas fa-check' className='' onClick={onSaveLabel} />
          </>
        ) : (
          <IconButton
            tooltip={formatMessage({id: 'ACTIONS.EDIT'})}
            iconSize='fs-3'
            icon='fas fa-edit'
            className=''
            onClick={onClickEdit}
          />
        )}
         <a href={baseHost+linkToView} target="_blank" rel="noreferrer">
        <IconButton
          icon='fas fa-external-link-alt'
          tooltip={formatMessage({id: 'ACTIONS.VIEW'})}
        />
        </a>
        <IconButton
          iconSize='fs-3'
          icon='fas fa-trash'
          tooltip={formatMessage({id: 'ACTIONS.DELETE'})}
          onClick={() => {
            onClickRemove(widget)
          }}
        />
       
      
      </section>
    </div>
  )
}

export default SelectedItem
