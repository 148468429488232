import React from 'react'
import {Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {getSumCredits} from '../../../services/dashboard'

interface Props {
  onRemove: () => void
}

const SumCredits = ({onRemove}: Props) => {
  const {data: sumCreditsData, isLoading} = useQuery(['dashboard-sum-credits'], getSumCredits)

  const intl = useIntl()

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <div className={`card  card-xl-stretch bg-primary`}>
        <div className='card-header border-0 p-4 py-0'>
          <h3 className='card-title align-items-start flex-column'></h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-white'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={onClose}
            >
              <span className='fas fa-times fs-3 text-white' />
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className='h-150px text-center d-flex justify-content-center align-items-center'>
            <Spinner variant='light' animation='grow' />
          </div>
        ) : (
          <div className='card-body p-5  pt-0  d-flex gap-3 align-items-end'>
            <span className={`svg-icon-primary text-white  fs-4x   far fa-credit-card`} />

            <div className={` fw-bold fs-2  mt-3 text-white d-flex gap-2 align-items-center`}>
              <div className='d-flex flex-column gap-1 align-items-start '>
                <div className='lh-sm fs-1 '>
                  <span className={` fas fa-dollar-sign text-white me-1 fs-1`} />
                  {sumCreditsData?.toString()}
                </div>

                <div className={`fw-semibold text-white fs-7`}>
                  {intl.formatMessage({id: 'SUM.CREDITS'})}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(SumCredits)
