import React from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {createCustomIncidentMessageWithIntegrations, createIncidentMessages, createMetricMessageWithIntegrations, generateCheckIncidentCause, generateCheckResolveIncidentCause, TYPES_TITLE} from '../../../../app/modules/widgets/components/Common'
import { returnSubjectTransalte } from '../../../../helpers/helperFunctions'

interface Props {
  device_label: string
  type_human_readable: string
  status_name: string
  details: Record<string, any>
  created_at_human_readable: string
  id: string | number
  is_check?:boolean
  module_id?:number
  incident?:Record<string,any>
}

const IncidentItem = ({
  device_label,
  type_human_readable,
  status_name,
  details,
  created_at_human_readable,
  id,
  is_check,
  module_id,
  incident
}: Props) => {
  const navigate = useNavigate()
  const intl = useIntl()

  const returnTypeDisplayer = (model: any) => {
    const type = TYPES_TITLE(model.details.type)
    let resultModel: any = {cause: '', value: '', title: type, resolver: {}}
    if (model?.module_id === 0) {
      resultModel.cause = createMetricMessageWithIntegrations(model, false,intl)
      resultModel.value = createIncidentMessages(model?.details,intl)
    }
    if (model?.module_id>0) {
      resultModel.cause =  generateCheckIncidentCause(model?.details, intl)
    }

    if (model.resolved_id > 0) {
      if (model.resolver?.module_id === 0) {
        resultModel.resolver.cause = createMetricMessageWithIntegrations(model, true,intl)
      }
      if (model.resolver?.module_id !== 0) {
        resultModel.resolver.cause = generateCheckResolveIncidentCause(model, intl)
      }
      if (model.resolver?.details.value) {
        resultModel.resolver.value = createIncidentMessages(model?.resolver?.details,intl)
      }
      if (model.resolver?.details?.is_manual) {
        resultModel.resolver.manual_message = createCustomIncidentMessageWithIntegrations(model?.resolver?.details,intl)
      }
    }
   
    return resultModel
  }
  
  
  const handleNavigate = () => {
    if (is_check) {
      navigate(type_human_readable==="ssl"?`/ssl/${id}?module=${module_id}` : `/uptime/${id}?module=${module_id}`)
    }else{
      navigate(`/servers/${id}/incidents`)
    }
   
  }

  return (
    <div
      className='p-3 bg-box d-flex justify-content-between bg-hover-primary'
      onClick={()=>navigate(`/incidents/${incident?.id}`)}
    >
      <div className='d-flex flex-column gap-4 '>
        <h4 className='fs-8'>{device_label}</h4>
        {details?.module_host ? (
          <h5 className='fs-8'>{`${type_human_readable} (${details?.module_host})`}</h5>
        ) : (
          <h5 className='fs-8'>{createIncidentMessages(details,intl)}</h5>
        )}

        <span className='fs-8'>{created_at_human_readable}</span>
      </div>
      <div className='d-flex flex-column justify-content-between align-items-end gap-2'>
        {details?.subject ? <h6 className='alert alert-secondary text-dark fs-8 ' style={{maxWidth:"150px"}}>{intl.formatMessage({id:returnSubjectTransalte(details?.subject)})}</h6> : null}
        <h6 className={`${status_name === 'Success' ? 'alert alert-success text-dark' : ''}`}>
          {status_name}
        </h6>
      </div>
    </div>
  )
}

export default IncidentItem
