/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Spinner} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import {toAbsoluteUrl} from '../../../helpers'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  title?: string
  desc?: string
  onClose?: () => void
  children?: React.ReactNode
  icon?: React.ReactNode
  thead?: React.ReactNode
  isLoading?: boolean
  dataLength?:number|string

}

const TablesWidget2: React.FC<Props> = ({
  className,
  title,
  desc,
  onClose,
  icon,
  thead,
  children,
  isLoading = false,
  dataLength=0

}) => {
  return (
    <div className={`card ${className} border `}>
      {/* begin::Header */}
      <section className='card-header border-0 p-4 py-2 pb-1' style={{minHeight:"50px"}}>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-5 d-flex gap-2 align-items-center  mb-1'>
            {icon} {title}
          </span>
          <span className='text-muted fw-semobold fs-7'>{desc}</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={onClose}
          >
            {/* <KTSVG path='media/icons/duotune/general/gen024.svg' className='svg-icon-2' /> */}
            <span className='fas fa-times fs-3' />
          </button>
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </section>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-4 py-2 pt-0'>
        {isLoading ? (
          <div className='h-150px text-center d-flex justify-content-center align-items-center'>
            <Spinner variant='primary' animation='grow' />
          </div>
        ) :dataLength<=0?<h5 className='text-center py-5'>
        <FormattedMessage id="NO_DATA_TABLE" />
      </h5>: (
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table align-middle gs-0 gy-5'>
              {/* begin::Table head */}
              <thead>
              {thead}

                {/* <tr>
                  <th className='p-0 w-50px'></th>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-125px'></th>
                  <th className='p-0 min-w-40px'></th>
                </tr> */}
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
              {children}

                {/* <tr>
                  <td>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                          className='h-50 align-self-center'
                          alt=''
                        />
                      </span>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Top Authors
                    </a>
                    <span className='text-muted fw-semobold d-block fs-7'>Successful Fellas</span>
                  </td>
                  <td className='text-end'>
                    <span className='badge badge-light-danger fw-semobold me-1'>Angular</span>
                    <span className='badge badge-light-info fw-semobold me-1'>PHP</span>
                  </td>
                  <td className='text-end'>
                    <span className='text-muted fw-semobold'>4600 Users</span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                          className='h-50 align-self-center'
                          alt=''
                        />
                      </span>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Popular Authors
                    </a>
                    <span className='text-muted fw-semobold d-block fs-7'>Most Successful</span>
                  </td>
                  <td className='text-end'>
                    <span className='badge badge-light-danger fw-semobold me-1'>HTML</span>
                    <span className='badge badge-light-info fw-semobold me-1'>CSS</span>
                  </td>
                  <td className='text-end'>
                    <span className='text-muted fw-semobold'>7200 Users</span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                          className='h-50 align-self-center'
                          alt=''
                        />
                      </span>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      New Users
                    </a>
                    <span className='text-muted fw-semobold d-block fs-7'>Awesome Users</span>
                  </td>
                  <td className='text-end'>
                    <span className='badge badge-light-danger fw-semobold me-1'>React</span>
                    <span className='badge badge-light-info fw-semobold me-1'>SASS</span>
                  </td>
                  <td className='text-end'>
                    <span className='text-muted fw-semobold'>890 Users</span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                          className='h-50 align-self-center'
                          alt=''
                        />
                      </span>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Active Customers
                    </a>
                    <span className='text-muted fw-semobold d-block fs-7'>Best Customers</span>
                  </td>
                  <td className='text-end'>
                    <span className='badge badge-light-danger fw-semobold me-1'>Java</span>
                    <span className='badge badge-light-info fw-semobold me-1'>PHP</span>
                  </td>
                  <td className='text-end'>
                    <span className='text-muted fw-semobold'>6370 Users</span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='symbol symbol-50px me-2'>
                      <span className='symbol-label'>
                        <img
                          src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                          className='h-50 align-self-center'
                          alt=''
                        />
                      </span>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                      Bestseller Theme
                    </a>
                    <span className='text-muted fw-semobold d-block fs-7'>Amazing Templates</span>
                  </td>
                  <td className='text-end'>
                    <span className='badge badge-light-danger fw-semobold me-1'>Python</span>
                    <span className='badge badge-light-info fw-semobold me-1'>MySQL</span>
                  </td>
                  <td className='text-end'>
                    <span className='text-muted fw-semobold'>354 Users</span>
                  </td>
                  <td className='text-end'>
                    <a
                      href='#'
                      className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                    >
                      <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2' />
                    </a>
                  </td>
                </tr> */}
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        )}
        {/* end::Table container */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget2}
