import React from 'react'
import * as flagIcons from 'country-flag-icons/react/3x2'

type FlagComponents = typeof flagIcons

interface IFlags {
  className?: string
  country: keyof FlagComponents
}

const Flags = (props: IFlags) => {
  const {className, country} = props

  const FlagComponent = flagIcons[country]

  if (!FlagComponent) {
    return <></> // or display a default flag icon for unknown countries
  }

  return <FlagComponent className={`w-20px ${className}`} />
}

export default Flags
