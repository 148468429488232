import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Paper} from '@mui/material'
import {LocalizationProvider, StaticDateTimePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import React, {FC, useEffect, useState, useMemo} from 'react'
import {Form, Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {notify} from '../../../../helpers'

import {returnTypeUptimeWidget} from '../../../../helpers/helperFunctions'
import {bulkCheckApi} from '../../../../services/uptime'
import {RootState} from '../../../../setup'
import useCssVariable from '../../../Hooks/useCssVariable'

import WaveSpinner from '../../../modules/global/WaveSpinner'

import '../style.scss'
import UptimeGroup from './UptimeGroup'
import dayjs, {Dayjs} from 'dayjs'
import {deepSearch} from '../../../../helpers/deepSearch'
import {TYPES_TITLE} from '../../../modules/widgets/components/Common'
import UptimeListCompact from './UptimeListCompact'
import {useLocalStorage} from '../../../Hooks/useLocalStorage'
import IconButtonGroup from '../../../modules/global/IconButtonGroup'
import { IGroup } from '../../../store/groupStore/types'
import { getGroupsAction } from '../../../store/groupStore/actions'
import MuiSelect from '../../../modules/global/UI/MuiSelect'

interface IUpTimeTable {
  selectedChecks: any[]
  isBulkEnable: boolean
  selectedOp: string
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  onSelectCheck: (checks: any[]) => void
  types: any
  period: string
  refetch: () => void
  uptime: Record<string, any>
  isLoading: boolean
  selectedMode:string
}

const UpTimeTable: FC<IUpTimeTable> = ({
  types = [],
  period = 'this_month',
  refetch,
  selectedChecks,
  isBulkEnable,
  selectedOp,
  openModal,
  setOpenModal,
  onSelectCheck,
  uptime: upTime,
  isLoading: loadingUptime,
  selectedMode
}) => {
  const secondary = useCssVariable('--kt-secondary')
  const navigate = useNavigate()
  const dispatch=useDispatch()
  const serverGroups: [IGroup] = useSelector<RootState>(
    (state: {groups: {list: [IGroup]}}) => state.groups.list
  ) as [IGroup]

  const [dataList, setDataList] = useState([] as any)
  const [groups, setGroups] = useState<IGroup[]>([])

  const [pauseTime, setPauseTime] = useState(2114380800)
  const [groupId, setGroupId] = useState("0")

  const [isLoading, setIsLoading] = useState(false)
  const [customPauseValue, setCustomPauseValue] = useState<Dayjs | null>(dayjs())
  const [showCustomPause, setShowCustomPause] = useState(false)

  const selectedChecksWithData = useMemo(
    () => selectedChecks?.map((checkId) => deepSearch(dataList, 'id', (k, v) => v == checkId)),
    [selectedChecks]
  )

  
  useEffect(() => {
    if (serverGroups.length > 0) {
      let tempList: any = []
      serverGroups.map((g: IGroup) => {
        tempList.push(g)
        if (g.subgroups.length > 0) {
          g.subgroups.map((sg: IGroup) => {
            tempList.push(sg)
          })
        }
      })

      setGroups([...tempList])
    }
  }, [serverGroups])


  const handlePauseTime = (hoursString: string) => {
    if (hoursString === 'custom') {
      setShowCustomPause(true)
      return
    }
    setShowCustomPause(false)
    let hours = +hoursString
    if (hours == 0) {
      setPauseTime(2114380800)
    } else {
      let tempDuration = hours * 3600
      tempDuration = Math.ceil(Date.now() / 1000) + tempDuration
      setPauseTime(tempDuration)
    }
  }


  useEffect(() => {
    
    dispatch(getGroupsAction())
  }, [])

  const intl = useIntl()

  const onFinishBulk = async () => {
    try {
      if (selectedChecks?.length === 0) {
        notify({type: 'error', message: intl.formatMessage({id: 'LABELS.ERROR.BULK.CHECKS'})})
        return
      }
      setIsLoading(true)

      await bulkCheckApi({
        operation: selectedOp,
        ids: selectedChecks,
        
        pause_until: selectedOp === 'unpause' ? 0 : showCustomPause ? customPauseValue : pauseTime,
        ...(selectedOp==="move"?{group_id:groupId}:{})
        
      })
      setIsLoading(false)
      setOpenModal(false)
      onSelectCheck([])
      refetch()
      notify({type: 'success', message: intl.formatMessage({id: 'SUCCESS.UPDATE'})})
    } catch (err: any) {
      setIsLoading(false)

      const error = err.response && (err.response || err.message)
      let data = error?.data
      if (selectedOp==="move"&&data?.message==="Unauthorized action") {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'LABELS.ERROR.MOVE.CHECKS'}),
        })
      }
      console.error('data error ', data)
      if (!data) {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
        })
      }
      let messages = []

      for (let i in data) {
        messages.push(
          <>
            {data[i]}
            <br />
          </>
        )
      }
      notify({type: 'error', message: <>{messages}</>})
    }
  }

  const onRedirectChecks = () => {
    if (!selectedChecks?.length) {
      notify({type: 'error', message: intl.formatMessage({id: 'LABELS.ERROR.BULK.CHECKS'})})
      return
    }
    const queryString = selectedChecks.join(',')

    if (selectedOp === 'status_page') {
      navigate(`/public-status-page/new?checks=${queryString}`)
    } else {
      navigate(`/maintenance/new?checks=${queryString}`)
    }
  }

  useEffect(() => {
    if (typeof upTime === 'object') {
      let tempData = [] as any
      Object.keys(upTime).map((item: string) => {
        let tempMainModel = {name: item, dev: [] as any, sub: [] as any}
        const tempDevice = upTime[item].devices
        Object.keys(tempDevice).map((dName: string) => {
          tempMainModel.dev.push({
            name: dName,
            devices: tempDevice[dName],
            id: tempDevice[dName][0] ? tempDevice[dName][0].device_id : 0,
          })
        })
        const sub = upTime[item].subgroups
        if (typeof sub === 'object') {
          Object.keys(sub).map((subName: string) => {
            let tempSubModel = {name: subName, dev: [] as any}

            // let subItem = { name: subName, devices: [] }
            const subDevices = sub[subName].devices
            Object.keys(subDevices).map((subDevicesName: string) => {
              tempSubModel.dev.push({
                name: subDevicesName,
                devices: subDevices[subDevicesName],
                id: subDevices[subDevicesName][0] ? subDevices[subDevicesName][0].device_id : 0,
              })
            })
            tempMainModel.sub.push(tempSubModel)
          })
        }
        tempData.push(tempMainModel)
      })
      setDataList(tempData)
    }
  }, [upTime])

  if (dataList.length === 0 && Array.isArray(upTime)) {
    return (
      <div className='text-center h-150px d-flex align-items-center justify-content-center flex-column gap-3'>
        <h5> {intl.formatMessage({id: 'DESCRIPTIONS.EMPTY_UPTIME'})}</h5>
        <Link to={'/new?mode=add-check'} className='btn btn-primary btn-sm'>
          {intl.formatMessage({id: 'ACTIONS.UPTIME_ADD_NEW_CHECK'})}
        </Link>
      </div>
    )
  }

  return (
    <div>
      
      {loadingUptime ? (
        <WaveSpinner isLoading></WaveSpinner>
      ) : (
        !!dataList?.length && (
          <>
            {selectedMode === 'block' ? (
              dataList.map((group: any, idx: number) => {
                return (
                  <UptimeGroup
                    groupData={group}
                    key={idx + '/'}
                    range={returnTypeUptimeWidget(period)}
                    isBulkEnable={isBulkEnable}
                    onSelectCheck={onSelectCheck}
                    selectedChecks={selectedChecks}
                  />
                )
              })
            ) : (
              <UptimeListCompact
                dataList={dataList}
                range={returnTypeUptimeWidget(period)}
                isBulkEnable={isBulkEnable}
                onSelectCheck={onSelectCheck}
                selectedChecks={selectedChecks}
              />
            )}
          </>
        )
      )}

      <Dialog
        onClose={() => setOpenModal(false)}
        open={openModal}
        PaperComponent={Paper}
        sx={{
          fontFamily: 'Poppins',
          '& .MuiDialog-paperScrollPaper': {
            minWidth: 400,
          },
          '& .MuiDialog-root': {
            zIndex: 5000,
          },
        }}
        disableEnforceFocus
        container={document.getElementById('root')}
      >
        <DialogTitle component={'h1'}>
          <div className='d-flex align-items-start justify-content-between text-dark gap-6 fw-bold'>
            <h4 className='m-0 fs-5'>
              {selectedOp === 'pause'
                ? intl.formatMessage({id: 'LABES.BULK.PAUSE.WARN'})
                : selectedOp === 'unpause'
                ? intl.formatMessage({id: 'LABES.BULK.UNPAUSE.WARN'})
                : selectedOp === 'status_page'
                ? intl.formatMessage({id: 'LABELS.CREATE.PUBLIC.STATUS'})
                : selectedOp === 'maintenance'
                ? intl.formatMessage({id: 'MAINTENANCE.Add'})
                :selectedOp === 'move' ?
                intl.formatMessage({id: 'ACTIONS.MOVE_TO_GROUP'})
                 : intl.formatMessage({id: 'LABES.BULK.DELETE.WARN'})}
            </h4>

            <span
              className='fas fa-times fs-1 cursor-pointer'
              onClick={() => setOpenModal(false)}
            />
          </div>
        </DialogTitle>
        <DialogContent
          className='text-dark pt-2'
          sx={{
            '& .MuiPickerStaticWrapper-root ': {
              '& .MuiDialogActions-spacing': {
                display: 'none',
              },
              '& .MuiPickersToolbar-penIconButton ': {
                display: 'none',
              },
              '& .MuiTypography-overline': {
                display: 'none',
              },
            },
            '& .MuiPickerStaticWrapper-content': {
              backgroundColor: secondary,
              borderRadius: '0.5rem',
            },
          }}
        >
          {!!selectedChecksWithData?.length && (
            <>
              {' '}
              <p className='mb-1 fs-7'>{intl.formatMessage({id: 'LABELS.SELECTED.CHECKS'})} :</p>
              <ul style={{listStyle: 'circle', maxHeight: '150px', overflow: 'auto'}}>
                {selectedChecksWithData?.map((checkData: any) => {
                  return (
                    <li key={'--' + checkData?.id}>
                      {TYPES_TITLE(checkData?.type)} {checkData?.host ? ' - ' : ''}{' '}
                      {checkData?.host}
                    </li>
                  )
                })}
              </ul>
            </>
          )}

          {selectedOp === 'pause' && (
            <section>
              <Form.Label>{intl.formatMessage({id: 'LABELS.PAUSE.UNTIL'})}</Form.Label>
              <MuiSelect height='sm' onChange={(e) => handlePauseTime(e.target.value)}>
                <MenuItem value={'0'}>{intl.formatMessage({id: 'LABELS.MANUAL'})}</MenuItem>
                <MenuItem value={'1'}>1 {intl.formatMessage({id: 'LABELS.HOUR'})}</MenuItem>
                <MenuItem value={'3'}>3 {intl.formatMessage({id: 'LABELS.HOURS'})}</MenuItem>
                <MenuItem value={'6'}>6 {intl.formatMessage({id: 'LABELS.HOURS'})}</MenuItem>
                <MenuItem value={'12'}>12 {intl.formatMessage({id: 'LABELS.HOURS'})}</MenuItem>
                <MenuItem value={'24'}>1 {intl.formatMessage({id: 'LABELS.DAY'})}</MenuItem>
                <MenuItem value={'72'}>3 {intl.formatMessage({id: 'LABELS.DAYS'})}</MenuItem>
                <MenuItem value={'168'}>1 {intl.formatMessage({id: 'LABELS.WEEK'})}</MenuItem>
                <MenuItem value={'custom'}>{intl.formatMessage({id: 'LABELS.CUSTOM'})}</MenuItem>
              </MuiSelect>

              {showCustomPause && (
                <div className='mt-3'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateTimePicker
                      onChange={(newValue) => setCustomPauseValue(newValue)}
                      renderInput={() => <Form.Control />}
                      value={customPauseValue}
                      disablePast
                    />
                  </LocalizationProvider>
                </div>
              )}
            </section>
          )}

          {
            selectedOp==="move"&&<section>
              <Form.Group className=''>
              <Form.Label className='fs-7'>
                {intl.formatMessage({id: 'LABELS.SERVER_GROUP'})}
              
              </Form.Label>
              <MuiSelect
                value={groupId}
                onChange={(e) => {
                  setGroupId(e.target.value)
                }}
                // size="sm"
                
              >
                {groups?.map((g: IGroup, idx: number) => (
                  <MenuItem value={g.id} key={idx}>
                    {g.parent > 0 && <>&nbsp; &nbsp;</>}
                    {g.name}
                  </MenuItem>
                ))}
                <MenuItem value='0'>{intl.formatMessage({id: 'OPTIONS.UNGROUPED'})}</MenuItem>
              </MuiSelect>
            </Form.Group>
            </section>
          }
        </DialogContent>
        <DialogActions>
          <button className='btn btn-sm btn-secondary ' onClick={() => setOpenModal(false)}>
            {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
          </button>
          <button
            className='btn btn-sm btn-primary w-80px '
            onClick={() =>
              selectedOp === 'status_page' || selectedOp === 'maintenance'
                ? onRedirectChecks()
                : onFinishBulk()
            }
          >
            {isLoading ? (
              <Spinner size='sm' animation='grow' />
            ):selectedOp === 'move' ? 
            intl.formatMessage({id: 'ACTIONS.MOVE'})
            : selectedOp === 'pause' ? (
              intl.formatMessage({id: 'PAUSE'})
            ) : selectedOp === 'unpause' ? (
              intl.formatMessage({id: 'LABELS.UNPAUSE'})
            ) : selectedOp === 'status_page' || selectedOp === 'maintenance' ? (
              intl.formatMessage({id: 'ACTIONS.CREATE'})
            ) : (
              intl.formatMessage({id: 'ACTIONS.DELETE'})
            )}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UpTimeTable
