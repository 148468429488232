import {IGroup} from '../app/store/groupStore/types'

export function deepSearch(
  object: Record<string, any>,
  key: string,
  predicate: (key: string, val: any) => boolean
): null | Record<string, any>  {

  if (!object) {
    return {}
  }

  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) return object

  for (let i = 0; i < Object.keys(object).length; i++) {
    let value = object[Object.keys(object)[i]]
    if (typeof value === 'object' && value != null) {
      let o = deepSearch(object[Object.keys(object)[i]], key, predicate)
      if (o != null) return o
    }
  }
  return null
}

// / deepSearch(myObject, 'title', (k, v) => v === 'Some Recommends');

export const isSubGroup = (serverGroups: IGroup[], serachBy: string, value: any) => {
  const obj = deepSearch(serverGroups, serachBy, (k, v) => v === value)
  if (!obj) {
    return false
  }

  if (obj?.parent > 0) {
    return true
  } else {
    return false
  }
}


export function deepSearchWithParent(
  object: Record<string, any>,
  key: string,
  predicate: (key: string, val: any) => boolean,
  parentKeys: string[] = []
): { parentKeys: string[], value: Record<string, any> } | null {
  let lastParents: { parentKeys: string[], value: Record<string, any> } | null = null;

  if (!object) {
    return null;
  }

  if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
    lastParents = { parentKeys: parentKeys.slice(-2), value: object };
  }

  for (const currentKey in object) {
    if (object.hasOwnProperty(currentKey)) {
      const value = object[currentKey];
      if (typeof value === 'object' && value !== null) {
        const result = deepSearchWithParent(value, key, predicate, [...parentKeys, currentKey]);
        if (result !== null) {
          lastParents = result;
        }
      }
    }
  }

  return lastParents;
}


