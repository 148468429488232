import React from 'react'
import {useLocation} from 'react-router-dom'

const useLocationGetter = () => {
  const {pathname} = useLocation()

  const locationSplitted = pathname?.split('/')
  const validationUrl = locationSplitted?.filter((url) => !!url?.length)

  const cloneArray=[...validationUrl]

  cloneArray[validationUrl.map((x:any, i) => [i, x]).filter(x => !isNaN(+x[1]))?.[0]?.[0]] = "id"

  const urlWithChild=cloneArray.join("_")

  const parent =cloneArray?.[0]

  return {urlWithChild,parent}
}

export default useLocationGetter
