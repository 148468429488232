import React from 'react'
import {useDispatch} from 'react-redux'
import * as authMain from '../modules/auth/redux/AuthRedux'
import useUser from './useUser'

const useUpdateWizard = () => {
  const dispatch = useDispatch()
  const user = useUser()

  const calculateScore = (): number => {
    let score = 0

    if (user?.wizard?.completedSteps?.email_confirmation) {
      score += 10
    }
    if (user?.wizard?.completedSteps?.mobile_app) {
      score += 10
    }
    if (user?.wizard?.completedSteps?.monitoring) {
      score += 10
    }
    if (user?.wizard?.completedSteps?.sub_account) {
      score += 10
    }
    if (user?.wizard?.completedSteps?.notification_roles) {
      score += 10
    }
    if (user?.wizard?.completedSteps?.status_page) {
      score += 10
    }
    if (user?.wizard?.completedSteps?.report_email) {
      score += 10
    }
    if (user?.wizard?.completedSteps?.complete_profile) {
      score += 10
    }
    if (user?.wizard?.completedSteps?.upgrade_plan) {
      score += 20
    }
    return score
  }

  return {
    score:calculateScore()
  }
}

export default useUpdateWizard
