import React from 'react'
import {Box, TextField, TextFieldProps} from '@mui/material'

interface IMuiTextFieldAdditionalProps {
  value?: any;
  helperText?: any
  characterLimit?: number
  fullWidth?: boolean
  hasError?: boolean
  hasWarning?: boolean
  size?: 'small' | 'medium'
  onChange?: (event: any) => void
  height?: 'sm' | 'md' | number
  defaultStyles?: boolean
  customRootInputsx?:Record<string,any>
}

type IMuiTextField = TextFieldProps & IMuiTextFieldAdditionalProps

const MuiTextField: React.FC<IMuiTextField> = ({
  fullWidth = true,
  hasError,
  hasWarning,
  size = 'small',
  onChange,
  height = 'md',
  defaultStyles,
  characterLimit,
  helperText,
  sx,
  customRootInputsx,
  ...props
}) => {
  const inputValueCount = props?.value?.length as any
  const isVisible = !!characterLimit && (inputValueCount / characterLimit) * 100 > 60
  
  return (
    <TextField
      {...props}
      fullWidth={fullWidth}
      onChange={onChange}
      size={size}
      error={hasError}
      InputProps={{ ...props?.InputProps, inputProps: { maxLength: characterLimit, min: 0}, }}   
      helperText={
        (helperText||isVisible)&& <Box
          component="span"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <span>{helperText}</span>
          {isVisible && (
            <span>
              {`${inputValueCount} / ${
                characterLimit
              }`}
            </span>
          )}
        </Box>
      }
      sx={{
        width: '100%',

        '& input': {
          width: '100%',
        cursor:props?.disabled?"not-allowed":"auto",

        },
        '& .MuiOutlinedInput-root': {
          minHeight: height == 'sm' ? 38 : height == 'md' ? 43 : height,
          boxShadow: hasError ? '0 0 3px 0.2px var(--kt-danger) !important' : 'none',
          borderRadius: '0.475rem',
          bgcolor: 'var(--kt-card-bg)',
          '& fieldset': {
            borderColor: hasError
              ? 'var(--kt-danger) !important'
              : hasWarning
              ? 'var(--kt-warning) !important'
              : 'var(--kt-input-border-color) !important',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: 'var(--kt-input-border-color)',
            borderWidth: '1px',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'var(--kt-input-border-color)',
            borderWidth: '1px',
          },
          ...customRootInputsx
        },
        ...sx,
      }}
    />
  )
}

export default MuiTextField

// input-group-text {
  // color: var(--kt-input-group-addon-color);
  // background-color: var(--kt-input-group-addon-bg);
  // border: 1px solid var(--kt-input-group-addon-border-color);
// }