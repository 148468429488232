type Widget = {
  id: string;
  name: string;
  [key: string]: any;
};

type Column = {
  id: string;
  widgetsIds: string[];
};

type Mode = {
  [key: string]: Column;
};


type IObject = {
  widgets: {
    id: string;
    name: string;
    type: string;
    enable: boolean;
    widgets: Widget[];
    [key: string]: any;
  }[];
  mode_2: Mode;
  mode_3: Mode;
  mode_4: Mode;
};


const distributeWidgets = (widgets: string[], columnsCount: number): string[][] => {
  const columns: string[][] = Array.from({ length: columnsCount }, () => []);
  widgets.forEach((widget, index) => {
    columns[index % columnsCount].push(widget);
  });
  return columns;
};

const createModeObject = (columnsCount: number, widgetsIds: any): Mode => {
  const modeObject: Mode = {};
  const columns = distributeWidgets(widgetsIds, columnsCount);
  for (let i = 1; i <= columnsCount; i++) {
    modeObject[`column-${i}`] = {
      id: `column-${i}`,
      widgetsIds: columns[i - 1],
    };
  }
  return modeObject;
};

export const createDynamicColObject = (object: any): IObject => {
  const column1Widgets = object?.columns?.["column-1"]?.widgetsIds || [];
  const column2Widgets = object?.columns?.["column-2"]?.widgetsIds || [];
  
  const column1 = object?.columns?.["column-1"] || [];
  const column2 = object?.columns?.["column-2"] || [];
  
  const allWidgetIds = [
    ...column1Widgets,
    ...column2Widgets
  ];

  return {
    widgets: object?.widgets,
    mode_2: {
      "column-1": column1,
      "column-2": column2
    },
    mode_3: createModeObject(3, allWidgetIds),
    mode_4: createModeObject(4, allWidgetIds),
  };
};
