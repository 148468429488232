
import {useDispatch} from 'react-redux'
import * as authMain from '../modules/auth/redux/AuthRedux'

export function useJwtToken() {
  const dispatch = useDispatch()


  const  injectJWT=async()=>{
    const cookieName = 'jwtToken' // Change this to your JWT cookie name
    const cookieString = document.cookie
    const cookies = await cookieString.split(';').map((cookie) => cookie.trim())
    
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=')
      
      if (name === cookieName ) {
       await dispatch(authMain.actions.login(value))

        return
      }
    }
  }
 

 return {injectJWT}
}
