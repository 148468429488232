import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {getLastTickets} from '../../../services/dashboard'
import {TablesWidget5} from '../../../_metronic/partials/widgets'
import classes from './style.module.scss'

interface Props {
  onRemove: () => void
}

const LastActiveTickets = ({onRemove}: Props) => {
  const intl = useIntl()

  const {data: lastTickets, isLoading} = useQuery(['dashboard-tickets'], getLastTickets)
  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <TablesWidget5
        onClose={onClose}
        isLoading={isLoading}
        dataLength={lastTickets?.data?.length}
        title={intl.formatMessage({id: 'LAST.ACTIVE.TICKETS'})}
        className={classes['custom_table']}
        icon={<span className='fas fa-ticket-alt fs-1' />}
        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '45%'}}>
              {intl.formatMessage({id: 'LABELS.SUBJECT'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '30%'}}>
              {intl.formatMessage({id: 'LABELS.DEPARTMENT'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{minWidth: '10%'}}>
              {intl.formatMessage({id: 'LABELS.STATUS'})}
            </th>
            <th className='bg-transparent fw-bold text-end fs-7 p-2 px-1' style={{minWidth: '15%'}}></th>
          </tr>
        }
      >
        {!!lastTickets?.data?.length &&
          lastTickets?.data.map((ticket: Record<string, any>, index: number) => (
            <tr key={ticket?.id}>
              <td>
                <div>
                  <span
                    className='text-truncate d-block text-dark fw-bold mb-1 fs-7'
                    data-tip
                    data-for={'ticket-' + index}
                    style={{width: '14ch'}}
                  >
                    {ticket?.subject}
                  </span>
                  {ticket?.subject?.length >= 14 && (
                    <ReactTooltip id={'ticket-' + index} className='tooltip-wrapper'>
                      {ticket?.subject}
                    </ReactTooltip>
                  )}
                </div>
                <span className='text-muted fs-7 fw-semobold d-block mt-1'>
                  {ticket?.updated_at}
                </span>
              </td>
              <td className='text-dark fw-semobold'>{ticket?.department_name}</td>
              <td className=''>
                <span className='badge ' style={{backgroundColor: ticket?.status_color}}>
                  {ticket?.status_name}
                </span>
              </td>
              <td className='text-end'>
                <Link
                  to={`/tickets/${ticket?.id}`}
                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                >
                  <span className='fas fa-arrow-right fs-4' />
                </Link>
              </td>
            </tr>
          ))}
      </TablesWidget5>
    </div>
  )
}

export default  React.memo(LastActiveTickets)
