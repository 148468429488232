import React, {FC} from 'react'
import {Col, Dropdown, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import IconButton from '../../../../modules/global/IconButton'
import {NoteTextSVG} from '../../../../modules/widgets/components/SVGIcons'

const DeviceCPUConsumer: FC<{data: any}> = ({data}) => {
  const intl = useIntl()

  return (
    <Dropdown>
      <Dropdown.Toggle variant='Secondary' className='group-item-menu'>
        <IconButton icon='fas fa-list-alt' />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data?.length > 0 ? (
          <div>
            <Col xs={12}>
              <Row>
                <Col xs={2} className='text-center'>
                  PID
                </Col>
                <Col xs={6} className='text-center'>
                  CMD
                </Col>
                <Col xs={4} className='text-center'>
                  %CPU
                </Col>
              </Row>
              <hr className='mt0 mb0' />
            </Col>
            {data?.map((item: any, idx: number) => (
              <Col xs={12} key={idx} className='data-item'>
                <Row>
                  <Col xs={2} className='text-center'>
                    {item?.pid}
                  </Col>
                  <Col xs={6} className='cmd-container' title={item?.cmd}>
                    {item?.cmd?.substr(0, 50)}
                  </Col>
                  <Col xs={4} className='text-center'>
                    {(+item?.cpu_usage||0)?.toFixed?.(2)}
                  </Col>
                </Row>
              </Col>
            ))}
          </div>
        ) : (
          <div>{intl.formatMessage({id: 'DESCRIPTIONS.NO_RECORDS'})}</div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DeviceCPUConsumer
