import {Stack} from '@mui/system'
import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {integrationGraphsArray} from '../../../../helpers/integrationsGraphsArray'
import {getServerUptimePingHttp} from '../../../../services/uptime'
import {KTSVG} from '../../../../_metronic/helpers'
import useServer from '../../../Hooks/useServer'
import WaveSpinner from '../../../modules/global/WaveSpinner'
import {moduleTypeToHumanReadable} from '../../../modules/widgets/components/Common'
import {IWidgetType, State} from './reducer'

interface IProps {
  onSelect: (data: Record<string, any>, isCheck: boolean) => void
  serverId: number | null
  widgets: IWidgetType[]
  moduleIds: number[]
  graphAssignMode: boolean
}

const ServerSelectGraphs = (props: IProps) => {
  const {onSelect, serverId, widgets, moduleIds, graphAssignMode} = props
  const intl = useIntl()

  const server = useServer(String(serverId))

  const {data: serverCheck}: any = useQuery(['server-check',serverId], () =>
    getServerUptimePingHttp(String(serverId))
  )

  const filterPingHtpp = serverCheck?.filter(
    (ch: any) => ch?.check?.type === 20 || ch?.check?.type === 21
  )

  const {
    PostgreSQLGraphs,
    IISGraphs,
    haproxyGraphs,
    mongoGraphs,
    mysqlGraphs,
    dockerGraphs,
    fpmGraphs,
    keydbGraphs,
    redisGraphs,
    ngnixGraphs,
    apacheGraphs,
    RabbitMQGraphs,
    VarnishGraphs,
    CouchDBGraphs
  } = integrationGraphsArray(intl)

  const metricGraphs: IWidgetType[] = [
    {
      id: 'm-1',
      name: 'cpu-usage',
      title: intl.formatMessage({id: 'LABELS.CPU'}),
      icon: 'fas fa-microchip ',
    },
    {
      id: 'm-2',
      name: 'disk-io',
      title: intl.formatMessage({id: 'LABELS.DISK_IO'}),
      icon: 'far fa-save ',
    },
    {
      id: 'm-3',
      name: 'disk-usage',
      title: intl.formatMessage({id: 'LABELS.DISK_USAGE'}),
      icon: 'far fa-hdd',
    },
    {
      id: 'm-6',
      name: 'network',
      title: intl.formatMessage({id: 'LABELS.NETWORK_BYTES'}),
      icon: 'fas fa-network-wired ',
    },
    {
      id: 'm-7',
      name: 'network-packets',
      title: intl.formatMessage({id: 'LABELS.NETWORK_PACKETS'}),
      icon: 'fas fa-network-wired ',
    },
    {
      id: 'm-8',
      name: 'network-dropped',
      title: intl.formatMessage({id: 'LABELS.NETWORK_DROPPED'}),
      icon: 'fas fa-network-wired ',
    },
    {
      id: 'm-5',
      name: 'memory-usage',
      title: intl.formatMessage({id: 'LABELS.MEMORY'}),
      icon: ' fas fa-memory',
    },

    {
      id: 'm-4',
      name: 'cpu-load-average',
      title: intl.formatMessage({id: 'LABELS.LOAD_AVERAGE'}),
      icon: 'fas fa-microchip',
    },
  ]



  function widgetsExistsInArray(objectId: string | number , serverId:number) {
    return widgets.some((item) => (String(`${item.id}`) === `${objectId}-${serverId}` ) )
  }
  return (
    <div
      style={{
        maxHeight: '400px',
        overflowY: 'auto',
      }}
      className='pe-2 pb-2'
    >
      <h2 className='text-center mb-6'>{intl.formatMessage({id: 'LABELS.SELECT.GRAPHS'})}</h2>

     {!server?.isLoading&& <section className='border border-dashed rounded p-2'>
        <h4 className='mb-4 text-center'>{intl.formatMessage({id: 'LABELS.METRIC'})}</h4>
        <Stack direction={'row'} gap={1} flexWrap='wrap'>
          {metricGraphs
            ?.filter((metG) =>
              server?.description?.type === 'windows' ? metG?.name !== 'cpu-load-average' : true
            )
            .map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
        </Stack>
      </section>}

      {server?.isLoading && <WaveSpinner isLoading />}

      {!server?.hideIntegration('Apache') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <span className='bg-apache2' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABEL.APACHE'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {apacheGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}
      {!server?.hideIntegration('Nginx') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <div className='bg-nginx' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABEL.NGINX'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {ngnixGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}
      {!server?.hideIntegration('Redis') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <span className='bg-redis' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.REDIS'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {redisGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!server?.hideIntegration('Keydb') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <span className='bg-keydb' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.KEYDB'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {keydbGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!server?.hideIntegration('Fpm') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <span className='bg-php-fpm' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.FPM'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {fpmGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!server?.hideIntegration('Docker') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <span className='bg-docker' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.DOCKER'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {dockerGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!server?.hideIntegration('Mysql') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <div className='bg-mysql' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.MYSQL'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {mysqlGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!server?.hideIntegration('Mongo') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <KTSVG path={'/media/icons/mongodb.svg'} svgClassName='w-30px h-30px' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.MONGO'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {mongoGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}
      {!server?.hideIntegration('IIS') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <KTSVG path={'/media/icons/IIS.svg'} svgClassName='w-30px h-30px' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.IIS'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {IISGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!server?.hideIntegration('HAProxy') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <KTSVG path='/media/icons/haproxy.svg' svgClassName='w-30px h-30px' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.HAPROXY'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {haproxyGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!server?.hideIntegration('Postgresql') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <KTSVG path={'/media/icons/postgresql.svg'} svgClassName='w-30px h-30px' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.POSTGRESQL'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {PostgreSQLGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}
      {!server?.hideIntegration('RabbitMQ') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <KTSVG path={'/media/icons/rabbitmq.svg'} svgClassName='w-30px h-30px' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.RABBITMQ'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {RabbitMQGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

     {!server?.hideIntegration('Varnish') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <KTSVG path={'/media/icons/varnish.svg'} svgClassName='w-30px h-30px' />
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.VARNISH'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {VarnishGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!server?.hideIntegration('CouchDB') && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            {/* <KTSVG path={'/media/icons/couch.svg'} svgClassName='w-70px h-40px' /> */}
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.COUCHDB'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {CouchDBGraphs.map((data) => {
              return (
                <BoxGraph
                  isSelect={widgetsExistsInArray(data.id,server.id)}
                  onClick={() =>
                    onSelect(
                      {
                        ...data,
                        label: server?.label,
                      },
                      false
                    )
                  }
                  data={data}
                  key={data.id}
                />
              )
            })}
          </Stack>
        </section>
      )}

      {!!filterPingHtpp?.length && (
        <section className='mt-6 border border-dashed rounded p-2 '>
          <div className='d-flex gap-1 align-items-center justify-content-center mb-4'>
            <h4 className='m-0'>{intl.formatMessage({id: 'LABELS.CHECKS'})}</h4>
          </div>
          <Stack direction={'row'} gap={1} flexWrap='wrap'>
            {filterPingHtpp.map((check: Record<string, any>) => {
              
              let isSelect = moduleIds?.includes(check?.check?.id)
              return (
                <section
                  key={check?.check?.id}
                  className={`p-2 position-relative min-w-100px  d-flex flex-column gap-2 justify-content-center align-items-center border border-1 border-custom bg-hover-primary rounded ${
                    isSelect ? ' bg-primary' : ''
                  }`}
                  onClick={() => {
                    onSelect(
                      {
                        type_human_readable: check?.check.type === 20 ? 'ping' : 'http',
                        name: 'check',
                        device_id: serverId,
                        id:check?.check?.id,
                        type:check?.check.type,
                        title: `${moduleTypeToHumanReadable(check?.check.type)?.toUpperCase()} ${
                          check?.check?.data?.host
                        }`,
                        label:check?.check?.label
                      },
                      true
                    )
                  }}
                >
                  {isSelect && (
                    <span className='position-absolute top-0 end-0 far fa-check-square  text-dark fs-3' />
                  )}
                  <span className={`fs-4 fw-bold text-uppercase text-muted`}>
                    {check?.check.type_human_readable}
                  </span>
                  <strong className='fs-7'>{check?.check?.data?.host}</strong>
                </section>
              )
            })}
          </Stack>
        </section>
      )}
    </div>
  )
}

const BoxGraph = ({
  isSelect,
  onClick,
  data,
}: {
  onClick: () => void
  isSelect: boolean
  data: Record<string, any>
}) => {
  return (
    <div
      className={`p-2 position-relative min-w-100px  d-flex flex-column gap-2 justify-content-center align-items-center border border-1 border-custom bg-hover-primary rounded ${
        isSelect ? ' bg-primary' : ''
      }`}
      onClick={onClick}
    >
      {isSelect && (
        <span className='position-absolute top-0 end-0 far fa-check-square  text-dark fs-3' />
      )}
      <span className={`${data.icon} fs-2x text-muted`} />
      <strong className='fs-7'>{data.title}</strong>
    </div>
  )
}

export default ServerSelectGraphs
