import React from 'react'
import {Button, Stack} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {useWindowSize} from '../../Hooks/useWindowSize'
import {addToIgnore} from '../../store/commonAction'

interface IWarnProps {
  text: string
  name?: string
  isWarning?: boolean
  customNode?: React.ReactNode
  className?:string
}

const WarnAlert = ({text, name, isWarning = false, customNode,className='my-4'}: IWarnProps) => {
  const state: any = useSelector<RootState>(
    ({commonReducer}) => commonReducer,
    shallowEqual
  ) as Object

  const {width} = useWindowSize()
  const dispatch = useDispatch()

  const addTOIgnoreListHand = () => {
    dispatch(addToIgnore(name ?? ''))
    localStorage.setItem(name ?? 'generalAlert', 'true')
  }
  const intl = useIntl()

  return (
    <>
      {!state?.ignoreList.includes(name) && (
        <div className={`alert alert-warning  ${className}`} role='alert'>
          <Stack direction={width > 768 ? 'horizontal' : 'vertical'} className={`gap-3 ${width<768&&"justify-content-center align-items-center"}`}>
            <span className='fas fa-exclamation-triangle fs-1 text-white' />
            <p className='m-0 p-0 fs-7 text-center text-white'>{text}</p>
            {!isWarning && (
              <span
                className='text-dark fw-bold '
                onClick={addTOIgnoreListHand}
                style={{cursor: 'pointer'}}
              >
                <FormattedMessage id='IGNORE' />
              </span>
            )}
            {customNode && customNode}
            {name === 'emailconfirmation' && (
              <Button className='btn-sm'> {intl.formatMessage({id: 'ACTIONS.RESEND'})}</Button>
            )}
          </Stack>
        </div>
      )}
    </>
  )
}

export default WarnAlert
