import {IntlShape} from 'react-intl'
import {IWidgetType} from '../app/pages/customizedDashboard/CustomizedForm/reducer'

export const integrationGraphsArray = (intl: IntlShape) => {
  const apacheGraphs: IWidgetType[] = [
    {
      id: 'apache-1',
      name: 'integration_apache_per_sec',
      title: intl.formatMessage({id: 'LABEL.APACHE.PER.SECONDS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABEL.APACHE'}),
    },
    {
      id: 'apache-2',
      name: 'integration_apache_per_req',
      title: intl.formatMessage({id: 'LABEL.APACHE.PER.REQ'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABEL.APACHE'}),
    },
  ]

  const ngnixGraphs: IWidgetType[] = [
    {
      id: 'ngnix-1',
      name: 'integration_nginx_active',
      title:
        intl.formatMessage({id: 'LABEL.ACTIVE'}) +
        ' ' +
        intl.formatMessage({id: 'LABEL.CONNECTIONS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABEL.NGINX'}),
    },
    {
      id: 'ngnix-2',
      name: 'integration_nginx_requests',
      title: intl.formatMessage({id: 'LABEL.REQUESTS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABEL.NGINX'}),
    },
  ]

  const redisGraphs: IWidgetType[] = [
    {
      id: 'redis-1',
      name: 'integration_redis_clients',
      title: intl.formatMessage({id: 'LABELS.CLIENTS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.REDIS'}),
    },
    {
      id: 'redis-2',
      name: 'integration_redis_memory_cpu',
      title: intl.formatMessage({id: 'LABELS.MEMORY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.REDIS'}),
      componentName: 'integration_redis_memory',
    },
    {
      id: 'redis-3',
      name: 'integration_redis_memory_cpu',
      title: intl.formatMessage({id: 'LABELS.CPU'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.REDIS'}),
      componentName: 'integration_redis_cpu',
    },
    {
      id: 'redis-4',
      name: 'integration_redis_read_write_per_sec',
      title: intl.formatMessage({id: 'LABELS.READ_WRITE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.REDIS'}),
    },
    {
      id: 'redis-5',
      name: 'integration_redis_keys',
      title: intl.formatMessage({id: 'LABELS.KEYS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.REDIS'}),
    },
  ]

  const keydbGraphs: IWidgetType[] = [
    {
      id: 'key-1',
      name: 'integration_keydb_clients',
      title: intl.formatMessage({id: 'LABELS.CLIENTS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.KEYDB'}),
    },
    {
      id: 'key-2',
      name: 'integration_keydb_memory_cpu',
      title: intl.formatMessage({id: 'LABELS.MEMORY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.KEYDB'}),
      componentName: 'integration_keydb_memory',
    },
    {
      id: 'key-3',
      name: 'integration_keydb_memory_cpu',
      title: intl.formatMessage({id: 'LABELS.CPU'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.KEYDB'}),
      componentName: 'integration_keydb_cpu',
    },
    {
      id: 'key-4',
      name: 'integration_keydb_read_write_per_sec',
      title: intl.formatMessage({id: 'LABELS.READ_WRITE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.KEYDB'}),
    },
    {
      id: 'key-5',
      name: 'integration_keydb_keys',
      title: intl.formatMessage({id: 'LABELS.KEYS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.KEYDB'}),
    },
  ]

  const fpmGraphs: IWidgetType[] = [
    {
      id: 'fpm-1',
      name: 'integration_fpm_processes',
      title: intl.formatMessage({id: 'LABELS.PROCESSES'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.FPM'}),
    },
    {
      id: 'fpm-2',
      name: 'integration_fpm_averages',
      title: intl.formatMessage({id: 'LABELS.MEMORY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.FPM'}),
      componentName: 'integration_fpm_memory',
    },
    {
      id: 'fpm-3',
      name: 'integration_fpm_averages',
      title: intl.formatMessage({id: 'LABELS.CPU'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.FPM'}),
      componentName: 'integration_fpm_cpu',
    },
    {
      id: 'fpm-4',
      name: 'integration_fpm_queue',
      parentTitle: intl.formatMessage({id: 'LABELS.FPM'}),
      icon: 'fas fa-chart-area',
      title: intl.formatMessage({id: 'LABELS.QUEUE'}),
    },
  ]

  const dockerGraphs: IWidgetType[] = [
    {
      id: 'docker-1',
      name: 'integration_docker_container_cpu',
      title: intl.formatMessage({id: 'LABELS.DOCKER.CONTAINER.CPU'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.DOCKER'}),
    },
    {
      id: 'docker-2',
      name: 'integration_docker_container_memory',
      title: intl.formatMessage({id: 'LABELS.DOCKER.CONTAINER.MEMORY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.DOCKER'}),
    },
    {
      id: 'docker-3',
      name: 'integration_docker_container_net_io',
      title: intl.formatMessage({id: 'LABELS.DOCKER.CONTAINER.NET'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.DOCKER'}),
    },
    {
      id: 'docker-4',
      name: 'integration_docker_container_block_io',
      title: intl.formatMessage({id: 'LABELS.DOCKER.BLOCK.IO'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.DOCKER'}),
    },
  ]

  const mysqlGraphs: IWidgetType[] = [
    {
      id: 'sql-1',
      name: 'integration_mysql_cpu',
      title: intl.formatMessage({id: 'PROPERTIES.CPU.USAGE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.MYSQL'}),
    },
    {
      id: 'sql-2',
      name: 'integration_mysql_memory',
      title: intl.formatMessage({id: 'LABELS.MEMORY.USAGE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.MYSQL'}),
    },
    {
      id: 'sql-3',
      name: 'integration_mysql_query',
      title: intl.formatMessage({id: 'LABELS.QUERY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.MYSQL'}),
    },
  ]

  const mongoGraphs: IWidgetType[] = [
    {
      id: 'mongo-1',
      name: 'integration_mongo_conn',
      title: intl.formatMessage({id: 'LABELS.CONNECTION'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.MONGO'}),
    },
    {
      id: 'mongo-2',
      name: 'integration_mongo_cpu',
      title: intl.formatMessage({id: 'PROPERTIES.CPU'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.MONGO'}),
    },
    {
      id: 'mongo-3',
      name: 'integration_mongo_memory',
      title: intl.formatMessage({id: 'LABELS.MEMORY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.MONGO'}),
    },
    {
      id: 'mongo-4',
      name: 'integration_mongo_query',
      title: intl.formatMessage({id: 'LABELS.QUERY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.MONGO'}),
    },
  ]

  const haproxyGraphs: IWidgetType[] = [
    {
      id: 'haproxy-1',
      name: 'integration_haproxy_qcur',
      title: intl.formatMessage({id: 'LABELS.QUEUED'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.HAPROXY'}),
    },
    {
      id: 'haproxy-2',
      name: 'integration_haproxy_rate',
      title: intl.formatMessage({id: 'LABELS.RATE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.HAPROXY'}),
    },
    {
      id: 'haproxy-3',
      name: 'integration_haproxy_req_rate',
      title: intl.formatMessage({id: 'LABELS.REQ.RATE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.HAPROXY'}),
    },
    {
      id: 'haproxy-4',
      name: 'integration_haproxy_scur',
      title: intl.formatMessage({id: 'LABELS.SESSIONS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.HAPROXY'}),
    },
  ]

  const IISGraphs: IWidgetType[] = [
    {
      id: 'iis-1',
      name: 'integration_iis_bytes',
      title: intl.formatMessage({id: 'LABELS.BYTES'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.IIS'}),
    },
    {
      id: 'iis-2',
      name: 'integration_iis_current_conns',
      title: intl.formatMessage({id: 'LABELS.CURRENT.CONNECTIONS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.IIS'}),
    },
    {
      id: 'iis-3',
      name: 'integration_iis_requests',
      title: intl.formatMessage({id: 'LABEL.REQUESTS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.IIS'}),
    },
  ]
  const PostgreSQLGraphs: IWidgetType[] = [
    {
      id: 'PostgreSQL-1',
      name: 'integration_postgresql_cpu',
      title: intl.formatMessage({id: 'PROPERTIES.CPU'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.POSTGRESQL'}),
    },
    {
      id: 'PostgreSQL-2',
      name: 'integration_postgresql_memory',
      title: intl.formatMessage({id: 'LABELS.MEMORY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.POSTGRESQL'}),
    },
    {
      id: 'PostgreSQL-3',
      name: 'integration_postgresql_cache',
      title: intl.formatMessage({id: 'LABELS.CACHE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.POSTGRESQL'}),
    },
    {
      id: 'PostgreSQL-4',
      name: 'integration_postgresql_queries',
      title: intl.formatMessage({id: 'LABELS.QUERY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.POSTGRESQL'}),
    },
    {
      id: 'PostgreSQL-5',
      name: 'integration_postgresql_cons',
      title: intl.formatMessage({id: 'LABEL.CONNECTIONS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.POSTGRESQL'}),
    },
  ]

  const RabbitMQGraphs: IWidgetType[] = [
    {
      id: 'RabbitMQ-1',
      name: 'integration_rabbit_q_totals',
      title: intl.formatMessage({id: 'LABELS.QUEUE.TOTALS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-2',
      name: 'integration_rabbit_octets',
      title: intl.formatMessage({id: 'LABELS.OCTETS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-3',
      name: 'integration_rabbit_exch_pubin',
      title: intl.formatMessage({id: 'LABELS.EXCHANGES.IN'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-4',
      name: 'integration_rabbit_exch_pubout',
      title: intl.formatMessage({id: 'LABELS.EXCHANGES.OUT'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-5',
      name: 'integration_rabbit_node_fd',
      title: intl.formatMessage({id: 'LABELS.NODES.USED.FILE.DESC'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-6',
      name: 'integration_rabbit_node_sock',
      title: intl.formatMessage({id: 'LABELS.NODES.USED.SOCKETS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-7',
      name: 'integration_rabbit_node_ior',
      title: intl.formatMessage({id: 'LABELS.NODES.IO.READ'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-8',
      name: 'integration_rabbit_node_iow',
      title: intl.formatMessage({id: 'LABELS.NODES.IO.WRITE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-9',
      name: 'integration_rabbit_node_mem',
      title: intl.formatMessage({id: 'LABELS.NODES.MEMORY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-10',
      name: 'integration_rabbit_q_consutil',
      title: intl.formatMessage({id: 'LABELS.QUEUES.COSUMER'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-11',
      name: 'integration_rabbit_q_m_byteram',
      title: intl.formatMessage({id: 'LABELS.QUEUES.MSG.RAM'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-12',
      name: 'integration_rabbit_q_m_bytepers',
      title: intl.formatMessage({id: 'LABELS.QUEUES.MSG.BYTES.PERSISTENT'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-13',
      name: 'integration_rabbit_q_m_bytepout',
      title: intl.formatMessage({id: 'LABELS.QUEUES.BYTES.PAGED.OUT'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-14',
      name: 'integration_rabbit_q_m_pout',
      title: intl.formatMessage({id: 'LABELS.QUEUES.PAGED.OUT'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-15',
      name: 'integration_rabbit_q_m_pers',
      title: intl.formatMessage({id: 'LABELS.QUEUES.MSG.PERSISTENT'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-16',
      name: 'integration_rabbit_q_m_ready',
      title: intl.formatMessage({id: 'LABELS.QUEUES.MSG.READY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-17',
      name: 'integration_rabbit_q_m_unack',
      title: intl.formatMessage({id: 'LABELS.QUEUES.MSG.UNAK'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
    {
      id: 'RabbitMQ-18',
      name: 'integration_rabbit_q_mem',
      title: intl.formatMessage({id: 'LABELS.QUEUES.MEMORY'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
    },
  ]  

  const VarnishGraphs: IWidgetType[] = [
    {
      id: 'varnish-1',
      name: 'integration_varnish_sess',
      title: intl.formatMessage({id: 'LABELS.SESSIONS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.VARNISH'}),
    },
    {
      id: 'varnish-2',
      name: 'integration_varnish_cache',
      title: intl.formatMessage({id: 'LABELS.CACHE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.VARNISH'}),
    },
    {
      id: 'varnish-3',
      name: 'integration_varnish_back',
      title: intl.formatMessage({id: 'LABELS.BACKEND'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.VARNISH'}),
    },
  ]

  const CouchDBGraphs: IWidgetType[]=[
    {
      id: 'couch-1',
      name: 'integration_couchdb_bulk',
      title: intl.formatMessage({id: 'LABELS.BULK.REQUEST'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-2',
      name: 'integration_couchdb_req_2xx',
      title: intl.formatMessage({id: 'LABELS.REQUEST.STATUS.2XX'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-3',
      name: 'integration_couchdb_req_3xx',
      title: intl.formatMessage({id: 'LABELS.REQUEST.STATUS.3XX'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-4',
      name: 'integration_couchdb_req_4xx',
      title: intl.formatMessage({id: 'LABELS.REQUEST.STATUS.4XX'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-5',
      name: 'integration_couchdb_req_5xx',
      title: intl.formatMessage({id: 'LABELS.REQUEST.STATUS.5XX'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-6',
      name: 'integration_couchdb_req_copy',
      title: intl.formatMessage({id: 'LABELS.COPY.REQUEST'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-7',
      name: 'integration_couchdb_req_delete',
      title: intl.formatMessage({id: 'LABELS.DELETE.REQUEST'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-8',
      name: 'integration_couchdb_req_get',
      title: intl.formatMessage({id: 'LABELS.GET.REQUEST'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-9',
      name: 'integration_couchdb_req_head',
      title: intl.formatMessage({id: 'LABELS.HEAD.REQUEST'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-10',
      name: 'integration_couchdb_req_option',
      title: intl.formatMessage({id: 'LABELS.OPTIONS.REQUEST'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-11',
      name: 'integration_couchdb_req_post',
      title: intl.formatMessage({id: 'LABELS.POST.REQUEST'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-12',
      name: 'integration_couchdb_req_put',
      title: intl.formatMessage({id: 'LABELS.PUT.REQUEST'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-13',
      name: 'integration_couchdb_time',
      title: intl.formatMessage({id: 'LABELS.REQUEST.TIME'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-14',
      name: 'integration_couchdb_workload_r',
      title: intl.formatMessage({id: 'LABELS.WORKLOAD.READ'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
    {
      id: 'couch-15',
      name: 'integration_couchdb_workload_w',
      title: intl.formatMessage({id: 'LABELS.WORKLOAD.WRITE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.COUCHDB'}),
    },
  ]

  
  const MetricsGraphs: IWidgetType[] = [
    {
      id: 'metrics-1',
      name: 'cpu-usage',
      title: intl.formatMessage({id: 'LABELS.CPU_USAGE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.METRIC'}),
    },
    {
      id: 'metrics-2',
      name: 'cpu-load-average',
      title: intl.formatMessage({id: 'LABELS.LOAD_AVERAGE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.METRIC'}),
    },
    {
      id: 'metrics-3',
      name: 'disk-usage',
      title: intl.formatMessage({id: 'LABELS.DISK_USAGE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.METRIC'}),
    },
    {
      id: 'metrics-4',
      name: 'memory-usage',
      title: intl.formatMessage({id: 'LABELS.MEMORY_USAGE'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.METRIC'}),
    },
    {
      id: 'metrics-5',
      name: 'network',
      title: intl.formatMessage({id: 'LABELS.NETWORK'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.METRIC'}),
    },
    {
      id: 'metrics-6',
      name: 'disk-io',
      title: intl.formatMessage({id: 'LABELS.DISK_IO'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.METRIC'}),
    },
    {
      id: 'metrics-7',
      name: 'network-packets',
      title: intl.formatMessage({id: 'LABELS.NETWORK_PACKETS'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.METRIC'}),
    },
    {
      id: 'metrics-6',
      name: 'network-dropped',
      title: intl.formatMessage({id: 'LABELS.NETWORK_DROPPED'}),
      icon: 'fas fa-chart-area',
      parentTitle: intl.formatMessage({id: 'LABELS.METRIC'}),
    },
  ]
  return {
    PostgreSQLGraphs,
    IISGraphs,
    haproxyGraphs,
    mongoGraphs,
    mysqlGraphs,
    dockerGraphs,
    fpmGraphs,
    keydbGraphs,
    redisGraphs,
    ngnixGraphs,
    apacheGraphs,
    RabbitMQGraphs,
    VarnishGraphs,
    CouchDBGraphs,
    MetricsGraphs
  }
}
