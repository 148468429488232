import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {useLocation} from 'react-router'
import {useLocalStorage} from '../../../Hooks/useLocalStorage'

const OAuths = () => {
  const intl = useIntl()
  const location = useLocation()
  const locationState = location.search as any
  const [zapierValue, setZapierValue] = useLocalStorage<any>('zapier-integration', {})
  const [cliValue] = useLocalStorage<any>('cli-integration', {})

  const locationParams=!!zapierValue.params ? zapierValue.params :!!cliValue?.params ? cliValue?.params: locationState

  return (
    <div className='d-flex flex-column gap-2'>
      <div>
        <a
          href={`${process.env.REACT_APP_API_URL_NOT_VERSION}/oauth/google/login${
            locationParams
          }`}
          className='btn btn-flex btn-outline btn-text-dark btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          <KTSVG path={'/media/icons/google-icon.svg'} className={`h-16px me-3`} />
          {intl.formatMessage({id: 'SIGN.IN.GOOGLE'})}
        </a>
      </div>
      <div>
        <a
          href={`${process.env.REACT_APP_API_URL_NOT_VERSION}/oauth/github/login${
            locationParams
          }`}
          className='btn btn-flex btn-outline btn-text-dark btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          <KTSVG path={'/media/icons/github-mark.svg'} className={`h-16px me-3`} />
          {intl.formatMessage({id: 'SIGN.IN.GITHUB'})}
        </a>
      </div>
      <div>
        <a
          href={`${process.env.REACT_APP_API_URL_NOT_VERSION}/oauth/microsoft/login${
            locationParams
          }`}
          className='btn btn-flex btn-outline btn-text-dark btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          <KTSVG path={'/media/icons/microsoft-icon.svg'} className={`h-16px me-3`} />
          {intl.formatMessage({id: 'SIGN.IN.MICROSOFT'})}
        </a>
      </div>
    </div>
  )
}

export default OAuths
