import AccountBalance from '../AccountBalance'
import ChecksMostDowntime from '../ChecksMostDowntime'
import Invoices from '../Invoices'
import LastActiveTickets from '../LastActiveTickets'
import LastLogin from '../LastLogin'
import OpenIncidents from '../OpenIncidents'
import RecentOrders from '../RecentOrders'
import RecentQoutations from '../RecentQoutations'
import RecentSessions from '../RecentSessions'
import ResolvedIncidents from '../ResolvedIncidents'
import ServersHighUptime from '../ServersHighUptime'
import ServersLeastUptime from '../ServersLeastUptime'
import SMSCredits from '../SMSCredits'
import SslExpiringSoon from '../SslExpiringSoon'
import SumCredits from '../SumCredits'
import TopCpu from '../TopCpu'
import TopIO from '../TopIO'
import TopMemory from '../TopMemory'
import TotalChecks from '../TotalChecks'
import TotalIncident from '../TotalIncident'

export interface ComponentWidgetType {
  [key: string]: any
}

export const componentWidgets: ComponentWidgetType = {
  'widget-1': LastActiveTickets,
  'widget-2': RecentSessions,
  'widget-3': ResolvedIncidents,
  'widget-4': ServersHighUptime,
  'widget-5': TotalIncident,
  'widget-6': TopMemory,
  'widget-7': LastLogin,
  'widget-8': TopIO,
  'widget-9': OpenIncidents,
  'widget-10': SumCredits,
  'widget-11': AccountBalance,
  'widget-12': Invoices,
  'widget-13': RecentQoutations,
  'widget-14': TopCpu,
  'widget-15': RecentOrders,
  'widget-16': SMSCredits,
  'widget-17': ServersLeastUptime,
  'widget-18': TotalChecks,
  'widget-19': ChecksMostDowntime,
  'widget-20': SslExpiringSoon,
}
