export function isHasAccess(checker: string|null, user: any) {
  const access = user?.access

  //is client
  if (!('parent' in user) || !('access' in user)) {
    return true
  }

  //has full access
  if (!!user?.full_access) {
    return true
  }

  if (checker && access) {
    return !!access[checker]
  }

  return false
}


export function filterObjectByTimestamps(obj: { [key: string]: number }): { [key: string]: number } {
  const currentTime = Math.floor(Date.now() / 1000); // get current timestamp in seconds
  const keys = Object.keys(obj).sort().slice(-3); // get the last 3 keys sorted by timestamp
  const filteredObj: { [key: string]: number } = {};

  for (const key in obj) {
    if (keys.includes(key)) {
      const timestamp = parseInt(key);

      // check if timestamp is within 1 minutes in the past from current time
      if (!(timestamp >= currentTime - 60) && timestamp < currentTime) {
        filteredObj[key] = obj[key];
      }
    } else {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
}


export function isWithinLastTwoMinutes(timestamp: number): boolean {
  const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
  const twoMinutesAgo = currentTime - 60; // two minutes ago in seconds
  return timestamp < twoMinutesAgo && timestamp < currentTime;
}
