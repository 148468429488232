import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';


const CopyToClipBoard = ({children,onCopy,text}) => {
    return (
        <CopyToClipboard text={text} onCopy={onCopy}>
            {children}
        </CopyToClipboard>
    )
}

export default CopyToClipBoard