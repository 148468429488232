
import Http, { _axios } from '../app/store/core/inceptor'
import {loadTableParams} from '../helpers'


export const Account = {
  profile: () => _axios.get('/account'),
  update: (params: any) => {
    let formData = new FormData()

    formData.append('_method', 'PUT')

    for (let i in params) {
      if ('file' in params) {
        formData.append('avatar', params['file'])
      } else {
        formData.append(i, params[i])
      }
    }

    return _axios.post('/account', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        enctype: 'multipart/form-data',
      },
    })
  },
  getLogs: (per_page:number,page:number) => {
   
    return _axios.get(`/account/logs?per_page=${per_page}&page=${page} `)
  },
  delete: () => _axios.delete('/client'),
}


export const getAccount=()=>Http.get<any,Record<string,any>>('/account')


interface  IgetContactsWithParams{
 limit:number,
 offset:number
}


export const getContactsWithParams=(query:IgetContactsWithParams)=>Http.get(`/contacts`,{
  params:{
    limit:query.limit,
    offset:query.offset
  },
})
