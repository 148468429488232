

export const clearAllStoredData = () => {
    
    let mainURL = getMainURL().toLowerCase().replace('www.', '')

    var cookies = document.cookie.split(";");


    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;' +
            'expires=Thu, 01-Jan-1970 00:00:01 GMT;' +
            'path=' + '/;' +
            'domain=' + mainURL + ';' +
            'secure=;';
    }
    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        
        document.cookie = name + '=;' +
            'expires=Thu, 01-Jan-1970 00:00:01 GMT;' +
            'path=' + '/;' +
            'domain=' + window.location.host + ';' +
            'secure=;';
    }

    localStorage.clear();

}


// get-main-url.js v1
function getMainURL(url:any = window.location.href){
    url = url.replace(/.+?\/\//, ''); // remove protocol
    url = url.replace(/(\#|\?|\/)(.+)?/, ''); // remove parameters and paths
    // remove subdomain
    if( url.split('.').length === 3 ){
        url= url.split('.')
        url.shift();
        url = url.join('.');
    };
    return url;
};