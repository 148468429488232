import React from 'react'
import {useIntl} from 'react-intl'
import ReactTooltip from 'react-tooltip'
import classes from './style.module.scss'

interface Props {
  getPassFunction: (password: string) => void
}

const RandomPassword = ({getPassFunction}: Props) => {
  const intel = useIntl()

  const chars = 'abcdefghijklmnopqrstuvwxyz'

  const charUpper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

  const numbers = '0123456789'

  const symbols = '!@#$%^&*()?'

  const onClickHand = () => {
    let password = ''
    const length = 10
    while (password.length < length) {
      let entity1 = Math.ceil(chars.length * Math.random() * Math.random())
      let entity2 = Math.ceil(numbers.length * Math.random() * Math.random())
      let entity3 = Math.ceil(symbols.length * Math.random() * Math.random())
      let hold = chars.charAt(entity1)
      let character: any=password
      hold = password.length % 2 == 0 ? hold.toUpperCase() : hold
      
      character += hold
      character += numbers.charAt(entity2)
      character += symbols.charAt(entity3)
      password = character
    }
    
    password = password
      .split('')
      .sort(function () {
        return 0.5 - Math.random()
      })
      .join('')
    getPassFunction(password.substring(0, length))
  }

  return (
    <>
      <span
        onClick={onClickHand}
        className={`${classes['password-box-gen']} btn btn-icon btn-active-light-primary`}
        data-for='random.pass'
        data-tip
      >
        <span className='fas fa-key'></span>
      </span>
      <ReactTooltip id='random.pass'>{intel.formatMessage({id: 'RANDOM.PASSWORD'})}</ReactTooltip>
    </>
  )
}

export default RandomPassword
