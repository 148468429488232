import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl';
import CustomModal from '../../../../../helpers/CustomModal'

const IPSModal:FC<{handleClose: () => void,display:boolean,ips:string[]}> = ({handleClose,display,ips=[]}) => {
    const intl = useIntl();

  return (
    <CustomModal
    display={display}
    size="sm"
    title= {intl.formatMessage({ id: 'TITLES.IPS' })}
    handleClose={handleClose}
    showFooterButtons={false}

>
    <div>
        <ul>
            {ips?.map?.((ip:string,idx:number)=><li key={idx}>{ip}</li>)}
        </ul>

    </div>
    <div className='d-flex flex-row-reverse mt-3'>

        <Button variant='primary' className='me-2' onClick={handleClose}>
            {intl.formatMessage({ id: 'ACTIONS.OK' })}

        </Button>


    </div>
</CustomModal>
  )
}

export default IPSModal