

export interface IWidgetType {
  type_human_readable?: string
  label?:string
  name: string
  title: string
  id: number | string
  icon: string
  parentTitle?: string
  componentName?: string
  device_id?:string|number
  serverId?:number
  customLabel?:string
}

export interface IWidgets {
  id: string
  name: string
  type: 'graph'
  enable: boolean
  widget_type: 'server' | 'check'
  server_id: number | null
  module_id: number[] 
  widgets: IWidgetType[]
}

export type State = {
  mode_2:any
  mode_3:any
  mode_4: any
  widgets: IWidgets[]
}

// Define the action type
export type Action =
  | {type: 'SET_INIT'; payload: any}
  | {
      type: 'SET_VAL'
      payload: {
        name: keyof IWidgets
        value: any
      }
    }
  | {
      type: 'TOGGLE_WIDGET'
      payload: IWidgetType
    }
  | {type: 'RESET'}
  |{
    type: 'TOGGLE_CHECK'
    payload: number
  }|
  {
    type: 'CLEAR_WIDGETS'
  }|
  {
    type: 'CUSTOM_LABEL_WIDGET'
    payload: {widget:IWidgetType,customLabel:string}
  }

export const reducer = (state: IWidgets, action: Action) => {
  
  switch (action.type) {
    case 'SET_INIT':
      return {
        ...action.payload,
      }

    case 'SET_VAL':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      }

    case 'TOGGLE_WIDGET':
      const cloneWidgets = state.widgets
      const existingIndex = cloneWidgets.findIndex((item) => item.id === action.payload.id)

      if (existingIndex !== -1) {
        // Object exists, remove it
        return {
          ...state,
          widgets: cloneWidgets.filter((item) => item.id !== action.payload.id),
        }
      } else {
        // Object doesn't exist, add it
        return {
          ...state,
          widgets: [...state.widgets, action.payload],
        }
      }
    case "CUSTOM_LABEL_WIDGET":{
      const cloneWidgets = [...state.widgets]
      const existingIndex = cloneWidgets.findIndex((item) => item.id === action.payload.widget.id)

      if (existingIndex !== -1) {
         cloneWidgets[existingIndex]={
          ...cloneWidgets[existingIndex],
          customLabel:action.payload.customLabel
         }
        return {
          ...state,
          widgets: cloneWidgets,
        }
      } else {
        // Object doesn't exist
        return {
          ...state,
          widgets: cloneWidgets,
        }
      }
    }


    case 'RESET':
      return {
        id: '',
        name: '',
        type: 'graph',
        enable: true,
        widget_type: 'server',
        server_id: null,
        module_id: [],
        widgets: [],
      }

    case 'TOGGLE_CHECK': {
      
      const checks=[...state?.module_id]
    
      if (checks.includes(action.payload)) {
        return {
          ...state,
          module_id:checks?.filter((c)=>c!=action.payload)
        }
      }
      return {
        ...state,
        module_id:[...checks,action.payload]
      }
    }
    case 'CLEAR_WIDGETS': {
      
      
      return {
        ...state,
        widgets:[]
      }
    }
    default:
      return state
  }
}
