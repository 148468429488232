import React, {useState, useEffect} from 'react'
import TimezoneSelect, {allTimezones, useTimezoneSelect} from 'react-timezone-select'
import type {ITimezone, ITimezoneOption} from 'react-timezone-select'
import {editTimezone} from '../../../services/dashboard'
import {getProfile} from '../auth/core/_requests'
import {shallowEqual, useDispatch} from 'react-redux'
import * as authMain from '../../../app/modules/auth/redux/AuthRedux'
import {UserModel} from '../auth/models/UserModel'
import {useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {Dropdown, Form} from 'react-bootstrap'
import useUser from '../../Hooks/useUser'
import { useQueryClient } from 'react-query'

const showTimeWithTZ = (timezone: string) => {
  const date = new Date()

  return date.toLocaleString('en', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: timezone,
  })
}

const TimeZoneSelect = ({show}: {show: boolean}) => {
  const {timezone}: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  const [selectedTimezone, setSelectedTimezone] = useState<any>(timezone)
  const queryClient = useQueryClient();
  const [currentTime, setCurrentTime] = useState<string>(showTimeWithTZ(timezone))
  const {options, parseTimezone} = useTimezoneSelect({
    labelStyle: 'original',
    timezones: allTimezones,
  })
  const dispatch = useDispatch()

  const removedTimezone = ['Asia/Rangoon', 'America/Godthab']

  const filterTimezone = options?.filter((tz) => !removedTimezone.includes(tz.value))
  const user=useUser()

  const changeNameUTC = filterTimezone.map((tz) => {
    let clone = {...tz}
    if (tz.value === 'Etc/GMT') {
      clone.label = '(GMT+0:00) UTC - GMT'
    }
    return clone
  })

  useEffect(() => {
    let interval = setInterval(() => {
      setCurrentTime(showTimeWithTZ(selectedTimezone))
    }, 60000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const onFixtimeZone = (timezone: ITimezoneOption) => {
    setCurrentTime(showTimeWithTZ(timezone.value))
    let value = timezone.value
    queryClient.invalidateQueries({active:true});
    queryClient.refetchQueries({active:true});
    // queryClient.clear();
    switch (value) {
      case 'Etc/GMT':
        value = 'UTC'
        setSelectedTimezone({...timezone, value: 'UTC'})
        dispatch(authMain.actions.setUser({...user,timezone: 'UTC'}))

        break

      default:
        dispatch(authMain.actions.setUser({...user,timezone:timezone.value}))
        setSelectedTimezone({...timezone})

        break
    }

    editTimezone(value).then(() => {})
  }
  useEffect(() => {
    setSelectedTimezone(timezone)
  }, [timezone])

  
  return (
    <div className='h-100 align-items-center' style={{display: show ? 'flex' : 'none'}}>
      <Dropdown className='d-inline me-2'>
        <Dropdown.Toggle id='dropdown-autoclose-true' as={CustomToggle}>
          <div className='d-flex'>
            <span className='me-2 fs-bold text-dark'> {currentTime}</span>
            <span className='text-muted fs-7'>{selectedTimezone?.value ?? selectedTimezone}</span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu as={CustomMenu}>
          <TimezoneSelect
            value={selectedTimezone}
            onChange={onFixtimeZone}
            className={`react-select-container w-lg-250px`}
            classNamePrefix='react-select'
            menuIsOpen
            options={changeNameUTC}
          />
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default TimeZoneSelect

const CustomToggle = React.forwardRef(({children, onClick}: any, ref: any) => (
  <div
    className='d-flex align-items-center gap-3 text-muted'
    style={{
      cursor: 'pointer',
    }}
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
    &#x25bc;
  </div>
))

const CustomMenu = React.forwardRef(
  ({children, style, className, 'aria-labelledby': labeledBy}: any, ref: any) => {
    const [value, setValue] = useState('')

    return (
      <div
        ref={ref}
        style={style}
        className={`${className} h-350px p-1`}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>
    )
  }
)
