import React, {useRef, useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {AsideMenuMain} from './AsideMenuMain'
import {DrawerComponent, ScrollComponent, ToggleComponent} from '../../../assets/ts/components'
import CircularProgressMui from '../../../../app/modules/global/UI/CircularProgressMui'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import useAccountScore from '../../../../app/Hooks/useAccountScore'
import useUser from '../../../../app/Hooks/useUser'
import useKeyboardShortcuts from '../../../../app/Hooks/useKeyboardShortcuts'
import {Button, Modal} from 'react-bootstrap'
import ShortcutKeyModal from '../../../../app/modules/shortcutKey/shortcutKeyModal'

type Props = {
  asideMenuCSSClasses: string[]
}

const AsideMenu: React.FC<Props> = ({asideMenuCSSClasses}) => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const {pathname} = useLocation()
  const {score} = useAccountScore()
  const user = useUser()
  const [open, setOpen] = useState(false)
  const intl = useIntl()
  const xClicked = useKeyboardShortcuts(setOpen)

  useEffect(() => {
    setTimeout(() => {
      DrawerComponent.reinitialization()
      ToggleComponent.reinitialization()
      ScrollComponent.reinitialization()
      if (scrollRef.current) {
        scrollRef.current.scrollTop = 0
      }
    }, 50)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <div
      id='kt_aside_menu_wrapper'
      ref={scrollRef}
      className='hover-scroll-overlay-y my-5 my-lg-5 d-flex flex-column justify-content-between'
      data-kt-scroll='true'
      data-kt-scroll-activate='{default: false, lg: true}'
      data-kt-scroll-height='auto'
      data-kt-scroll-dependencies='#kt_aside_logo, #kt_aside_footer'
      data-kt-scroll-wrappers='#kt_aside_menu'
      data-kt-scroll-offset='0'
      style={{height: '90%'}}
    >
      <div
        id='#kt_aside_menu'
        data-kt-menu='true'
        className={clsx(
          'menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500',
          asideMenuCSSClasses.join(' ')
        )}
      >
        <AsideMenuMain xClicked={xClicked} />
      </div>
      <div className='menu-title'>
        {score !== 100 && !user?.parent && (
          <Link
            to={'/wizard'}
            className='w-100 d-flex justify-content-center flex-column align-items-center gap-3 mt-10 fs-7 '
            style={{color: '#9899ac'}}
          >
            <CircularProgressMui size={70} value={score} />
            <FormattedMessage id='LABELS.ASIDE.SCORE' />
          </Link>
        )}
      </div>

      <Modal
        show={open}
        onHide={() => setOpen(false)}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            {intl.formatMessage({id: `LABELS.SHORTCUT_KEYS`})}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{maxHeight: '50vh', overflowY: 'scroll'}}>
            <ShortcutKeyModal />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setOpen(false)}>
            {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export {AsideMenu}
