import React, {useState} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {notify} from '../../../../helpers'
import {Api} from '../../../../services/Api'
import {getApiTokens, removeAPlToken} from '../../../../services/ApiToken'
import WaveSpinner from '../../../modules/global/WaveSpinner'
import {ApiKeyItem} from './ApiKeyItem'

const ApiTokenList = () => {
  const [perPage, setPerPage] = useState(3)
  const intl = useIntl()

  const {
    data: response,
    refetch,
    isLoading,
  }: any = useQuery(['api-tokens-keys', perPage], () => getApiTokens(), {keepPreviousData: true})

  const reloadListHandler = () => {
    refetch()
  }

  const loadMore = () => {
    setPerPage(perPage + 3)
  }

  const deleteApiHandler = (id: number, title: string) => {
    removeAPlToken(id).then((response) => {
      notify({
        type: 'success',
        message: intl.formatMessage({id: 'NOTIFICATIONS.ITEM_DELETED'}, {name: title}),
      })

      refetch()
    })
  }

  if (isLoading) {
    return <WaveSpinner isLoading={isLoading} />
  }

  return (
    <div className='mt-4'>
      <div className='mt-6'>
        {!!response?.length ? (
          response?.map((item: any) => {
            return (
              <ApiKeyItem
                key={item.id}
                {...item}
                token={item.key}
                title={item.name}
                onDelete={() => deleteApiHandler(item.id, item.name)}
                isApiTokens
              />
            )
          })
        ) : (
          <p className='text-center'>
            <FormattedMessage id='ACCOUNT.APIS.EMPTY' />
          </p>
        )}

        {/* {perPage < response?.length && (
        <button className={`btn btn-primary btn-sm w-100 mt-4`} onClick={loadMore}>
          <FormattedMessage id='ACTIONS.LOAD_MORE' />
        </button>
      )} */}
      </div>
    </div>
  )
}

export default ApiTokenList
