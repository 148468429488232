/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import { getProfile, twoFALogin} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {getMainServiceAction} from '../../../store/billing/actions'
import Bugsnag from '@bugsnag/js'
import * as authMain from '../../auth/redux/AuthRedux'
import { Global } from '../../../../services/Global'
import * as configRedux from '../../config/redux/ConfigRedux'

const twoFactorAuthSchema = Yup.object().shape({
  oneTimePassword: Yup.string()
    .length(6, 'Length 6 number')
    .required('One time password is required'),
})

const twoFactorAuthInitialValues = {
  oneTimePassword: '',
  no_redirect: 'true',
}

const TwoFactorAuth = () => {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const rememberMe = searchParams.get('rememberMe')
  const defaultDash = localStorage.getItem('defaultDash')
  const dashboardLocation = !!defaultDash ? `/customized-dashboard/${defaultDash}` : '/dashboard'

  const dispatch = useDispatch()

  const twoFactorAuthFormik = useFormik({
    initialValues: twoFactorAuthInitialValues,
    validationSchema: twoFactorAuthSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        const response = await twoFALogin(values.oneTimePassword,rememberMe==="true")
        const token = response?.data.token || response?.data
        dispatch(authMain.actions.login(token))
        const profile = await getProfile()
        const configDate= await Global.getConfig()
        dispatch(configRedux.configActions.setConfig(configDate.data))

        dispatch(authMain.actions.setUser(profile?.data))
        if (profile?.data) {
          setCurrentUser(profile?.data)
          dispatch(getMainServiceAction())
          Bugsnag.addMetadata('userInformation', {
            id: profile.data.id,
            username: profile.data.username,
            email: profile.data.email,
          })
        }
        navigate(dashboardLocation)
      } catch (err: any) {
        const error = err.response && (err.response || err.message)
        
        saveAuth(undefined)
        setStatus(error?.data?.message)
        setSubmitting(false)
        setLoading(false)
        if (error?.status===500) {
          navigate('/auth')
            
          }
      }
    },
  })

  return (
    <div className=''>
      <form
        className='form w-100'
        onSubmit={twoFactorAuthFormik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h5 className='text-dark mb-3'>{intl.formatMessage({id: 'PAGE.DESCRIPTIONS.2FA'})}</h5>
        </div>
        {/* begin::Heading */}

        {twoFactorAuthFormik.status && (
          <div className='mb-lg-15 alert alert-danger text-dark'>
            <div className='alert-text font-weight-bold'>{twoFactorAuthFormik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <input
            placeholder={intl.formatMessage({id: 'LABELS.ONE_TIME_PASSWORD'})}
            {...twoFactorAuthFormik.getFieldProps('loginName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid':
                  twoFactorAuthFormik.touched.oneTimePassword &&
                  twoFactorAuthFormik.errors.oneTimePassword,
              },
              {
                'is-valid':
                  twoFactorAuthFormik.touched.oneTimePassword &&
                  !twoFactorAuthFormik.errors.oneTimePassword,
              }
            )}
            type='text'
            name='oneTimePassword'
            value={twoFactorAuthFormik.values.oneTimePassword}
            onChange={twoFactorAuthFormik.handleChange}
            autoComplete='off'
          />
          {twoFactorAuthFormik.touched.oneTimePassword &&
            twoFactorAuthFormik.errors.oneTimePassword && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{twoFactorAuthFormik.errors.oneTimePassword}</span>
              </div>
            )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={twoFactorAuthFormik.isSubmitting || !twoFactorAuthFormik.isValid}
          >
            {!loading && (
              <span className='indicator-label'>
                {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON'})}
              </span>
            )}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'ACTIONS.WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button
            type='button'
            className='btn btn-lg btn-outline-primary w-100 mb-5'
            onClick={() => navigate('/auth')}
          >
            {intl.formatMessage({id: 'AUTH.GENERAL.BACK_BUTTON'})}
          </button>
        </div>
        {/* end::Action */}
      </form>
    </div>
  )
}

export default TwoFactorAuth
