import {call, put, takeEvery} from 'redux-saga/effects'
import {deleteServerRequest, getAgentKey, getNotificationRoles, getServers, updateServerRequest} from './requests'
import {types} from '../actionTypes'
import { handleError } from '../../../modules/widgets/components/Common'
  interface Response {
    data: any
  }
function* requestServers() {
  try {
    const response: Response = yield call(getServers)
    yield put({
      type: types.GET_SERVERS_SUCCESS,
      payload:response.data

    })
  } catch (e: any) {
    yield put({
      type: types.GET_SERVERS_ERROR,
      error:e.data.message

    })
  }
}

function* requestAgentKey() {
  try {
    const response: Response = yield call(getAgentKey)
    yield put({
      type: types.GET_AGENTKEY_SUCCESS,
      payload:response.data

    })
  } catch (e: any) {
    yield put({
      type: types.GET_AGENTKEY_ERROR,
      error:e.data.message
    })
  }
}

function* requestNotificationRoles() {
  try {
    const response: Response = yield call(getNotificationRoles)
    yield put({
      type: types.GET_NOTIFICATIONROLES_SUCCESS,
      payload:response.data

    })
  } catch (e: any) {
    yield put({
      type: types.GET_NOTIFICATIONROLES_ERROR,
      error:e.data.message

    })
  }
}

function* updateServerHandler(action:any) {
  try {
    const response: Response = yield call(updateServerRequest,action.id,action.params)
    yield put({
      type: types.UPDATE_SERVER_REQUEST_SUCCESS,
      payload:response.data

    })
  } catch (e: any) {

    yield put({
      type: types.UPDATE_SERVER_REQUEST_ERROR,
      error:handleError(e)

    })
  }
}


function* deleteServerHandler(action:any) {
  try {
    const response: Response = yield call(deleteServerRequest,action.id)
    yield put({
      type: types.DELETE_SERVER_REQUEST_SUCCESS,
      payload:response.data

    })
  } catch (e: any) {
    yield put({
      type: types.DELETE_SERVER_REQUEST_ERROR,
      error:handleError(e)

    })
  }
}


function* pauseServerHandler(action:any) {
  try {
    const response: Response = yield call(updateServerRequest,action.serverId,action.pauseUntil)
    yield put({
      type: types.PAUSE_SERVER_REQUEST_SUCCESS,
      payload:response.data

    })
  } catch (e: any) {
    yield put({
      type: types.PAUSE_SERVER_REQUEST_ERROR,
      error:handleError(e)

    })
  }
}





function* watcherServerSaga() {
  yield takeEvery(types.GET_SERVERS, requestServers)
  yield takeEvery(types.GET_SERVERS_REFETCH, requestServers)
  yield takeEvery(types.GET_AGENTKEY, requestAgentKey)
  yield takeEvery(types.GET_NOTIFICATIONROLES, requestNotificationRoles)
  yield takeEvery(types.UPDATE_SERVER_REQUEST, updateServerHandler)
  yield takeEvery(types.DELETE_SERVER_REQUEST, deleteServerHandler)
  yield takeEvery(types.PAUSE_SERVER_REQUEST, pauseServerHandler)


}

export default watcherServerSaga
