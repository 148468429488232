import {Suspense} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import 'react-toastify/dist/ReactToastify.min.css'
import 'react-phone-number-input/style.css'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import {DashboardProvider} from './modules/context/DashboardCtx'
import {ToastContainer} from 'react-toastify'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../setup'
import AuthOnModal from './modules/auth/components/AuthOnModal'
import { useThemeMode } from '../_metronic/partials'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'react-credit-cards/es/styles-compiled.css'
import  'moment-timezone'
import { MetricsPageProvider } from './modules/context/MetricsPageProvider'

const App = () => {
  const location = useLocation()
  const state: any = useSelector<RootState>(
    ({commonReducer}) => commonReducer,
    shallowEqual
  ) as Object
   const theme=useThemeMode()

   const darkTheme = createTheme({
    palette: {
      mode: theme?.mode==="dark"?"dark":"light",
      primary:{
        main:"#06d2dd"
      }
    },
    typography: {
      "fontFamily": `Poppins, Helvetica, "sans-serif"`,
   
     }
  });


  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
      <ThemeProvider theme={darkTheme}>

          <DashboardProvider>
          <MetricsPageProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
              {!state?.isAuth && !location.pathname.includes('auth') && <AuthOnModal />}
            </AuthInit>
            <ToastContainer
              position='bottom-right'
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </LayoutProvider>
         
          </MetricsPageProvider>
          </DashboardProvider>
        </ThemeProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
