import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getAvatarLogs } from '../../../../services/HeaderNotifications'

interface Props {
  id: number
  description: string
  name: string
  date: string
  isClient: boolean
  avatar: string
}

const LogsItem = ({id, description, name, date,isClient, avatar}: Props) => {
    const [src, setSrc] = useState<any>();
    const navigate=useNavigate()

  // useEffect(() => {
  //   getAvatarLogs(id,isClient).then((response) => {
  //     setSrc(URL.createObjectURL(response.data));
  //   });
  // }, [id]);

  return (
    <div className='p-3 bg-box bg-hover-primary' onClick={()=>navigate('/logs/server')}>
      <div className='d-flex gap-5 '>
        <div className='image-wrapper'>
            <img src={avatar}/>
        </div>
        <h5 style={{width:"90%",fontSize:"11px"}} >{description}</h5>
      </div>
      <hr className='my-3'/>
      <div className='d-flex justify-content-between'>
        <h6>{name}</h6>
        <h6>{date}</h6>
      </div>
    </div>
  )
}

export default LogsItem
