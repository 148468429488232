export const INVALID_ATTACHMENT_SIZE = "invalid_size";
export const INVALID_ATTACHMENT_TYPE = "invalid_type";

export function areAttachmentsValid({ attachments, maxSize, extensions }: any) {
  for (const i in attachments) {
    if (!("name" in attachments[i])) {
      continue;
    }

    const { size, name, type } = attachments[i];

    if (size > maxSize * 1024) {
      return [false, INVALID_ATTACHMENT_SIZE, name];
    }

    const validMimeTypes = getValidMimeTypes(extensions);

    if (!validMimeTypes.includes(type)) {
      return [false, INVALID_ATTACHMENT_TYPE, name];
    }
  }

  return [true, "", ""];
}

function getValidMimeTypes(extensions: any) {
  let mimeTypes = [];

  for (const extension of extensions) {
    mimeTypes.push(convertExtensionToMimeType(extension));
  }

  return mimeTypes;
}

function convertExtensionToMimeType(extension: string) {
  const mimeTypes: any = {
    txt: "text/plain",
    htm: "text/html",
    html: "text/html",
    php: "text/html",
    css: "text/css",
    js: "application/javascript",
    json: "application/json",
    xml: "application/xml",
    swf: "application/x-shockwave-flash",
    flv: "video/x-flv",

    // images
    png: "image/png",
    jpe: "image/jpeg",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    gif: "image/gif",
    bmp: "image/bmp",
    ico: "image/vnd.microsoft.icon",
    tiff: "image/tiff",
    tif: "image/tiff",
    svg: "image/svg+xml",
    svgz: "image/svg+xml",

    // archives
    zip: "application/zip",
    rar: "application/x-rar-compressed",
    exe: "application/x-msdownload",
    msi: "application/x-msdownload",
    cab: "application/vnd.ms-cab-compressed",
    tgz: "application/x-gzip-compressed",

    // audio/video
    mp3: "audio/mpeg",
    qt: "video/quicktime",
    mov: "video/quicktime",

    // adobe
    pdf: "application/pdf",
    psd: "image/vnd.adobe.photoshop",
    ai: "application/postscript",
    eps: "application/postscript",
    ps: "application/postscript",

    // ms office
    doc: "application/msword",
    rtf: "application/rtf",
    xls: "application/vnd.ms-excel",
    ppt: "application/vnd.ms-powerpoint",

    // open office
    odt: "application/vnd.oasis.opendocument.text",
    ods: "application/vnd.oasis.opendocument.spreadsheet",
  };

  if (extension in mimeTypes) {
    return mimeTypes[extension];
  }

  return "";
}
