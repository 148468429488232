import React from 'react'
import {Spinner} from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'

type Sizes = 'fs-1' | 'fs-2' | 'fs-3' | 'fs-4' | 'fs-5' | 'fs-6' | 'fs-7'

interface IICONBUTTON {
  icon: string
  iconSize?: Sizes
  onClick?: (e?: any) => void
  className?: string
  pending?: boolean
  tooltip?:string
  place?:'top' | 'right' | 'bottom' | 'left';
  [key: string]: any
  id?: number | string
}

const IconButton = ({
  icon,
  iconSize = 'fs-3',
  onClick,
  className,
  pending,
  tooltip,
  place = "left",
  id,
  ...rest
}: IICONBUTTON) => {
  return (
    <>
      <button
        onClick={onClick}
        data-tip
        data-for={!!id ? `btn-${id}` : `btn-${icon}-${tooltip}`}
        className={`btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px w-md-30px h-md-30px ${className}`}
        {...rest}
        type="button"
      >
        {pending ? (
          <Spinner animation='grow' size='sm' variant='primary' />
        ) : (
          <span className={`${icon} ${iconSize}`} />
        )}
      </button>
      {tooltip&&
      <ReactTooltip id={!!id ? `btn-${id}` : `btn-${icon}-${tooltip}`}  effect='solid' place={place}>
        {tooltip}
      </ReactTooltip>}
    </>
  )
}

export default IconButton
