export const types = {
    GET_SERVER: 'GET_SERVER',
    GET_SERVER_SUCCESS: 'GET_SERVER_SUCCESS',
    GET_SERVER_ERROR: 'GET_SERVER_ERROR',

    GET_SERVER_ip: 'GET_SERVER_ip',
    GET_SERVER_ip_SUCCESS: 'GET_SERVER_ip_SUCCESS',
    GET_SERVER_ip_ERROR: 'GET_SERVER_ip_ERROR',

    
    GET_SERVER_UPTIME: 'GET_SERVER_UPTIME',
    GET_SERVER_UPTIME_SUCCESS: 'GET_SERVER_UPTIME_SUCCESS',
    GET_SERVER_UPTIME_ERROR: 'GET_SERVER_UPTIME_ERROR',

    
    GET_SERVER_INCIDENT: 'GET_SERVER_INCIDENT',
    GET_SERVER_INCIDENT_SUCCESS: 'GET_SERVER_INCIDENT_SUCCESS',
    GET_SERVER_INCIDENT_ERROR: 'GET_SERVER_INCIDENT_ERROR',
    
    
    REQUEST_TEST_ADD_NEW_CHECK: 'REQUEST_TEST_ADD_NEW_CHECK',
    REQUEST_TEST_ADD_NEW_CHECK_SUCCESS: 'REQUEST_TEST_ADD_NEW_CHECK_SUCCESS',
    REQUEST_TEST_ADD_NEW_CHECK_ERROR: 'REQUEST_TEST_ADD_NEW_CHECK_ERROR',

    REQUEST_ADD_NEW_CHECK: 'REQUEST_ADD_NEW_CHECK',
    REQUEST_ADD_NEW_CHECK_SUCCESS: 'REQUEST_ADD_NEW_CHECK_SUCCESS',
    REQUEST_ADD_NEW_CHECK_ERROR: 'REQUEST_ADD_NEW_CHECK_ERROR',

    REQUEST_REMOVE_CHECK: 'REQUEST_REMOVE_CHECK',
    REQUEST_REMOVE_CHECK_SUCCESS: 'REQUEST_REMOVE_CHECK_SUCCESS',
    REQUEST_REMOVE_CHECK_ERROR: 'REQUEST_REMOVE_CHECK_ERROR',

    
    REQUEST_GET_CHECKS: 'REQUEST_GET_CHECKS',
    REQUEST_GET_CHECKS_SUCCESS: 'REQUEST_GET_CHECKS_SUCCESS',
    REQUEST_GET_CHECKS_ERROR: 'REQUEST_GET_CHECKS_ERROR',

    
    REQUEST_PAUSE_CHECKS: 'REQUEST_PAUSE_CHECKS',
    REQUEST_PAUSE_CHECKS_SUCCESS: 'REQUEST_PAUSE_CHECKS_SUCCESS',
    REQUEST_PAUSE_CHECKS_ERROR: 'REQUEST_PAUSE_CHECKS_ERROR',

    REQUEST_UNPAUSE_CHECKS: 'REQUEST_UNPAUSE_CHECKS',
    REQUEST_UNPAUSE_CHECKS_SUCCESS: 'REQUEST_UNPAUSE_CHECKS_SUCCESS',
    REQUEST_UNPAUSE_CHECKS_ERROR: 'REQUEST_UNPAUSE_CHECKS_ERROR',

    
    REQUEST_UPDATE_CHECKS: 'REQUEST_UPDATE_CHECKS',
    REQUEST_UPDATE_CHECKS_SUCCESS: 'REQUEST_UPDATE_CHECKS_SUCCESS',
    REQUEST_UPDATE_CHECKS_ERROR: 'REQUEST_UPDATE_CHECKS_ERROR',

    REQUEST_GRAPH: 'REQUEST_GRAPH',
    REQUEST_GRAPH_SUCCESS: 'REQUEST_GRAPH_SUCCESS',
    REQUEST_GRAPH_ERROR: 'REQUEST_GRAPH_ERROR',

    
    REQUEST_GRAPH_CPU_LOAD_AVERAGE: 'REQUEST_GRAPH_CPU_LOAD_AVERAGE',
    REQUEST_GRAPH_CPU_LOAD_AVERAGE_SUCCESS: 'REQUEST_GRAPH_CPU_LOAD_AVERAGE_SUCCESS',
    REQUEST_GRAPH_CPU_LOAD_AVERAGE_ERROR: 'REQUEST_GRAPH_CPU_LOAD_AVERAGE_ERROR',

    REQUEST_GRAPH_CPU_USAGE: 'REQUEST_GRAPH_CPU_USAGE',
    REQUEST_GRAPH_CPU_USAGE_SUCCESS: 'REQUEST_GRAPH_CPU_USAGE_SUCCESS',
    REQUEST_GRAPH_CPU_USAGE_ERROR: 'REQUEST_GRAPH_CPU_USAGE_ERROR',

    REQUEST_GRAPH_DISK_USAGE: 'REQUEST_GRAPH_DISK_USAGE',
    REQUEST_GRAPH_DISK_USAGE_SUCCESS: 'REQUEST_GRAPH_DISK_USAGE_SUCCESS',
    REQUEST_GRAPH_DISK_USAGE_ERROR: 'REQUEST_GRAPH_DISK_USAGE_ERROR',

    REQUEST_GRAPH_MEMORY_USAGE: 'REQUEST_GRAPH_MEMORY_USAGE',
    REQUEST_GRAPH_MEMORY_USAGE_SUCCESS: 'REQUEST_GRAPH_MEMORY_USAGE_SUCCESS',
    REQUEST_GRAPH_MEMORY_USAGE_ERROR: 'REQUEST_GRAPH_MEMORY_USAGE_ERROR',

    REQUEST_GRAPH_NETWORK: 'REQUEST_GRAPH_NETWORK',
    REQUEST_GRAPH_NETWORK_SUCCESS: 'REQUEST_GRAPH_NETWORK_SUCCESS',
    REQUEST_GRAPH_NETWORK_ERROR: 'REQUEST_GRAPH_NETWORK_ERROR',

    
    REQUEST_GRAPH_DISK_IO: 'REQUEST_GRAPH_DISK_IO',
    REQUEST_GRAPH_DISK_IO_SUCCESS: 'REQUEST_GRAPH_DISK_IO_SUCCESS',
    REQUEST_GRAPH_DISK_IO_ERROR: 'REQUEST_GRAPH_DISK_IO_ERROR',

    REQUEST_SERVER_CHECK_GRAPH: 'REQUEST_SERVER_CHECK_GRAPH',
    REQUEST_SERVER_CHECK_GRAPH_SUCCESS: 'REQUEST_SERVER_CHECK_GRAPH_SUCCESS',
    REQUEST_SERVER_CHECK_GRAPH_ERROR: 'REQUEST_SERVER_CHECK_GRAPH_ERROR',

    
    REQUEST_SERVER_TRIGGERS: 'REQUEST_SERVER_TRIGGERS',
    REQUEST_SERVER_TRIGGERS_SUCCESS: 'REQUEST_SERVER_TRIGGERS_SUCCESS',
    REQUEST_SERVER_TRIGGERS_ERROR: 'REQUEST_SERVER_TRIGGERS_ERROR',

    REQUEST_SERVER_CHECKS_TRIGGERS: 'REQUEST_SERVER_CHECKS_TRIGGERS',
    REQUEST_SERVER_CHECKS_TRIGGERS_SUCCESS: 'REQUEST_SERVER_CHECKS_TRIGGERS_SUCCESS',
    REQUEST_SERVER_CHECKS_TRIGGERS_ERROR: 'REQUEST_SERVER_CHECKS_TRIGGERS_ERROR',

    REQUEST_CREATE_TRIGGERS: 'REQUEST_CREATE_TRIGGERS',
    REQUEST_CREATE_TRIGGERS_SUCCESS: 'REQUEST_CREATE_TRIGGERS_SUCCESS',
    REQUEST_CREATE_TRIGGERS_ERROR: 'REQUEST_CREATE_TRIGGERS_ERROR',

    REQUEST_DELETE_TRIGGERS: 'REQUEST_DELETE_TRIGGERS',
    REQUEST_DELETE_TRIGGERS_SUCCESS: 'REQUEST_DELETE_TRIGGERS_SUCCESS',
    REQUEST_DELETE_TRIGGERS_ERROR: 'REQUEST_DELETE_TRIGGERS_ERROR',
    
    REQUEST_UPDATE_TRIGGERS: 'REQUEST_UPDATE_TRIGGERS',
    REQUEST_UPDATE_TRIGGERS_SUCCESS: 'REQUEST_UPDATE_TRIGGERS_SUCCESS',
    REQUEST_UPDATE_TRIGGERS_ERROR: 'REQUEST_UPDATE_TRIGGERS_ERROR',
    
    REQUEST_CREATE_CHECK_TRIGGERS: 'REQUEST_CREATE_CHECK_TRIGGERS',
    REQUEST_CREATE_CHECK_TRIGGERS_SUCCESS: 'REQUEST_CREATE_CHECK_TRIGGERS_SUCCESS',
    REQUEST_CREATE_CHECK_TRIGGERS_ERROR: 'REQUEST_CREATE_CHECK_TRIGGERS_ERROR',
    
    
    REQUEST_UPDATE_CHECK_TRIGGERS: 'REQUEST_UPDATE_CHECK_TRIGGERS',
    REQUEST_UPDATE_CHECK_TRIGGERS_SUCCESS: 'REQUEST_UPDATE_CHECK_TRIGGERS_SUCCESS',
    REQUEST_UPDATE_CHECK_TRIGGERS_ERROR: 'REQUEST_UPDATE_CHECK_TRIGGERS_ERROR',

    REQUEST_DELETE_CHECK_TRIGGERS: 'REQUEST_DELETE_CHECK_TRIGGERS',
    REQUEST_DELETE_CHECK_TRIGGERS_SUCCESS: 'REQUEST_DELETE_CHECK_TRIGGERS_SUCCESS',
    REQUEST_DELETE_CHECK_TRIGGERS_ERROR: 'REQUEST_DELETE_CHECK_TRIGGERS_ERROR',

    SELECT_CHECK_TO_EDIT: 'SELECT_CHECK_TO_EDIT',

    RESET_SERVICE_OVERVIEW_PROPS: 'RESET_SERVICE_OVERVIEW_PROPS',
    RESET_Graph_PROPS:'RESET_Graph_PROPS',

    RESET_STATISTIC_PROPS:'RESET_STATISTIC_PROPS',
    GET_SERVER_REFETCH:"GET_SERVER_REFETCH"
  
  }
  