import React, { FC } from 'react'
import { ProgressBar } from 'react-bootstrap'

const ProgressWidget: FC<{ val: number, unit: string, min?: string, max?: string }> = ({ val, unit, min, max }) => {
  const variantCal=()=>{
    
    if (val <= 40) {
      return "custom-progress-green";
  }

  if (val > 40 && val <= 60) {
      return "custom-progress-yellow";
  }

  if (val > 60 && val <= 80) {
      return "custom-progress-orange";
  }

  if (val > 80) {
      return "custom-progress-red" ;
  }
  }
  return (
    <div>
      <div>
        <ProgressBar
          animated
          // variant={variantCal()}
          now={val}
          label={<span className='text-white'>{`${val}${unit}`}</span>}
          className={variantCal()}
        ></ProgressBar>
      </div>
      <div className='d-flex justify-content-between' >
        <div>{min}</div>
        <div>{max}</div>

      </div>
    </div>
  )
}

export default ProgressWidget