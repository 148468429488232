import React from 'react'
import useUser from './useUser'

const useIsDigitalOceanUser = () => {
 const user=useUser()

 return user?.provider==="Digitalocean"

}

export default useIsDigitalOceanUser