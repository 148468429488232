import React from 'react'
import {Action} from './reducer'
import ServerSelect from './ServerSelect'
import CheckSelect from './CheckSelect'
interface IStep2 {
  memoizedDispatch: (data: Action) => void
  widgetType: string
  moduleId:number[]
  serverId: number | null
  graphAssignMode: boolean
}

const Step2 = (props: IStep2) => {
  const {memoizedDispatch, moduleId, serverId, widgetType,graphAssignMode} = props

  const handleSelectServer = (id: number) => {
    memoizedDispatch({
      type: 'SET_VAL',
      payload: {
        name: 'server_id',
        value: id,
      },
    })
    if (!graphAssignMode) {
      if (serverId!=id) {
        memoizedDispatch({
          type: 'CLEAR_WIDGETS'
         
        })
      }
    }
   
    
  }

  const handleSelectCheck = (data: any) => {
    memoizedDispatch({
      type: 'TOGGLE_CHECK',
      payload: data?.id,
    })

    memoizedDispatch({
      type: 'TOGGLE_WIDGET',
      payload: data
    })
  }

  return (
    <div>
      {widgetType === 'server' ? (
        <ServerSelect serverId={serverId} onSelect={handleSelectServer} />
      ) : (
        <CheckSelect onSelect={handleSelectCheck} moduleIds={moduleId} />
      )}
    </div>
  )
}

export default Step2
