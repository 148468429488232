
import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {getDiskIo} from '../../../services/dashboard'
import {TablesWidget3} from '../../../_metronic/partials/widgets'
import LongTitle from '../../modules/global/UI/LongTitle'
import {byteToHumanReadable, diskIoToHumanReadable} from '../../modules/widgets/components/Common'
import classes from "./style.module.scss"

interface Props {
  onRemove: () => void
}

const TopIO = ({onRemove}:Props) => {
  const intl = useIntl()
  const {data: TopDisk, isLoading} = useQuery(['dashboard-io'], getDiskIo,{
    // refetchInterval:30000, @TODO commented by saman - tempo
  })

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <TablesWidget3
        onClose={onClose}
        isLoading={isLoading}
        title={intl.formatMessage({id: 'TOP.DISK'})}
        className={classes['custom_table']}

        icon={<span className='far fa-save fs-1' />}
        dataLength={TopDisk?.data?.length}

        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '15%'}}>
            {intl.formatMessage({id:"LABELS.OS"})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '55%'}}>
              {intl.formatMessage({id: 'LABELS.LABEL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '20%'}}>
              {intl.formatMessage({id: 'LABELS.TOTAL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1' style={{minWidth: '10%'}}>
            </th>
          </tr>
        }
      >
        {!!TopDisk?.data?.length &&
          TopDisk?.data?.map((disk: Record<string, any>, index: number) => (
            <tr key={disk?.total + index}>
              <td>
                <div className='symbol symbol-40px me-2'>
                  <span className='symbol-label'>
                    <span
                      className={`${
                        disk?.device_platform == 0 ? 'fab fa-linux ' : 'fab fa-windows '
                      }fs-3`}
                    />
                  </span>
                </div>
              </td>
              <td style={{whiteSpace: 'nowrap'}} className='text-dark fw-semobold mb-1 fs-6'>
              <LongTitle title={disk?.device_label} id={'io-d' + index} />

              <span className='text-muted fw-semobold d-block fs-7'>
                {!!disk?.is_outdated && (
                    <>
                      <span 
                       data-tip
                       data-for={disk?.device_id+"-disk"} 
                      className=' fas fa-exclamation-triangle fs-5 text-warning me-2' />
                      <ReactTooltip id={disk?.device_id+"-disk"} className='tooltip-wrapper' place='right'>
                        {intl.formatMessage({id: 'STATUS.OUTDATED'})}
                      </ReactTooltip>
                    </>
                  )}
                  {disk?.device_platform == 0 ? 'Linux' : 'Windows'}
                </span>
              </td>
              <td className='text-dark fw-semobold'>{diskIoToHumanReadable(disk?.total)}</td>
              <td className='text-end p-0'>
                <Link
                  to={`/servers/${disk?.device_id}/overview`}
                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                >
                  <span className='fas fa-arrow-right fs-4' />
                </Link>
              </td>
            </tr>
          ))}
      </TablesWidget3>
    </div>
  )
}

export default React.memo(TopIO)
