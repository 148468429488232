/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'

type Props = {
  className: string
  color: string
  svgIcon?: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  nestedParent?: string
  iconAwesome?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  nestedParent,
  iconAwesome,
}) => {
  const intl = useIntl()

  return (
    <div className={`card  ${className}`}>
      <div className='card-body d-flex flex-column flex-lg-row gap-7 align-items-center'>
        {svgIcon ? (
          <KTSVG path={svgIcon} svgClassName='w-70px h-80px' />
        ) : (
          <span className={`${iconAwesome} d-felx justify-content-center fs-5x  `} />
        )}

        <div>
          <div className={`text-${titleColor} fw-bold fs-5 fs-lg-3 mb-2 `}>{title}</div>

          <div className={`fw-semibold text-${descriptionColor} fs-lg-5`}>{description}</div>
          {nestedParent === 'gettingStarted' && (
            <div className='d-flex  d-sm-none justify-content-end'>
              <span className='btn btn-sm  btn-light btn-secondary fw-bolder mt-6 w-100 text-center '>
                {intl.formatMessage({id: 'PAGE.TITLES.STARTED'})}{' '}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export {StatisticsWidget5}
