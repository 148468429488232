import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import React from 'react'
import {FormattedMessage} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import useMainConfig from '../../Hooks/useMainConfig'
import {DashboardType} from '../../modules/context/CustomizedDashboardCtx'
import PaidBadge from '../../modules/global/PaidBadge'

const ToggleDashboards = ({customizedDashboards,onAdd,limit}: {customizedDashboards: DashboardType[] ,onAdd: () => void, limit:boolean}) => {
  const navigate = useNavigate()
  const params = useParams()
  const selectedRoute = params?.id ?? 'dashboard'
  const { is_free_plan } = useMainConfig()

  return (
    <ToggleButtonGroup color='primary' value={selectedRoute} exclusive aria-label='Platform' sx={{flexWrap:"wrap",
    "& .Mui-selected":{
      backgroundColor:"inherit !important"
    }
    }}>
      <ToggleButton  value='dashboard' onClick={() => navigate(`/dashboard`)}>
        <FormattedMessage id='LABELS.MAIN.DASHBOARD' />
      </ToggleButton>
      {customizedDashboards?.map((dashboard) => {
        return (
          <ToggleButton
            value={`${dashboard?.id}`}
            onClick={() => navigate(`/customized-dashboard/${dashboard?.id}`)}
            key={dashboard?.id}
          >
            {dashboard?.name}
          </ToggleButton>
        )
      })}
      <ToggleButton  value='add' onClick={onAdd}>
       + <FormattedMessage id='ACTIONS.ADD' />
       {(is_free_plan || limit )&& <PaidBadge />}
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default React.memo(ToggleDashboards)
