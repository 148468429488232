import React, {useState} from 'react'

type IconBtnType = {
  name: string
  icon: React.ReactNode
}

interface IIconButtonGroup {
  iconBtnList: IconBtnType[]
  className?: string
  onChange:(name:string)=>void
  selected?:string
}

const IconButtonGroup = (props: IIconButtonGroup) => {
  const {iconBtnList,  className,onChange,selected} = props

 

  return (
    <div className={`d-flex  ${className} border border-custom rounded overflow-hidden`}>
      {iconBtnList?.map((iconBtn,index:number) => {
        return (
          <div
            key={iconBtn.name}
            className={`${
              selected === iconBtn.name ? ' bg-primary text-white' : ' '
            } bg-hover-primary py-3 px-4 d-flex align-items-center 
            ${iconBtnList?.length-index!=1 ?"border border-top-0 border-bottom-0 border-left-0 border-right-1 border-custom":""}
            `}
            onClick={()=>onChange(iconBtn.name)}
          >
            {iconBtn.icon}
          </div>
        )
      })}
    </div>
  )
}

export default IconButtonGroup
