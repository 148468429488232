import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {Toolbar} from './components/toolbar/Toolbar'
import {RightToolbar} from '../partials/layout/RightToolbar'
// import {ScrollTop} from './components/ScrollTop'
import {Content} from './components/Content'
import {PageDataProvider} from './core'
import {useLocation} from 'react-router-dom'
// import {
//   ActivityDrawer,
//   Main,
//   InviteUsers,
//   UpgradePlan,
//   ThemeModeProvider,
// } from '../partials'
import {MenuComponent} from '../assets/ts/components'
import { RequestFeedback } from '../../app/modules/feedback/RequestFeedback'
import MyErrorBoundary from '../../ErrorBoundary'
import useUser from '../../app/Hooks/useUser'
import Congrate from '../../app/modules/global/UI/Congrate'
import useAccountWizardListener from '../../app/Hooks/useAccountWizardListener'
import { CustomizedDashboardProvider } from '../../app/modules/context/CustomizedDashboardCtx'
import { useMediaQuery } from '@mui/material'
import { MetricsPageProvider } from '../../app/modules/context/MetricsPageProvider'

const MasterLayout = () => {
  const location = useLocation()
  useAccountWizardListener()
  const matches=useMediaQuery("(min-width:600px)")

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])


  return (
    <PageDataProvider>
          <CustomizedDashboardProvider>

        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideDefault />
          <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
            <HeaderWrapper />

            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <Toolbar />
              <div className='post d-flex flex-column-fluid' id='kt_post'>
                <Content>
                  <MyErrorBoundary>
                  <Outlet />
                 {matches&&    <Congrate/>}
                  </MyErrorBoundary>
                </Content>
              </div>
            </div>
            <Footer />
          </div>
        </div>

        {/* begin:: Drawers */}
        <RightToolbar />
        {/* end:: Drawers */}
        {/* <ActivityDrawer/> */}
        {/* begin:: Modals */}
        {/* <Main /> */}
        {/* <InviteUsers /> */}
        {/* <UpgradePlan /> */}
        <RequestFeedback />
        {/* end:: Modals */}
        {/* <ScrollTop /> */}
        </CustomizedDashboardProvider>
    </PageDataProvider>
  )
}

export {MasterLayout}
