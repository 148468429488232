import React, {FC, useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {notify} from '../../../../../../helpers'
import {updateHeartbeat} from '../../../../../../services/Server'
import {RootState} from '../../../../../../setup'
import MiniSpinner from '../../../../../modules/widgets/components/MiniSpinner'
import {PauseSVG, UnPauseSVG} from '../../../../../modules/widgets/components/SVGIcons'
import {
  reqGetChecksAction,
  reqUnpauseCheckAction,
  resetServerDetailProps,
} from '../../../../../store/serverOverview/actions'
import { unpauseServerChecks } from '../../../../../store/serverOverview/saga/requests'
import PauseTypes from './PauseTypes'
import './style.scss'

const PauseCheck: FC<{item: any; type: string; className?: string; refetch?: any , openIncidentsCount?:number,openResolveModalHand?:()=>void,incidents?:any[]}> = ({
  item,
  type,
  className = '',
  refetch,
  openIncidentsCount,
  openResolveModalHand,
  incidents
}) => {
  const success: boolean = useSelector<RootState>(
    (state) => state.serverView.unpauseCheckSuccess
  ) as boolean
  const waiting: boolean = useSelector<RootState>(
    (state) => state.serverView.pendingUnpauseCheck
  ) as boolean

  const [displayTypes, setDisplayTypes] = useState(false)
  const [selectedItemToUnpause, setSelectedItemToUnpause] = useState()
  const dispatch = useDispatch()
  const param = useParams()
  const intl = useIntl()

  const wrapperRef = useRef(null)
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (success && selectedItemToUnpause === item) {
      refetch?.()
      notify({
        type: 'success',
        message: intl.formatMessage(
          {id: 'NOTIFICATIONS.SUCCESS_UNPAUSE_CHECK'},
          {
            type: String(item?.type_human_readable)?.toUpperCase(),
            host: item?.data?.host ? `(${item?.data?.host})` : ``,
          }
        ),
      })
      dispatch(resetServerDetailProps())
      dispatch(reqGetChecksAction(param.id))
    }
  }, [success])

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current) {
      const current: any = wrapperRef?.current
      if (!current.contains(event.target)) {
        setDisplayTypes(false)
      }
    }
  }

  const handleUnpause = async () => {
    try {
      if (type == '30') {
        await updateHeartbeat(item.id, {
          check: {
            pause_until: 0,
          },
        })
      } else {
        // dispatch(reqUnpauseCheckAction(param.id, item.id))
        await unpauseServerChecks(param.id as string,item.id)
      }
      notify({
        type: 'success',
        message: intl.formatMessage(
          {id: 'NOTIFICATIONS.SUCCESS_UNPAUSE_CHECK'},
          {type: '', host: item?.data.host}
        ),
      })
      refetch?.()
      dispatch(resetServerDetailProps())
      dispatch(reqGetChecksAction(param.id))
    } catch (err: any) {
      const error = err.response && (err.response || err.message)
      let data = error?.data
      console.error('data error ', data)
      if (!data) {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
        })
      }
      let messages = []

      for (let i in data) {
        messages.push(
          <>
            {data[i]}
            <br />
          </>
        )
      }
      notify({type: 'error', message: <>{messages}</>})
    }
  }

  return (
    <span className={` pause-spn  text-dark ms-3 ${className}`} ref={wrapperRef}>
      {waiting && selectedItemToUnpause === item && (
        <MiniSpinner animation='border' variant='secondary' />
      )}
      {item?.is_paused && (
        <span
          className='cursor-pointer btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px text-dark'
          onClick={handleUnpause}
        >
          <span className='fas fa-play fs-3 ' />
        </span>
      )}
      <span
        className='btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px'
        onClick={() => setDisplayTypes(true)}
      >
        <span className='fs-3 fas fa-pause' />
      </span>

      <PauseTypes item={item} incidents={incidents} type={type} displayTypes={displayTypes} refetch={refetch} openIncidentsCount={openIncidentsCount} openResolveModalHand={openResolveModalHand} />
    </span>
  )
}

export default PauseCheck
