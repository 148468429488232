import React, {useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {useSelector} from 'react-redux'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import {notify} from '../../../helpers'
import { getLastTimestamps } from '../../../helpers/timeHelper'
import {createHeartbeatXitogent} from '../../../services/Server'
import {getServerUptime} from '../../../services/uptime'
import {RootState} from '../../../setup'
import {getNotificationRolesAction} from '../../store/serverStore/actions'
import HeartbeatGraph from '../upTimeOverview/HeartbeatGraph'

const HeartbeatTimeline = () => {
  const params = useParams()
  const id = params?.id as string
  const dispatch = useDispatch()
  const {
    data: serverUptime,
    refetch,
    isLoading: LoadingUptime,
  }: any = useQuery(['server-uptime', id], () => getServerUptime(id), {
    cacheTime: 0,
    staleTime: 0,
  })
  const roles = useSelector<RootState>((state) => state.servers.roles) as any
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const findXitogentCheck: () => Record<string, any> | undefined = () => {
    return serverUptime?.find((item: any) => item.type === 30 && !!item?.check?.xitogent_heartbeat)
  }

  useEffect(() => {
    dispatch(getNotificationRolesAction())
  }, [])
  

  

  const createXitogent = async () => {
    try {
      setLoading(true)
      await createHeartbeatXitogent({
        is_agent: true,
        server_id: id,
        check: {
          label: 'Xitogent-heartbeat',
        },
        trigger: {
          data: {
            fault_tolerance: 1,
            notification_roles: [roles?.['0']?.id],
          },
        },
      })
      refetch()
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      const error = err.response && (err.response || err.message)
      let data = error?.data
      if (!data) {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
        })
      }
      let messages = []

      for (let i in data) {
        messages.push(
          <>
            {data[i]}
            <br />
          </>
        )
      }
      notify({type: 'error', message: <>{messages}</>})
    }
  }

  if (LoadingUptime) {
    return (
      <div className='card d-flex justify-content-center align-items-center text-center py-6 mb-4'>
        <Spinner animation='grow' variant='primary' />
      </div>
    )
  }

  return (
    <div className='card px-4 mb-4 py-0 pt-4'>
      {findXitogentCheck() ? (
        <>
        <h6 className='m-0'>
            {intl.formatMessage({id:"HEARTBEAT.SERVER.XITOGENT.TIMELINE"})}
        </h6>
         <HeartbeatGraph
         
          checkId={findXitogentCheck()?.check?.id}

          selectFilter={false}
          range="last_thirty_days"
        />
        </>
       
      ) : (
        <HeartbeatCreate loading={loading} onCreate={createXitogent} />
      )}
    </div>
  )
}

const HeartbeatCreate = (props: {onCreate: () => void; loading: boolean}) => {
  const intl = useIntl()
  const {onCreate, loading} = props
  return (
    <section className='text-dark d-flex flex-column flex-lg-row gap-6 align-items-center fs-6 pb-4'>
      <p className='m-0'>{intl.formatMessage({id: 'HEARTBEAT.SERVER.XITOGENT'})}</p>
      <button className='btn btn-primary btn-sm w-100px' onClick={onCreate}>
        {!loading ? (
          intl.formatMessage({id: 'ACTIONS.CREATE'})
        ) : (
          <Spinner animation='grow' size={'sm'} />
        )}
      </button>
    </section>
  )
}

export default HeartbeatTimeline
