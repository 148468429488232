import {
    types
  } from "./actionTypes";

  export const getModuleCountAction = () => ({
    type: types.GET_MODULE_COUNT,
  });

  
  export const getUptimeAction = (params:string) => ({
    type: types.GET_UPTIME,
    params:params
  });

  export const getUptimeReFetchAction = (params:string) => ({
    type: types.GET_UPTIME_REFETCH,
    params:params
  });
  
  export const addNewCheckAction = (params:any) => ({
    type: types.ADD_NEW_CHECK,
    params
  });

  
  export const resetUptimePropsAction = () => ({
    type: types.RESET_UPTIME_PROPS,
  });
