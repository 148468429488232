import React from 'react'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {getTopCpu} from '../../../services/dashboard'
import {TablesWidget1} from '../../../_metronic/partials/widgets'
import LongTitle from '../../modules/global/UI/LongTitle'
import classes from "./style.module.scss"

interface Props {
  onRemove: () => void
}

const TopCpu = ({onRemove}:Props) => {
  const intl = useIntl()

  const {data: topCpus, isLoading} = useQuery(['dashboard-top-cpu'], getTopCpu,{
    // refetchIntervalInBackground:true,
    // refetchInterval:30000, @TODO commented by saman - tempo

  })

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <TablesWidget1
        isLoading={isLoading}
        title={intl.formatMessage({id: 'TOP.CPU.DASHBOARD'})}
        icon={<span className='fas fa-microchip fs-1' />}
        onClose={onClose}
        dataLength={topCpus?.data?.length}
        className={classes['custom_table']}

        thead={
          <tr>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{maxWidth: '10%', width: '10%'}}>
              {intl.formatMessage({id:"LABELS.OS"})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{maxWidth: '35%', width: '35%'}}>
              {intl.formatMessage({id: 'LABELS.LABEL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{maxWidth: '40%', width: '40%'}}>
              {intl.formatMessage({id: 'LABELS.TOTAL'})}
            </th>
            <th className='bg-transparent fw-bold fs-7 p-2 px-1 ' style={{maxWidth: '15%', width: '15%'}}>
            </th>
          </tr>
        }
      >
        {!!topCpus?.data?.length &&
          topCpus.data.map((cpu: Record<string, any>,i:number) => (
            <tr key={cpu?.device_id + cpu?.cpu_usage}>
              <td>
                <div className='symbol symbol-40px me-2'>
                  <span className='symbol-label'>
                    <span
                      className={`${
                        cpu?.device_platform == 0 ? 'fab fa-linux ' : 'fab fa-windows '
                      }fs-3`}
                    />
                  </span>
                </div>
              </td>

              <td>
                <span className='text-dark fw-bold  mb-1 fs-6'>
                <LongTitle title={cpu?.device_label} id={'cpu-d' + i} maxWidth />
                  </span>
                <span className='text-muted fw-semobold d-block fs-7'>
                {!!cpu?.is_outdated && (
                    <>
                      <span 
                       data-tip
                       data-for={cpu?.device_id+"-cpu"} 
                      className=' fas fa-exclamation-triangle fs-5 text-warning me-2' />
                      <ReactTooltip id={cpu?.device_id+"-cpu"} className='tooltip-wrapper' place='right'>
                        {intl.formatMessage({id: 'STATUS.OUTDATED'})}
                      </ReactTooltip>
                    </>
                  )}
                  {cpu?.device_platform == 0 ? 'Linux' : 'Windows'}
                </span>
              </td>
              <td className='p-0'>
                <div className='d-flex flex-column w-100 me-2'>
                  <div className='d-flex flex-stack mb-2'>
                    <span className='text-dark me-2 fs-7 fw-semobold'>{cpu?.cpu_usage}%</span>
                  </div>
                  <div className='progress h-6px w-100'>
                    <div
                      className='progress-bar bg-primary'
                      role='progressbar'
                      style={{width: `${cpu?.cpu_usage}%`}}
                    ></div>
                  </div>
                </div>
              </td>
              <td className='text-end p-0'>
                <Link
                  to={`/servers/${cpu?.device_id}/overview`}
                  className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                >
                  <span className='fas fa-arrow-right fs-4' />
                </Link>
              </td>
            </tr>
          ))}
      </TablesWidget1>
    </div>
  )
}

export default React.memo(TopCpu)
