import {FC, useState} from 'react'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'
import {RequestFeedback} from '../../../../app/modules/feedback/RequestFeedback'
import ReactTooltip from 'react-tooltip'
import { FormattedMessage } from 'react-intl'

type Props = {
  to: string
  title: React.ReactNode
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  target?: string
  modal?: string
  handLerFunc?: () => void
  hasAccess?: boolean
  isBeta?: boolean
  isChild?: boolean
  activeShortcut?:boolean
  keyId?:number | string
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  target = '_self',
  modal,
  handLerFunc,
  hasAccess = true,
  isBeta = false,
  isChild = false,
  activeShortcut,
  keyId
}) => {
  const location = useLocation()
  let urlLocation = useParams()['*']
  let rootLocation = urlLocation?.substring(0, urlLocation.indexOf('/'))
  if (rootLocation?.length == 0) {
    rootLocation = location.pathname.substring(1)
  }
  const [isHovered, setIsHovered] = useState(false);

  const isActive = checkIsActive(location.pathname, to,isChild)
  const {config} = useLayout()
  const {aside} = config

  const modalAttributes = modal ? {'data-bs-toggle': 'modal', 'data-bs-target': modal} : {}
  if (!hasAccess) {
    return <></>
  }
  return (
    <>
      <div className='menu-item'>
        {!handLerFunc ? (
          <Link
            className={clsx('menu-link text-hover-primary without-sub pe-3', {active: isActive})}
            to={to}
            target={target}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon' data-tip data-for={`btn-${to}-${title}`}>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
            <span className='menu-title '>
              {title}{' '}
              {isBeta && <strong className='badge badge-primary ms-2 text-white'>Beta</strong>}
              {(isHovered && keyId) && (
                <div className='d-flex align-items-center ms-auto gap-1'>
                  <strong className='border text-center px-1 text-white fs-9' style={{ borderRadius: '2px' }}>X</strong> {' + '} 
                  <strong className='border text-center text-white w-15px fs-9' style={{borderRadius:'2px'}}>{keyId}</strong>
                </div>
              )}
            </span>
          </Link>
        ) : (
          <span className={clsx('menu-link text-hover-primary without-sub', {active: isActive})} onClick={handLerFunc}>
            {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )}
            {icon && aside.menuIcon === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
            <span className='menu-title'>{title}</span>
          </span>
        )}
        {children}
      </div>
      {modal && <RequestFeedback />}
      <ReactTooltip id={`btn-${to}-${title}`} effect='solid' place={'right'}>
        {title}
      </ReactTooltip>
    </>
  )
}

export {AsideMenuItem}
