import React from 'react'
import {DataGrid} from '@mui/x-data-grid'
import {styled} from '@mui/material/styles'
import {Box} from '@mui/material'
import {Spinner} from 'react-bootstrap'
import {FormattedMessage} from 'react-intl'

// Define a custom styled DataGrid component
const StyledDataGrid = styled(DataGrid)(({theme}) => ({
  overflowX: 'hidden',
  // '& .MuiDataGrid-overlayWrapperInner': {
  //   minHeight: '200px',
  // },
  "& .MuiDataGrid-columnHeaders":{
    backgroundColor: 'var(--kt-page-bg)',
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'var(--kt-secondary)',
    '&:hover': {
      backgroundColor: 'var(--kt-secondary)',
    },
  },
  '& .MuiDataGrid-columnHeader:focus , .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:active': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiTablePagination-selectLabel': {
    margin: 0,
  },
  '& .MuiTablePagination-displayedRows': {
    margin: 0,
  },
}))

const LoadingTableOverlay = (props: any) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height='100%'
      component={'div'}
      // sx={{
      //   backgroundColor: 'rgba(0,0,0,0.1)',
      // }}
      {...props} // Pass down any additional props
    >
      <Spinner animation='grow' variant='primary' />
    </Box>
  )
}

const noRowsData = (props: any) => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      width='100%'
      height='100%'
      component={'div'}
      {...props} // Pass down any additional props
    >
      <h6 className='p-3 text-center m-0'>
        <FormattedMessage id='NO_DATA_TABLE' />
      </h6>
    </Box>
  )
}

export {StyledDataGrid, LoadingTableOverlay, noRowsData}
