/* eslint-disable jsx-a11y/anchor-is-valid */

import {Dispatch, FC, useState, useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useQuery, useQueryClient} from 'react-query'
import {shallowEqual, useSelector} from 'react-redux'

import useUser from '../../../../app/Hooks/useUser'
import {getProfile} from '../../../../app/modules/auth/core/_requests'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {isHasAccess} from '../../../../helpers/isHasAccess'
import {
  getAvatarLogs,
  getHeaderAccountLogs,
  getHeaderIncidents,
} from '../../../../services/HeaderNotifications'
import {RootState} from '../../../../setup'
import {useSocket} from '../../../../socket/client/client'
import {toAbsoluteUrl} from '../../../helpers'
import IncidentItem from './IncidentItem'
import LogsItem from './LogsItem'
import './style.scss'

let getLength = function (unread_notifications: number) {
  return unread_notifications > 10 ? unread_notifications : 10
}
let isFetchProfile = false

interface Props {
  updateNotification: (dataNotif: {incident: number; logs: number}) => void
}

const HeaderNotificationsMenu: FC<Props> = ({updateNotification}) => {
  const queryClient = useQueryClient()
  const [updateProfile, setUpdateProfile] = useState(false)

  const {unread_device_logs_count, unread_incidents_count}: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  const user = useUser()

  const intl = useIntl()
  const {data: incidents} = useQuery(
    ['incidents-header'],
    () => getHeaderIncidents(getLength(unread_incidents_count)),
    {
      enabled: isHasAccess(null, user),
    }
  )

  const {data: logs} = useQuery(
    ['logs-header'],
    () => getHeaderAccountLogs(getLength(unread_device_logs_count)),
    {
      enabled: isHasAccess('report', user),
    }
  )

  const incidentHandler = (e: any) => {
   

    if (!e.incident.send_notification) {
      return null
    }
    if (!isFetchProfile) {
      isFetchProfile = true

      setUpdateProfile(true)
      isFetchProfile = false
    }
    queryClient.setQueryData('incidents-header', (oldData: Record<string, any>) => {
      const cloneData = {...oldData}

      //prevent add duplicate item
      const isFound = cloneData?.data?.findIndex?.((item: Record<string, any>) => {
        return item.id == e.incident.id && item.status_name == e.incident.status_name
      })
      if (isFound >= 0) {
        return cloneData
      }
     

      cloneData?.data?.unshift(e.incident)
      return cloneData
    })
  }

  useSocket({
    type: 'CLIENT_BROWSER_NOTIFICATION',
    callBack: incidentHandler,
  })

  const logsHandler = async (e: any) => {
    if (!isFetchProfile) {
      isFetchProfile = true

      setUpdateProfile(true)
      isFetchProfile = false
    }

    queryClient.setQueryData('logs-header', (oldData: Record<string, any>) => {
      const cloneData = {...oldData}

      // prevent add duplicate item
      const isFound = cloneData.data.findIndex((item: Record<string, any>) => {
        return item.id == e.log.id && item.description == e.log.description
      })
      if (isFound >= 0) {
        return cloneData
      }

      cloneData.data.unshift(e.log)
      return cloneData
    })
  }

  useSocket({
    type: 'DEVICE_LOGS',
    callBack: logsHandler,
  })

  useEffect(() => {
    const getProfileHand = async () => {
      const {data} = await getProfile()

      updateNotification({
        incident: data?.unread_incidents_count,
        logs: data?.unread_device_logs_count,
      })
      setUpdateProfile(false)
    }

    if (updateProfile) {
      getProfileHand()
    }
  }, [updateProfile])

  const [avatars, setAvatars] = useState<Record<string, string>>({})

  const logsWithAvatars = useMemo(() => {
    const uniqueIds = Array.from(new Set(logs?.data.map((log: any) => log.user_id)))

    uniqueIds.forEach((id: any) => {
      const log = logs?.data.find((log: any) => log.user_id === id)
      const isClient = !!!log?.contact_id

      if (!avatars[id]) {
        getAvatarLogs(id, isClient).then((response: any) => {
          const newAvatar = URL.createObjectURL(response.data)
          setAvatars((prevAvatars) => ({...prevAvatars, [id]: newAvatar}))
        })
      }
    })

    return logs?.data.map((log: any) => ({
      ...log,
      avatar: avatars[log.user_id] || null,
    }))
  }, [logs, avatars])

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px'
      data-kt-menu='true'
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{backgroundImage: `url('${toAbsoluteUrl('/media/misc/pattern-1.jpg')}')`}}
      >
        <h3 className='fw-bold px-9 mt-10 mb-6'>
          {intl.formatMessage({id: 'NOTIFICATION.HEADER.TITLE'})}
        </h3>

        <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-4'>
          <li className='nav-item w-50 '>
            <a
              className='nav-link opacity-75 opacity-state-100 pb-4 w-100 text-center d-block active'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_1'
            >
              {intl.formatMessage({id: 'NOTIFICATION.HEADER.TAB.INCIDENTS'})}
            </a>
          </li>

          <li className='nav-item w-50'>
            <a
              className='nav-link opacity-75 opacity-state-100 pb-4 w-100 text-center d-block'
              data-bs-toggle='tab'
              href='#kt_topbar_notifications_3'
            >
              {intl.formatMessage({id: 'NOTIFICATION.HEADER.TAB.ACCOUNTLOGS'})}
            </a>
          </li>
        </ul>
      </div>

      <div className='tab-content'>
        <div className='tab-pane fade show active' id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-4'>
            {isHasAccess(null, user) ? (
              <>
                {!!incidents?.data?.length ? (
                  incidents?.data?.map((incident: Record<string, any>) => (
                    <IncidentItem
                      key={incident.id}
                      details={incident.details}
                      id={incident.device_id}
                      created_at_human_readable={incident.created_at_human_readable}
                      status_name={
                        incident.module_id == 0 && incident.status_name == 'Down'
                          ? 'Warning'
                          : incident.status_name
                      }
                      device_label={incident.device_object?.label}
                      type_human_readable={incident.type_human_readable}
                      is_check={!!incident?.module_id}
                      module_id={incident?.module_id}
                      incident={incident}
                    />
                  ))
                ) : (
                  <h6 className='text-center mt-6'>
                    {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.EMPTY'})}
                  </h6>
                )}
              </>
            ) : (
              <h4 className='text-center py-4'>{intl.formatMessage({id: 'ERROR.403.TITLE'})}</h4>
            )}
          </div>
        </div>

        <div className='tab-pane fade' id='kt_topbar_notifications_3' role='tabpanel'>
          <div className='scroll-y mh-325px my-5 px-4'>
            {isHasAccess('report', user) ? (
              <>
                {!!logsWithAvatars?.length ? (
                  logsWithAvatars?.map((log: Record<string, any>) => {
                    return (
                      <LogsItem
                        key={log.id}
                        description={log.description}
                        name={log.user_name}
                        date={log.created_at}
                        id={log.user_id}
                        isClient={!!!log.contact_id}
                        avatar={avatars[log.user_id]}
                      />
                    )
                  })
                ) : (
                  <h6 className='text-center py-4'>
                    {intl.formatMessage({id: 'BILLING_OPTIONS_SHOW.EMPTY'})}
                  </h6>
                )}
              </>
            ) : (
              <h4 className='text-center py-4'>{intl.formatMessage({id: 'ERROR.403.TITLE'})}</h4>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
