/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {KTSVG} from '../../../helpers'
import {useEffect, useState} from 'react'
import ReactMarkdown from 'react-markdown'
import {Spinner} from 'react-bootstrap'
import {availableSteps} from '../../../../helpers/ToutringSteps'
const PageComponent: FC<any> = ({location}) => {
  const [content, setContent] = useState('')

  const getHelpData = async () => {
    try {
      const helpData = await fetch(`/ui/md/en/${location}.md`)
      const contentType = helpData.headers.get("Content-Type");
      if (contentType !== 'application/octet-stream' && !contentType?.includes('text/markdown')) {
        throw new Error('File must be a Markdown file');
      }
      const textData = await helpData.text()
      setContent(textData)
    } catch (error) {

      const generalHelp = await fetch(`/ui/md/en/general.md`)
      const textData = await generalHelp.text()
      setContent(textData)
    }
  }

  useEffect(() => {
    getHelpData()
  }, [])

  return (
    <div className='post'>
      <ReactMarkdown children={content} />
      {!content && (
        <div className='h-150px text-center d-flex justify-content-center align-items-center'>
          <Spinner variant='primary' animation='grow' />
        </div>
      )}
    </div>
  )
}

const ToolbarHelperModal: FC<{
  show: boolean
  onHide: () => void
  location: string | undefined
  parent: string
}> = (props) => {
  const intl = useIntl()
  const startTouringHandler = () => {
    props.onHide()
    setTimeout(() => {
      document.getElementById(`${props.location}`)?.click()
    }, 111)
  }
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {intl.formatMessage({id: `MENU.${props.parent?.toUpperCase()}`})}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <p>{intl.formatMessage({ id: `SERVICEMODAL.CONTENT_${props.location?.toUpperCase()}` })}</p> */}
        <div style={{maxHeight: '50vh', overflowY: 'scroll'}}>
          <PageComponent location={props.location} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {availableSteps.includes(props.location || 'null') && (
          <Button onClick={startTouringHandler}>
            <KTSVG
              path='/media/icons/duotune/arrows/arr027.svg'
              className='svg-icon-2 svg-icon-gray-500'
            />
            {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_TOUR'})}
          </Button>
        )}
        <Button variant='secondary' onClick={props.onHide}>
          {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export {ToolbarHelperModal}
