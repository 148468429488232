import React from 'react'

interface Props {
  text?: string
  node?: React.ReactNode
  btnEL?:React.ReactNode
  className?:string
  align?:string
}

const InfoBox = ({text, node,btnEL,className="mt-10",align="align-items-center"}: Props) => {
  return (
    <div className={`${className} alert alert-secondary text-dark d-flex ${align} p-5  border-0 `}>
      <span className='svg-icon svg-icon-2hx svg-icon-primary me-3'>
        <span className='fas fa-info-circle text-dark fs-3 me-2' />
      </span>

      <div className='d-flex flex-column fs-7'>
        <p className='m-0'>{text} {btnEL}</p>
        {node}
      </div>
    </div>
  )
}

export default InfoBox
