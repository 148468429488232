import { FC } from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/en";

import enMessages from "./messages/en.json";

const allMessages = {
  en: enMessages,
};

const I18nProvider: FC<{children:any}> = ({ children }) => {
  const locale = useLang();
  const messages = Object(allMessages[locale]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };

