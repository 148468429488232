
import  Http, { _axios, _axiosV1 } from '../../core/inceptor'

export const getGroups = () => _axios.get<any>('/groups')
export const addGroup = (params: FormData) => _axios.post(`/groups`, params)
export const updateGroupRequest = (params: any,id:number) => _axios.put(`/groups/${id}`, params)
export const deleteGroupRequest = (id:number) => _axios.delete(`/groups/${id}`)


// @TODO API v1 to v2
export const unassignGroupServerRequest = (params:any) => 
_axiosV1.post(`/client/infrastructure/groups/devices/unassign`,params)
export const assignGroupServerRequest = (id:number,params:any) => 
_axiosV1.post(`/client/infrastructure/groups/${id}/devices/assign`,params)




export const updateGroupReq=(data:Record<string,any>)=>Http.put(`/groups/${data.id}`,data)

