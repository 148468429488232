import React, {FC, useEffect, useState} from 'react'
import Joyride, {CallBackProps, STATUS, Step} from 'react-joyride'
import {useSelector} from 'react-redux'
import {useLocation, useParams} from 'react-router'
import useSetState from 'use-setstate'
import ProfileDetail from '../app/store/touringUser/ProfileDetail'
import ProfileDetailUpdate from '../app/store/touringUser/ProfileDetailUpdate'
import {RootState} from '../setup'
import {
  dashboardSteps,
  incidentsSteps,
  serversSteps,
  uptimeSteps,
  notificationRolesSteps,
  billingSteps,
} from './ToutringSteps'
interface State {
  run: boolean
  steps?: any
}

const stepsDynamicSelector = (location: any) => {
  switch (location) {
    case 'dashboard':
      return dashboardSteps

    case 'incidents':
      return incidentsSteps

    case 'servers':
      return serversSteps

    case 'uptime':
      return uptimeSteps

    case 'notificationRoles':
      return notificationRolesSteps

    case 'billing':
      return billingSteps

    default:
      return []
  }
}
const TouringHelper = () => {
  const user: any = useSelector<RootState>(({auth}) => auth.user) as any
  const mutateProfileDetails = ProfileDetail()
  const profileUpdateMutation = ProfileDetailUpdate()
  const location = useLocation()
  const [profileUpdatedState, setProfileUpdatedState] = useState(user)
  let urlLocation = useParams()['*']
  let rootLocation = urlLocation?.substring(0, urlLocation.indexOf('/'))
  if (rootLocation?.length == 0) {
    rootLocation = location.pathname.substring(1)
  }
  const [{run, steps}, setState] = useSetState<State>({
    run: false,
    steps: stepsDynamicSelector(rootLocation),
  })
  const handleJoyrideCallback = (data: CallBackProps) => {
    const {status, type} = data
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
    if (finishedStatuses.includes(status)) {
      setState({run: false})
      let mutateData = {...profileUpdatedState, ui_tour: {...profileUpdatedState?.ui_tour}}
      mutateData.ui_tour[rootLocation || 'tour'] = true
      mutateData.ui_tour[`${rootLocation}_Length` || 'tour'] =
      stepsDynamicSelector(rootLocation).length
      profileUpdateMutation.mutate(mutateData)
      // USE line below to reset the ui_tour state in database
      // profileUpdateMutation.mutate({...user, ui_tour:null})
      mutateProfileDetails.mutate('', {
        onSuccess: (data: any) => {
          setProfileUpdatedState(data)
        },
      })
    }
  }
  const profileDetailHandler = () => {
    mutateProfileDetails.mutate('', {
      onSuccess: (data: any) => {
        setProfileUpdatedState(data)
        if (!data.ui_tour)
          setState({
            run: true,
          })
        if (data.ui_tour) {
          if (!data.ui_tour[rootLocation || 'tour'])
          setState({
            run: true,
          })
          if (data.ui_tour[`${rootLocation}_Length` || 'tour']) {
            let currentStep = [...stepsDynamicSelector(rootLocation)]
            let prevTouringStepLength = data.ui_tour[`${rootLocation}_Length` || 'tour']
            let currentTouringStepLength = stepsDynamicSelector(rootLocation).length
            if (currentTouringStepLength > prevTouringStepLength) {
              while (prevTouringStepLength--) currentStep.pop()
              setState({
                run: true,
                steps: currentStep,
              })
            } else {
              setState({
                run: false,
              })
            }
          }
        }
      },
    })
  }

  const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    setState({
      run: true,
    })
  }

  useEffect(() => {
    profileDetailHandler()
  }, [])


  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        scrollOffset={180}
        callback={handleJoyrideCallback}
        disableScrollParentFix={true}
        scrollToFirstStep={false}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Last',
          next: 'Next',
          open: 'Open',
          skip: 'Skip',
        }}
        styles={{
          options: {
            primaryColor: '#3F4254',
          },
        }}
      />
      <span id={`${rootLocation}`} onClick={handleClickStart} style={{visibility: 'hidden'}}></span>
    </>
  )
}

export default TouringHelper
