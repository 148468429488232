import {types} from './actionTypes'
import { AddGroupParams } from './types'

export const getGroupsAction = () => ({
  type: types.GET_GROUPS,
})

export const addGroupAction = (params:FormData) => ({
  type: types.ADD_GROUP,
  params,
})


export const updateGroupAction = (params:any,id:number) => ({
  type: types.UPDATE_GROUP,
  params,id
})


export const deleteGroupAction = (id:number) => ({
  type: types.DELETE_GROUP_REQUEST,
  id
})


export const assignServersToGroupAction = (id:number,params:any) => ({
  type: types.ASSIGN_GROUP_SERVERS_REQUEST,
  id,params
})


export const unassignServersToGroupAction = (params:any) => ({
  type: types.UNASSIGN_GROUP_SERVERS_REQUEST,
  params
})


export const resetProps = () => ({
  type: types.RESET_PROPS,
})


export const resetGroupProps = () => ({
  type: types.RESET_PROPS,
})
