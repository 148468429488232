/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Spinner} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'

type Props = {
  className: string
  title?: string
  desc?: React.ReactNode
  onClose?: () => void
  icon?: React.ReactNode
  isLoading?: boolean
  bg?: string
  listData?: Record<string, any>[]
  keyGetter: {
    title: string
    description: string
    value: string
    unit: string
  }
}

const ListsWidget6: React.FC<Props> = ({
  className,
  icon,
  title,
  desc,
  onClose,
  bg,
  isLoading,
  listData=[],
  keyGetter,
}) => {
  return (
    <div className={`card ${className} border`}>
      {/* begin::Header */}
      <div className='card-header border-0 p-4 align-items-start '>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-5 mb-1 d-flex gap-2 align-items-center'>
            {' '}
            {icon} {title}
          </span>
          <span className='text-muted mt-3 fw-semobold fs-6'>{desc}</span>
        </h3>
        <div className='card-toolbar m-0'>
          {/* begin::Menu */}
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={onClose}
          >
            {/* <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' /> */}
            <span className='fas fa-times fs-3' />
          </button>
          {/* <Dropdown1 /> */}
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body p-4 py-3'>
        {isLoading ? (
          <div className='h-150px text-center d-flex justify-content-center align-items-center'>
            <Spinner variant='primary' animation='grow' />
          </div>
        ) :listData.length<=0?<h5 className='text-center py-5'>
        <FormattedMessage id="NO_DATA_TABLE" />
      </h5>: (
          listData?.map((data: Record<string, any>) => {
            return (
              <div key={data?.id} className={`d-flex align-items-center ${bg} rounded p-5 mb-7`}>
                <span className='svg-icon svg-icon-warning me-5'>
                  <KTSVG path='/media/icons/duotune/abstract/abs027.svg' className='svg-icon-1' />
                </span>

                <div className='flex-grow-1 me-2'>
                  <span className='fw-bold text-gray-800 fs-6'>{data?.[keyGetter?.title]}</span>
                  <span className='text-muted fw-semobold d-block fs-7 mt-1'>
                    {data?.[keyGetter?.description]}
                  </span>
                </div>

                <span className='fw-bold text-warning py-1'>
                  {keyGetter?.unit}
                  {data?.[keyGetter?.value]}
                </span>
              </div>
            )
          })
        )}
      </div>
    </div>
  )
}

export {ListsWidget6}
