import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  ReactElement,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {getProfile} from './_requests'
import * as authMain from '../../auth/redux/AuthRedux'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import {Global} from '../../../../services/Global'
import {getMainServiceAction} from '../../../store/billing/actions'
import useDashboardValues, {DashboardTypes} from '../../context/DashboardCtx'
import {isHasAccess} from '../../../../helpers/isHasAccess'
import {CheckWidgets} from './AccessWidgets'
import {injectWidgets} from '../../../../helpers/helperFunctions'
import {useJwtToken} from '../../../Hooks/useJWTFromCookie'
import store from '../../../../setup/redux/Store'
import {useLocalStorage} from '../../../Hooks/useLocalStorage'
import moment from 'moment'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import { getDashboards } from '../../../../services/customizedDashboard'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<{children: any}> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<{children: any}> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const dispatch = useDispatch()
  const data = useSelector<RootState, any>((data) => data, shallowEqual)
  const [state, dispatchWidgets] = useDashboardValues()

  const {config} = data
  const accessToken = data?.auth?.accessToken
  const {injectJWT} = useJwtToken()

  const [searchParams] = useSearchParams()
  const zapierUrl = searchParams.get('zapier')
  const cliUrl = searchParams.get('cli_login')
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.search as any
  const expireTime: any = moment().add(5, 'minutes').valueOf()
  const expireTimeCli: any = moment().add(2, 'minutes').valueOf()
  const localZapier: any = localStorage.getItem('zapier-integration')
  const localCli: any = localStorage.getItem('cli-integration')
  const localExpireDate: any = JSON.parse(localZapier)?.expireTime
  const localExpireDateXcli: any = JSON.parse(localCli)?.expireTime
  const [zapierValue, setZapierValue] = useLocalStorage<any>('zapier-integration', {})
  const [cliValue, setCliValue] = useLocalStorage<any>('cli-integration', {})
  const [user , setUser] = useState()
  const now = moment().valueOf()
  
  const isCompleteProfile: any = localStorage.getItem('complete-profile')
  const checkCreateAndProvider = (user: any) => {
    if (!!user) {
      const now = moment.utc();
      const createdAt = moment(user?.created_at);
      const secondsDifference = now.diff(createdAt, 'seconds');
      return secondsDifference < 12660 && user.provider != null;
    } else {
       return false
    }
  } 

  const defaultDash = localStorage.getItem('defaultDash')
  const dashboardLocation = !!defaultDash ? `/customized-dashboard/${defaultDash}` : '/dashboard'
  
  useEffect(() => {
    if (!!zapierUrl && !localExpireDate) {
      setZapierValue({
        ...{
          params: locationState,
          expireTime: localExpireDate ? localExpireDate : expireTime,
        },
        ...zapierValue,
      })
    }

    if (!!cliUrl && !localExpireDateXcli) {
      setCliValue({
        ...{
          params: locationState,
          expireTime: localExpireDateXcli ? localExpireDateXcli : expireTimeCli,
        },
        ...cliValue,
      })
    }
  }, [locationState])

  useEffect(() => {
    if (zapierValue.expireTime < now) {
      navigate(location.pathname == 'zapier' ? dashboardLocation : '/auth')
      setZapierValue({})
    }
  }, [zapierValue.expireTime])


  useEffect(() => {
    if (cliValue.expireTime < now) {
      navigate(location.pathname == 'cli' ? dashboardLocation : '/auth')
      setCliValue({})
    }
  }, [cliValue.expireTime])

  useEffect(() => {
    if ((!isCompleteProfile && checkCreateAndProvider(user))) {
      navigate('/complete-profile')
    } else if (!checkCreateAndProvider(user) && location.pathname == '/complete-profile') {
      navigate(dashboardLocation)
    }
  }, [user])
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application

  useEffect(() => {
    const requestUser = async () => {
      await injectJWT()
      const accessToken = store.getState()?.auth?.accessToken
      try {
        if (!didRequest.current && accessToken) {
          const { data } = await getProfile()
          setUser(data)

          if (!defaultDash) {
            const customizedDashboards:any = await getDashboards()
            const defaultId = customizedDashboards?.find((item: any) => item.default === 1)?.id;
            localStorage.setItem('defaultDash',defaultId)
          }

          let configWidgets = injectWidgets({
            ...data?.widgets,
          })

          let widgetsStatus = CheckWidgets(configWidgets, data)

          dispatchWidgets({
            type: DashboardTypes.INITIAL,
            payload: {...configWidgets, widgets: widgetsStatus},
          })
          dispatch(authMain.actions.setUser(data))

          dispatch(
            authMain.actions.setWizard({
              completedSteps: data?.wizard
                ? JSON.parse(data?.wizard)?.completedSteps
                : {
                    email_confirmation: false,
                    monitoring: false,
                    sub_account: false,
                    notification_roles: false,
                    status_page: false,
                    report_email: false,
                    complete_profile: false,
                    upgrade_plan: false,
                    mobile_app: false,
                  },
              // numberOfStepsCompleted: data?.wizard
              //   ? JSON.parse(data?.wizard)?.numberOfStepsCompleted
              //   : [],
            })
          )
          if (data) {
            setCurrentUser(data)
            dispatch(getMainServiceAction())
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => {
        didRequest.current = true
      }
    }

    // if (localStorage.getItem('currentUser')) {
    //   setCurrentUser(JSON.parse(localStorage.getItem('currentUser') || '{}') )
    // }else {
    //   logout()
    //   setShowSplashScreen(false)
    // }
    requestUser()

    // if (auth && auth.api_token) {
    //   requestUser()
    // } else {
    //   logout()
    //   setShowSplashScreen(false)
    // }
    //eslint-disable-next-line
  }, [])



  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
