import {Popper} from '@mui/material'
import React, {ReactNode, useRef,useEffect} from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

interface IFilterPopup {
  children: ReactNode
  activeFilters: number
  width?:string
  onClear?:()=>void
}

const FilterPopup = (props: IFilterPopup) => {
  const {children, activeFilters,width="250px",onClear} = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const popperRef = useRef<HTMLDivElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  const closePopper = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.stopPropagation()
      const rootElement = document.getElementById('root');
      if (
        popperRef.current &&
        !popperRef.current.contains(event.target as Node) &&
        rootElement &&
        rootElement.contains(event.target as Node)
      ) {
        closePopper();
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <button
        className={`btn btn-icon position-relative bg-hover-primary text-hover-white btn-custom w-30px h-30px w-md-40px h-md-40px ${
          open ? 'bg-primary text-white' : ''
        } `}
        aria-describedby={id}
        onClick={handleClick}
      >
        <span className='fas fa-filter fs-3' />
        {activeFilters > 0 && (
        <span
          className='position-absolute top-0 start-0 translate-middle badge rounded-pill bg-primary'
         
        >
          {activeFilters}
        </span>
      )}
      </button>
   
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        style={{zIndex: 999}}
        ref={popperRef}
      >
        <section
          style={{width}}
          className='bg-custom p-4 rounded d-flex flex-column gap-4 min-width-300px border border-custom'
        >
          {children}

          <Button size='sm' onClick={onClear}>
               <FormattedMessage id='ACTIONS.CLEAR_FILTERS'/>
          </Button>
        </section>
      </Popper>
    </>
  )
}

export default FilterPopup
