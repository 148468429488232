import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'

interface Props {
  browser: string
  city: string
  country: string
  ip: string
  last_activity: string
  platform: string
}

const ViewSession: FC<Props> = ({browser, city, country, ip, last_activity, platform}) => {
  return (
    <div className='alert alert-secondary border-dark text-dark d-flex flex-column flex-md-row justify-content-between fs-7 p-2'>
      <div>
        <div className='d-flex'>
          <span className='text-dark  pe-1 fw-bold'>
            <FormattedMessage id='ACCOUNT.SESSION.PLATFORM' /> : {' '}
          </span>
          <span className='text-dark '>
            {platform}
          </span>
        </div>
        <div className='d-flex my-2'>
          <span className='text-dark  pe-1 fw-bold'>
            <FormattedMessage id='ACCOUNT.SESSION.IP' /> : {' '}
          </span>
          <span className='text-dark '>
            {ip}
          </span>
        </div>
        <div className='d-flex'>
          <span className='text-dark  pe-1 fw-bold'>
            <FormattedMessage id='ACCOUNT.SESSION.CITY' /> : {' '}
          </span>
          <span className='text-dark '>
            {city}
          </span>
        </div>
      </div>

      <div>
        <div className='d-flex'>
          <span className='text-dark  pe-1 fw-bold'>
            <FormattedMessage id='ACCOUNT.SESSION.COUNTRY' /> : {' '}
          </span>
          <span className='text-dark '>
            {country}
          </span>
        </div>

        <div className='d-flex my-2'>
          <span className='text-dark  pe-1 fw-bold'>
            <FormattedMessage id='ACCOUNT.SESSION.BROWSER' /> : {' '}
          </span>
          <span className='text-dark '>
            {browser}
          </span>
        </div>

        <div className='d-flex'>
          <span className='text-dark  pe-1 fw-bold'>
            <FormattedMessage id='ACCOUNT.SESSION.LAST' /> : {' '}
          </span>
          <span className='text-dark '>
            {last_activity}
          </span>
        </div>
      </div>
    </div>
  )
}

export default ViewSession
