/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import ClientAvatar from '../../../../app/modules/client/ClientAvatar'
import {FormattedMessage, useIntl} from 'react-intl'
import {clearAllStoredData} from '../../../../helpers/ClearAllStorage'
import useUser from '../../../../app/Hooks/useUser'
import {isHasAccess} from '../../../../helpers/isHasAccess'
import {useLocalStorage} from '../../../../app/Hooks/useLocalStorage'

const HeaderUserMenu: FC<{id: number}> = ({id}) => {
  const {currentUser, logout} = useAuth()
  const intl = useIntl()
  const naviagte = useNavigate()
  const user = useUser()
  const [value, setValue] = useLocalStorage<any>('zapier-integration', {})

  const logoutHand = () => {
    const localZapier:any = localStorage.getItem('zapier-integration')
    clearAllStoredData()
    if (!!localZapier) {
      setValue(JSON.parse(localZapier))
    }
    // logout()
    window.location.href = process.env.REACT_APP_API_URL_NOT_VERSION + '/logout'
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{currentUser?.name}</div>
          </div>
        </div>
      </div>

      <div className='separator my-2 '></div>

      <div className='menu-item px-5'>
        <Link to={'/profile/overview'} className='menu-link px-5'>
          <h6>{intl.formatMessage({id: 'PROFILE.TITLE'})}</h6>
        </Link>
      </div>
      <div className='menu-item px-5'>
        <Link to={'/account'} className='menu-link px-5'>
          <h6>{intl.formatMessage({id: 'MENU.ACCOUNT'})}</h6>
        </Link>
      </div>

      {isHasAccess('billing', user) && (
        <div className='menu-item px-5'>
          <Link to={'/billing'} className='menu-link px-5'>
            <h6>{intl.formatMessage({id: 'MENU.BILLING'})}</h6>
          </Link>
        </div>
      )}
      {isHasAccess('report', user) && (
        <div className='menu-item px-5'>
          <Link to={'/logs/account'} className='menu-link px-5'>
            <h6>{intl.formatMessage({id: 'PAGE.TITLES.ACCOUNT_LOGS'})}</h6>
          </Link>
        </div>
      )}
      <div className='menu-item px-5'>
        <a href={'https://xitoring.com/docs/'} className='menu-link px-5' target='_blank'>
          <h6>{intl.formatMessage({id: 'MENU.DOCUMENTATION'})}</h6>
        </a>
      </div>
      {isHasAccess('tickets', user) && (
        <div className='menu-item px-5'>
          <Link to={'/tickets'} className='menu-link px-5'>
            <h6>{intl.formatMessage({id: 'MENU.TICKETS'})}</h6>
          </Link>
        </div>
      )}

      <div className='separator my-2'></div>

      <div className='menu-item px-5'>
        <a onClick={logoutHand} className='menu-link px-5'>
          <h6>{intl.formatMessage({id: 'LOGS.TABS.LOGOUT.TITLE'})}</h6>
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
