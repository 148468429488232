import {listen} from '../socketHook'
import {createSocketConnection} from '../setup'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'

type Options = {
  type:
    | 'EMAIL_CONFIRMED'
    | 'CLIENT_UPDATED'
    | 'CLIENT_BROWSER_NOTIFICATION'
    | 'DEVICE_LOGS'
    | 'SERVICE_UPDATE'
    | 'INVOICE_CREATED'
    | 'ORDER_CREATED'
    | 'ORDER_UPDATE'
    | 'SMS_INCREASED'
    | 'INVOICE_UPDATED'
    | 'ORDER_CANCEL'
    | 'INVOICE_REFUNDED'
    | 'ACCOUNT_BALANCE_UPDATED'
  callBack: (payload: any) => void
}

export const useSocket = ({type, callBack}: Options) => {
  const {user} = useSelector<RootState, any>(({auth}) => auth, shallowEqual)
  const channel = 'access' in user ? `contact.${user.id}` : `client.${user.id}`

  createSocketConnection()

  switch (type) {
    case 'EMAIL_CONFIRMED': {
      return listen(channel, 'EmailConfirmed', callBack)
    }
    case 'CLIENT_UPDATED': {
      return listen(channel, 'ClientUpdated', callBack)
    }
    case 'CLIENT_BROWSER_NOTIFICATION': {
      return listen(channel, 'DeviceIncidentCreated', callBack)
    }
    case 'DEVICE_LOGS': {
      return listen(channel, 'DeviceLogCreated', callBack)
    }
    case 'SERVICE_UPDATE': {
      return listen(channel, 'ServiceUpdated', callBack)
    }
    case 'INVOICE_CREATED': {
      return listen(channel, 'InvoiceCreated', callBack)
    }
    case 'ORDER_CREATED': {
      return listen(channel, 'OrderCreated', callBack)
    }
    case 'ORDER_UPDATE': {
      return listen(channel, 'OrderUpdated', callBack)
    }
    case 'SMS_INCREASED': {
      return listen(channel, 'SmsCreditIncreased', callBack)
    }
    case 'INVOICE_UPDATED': {
      return listen(channel, 'InvoiceUpdated', callBack)
    }
    case 'ORDER_CANCEL': {
      return listen(channel, 'OrderCanceled', callBack)
    }
    case 'INVOICE_REFUNDED': {
      return listen(channel, 'InvoiceRefunded', callBack)
    }
    case 'ACCOUNT_BALANCE_UPDATED': {
      return listen(channel, 'ClientAccountBalanceUpdated', callBack)
    }
  }
}
