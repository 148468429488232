import {
  types
} from "./actionTypes";

const initialState: any = {
  pending: false,
  success: false,
  error: null,
  settings: null,
  pendingAdd: false,
  errorAdd: '',
  successAdd: false,
  pendingEdit: false,
  errorEdit: '',
  successEdit: false,
  pendingDelete: false,
  errorDelete: '',
  successDelete: false
};


const notificationRolesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_NOTIFICATION_ROLES_MAIN_SETTINGS:
      return {
        ...state,
        pending: true,
      };
    case types.GET_NOTIFICATION_ROLES_MAIN_SETTINGS_SUCCESS:

      return {
        ...state,
        pending: false,
        settings: action.payload,
      };
    case types.GET_NOTIFICATION_ROLES_MAIN_SETTINGS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case types.REQUEST_NOTIFICATION_ROLES_TEST:
      return {
        ...state,
        pendingTest: true,
        errorTest: '',
        successTest: false
      };
    case types.REQUEST_NOTIFICATION_ROLES_TEST_SUCCESS:

      return {
        ...state,
        pendingTest: false,
        successTest:true,
        testResult: action.payload,
      };
    case types.REQUEST_NOTIFICATION_ROLES_TEST_ERROR:
      return {
        ...state,
        pendingTest: false,
        errorTest: action.error,
      };

    case types.REQUEST_NOTIFICATION_ROLES_ADD:
      return {
        ...state,
        pendingAdd: true,
        errorAdd: '',
        successAdd: false,
      };
    case types.REQUEST_NOTIFICATION_ROLES_ADD_SUCCESS:

      return {
        ...state,
        pendingAdd: false,
        successAdd: true,

      };
    case types.REQUEST_NOTIFICATION_ROLES_ADD_ERROR:
      
      return {
        ...state,
        pendingAdd: false,
        errorAdd: action.error,
      };

    case types.REQUEST_NOTIFICATION_ROLES_EDIT:
      return {
        ...state,
        pendingEdit: true,
        errorEdit: '',
        successEdit: false
      };
    case types.REQUEST_NOTIFICATION_ROLES_EDIT_SUCCESS:

      return {
        ...state,
        pendingEdit: false,
        successEdit: true
      };
    case types.REQUEST_NOTIFICATION_ROLES_EDIT_ERROR:
      return {
        ...state,
        pendingEdit: false,
        errorEdit: action.error,
      };


      case types.REQUEST_NOTIFICATION_ROLES_DELETE:
        return {
          ...state,
          pendingDelete: true,
          errorDelete: '',
          successDelete: false
        };
      case types.REQUEST_NOTIFICATION_ROLES_DELETE_SUCCESS:
  
        return {
          ...state,
          pendingDelete: false,
          successDelete: true
        };
      case types.REQUEST_NOTIFICATION_ROLES_DELETE_ERROR:
        return {
          ...state,
          pendingDelete: false,
          errorDelete: action.error,
        };
  
        
      case types.REQUEST_NOTIFICATION_ROLE:
        return {
          ...state,
          pendingGet: true,
          errorGet: '',
          successGet: false
        };
      case types.REQUEST_NOTIFICATION_ROLE_SUCCESS:
  
        return {
          ...state,
          pendingGet: false,
          notificationRole:action.payload
        };
      case types.REQUEST_NOTIFICATION_ROLE_ERROR:
        return {
          ...state,
          pendingGet: false,
          errorGet: action.error,
        };
        case types.RESET_NOTIFICATION_ROLES_PROPS:
          return {
            ...state,
            pendingAdd: false,
            errorAdd: '',
            successAdd: false,
            pendingEdit: false,
            errorEdit: '',
            successEdit: false,
            pendingDelete: false,
            errorDelete: '',
            successDelete: false,
            pendingTest: false,
            errorTest: '',
            successTest: false
          };
  


    default:
      return {
        ...state,
       
      };
  }
};

export default notificationRolesReducer