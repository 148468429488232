import {TextField} from '@mui/material'
import React from 'react'
import TypeChooser from './TypeChooser'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import {useIntl} from 'react-intl'
import { Action } from './reducer'
import InfoBox from '../../../modules/global/InfoBox'
interface IStep1 {
  name: string
  type: string
  widgetType: string
  memoizedDispatch:(data: Action) => void
  editMode:boolean
  graphAssignMode:boolean
}

const Step1 = (props: IStep1) => {
  const {name, type, widgetType,memoizedDispatch,editMode,graphAssignMode} = props
  const intl = useIntl()

  const onChooseType = (value: Record<string, any>) => {
    memoizedDispatch({
        type:"SET_VAL",
        payload:{
            name:"type",
            value:value?.value
        }
    })
  }

  const handleChangeType = (event: React.MouseEvent<HTMLElement>, newType: string) => {
    memoizedDispatch({
        type:"SET_VAL",
        payload:{
            name:"widget_type",
            value:newType
        }
    })
  }

  const handleChangeName =(event: React.ChangeEvent<HTMLInputElement>)=>{
    memoizedDispatch({
        type:"SET_VAL",
        payload:{
            name:"name",
            value:event.target.value
        }
    })
  }

  return (
    <div>
     {!graphAssignMode&& <TextField
        id='outlined-basic'
        label={intl.formatMessage({id: 'LABELS.NAME'})}
        variant='outlined'
        fullWidth
        autoComplete='off'
        value={name}
        onChange={handleChangeName}
      />}
      {/* <TypeChooser value={type} onClick={onChooseType} /> */}

     {graphAssignMode&& <InfoBox
        node={
          <div>
            <p className='m-0'>{intl.formatMessage({id: 'LABELS.INFO.ASSIGN.GRAPH'})}</p>
          </div>
        }
        className='m-0 mt-4'
      />}
      <div className='mt-5'>
        <h4>{graphAssignMode ?intl.formatMessage({id:"LABELS.GRAPH.TYPE"}) : intl.formatMessage({id: 'LABELS.WIDGET.SECTION'})}</h4>
        <ToggleButtonGroup
          color='primary'
          value={widgetType}
          exclusive
          onChange={handleChangeType}
          aria-label='Platform'
          disabled={editMode}
        >
          <ToggleButton value='server'>{intl.formatMessage({id: 'LABELS.SERVER'})}</ToggleButton>
          <ToggleButton value='check' >{intl.formatMessage({id: 'MENU.UPTIME'})}</ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  )
}

export default Step1
