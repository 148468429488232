import {
    types
  } from "./actionTypes";

  const initialState: any = {
    pending: false,
    error: null,
    key:'',
    list:[],
    roles:[],
    getNotificationError:null,
    pendingUpdateServer: false,
    successUpdateServer:false,
    errorUpdateServer:'',
    pendingDeleteServer: false,
    successDeleteServer:false,
    errorDeleteServer:'',
    pendingMoveServer: false,
    successMoveServer:false,
    errorMoveServer:'',
    pendingPauseServer: false,
    successPauseServer:false,
    errorPauseServer:''
  };
  

  const serverReducer= (state = initialState, action: any) => {
    switch (action.type) {
      case types.GET_SERVERS:
        return {
          ...state,
          pending: true,
          list:[]
        };
        case types.GET_SERVERS_REFETCH:
          return {
            ...state,
            pending: false,
          };
      case types.GET_SERVERS_SUCCESS:
        return {
          ...state,
          pending: false,
          list:action.payload.data,
          error: null
        };
      case types.GET_SERVERS_ERROR:
        return {
          ...state,
          pending: false,
          error: action.error,
        };

        case types.GET_AGENTKEY:
          return {
            ...state,
            pending: true,
          };
        case types.GET_AGENTKEY_SUCCESS:
          return {
            ...state,
            pending: false,
            key:action.payload,
            error: null
          };
        case types.GET_AGENTKEY_ERROR:
          return {
            ...state,
            pending: false,
            error: action.error,
          };
         
          
        case types.GET_NOTIFICATIONROLES:
          return {
            ...state,
            pending: true,
          };
        case types.GET_NOTIFICATIONROLES_SUCCESS:
          return {
            ...state,
            pending: false,
            roles:action.payload.data,
            error: null
          };
        case types.GET_NOTIFICATIONROLES_ERROR:
          return {
            ...state,
            pending: false,
            getNotificationError: "error",
          };

          
        case types.UPDATE_SERVER_REQUEST:
          return {
            ...state,
            pendingUpdateServer: true,
            successUpdateServer:false,
            errorUpdateServer:''
          };
        case types.UPDATE_SERVER_REQUEST_SUCCESS:
          return {
            ...state,
            pendingUpdateServer: false,
            successUpdateServer:true,

          };
        case types.UPDATE_SERVER_REQUEST_ERROR:
          return {
            ...state,
            errorUpdateServer: action.error,
          };


    
          case types.DELETE_SERVER_REQUEST:
            return {
              ...state,
              pendingDeleteServer: true,
              successDeleteServer:false,
              errorDeleteServer:''
            };
          case types.DELETE_SERVER_REQUEST_SUCCESS:
            return {
              ...state,
              pendingDeleteServer: false,
              successDeleteServer:true,
  
            };
          case types.DELETE_SERVER_REQUEST_ERROR:
            return {
              ...state,
              errorDeleteServer: action.error,
            };

            
    
          case types.MOVE_SERVER_REQUEST:
            return {
              ...state,
              pendingMoveServer: true,
              successMoveServer:false,
              errorMoveServer:''
            };
          case types.MOVE_SERVER_REQUEST_SUCCESS:
            return {
              ...state,
              pendingMoveServer: false,
              successMoveServer:true,
  
            };
          case types.MOVE_SERVER_REQUEST_ERROR:
            return {
              ...state,
              errorMoveServer: action.error,
            };

            
          case types.PAUSE_SERVER_REQUEST:
            return {
              ...state,
              pendingPauseServer: true,
              successPauseServer:false,
              errorPauseServer:''
            };
          case types.PAUSE_SERVER_REQUEST_SUCCESS:
            return {
              ...state,
              pendingPauseServer: false,
              successPauseServer:true,
  
            };
          case types.PAUSE_SERVER_REQUEST_ERROR:
            return {
              ...state,
              errorPauseServer: action.error,
            };


          case types.RESET_SERVER_PROPS:
          return {
            ...state,
            pending: false,
            error: null,
            getNotificationError:null,
            success:false,
            pendingUpdateServer: false,
            successUpdateServer:false,
            errorUpdateServer:'',
            pendingDeleteServer: false,
            successDeleteServer:false,
            errorDeleteServer:'',
            pendingPauseServer: false,
            successPauseServer:false,
            errorPauseServer:'',
            list:[]
          };



      default:
        return {
          ...state,
        };
    }
  };

  export default serverReducer;
