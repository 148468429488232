import React, { useState } from 'react'
import {Col, Row} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import useMainConfig from '../../../Hooks/useMainConfig'
import useServer from '../../../Hooks/useServer'
import WarnAlert from '../../../modules/global/WarnAlert'
import { useWindowSize } from '../../../Hooks/useWindowSize'
import PaidModal from '../../../modules/global/PaidModal'
import { check } from 'prettier'

interface IAddIntegration {
  emptyList?: boolean
  setIsLimitModal: (show:boolean) => void
}

const AddIntegration = (props: IAddIntegration) => {
  const {emptyList, setIsLimitModal} = props
  const intl = useIntl()
  const {isPlanFullyFree} = useMainConfig()
  const navigate = useNavigate()
  const server = useServer()
  const {checkLimittedPlan} = useMainConfig()

  const integrationsList = [
    {
      title: intl.formatMessage({id: 'LABEL.NGINX'}),
      link: 'https://xitoring.com/docs/integrations/nginx/',
      img: '/media/images/logos/nginx.png',
      name: 'Nginx',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.REDIS'}),
      link: 'https://xitoring.com/docs/integrations/redis/',
      img: '/media/images/logos/redis.png',
      name: 'Redis',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.KEYDB'}),
      link: 'https://xitoring.com/docs/integrations/keydb/',
      img: '/media/images/logos/keydb.png',
      name: 'Keydb',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABEL.APACHE'}),
      link: 'https://xitoring.com/docs/integrations/apache/',
      img: '/media/images/logos/apache.png',
      name: 'Apache',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.FPM'}),
      link: 'https://xitoring.com/docs/integrations/php-fpm/',
      img: '/media/images/logos/fpm.png',
      name: 'Fpm',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.SUPERVISOR'}),
      link: 'https://xitoring.com/docs/integrations/supervisor/',
      img: '/media/images/logos/terminal.png',
      name: 'Supervisor',
      limit: checkLimittedPlan('server_integrations'),
      isExist: server?.description?.type !== 'windows',
    },
    {
      title: intl.formatMessage({id: 'LABELS.DOCKER'}),
      link: 'https://xitoring.com/docs/integrations/docker/',
      img: '/media/images/logos/docker.png',
      name: 'Docker',
      limit: checkLimittedPlan('server_integrations_docker'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.NETSTAT'}),
      link: 'https://xitoring.com/docs/integrations/netstat/',
      img: '/media/images/logos/netstat.webp',
      name: 'Netstat',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.MYSQL'}),
      link: 'https://xitoring.com/docs/integrations/mysql/',
      img: '/media/images/logos/mysql.png',
      name: 'Mysql',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.HAPROXY'}),
      link: 'https://xitoring.com/docs/integrations/haproxy/',
      img: '/media/images/logos/haproxy.png',
      name: 'HAProxy',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.MONGO'}),
      link: 'https://xitoring.com/docs/integrations/mongodb/',
      img: '/media/images/logos/mongodb.png',
      name: 'Mongo',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.IIS'}),
      link: 'https://xitoring.com/docs/integrations/iis/',
      img: '/media/images/logos/iis.png',
      name: 'IIS',
      limit: checkLimittedPlan('server_integrations'),
      isExist: server?.description?.type == 'windows',
    },
    {
      title: intl.formatMessage({id: 'LABELS.POSTGRESQL'}),
      link: 'https://xitoring.com/docs/integrations/postgreSQL/',
      img: '/media/images/logos/postgreSQL.png',
      name: 'Postgresql',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.RABBITMQ'}),
      link: 'https://xitoring.com/docs/integrations/rabbitmq/',
      img: '/media/images/logos/rabbitmq.png',
      name: 'RabbitMQ',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.DISK.HEALTH'}),
      link: 'https://xitoring.com/docs/integrations/disk-monitoring/',
      img: '/media/images/logos/disk.png',
      name: 'Disk_Health',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.VARNISH'}),
      link: 'https://xitoring.com/docs/integrations/varnish/',
      img: '/media/images/logos/varnish.png',
      name: 'Varnish',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
    {
      title: intl.formatMessage({id: 'LABELS.COUCHDB'}),
      link: 'https://xitoring.com/docs/integrations/couchdb/',
      img: '/media/images/logos/couch.png',
      name: 'CouchDB',
      limit: checkLimittedPlan('server_integrations'),
      isExist: true,
    },
  ]

  const integrations = (
    <>
      <Row className='gy-4'>
        {isPlanFullyFree && (
          <WarnAlert
            isWarning
            text={intl.formatMessage({id: 'LABEL.UPGRADE.INTEGRATIONS.ADD'})}
            customNode={
              <button className='btn btn-sm btn-primary' onClick={() => navigate('/billing')}>
                {intl.formatMessage({id: 'LABELS.UPGRADE_SERVICE'})}
              </button>
            }
          />
        )}
        {integrationsList.map((item) => {
          if (!item.isExist) {
            return
          }
          return <ItemIntegrations key={item.title}  {...item} setIsLimitModal={setIsLimitModal}/>
        })}
      </Row>
    </>
  )

  if (emptyList) {
    return (
      <section className='card p-5'>
        <h3 className='card-title align-items-start  flex-row justify-content-between mb-6'>
          <span className='card-label fw-bold fs-1 mb-1'>
            {intl.formatMessage({id: 'INTEGRATIONS.LABEL'})}
          </span>
        </h3>
        {integrations}
      </section>
    )
  } else {
    return (
      <section className='card p-5'>
        <h3 className='card-title align-items-start  flex-row justify-content-between mb-6'>
          <span className='card-label fw-bold fs-1 mb-1'>
            {intl.formatMessage({id: 'INTEGRATIONS.LABEL'})}
          </span>
        </h3>

        <Row className='g-4'>
          {isPlanFullyFree && (
            <WarnAlert
              isWarning
              text={intl.formatMessage({id: 'LABEL.UPGRADE.INTEGRATIONS.ADD'})}
              customNode={
                <button className='btn btn-sm btn-primary' onClick={() => navigate('/billing')}>
                  {intl.formatMessage({id: 'LABELS.UPGRADE_SERVICE'})}
                </button>
              }
            />
          )}
          {integrationsList.map((item) => {
            if (!item.isExist) {
              return
            }
            return <ItemIntegrations key={item.title} {...item} emptyList setIsLimitModal={setIsLimitModal} />
          })}
        </Row>
      </section>
    )
  }
}

const ItemIntegrations = ({
  title,
  link,
  img,
  name,
  emptyList,
  limit,
  setIsLimitModal
}: {
  title: string
  link: string
  img: string
  name: string
  emptyList?: boolean
  limit?: boolean
  setIsLimitModal?:any
}) => {
  const {findIntegration, hideIntegration,id,isIntegrationNormal} = useServer()

  return (
    <Col xs={6} md={2} className='h-100' onClick={limit ? () => setIsLimitModal(true) : () => {}}>
      {!hideIntegration(name) ? (
        <Link to={limit ? ' ' :`/servers/${id}/metrics?integration=${name}`} className='text-dark h-100'>
          <div className='p-4 border border-1 border-custom  rounded h-100px gap-2 bg-hover-secondary position-relative shadow border-dark d-flex flex-column justify-content-center align-items-center'>
            <span className={`badge bg-success   position-absolute start-0 top-0 text-white`}>
              <FormattedMessage id={'LABELS.ENABLED'} />
            </span>

            {!isIntegrationNormal(name)&&<span style={{top:"1%",right:"1%"}} className='fas fa-exclamation-triangle text-warning fs-1 position-absolute '/> }

            <img
              src={process.env.PUBLIC_URL + img}
              alt={title}
              className={'w-70px h-50px'}
              style={{objectFit: 'contain'}}
            />
            <p className='m-0 fw-bold '>{title}</p>
          </div>
        </Link>
      ) : (
        <a href={limit ? '#' : link} className='text-dark h-100' target={!limit ? '_blank' : ''}>
          <div className='p-4 border border-1 border-custom  rounded h-100px gap-2 bg-hover-secondary position-relative shadow border-dark d-flex flex-column justify-content-center align-items-center'>
            {!!findIntegration(name)?.exists && (
              <span
                className={`badge 
                bg-info
                 position-absolute start-0 top-0 text-white`}
              >
                <FormattedMessage id={'LABELS.Deteced'} />
              </span>
            )}
            

            <img
              src={process.env.PUBLIC_URL + img}
              alt={title}
              className={'w-70px h-50px'}
              style={{objectFit: 'contain'}}
            />
            <p className='m-0 fw-bold '>{title}</p>
          </div>
        </a>
      )}
    </Col>
  )
}

export default AddIntegration
