import { call, put, takeEvery } from "redux-saga/effects";
import { addGroup, assignGroupServerRequest, deleteGroupRequest, getGroups, unassignGroupServerRequest, updateGroupRequest } from "./requests";
import {
    types
  } from "../actionTypes";
import { handleError } from "../../../modules/widgets/components/Common";
interface Response{
    data: any
  }
  function* requestGroups() {
    try {
      const response: Response  = yield call(getGroups);
      yield put(
        {
            type: types.GET_GROUPS_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_GROUPS_ERROR,
          }
      );
    }
  }

  function* addGroupHandler(action:any) {
    try {
      const response: Response  = yield call(addGroup,action.params);
      yield put(
        {
            type: types.ADD_GROUP_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.ADD_GROUP_ERROR,
            error:handleError(e)
          }
      );
    }
  }

  
  function* updateGroupHandler(action:any) {
    try {
      const response: Response  = yield call(updateGroupRequest,action.params,action.id);
      yield put(
        {
            type: types.UPDATE_GROUP_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      
      yield put(
        {
            type: types.UPDATE_GROUP_ERROR,
            error:handleError(e)
          }
      );
    }
  }

    
  function* deleteGroupHandler(action:any) {
    try {
      const response: Response  = yield call(deleteGroupRequest,action.id);
      yield put(
        {
            type: types.DELETE_GROUP_REQUEST_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {      

      yield put(
        {
            type: types.DELETE_GROUP_REQUEST_ERROR,
            error:handleError(e)
          }
      );
    }
  }

  
    
  function* assignServersToGroupHandler(action:any) {
    try {
      const response: Response  = yield call(assignGroupServerRequest,action.id,action.params);
      yield put(
        {
            type: types.ASSIGN_GROUP_SERVERS_REQUEST_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {

      yield put(
        {
            type: types.ASSIGN_GROUP_SERVERS_REQUEST_ERROR,
            error:handleError(e)
          }
      );
    }
  }

  
    
  function* unassignGroupServersHandler(action:any) {
    try {
      const response: Response  = yield call(unassignGroupServerRequest,action.params);
      yield put(
        {
            type: types.UNASSIGN_GROUP_SERVERS_REQUEST_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {

      yield put(
        {
            type: types.UNASSIGN_GROUP_SERVERS_REQUEST_ERROR,
            error:handleError(e)
          }
      );
    }
  }




  function* watcherGroupSaga() {
    yield takeEvery(types.GET_GROUPS, requestGroups)
    yield takeEvery(types.ADD_GROUP, addGroupHandler)
    yield takeEvery(types.UPDATE_GROUP, updateGroupHandler)
    yield takeEvery(types.DELETE_GROUP_REQUEST, deleteGroupHandler)
    yield takeEvery(types.ASSIGN_GROUP_SERVERS_REQUEST, assignServersToGroupHandler)
    yield takeEvery(types.UNASSIGN_GROUP_SERVERS_REQUEST, unassignGroupServersHandler)



  }
  
  export default watcherGroupSaga