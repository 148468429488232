import React, { useMemo, useState } from 'react'
import {Button, Col, Row} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {useEditDashboard} from '../../../services/customizedDashboard'
import useUser from '../../Hooks/useUser'
import useCustomizedDashboardValues, {
  customizedDashboardTypes,
  DashboardType,
} from '../../modules/context/CustomizedDashboardCtx'
import IconButton from '../../modules/global/IconButton'

import classes from '../dashboard/style.module.scss'
import type {IWidgets, IWidgetType, State} from './CustomizedForm/reducer'
import CreateNewBtn from './CreateNewBtn'
import CustomizedForm from './CustomizedForm'
import CreateNewDashboardModal from './CreateNewDashboardModal'

const CustomizedSettingsDrawer = () => {
  const params = useParams()
  const user=useUser()
  const [customizedDashboards, customizedDispatch] = useCustomizedDashboardValues()
  const findedDashboard: DashboardType = customizedDashboards?.find(
    (dash) => dash.id == params.id
  ) as DashboardType
  const {mutateAsync} = useEditDashboard()
  const isSubAccount = 'access' in user && 'parent' in user

  const [showDashManagement, setShowDashManagement] = useState(false)
  const [showCreateNew, setCreateNew] = useState(false)

  const memoizedDashboard: DashboardType = useMemo(() => {
    const findedDashboard: DashboardType = customizedDashboards?.find(
      (dash) => dash.id == params.id
    ) as DashboardType

    return findedDashboard
  }, [params.id, customizedDashboards])

  const handleCloseDashManagement = () => setShowDashManagement(false)
  const handleShowDashManagement = () => setShowDashManagement(true)

  // const [customizedState, customizedDispatch] = useCustomizedDashboardValues()
  const listOfWidgets: any[] = findedDashboard?.widgets?.widgets
  
  
  const  isClientShare=!!isSubAccount && !!findedDashboard?.client_id

  const clickHandler = (id: string, status: boolean) => {
    if (isClientShare) {
      return
    }
    if (status) {
      let newWidgets = [...findedDashboard?.widgets?.widgets]

      const findedWidgetIndex = newWidgets?.findIndex((w) => w.id == id)
      if (findedWidgetIndex < 0) {
        return
      }
      newWidgets[findedWidgetIndex] = {
        ...newWidgets[findedWidgetIndex],
        enable: false,
      }

      mutateAsync({
        dashboard: {
          ...findedDashboard,
          widgets: {
            ...findedDashboard.widgets,
            widgets:newWidgets
          },
        },
        id: findedDashboard.id,
      })
      customizedDispatch({
        type: customizedDashboardTypes.DISABLE,
        payload: {id: id, DashId: findedDashboard.id},
      })
      return
    }
    let newWidgets = [...findedDashboard.widgets?.widgets]

    const findedWidgetIndex = newWidgets?.findIndex((w) => w.id == id)
    if (findedWidgetIndex < 0) {
      return
    }
    newWidgets[findedWidgetIndex] = {
      ...newWidgets[findedWidgetIndex],
      enable: true,
    }

    mutateAsync({
      dashboard: {
        ...findedDashboard,
        widgets: {
          ...findedDashboard.widgets,
          widgets:newWidgets
        },
      },
      id: findedDashboard.id,
    })

    customizedDispatch({
      type: customizedDashboardTypes.ENABLE,
      payload: {id, DashId: findedDashboard.id},
    })
  }

  const intl = useIntl()

  return (
    <div
      id='widget_custom_setting'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='widgetCustom'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'100%', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#widget_custom_setting_toggle'
      data-kt-drawer-close='#widget_custom_setting_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header px-6' id='kt_help_header'>
          <h5 className='card-title fw-bold text-dark'>
            {intl.formatMessage({id: 'WIDGET.SETTINGS'})}
          </h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='widget_custom_setting_close'
            >
              <IconButton icon='fas fa-times' iconSize='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body py-0 px-6 pt-4 ' id='kt_help_body'>
          {/* begin::Content */}
          <div
            id='kt_help_scroll'
            className='pb-6 h-100'
            data-kt-scroll='true'
            data-kt-scroll-height='auto'
            data-kt-scroll-wrappers='#kt_help_body'
            data-kt-scroll-dependencies='#kt_help_header'
            data-kt-scroll-offset='5px'
            style={{height:'100% !important'}}
          >
            <div className='d-flex flex-column justify-content-between h-100'>
            <Row
              className='gx-10 gy-4 pt-2 mb-auto'
              style={{maxHeight: '80% !important', overflow: 'auto'}}
            >
              {listOfWidgets?.map((widget: IWidgets, index: any) => {        
                
                return (
                  <Col xs={6} key={widget.id + index + '_'}>
                    <div
                      className={`d-flex align-items-center bg-hover-success  text-hover-light p-2  rounded h-100 ${
                        widget.enable ? 'bg-success text-white' : 'bg-light-secondary'
                      }`}
                      onClick={() => clickHandler(widget.id, widget.enable)}
                    >
                      <div
                        className={`d-flex flex-center w-30px h-30px w-lg-40px h-lg-40px fs-2  flex-shrink-0 rounded  fas fa-th-large `}
                        style={{backgroundColor: 'inherit'}}
                      ></div>

                      <div className='d-flex flex-stack flex-grow-1 ms-2 position-relative'>
                        {widget?.enable && (
                          <span
                            style={{left: '95%'}}
                            className='position-absolute  translate-middle   '
                          >
                            <span className='far fa-check-circle fs-3' />
                          </span>
                        )}

                        <div className='me-3 me-lg-5'>
                          <span className={` fw-bolder fs-7 ${classes['custom_wdget_drawer']}`}>
                            {widget.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                )
              })}

            </Row>
            <Row className='gx-10 gy-4 mt-4'>
              <Col xs={6}>
                  <Button size='sm' onClick={handleShowDashManagement} className='w-100'>
                    <FormattedMessage id='LABELS.CREATE.NEW.WIDGET' />
                  </Button>
              </Col>
              <Col xs={6}>
                <Button size='sm' onClick={()=>setCreateNew(true)} className='w-100'>
                  <FormattedMessage id='LABELS.DASHBOARD.MANAGEMENT' />
                </Button>
              </Col>
            </Row>
            </div>
          </div>
          {/* end::Content */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Card */}
      <CustomizedForm show={showDashManagement} handleClose={handleCloseDashManagement} memoizedDashboard={memoizedDashboard} />
      <CreateNewDashboardModal show={showCreateNew} setShow={setCreateNew} />
    </div>
  )
}

export {CustomizedSettingsDrawer}
