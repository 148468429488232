/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useWindowSize} from '../../../../app/Hooks/useWindowSize'
import useLocationGetter from '../../../../app/Hooks/useLocationGetter'
import {ToolbarHelperModal} from '../toolbar/ToolbarHelperModal'
import { Box } from '@mui/material'
import QuestionMarkRoundedIcon from '@mui/icons-material/QuestionMarkRounded'

const HelperNavButton: FC = ({toolbarButtonMarginClass}:any) => {
  const [modalShow, setModalShow] = React.useState(false)

  const helpClickHandler = () => {
    setModalShow(true)
  }
  const {urlWithChild, parent} = useLocationGetter()
  const {width} = useWindowSize()

  return (
    <Box
    sx={{
      display: {
        xs: 'none',
        sm: 'flex',
      },
      alignItems: 'center',
    }}
    className="ms-1 ms-lg-3"
  >
    <div className='' id='helpModalButton'>
      <span
        className={'btn btn-icon btn-active-light-primary btn-custom '}
        onClick={helpClickHandler}
      >
        <QuestionMarkRoundedIcon className='svg-icon' />
      </span>

      <ToolbarHelperModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        location={urlWithChild}
        parent={parent}
      />
    </div>
  </Box>
  )
}

export {HelperNavButton}
