import React, {useState, useTransition} from 'react'
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import MuiSelect from '../../../modules/global/UI/MuiSelect'
import { MenuItem } from '@mui/material'

interface IBulkActions {
  handleEnableBulk: (status: boolean) => void
  handleSelectOp: (operator: string) => void
  selectedOp: string
  onOpenConfirmModal: () => void
  clearSelected: () => void
  ssl?:boolean
}

const BulkActions = (props: IBulkActions) => {
  const [isPending, startTransition] = useTransition()
  const {handleEnableBulk, selectedOp, handleSelectOp, onOpenConfirmModal, clearSelected,ssl=false} = props
  const intl = useIntl()
  const [enable, setEnable] = useState(false)
 

  const handleEnable = () => {
    startTransition(() => {
      handleEnableBulk(true)
    })
    setEnable(true)
  }

  const onDoneClick = async () => {
    onOpenConfirmModal()
  }

  return (
    <section className=' d-flex justify-content-end'>
      {!enable ? (
        <Button size='sm' className='d-flex gap-1 align-items-center w-100 w-lg-150px justify-content-center' onClick={handleEnable}>
          <span className='far fa-check-circle fs-3 text-white' />

          {intl.formatMessage({id: 'LABELS.BULK.ACTIONS'})}
        </Button>
      ) : (
        <div className='d-flex gap-3 align-items-end w-100 justify-content-end'>
         
            <Form.Group className=' w-100 w-lg-200px'> 
              <MuiSelect
                // size='small'
                height={38}
                value={selectedOp}
                onChange={(e) => {
                  handleSelectOp(e.target.value)
                }}
              >
                <MenuItem value={'pause'}>{intl.formatMessage({id: 'LABELS.PAUSE'})}</MenuItem>
                <MenuItem value={'unpause'}>{intl.formatMessage({id: 'LABELS.UNPAUSE'})}</MenuItem>
                <MenuItem value={'delete'}>{intl.formatMessage({id: 'ACTIONS.DELETE'})}</MenuItem>
                {!ssl&&<>
                  <MenuItem value={'status_page'}>{intl.formatMessage({id: 'LABELS.CREATE.PUBLIC.STATUS'})}</MenuItem>
                <MenuItem value={'maintenance'}>{intl.formatMessage({id: 'MAINTENANCE.TITLE'})}</MenuItem>
                <MenuItem value={'move'}>{intl.formatMessage({id: 'ACTIONS.MOVE_TO_GROUP'})}</MenuItem>

                </>}
                
              </MuiSelect>
            </Form.Group>
       

         
            <div className='d-flex gap-2'>
              <Button
                size='sm'
                className='d-flex align-items-center justify-content-center'
                onClick={() => {
                  setEnable(false)
                  startTransition(() => {
                    handleEnableBulk(false)
                  })
                  clearSelected()
                }}
              >
                <span className='fas fa-times fs-5 text-white' />
              </Button>
              <Button size='sm' onClick={onDoneClick}
                className='d-flex align-items-center justify-content-center'
              
              >
               <span className='fas fa-check fs-5 text-white' />
              </Button>
            </div>
          
        </div>
      )}
    </section>
  )
}

export default React.memo(BulkActions)
