import React, {useEffect} from 'react'
import { useQuery } from 'react-query'
import {useDispatch} from 'react-redux'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import { getServerData } from '../../services/Server'
import {RootState} from '../../setup'
import {getServerDetailAction} from '../store/serverOverview/actions'
import useAccessControl from './useAccessControl'

const useServer: (serverId?:string|number,getIntegration?:boolean) => {
  findIntegration: (int: string) => Record<string, any>
  isIntegrationCannotCollect: (n: string) => boolean
  isIntegrationOutdated: (n: string) => boolean
  hideIntegration: (n: string) => boolean
  isIntegrationNormal:(n:string)=>boolean
  [key: string]: any
} = (serverId?:string|number,getIntegration?:boolean) => {

  // const serverDetail: any = useSelector<RootState>((state) => state.serverView.detail) as object
  // const dispatch = useDispatch()
  const params = useParams()
  const {data,isLoading}: any = useQuery(['server',serverId?serverId:params.id,!!getIntegration], () => getServerData(serverId?serverId:params.id,!!getIntegration),{
    enabled:!!params.id||!!serverId,
    refetchInterval:30000
  })

  // useEffect(() => {
  //   if (params.id||serverId) {
  //     if (!data?.label) {
  //       dispatch(getServerDetailAction(serverId?serverId:params.id))
  //     }
  //   }
  // }, [params?.id,serverId])
  const hasWriteAcc=useAccessControl(`group_${data?.group_id}_write`)

  const findIntegration = (integ: string) => {
    if (!!!data?.integrations_status?.length) {
      return {}
    }

    return data?.integrations_status?.find(
      (intObj: Record<string, any>) => intObj?.type_id == integ
    )
  }

  const isIntegrationOutdated = (name: string) => {
    const integration = findIntegration(name)

    return integration?.config_status == 1 && integration?.status == 0
  }

  const isIntegrationCannotCollect = (name: string) => {
    const integration = findIntegration(name)
    return integration?.config_status == 2
  }

  const hideIntegration = (name: string) => {
    const integration = findIntegration(name)
    return (
      integration?.status != 1 && !isIntegrationOutdated(name) && !isIntegrationCannotCollect(name)
    )
  }

  const isIntegrationNormal = (name: string) => {
    
    const integration = findIntegration(name)

    return integration?.status == 1
  }

  return {
    ...data,
    findIntegration,
    isIntegrationOutdated,
    isIntegrationCannotCollect,
    hideIntegration,
    isIntegrationNormal,
    isLoading:isLoading,
    hasWriteAcc
  }
}

export default useServer
