import React from 'react'
import {Button, Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useMutation} from 'react-query'
import {notify} from '../../../helpers'
import {ResendConfirmationContactReq} from '../../../services/Contact'
import IconButton from './IconButton'

interface Props {
  id: number
  mode?: 'icon' | 'btn'
}

const ResendEmailConfirmContact = (props: Props) => {
  const {id, mode = 'icon'} = props
  const intl = useIntl()
  const {mutateAsync, isLoading} = useMutation(ResendConfirmationContactReq)

  const onSend = async (e: any) => {
    e.stopPropagation()

    try {
      await mutateAsync(id)
      return notify({
        type: 'success',
        message: intl.formatMessage({id: 'LABELS.SEND.EMAIL.CONF'}),
      })
    } catch (err: any) {
      const error = err.response && (err.response || err.message)
      let data = error?.data
      console.error('data error ', data)
      if (!data) {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
        })
      }
      let messages = []

      for (let i in data) {
        messages.push(
          <>
            {data[i]}
            <br />
          </>
        )
      }
      notify({type: 'error', message: <>{messages}</>})
    }
  }

  return (
    <div>
      {mode === 'icon' ? (
        <IconButton
          icon='fa fa-refresh'
          onClick={onSend}
          pending={isLoading}
          tooltip={intl.formatMessage({id: 'MODALS.EMAIL_CONFIRMATION.TITLE'})}
        />
      ) : (
        <Button size='sm' onClick={onSend} disabled={isLoading} style={{minWidth: '200px'}}>
          {isLoading ? (
            <Spinner animation='grow' size='sm' />
          ) : (
            intl.formatMessage({id: 'MODALS.EMAIL_CONFIRMATION.TITLE'})
          )}
        </Button>
      )}
    </div>
  )
}

export default ResendEmailConfirmContact
