import React from 'react'
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../setup';
import { UserModel } from '../modules/auth/models/UserModel';

const useUser:()=>UserModel = () => {

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel;

return user
}

export default useUser