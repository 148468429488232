import {
  types
} from "./actionTypes";

const initialState: any = {
  pending: false,
  error: null,
  count: {},
  upTimeData: {},
  pendingAddCheck: false,
  errorAddCheck: null,
  successAddCheck: false
};


const upTimeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_MODULE_COUNT:
      return {
        ...state,
        pending: true,
      };
    case types.GET_MODULE_COUNT_SUCCESS:

      return {
        ...state,
        pending: false,
        count: action.payload,
        error: null
      };
    case types.GET_MODULE_COUNT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case types.GET_UPTIME:
      return {
        ...state,
        pending: true,
      };
      case types.GET_UPTIME_REFETCH:
      return {
        ...state,
        pending: false,
      };
    case types.GET_UPTIME_SUCCESS:
      return {
        ...state,
        pending: false,
        upTimeData: action.payload,
        error: null
      };
    case types.GET_UPTIME_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };


    case types.ADD_NEW_CHECK:
      return {
        ...state,
        pendingAddCheck: true,
        errorAddCheck: null,
        successAddCheck: false

      };
    case types.ADD_NEW_CHECK_SUCCESS:
      return {
        ...state,
        pendingAddCheck: false,
        successAddCheck: true

      };
    case types.ADD_NEW_CHECK_ERROR:
      return {
        ...state,
        pendingAddCheck: false,
        errorAddCheck: action.error,
      };

    case types.RESET_UPTIME_PROPS:
      return {
        ...state,
        pending: false,
        error: null,
        success: false,
        pendingAddCheck: false,
        errorAddCheck: null,
        successAddCheck: false
      };

    default:
      return {
        ...state,
      };
  }
};

export default upTimeReducer