import React, {useState, useEffect, useCallback} from 'react'
import useMainConfig from './useMainConfig'
import useUser from './useUser'
import * as authMain from '../modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import {useUpdateEffect} from './useUpdateEffect'
import {editWizardAPI} from '../../services/dashboard'
import {notify} from '../../helpers'
import {useIntl} from 'react-intl'

export interface StepKeys {
  email_confirmation: boolean
  monitoring: boolean
  sub_account: boolean
  notification_roles: boolean
  status_page: boolean
  report_email: boolean
  complete_profile: boolean
  upgrade_plan: boolean
  mobile_app: boolean
}

const useAccountWizardListener = () => {
  const user = useUser()
  const main = useMainConfig()
  const dispatch = useDispatch()
  const intl = useIntl()

  const [completedSteps, setCompletedSteps] = useState<StepKeys>(() => ({
    ...user?.wizard?.completedSteps,
  }))

  const [numberOfStepsCompleted, setNumberOfStepsCompleted] = useState<number[]>(
    user?.wizard?.numberOfStepsCompleted ?? []
  )

  const CompeleStepHand = (nameOfStep: keyof StepKeys) => {
    if (!user?.wizard?.completedSteps?.[nameOfStep]) {
      setCompletedSteps((prev) => {
        return {...prev, [nameOfStep]: true}
      })
      // setNumberOfStepsCompleted((prev) => {
      //   return Array.from(new Set([stepNumber, ...prev]))
      // })
    }

    // if (!user?.wizard?.[nameOfStep]) {
    //   setCompletedSteps((prev) => {
    //     return {...prev, [nameOfStep]: true}
    //   })
    //   setNumberOfStepsCompleted((prev) => {
    //     return Array.from(new Set([stepNumber, ...prev]))
    //   })
    // }
  }

  const UnCompeleStepHand = (nameOfStep: keyof StepKeys) => {
    if (user?.wizard?.completedSteps?.[nameOfStep] && !user?.wizard?.completedSteps?.is_force) {
      setCompletedSteps((prev) => {
        return {...prev, [nameOfStep]: false}
      })

      // setNumberOfStepsCompleted((prev) => prev.filter((num) => num !== stepNumber))
    }
  }

  useEffect(() => {
    if (user?.email_confirmed) {
      CompeleStepHand('email_confirmation')
    }

    if (user?.address && user?.city && user?.country && user?.zip) {
      CompeleStepHand('complete_profile')
    }

    return () => {}
  }, [user?.email_confirmed, user?.address, user?.city, user?.country, user?.zip])

  useEffect(() => {
    if (main?.options?.device?.used > 0 || main?.options?.module?.used > 0) {
      CompeleStepHand('monitoring')
    }

    if (main?.options?.device?.used === 0 && main?.options?.module?.used === 0) {
      UnCompeleStepHand('monitoring')
    }

    /* ------------------------------------ . ----------------------------------- */
    if (main?.options?.contacts?.used > 0) {
      CompeleStepHand('sub_account')
    }

    if (main?.options?.contacts?.used === 0) {
      UnCompeleStepHand('sub_account')
    }
    /* ------------------------------------ . ----------------------------------- */
    if (main?.options?.status_page?.used > 0) {
      CompeleStepHand('status_page')
    }

    if (main?.options?.status_page?.used === 0) {
      UnCompeleStepHand('status_page')
    }
    // /* ------------------------------------ . ----------------------------------- */
    // if (main?.options?.report_email?.used > 0) {
    //   CompeleStepHand( 'report_email')
    // }

    // if (main?.options?.report_email?.used === 0) {
    //   UnCompeleStepHand( 'report_email')
    // }
    /* ------------------------------------ . ----------------------------------- */
    if (main?.is_free_plan === false) {
      CompeleStepHand('upgrade_plan')
    }
    if (main?.is_free_plan) {
      UnCompeleStepHand('upgrade_plan')
    }
    // if (!!user.registered_on_mobile_app) {
    //   CompeleStepHand('mobile_app')
    // }
    // if (!!!user.registered_on_mobile_app) {
    //   UnCompeleStepHand('mobile_app')
    // }
  }, [main?.options, main?.is_free_plan])

  useUpdateEffect(() => {
    if (user?.wizard?.completedSteps) {
      if (!user?.wizard?.completedSteps?.notification_roles) {
        setCompletedSteps((prev) => {
          return {...prev, notification_roles: true}
        })
        // setNumberOfStepsCompleted((prev) => {
        //   return Array.from(new Set([4, ...prev]))
        // })
      }
    }
  }, [user?.wizard?.completedSteps?.notification_roles])

  useUpdateEffect(() => {
    if (user?.wizard?.completedSteps) {
      if (!user?.wizard?.completedSteps?.report_email) {
        setCompletedSteps((prev) => {
          return {...prev, report_email: true}
        })
        // setNumberOfStepsCompleted((prev) => {
        //   return Array.from(new Set([6, ...prev]))
        // })
      }
    }
  }, [user?.wizard?.completedSteps?.report_email])

  const updateWizardReq = async (data: Record<string, any>) => {
    try {
      await editWizardAPI(data)
    } catch (err: any) {
      const error = err.response && (err.response || err.message)
      let data = error?.data
      console.error('data error ', data)
      if (!data) {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
        })
      }
      let messages = []

      for (let i in data) {
        messages.push(
          <>
            {data[i]}
            <br />
          </>
        )
      }
      notify({type: 'error', message: <>{messages}</>})
    }
  }

  useUpdateEffect(() => {
    dispatch(
      authMain.actions.setWizard({
        // numberOfStepsCompleted: numberOfStepsCompleted,
        completedSteps: completedSteps,
      })
    )
  }, [numberOfStepsCompleted, completedSteps])

  useUpdateEffect(() => {
    updateWizardReq({
      // numberOfStepsCompleted: user?.wizard?.numberOfStepsCompleted,
      completedSteps: user?.wizard?.completedSteps,
    })
  }, [user?.wizard])

  return {
    completedSteps: user?.wizard?.completedSteps,
    // numberOfStepsCompleted: user?.wizard?.numberOfStepsCompleted,
    CompeleStepHand,
    UnCompeleStepHand,
  }
}

export default useAccountWizardListener
