import {Divider} from '@mui/material'
import React from 'react'
import {useIntl} from 'react-intl'
import IconButton from '../../../modules/global/IconButton'
import InfoBox from '../../../modules/global/InfoBox'
import {Action, IWidgetType, IWidgets} from './reducer'
import SelectedItem from './SelectedItem'

interface Props {
  memoizedDispatch: (data: Action) => void
  state: IWidgets
  title?: string
  onAssignGraph?: (state: IWidgets) => void
}

const SelectedChecks = (props: Props) => {
  const {memoizedDispatch, title, state, onAssignGraph} = props

  const {formatMessage} = useIntl()

  const onEditLabel = (widget: IWidgetType, customLabel: string) => {
    const cloneWidgets = [...state.widgets]
    let updatedState:IWidgets = {...state}
    const existingIndex = cloneWidgets.findIndex((item) => item.id === widget.id)

    if (existingIndex !== -1) {
      cloneWidgets[existingIndex] = {
        ...cloneWidgets[existingIndex],
        customLabel: customLabel,
      }
      updatedState = {
        ...state,
        widgets: cloneWidgets,
      }
    } else {
      // Object doesn't exist
      updatedState = {
        ...state,
        widgets: cloneWidgets,
      }
    }

    onAssignGraph?.(updatedState)

    memoizedDispatch({
      type: 'CUSTOM_LABEL_WIDGET',
      payload: {widget, customLabel},
    })
  }

  const onClickRemove = (data: IWidgetType) => {
    const cloneOfState = {...state}

    const filteredModuleIds = cloneOfState?.module_id?.filter(
      (checkId) => String(checkId) !== String(data?.id)
    )

    const filteredWidgets = cloneOfState?.widgets?.filter(
      (widget) => String(widget?.id) !== String(data?.id)
    )

    const updatedState = {
      ...state,
      module_id: filteredModuleIds,
      widgets: filteredWidgets,
    }

    onAssignGraph?.(updatedState)

    memoizedDispatch({
      type: 'TOGGLE_CHECK',
      payload: +data?.id,
    })

    memoizedDispatch({
      type: 'TOGGLE_WIDGET',
      payload: data,
    })
  }

  return (
    <div className='mt-4'>
      <Divider>
        {' '}
        <strong>{title}</strong>{' '}
      </Divider>
      <InfoBox
        node={
          <div>
            <p className='m-0'>{formatMessage({id: 'LABELS.EDIT.LABEL.ASSIGN.GRAPH'})}</p>
          </div>
        }
        className='m-0 mt-4'
      />
      <section
        className='d-flex gap-3 flex-column mt-4'
        style={{maxHeight: '250px', overflowY: 'auto'}}
      >
        {state?.widgets?.length ? (
          state?.widgets?.map((widget) => {
            return (
              <SelectedItem
                key={widget?.id}
                widget={widget}
                onClickRemove={onClickRemove}
                onEditLabel={onEditLabel}
              />
            )
          })
        ) : (
          <p className='text-center mt-6 fs-8'>{formatMessage({id: 'NO_DATA_TABLE'})}</p>
        )}
      </section>
    </div>
  )
}

export default SelectedChecks
