import {
    types
  } from "./commonActionTypes";

 
  
  export const handleOpenFeedback = (params:boolean) => ({
    type: types.HANDLE_OPEN_FEEDBACK_MODAL,
    params
  });


  
  export const addToIgnore = (name:string) => ({
    type: types.ADD_TO_IGNORE,
    params:name
  });
