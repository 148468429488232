import React, {useState} from 'react'
import ExpandSubAccTable from './ExpandSubAccTable'
import {FormattedMessage, useIntl} from 'react-intl'
import {Contact} from '../../../../services/Contact'
import {Link} from 'react-router-dom'
import {useSocket} from '../../../../socket/contact/contact'
import {IconButton as MUIIconButton, Skeleton} from '@mui/material'
import IconButton from '../../global/IconButton'
import {useDispatch} from 'react-redux'
import {decrementOption} from '../../../store/billing/actions'
import ResendEmailConfirmContact from '../../global/ResendEmailConfirmContact'
import {useMutation, useQuery} from 'react-query'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import Paper from '@mui/material/Paper'
import ConfirmModal from '../../widgets/components/ConfirmModal'
import {notify} from '../../../../helpers'
import useUser from '../../../Hooks/useUser'
import {getContactsWithParams} from '../../../../services/Account'
import TablePagination from '@mui/material/TablePagination'
import {Box} from '@mui/system'

function Row(props: {row: Record<string, any>; isOdd: boolean; reloadTable: () => void}) {
  const {row, isOdd, reloadTable} = props
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [open, setOpen] = React.useState(false)
  const intl = useIntl()
  const user = useUser()
  const {mutateAsync, isLoading: loadingRemove} = useMutation({
    mutationFn: () => Contact.delete(row?.id),
  })

  const badgeCondition = (row: any) => {
    return (
      (row.name.trim() === '' && row.last.trim() === '' && !!!row.active) || !row.email_confirmed
    )
  }

  const hasUserAccessToActionButtons = () => {
    //is client or has full access
    return !('parent' in user) || !!user.full_access
  }

  const deleteContactHandler = async () => {
    try {
      setOpenModal(false)
      await mutateAsync()
      reloadTable()
      dispatch(decrementOption('contacts'))
      notify({
        type: 'success',
        message: intl.formatMessage({id: 'NOTIFICATIONS.ITEM_DELETED'}, {name: row?.name}),
      })
    } catch (err: any) {
      const error = err.response && (err.response || err.message)
      let data = error?.data
      console.error('data error ', data)
      if (!data) {
        return notify({
          type: 'error',
          message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
        })
      }
      let messages = []

      for (let i in data) {
        messages.push(
          <>
            {data[i]}
            <br />
          </>
        )
      }
      notify({type: 'error', message: <>{messages}</>})
    }
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '& > *': {
            borderBottom: 'none !important',
            whiteSpace: 'nowrap',
          },

          backgroundColor: isOdd ? 'var(--kt-card-bg)' : 'var(--kt-secondary)',
        }}
      >
        <TableCell style={{padding: '0.8rem'}}>
          <MUIIconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? (
              <span className='fas fa-angle-up fs-3 px-1' />
            ) : (
              <span className='fas fa-angle-down fs-3 px-1' />
            )}
          </MUIIconButton>
        </TableCell>
        <TableCell component='th' scope='row' style={{padding: '0.8rem'}}>
          <span className='me-2'>{row.email}</span>

          {badgeCondition(row) && (
            <span className={`badge   ${!badgeCondition(row) ? 'badge-success' : 'badge-warning'}`}>
              {!badgeCondition(row)
                ? intl.formatMessage({id: 'BADGES.CONFIRMED'})
                : intl.formatMessage({id: 'LABELS.PENDING'})}
            </span>
          )}
        </TableCell>
        <TableCell style={{padding: '0.8rem'}}>
          <i
            className={` fs-4 ${
              !!row.full_access
                ? 'fa fa-check-circle  text-success text-center'
                : 'fa fa-times-circle text-center'
            }`}
          ></i>
        </TableCell>

        <TableCell style={{padding: '0.8rem'}}>
          <div className='d-flex gap-2 align-items-center justify-content-end'>
            {badgeCondition(row) && <ResendEmailConfirmContact id={row.id} />}

            {!!hasUserAccessToActionButtons() && (
              <Link to={`/account/sub-accounts/${row.id}/edit`} className='d-flex'>
                <IconButton icon='fa fa-edit' />
              </Link>
            )}
            {!!hasUserAccessToActionButtons() && (
              <IconButton
                onClick={() => setOpenModal(true)}
                icon={'fa fa-trash'}
                pending={loadingRemove}
              />
            )}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <ExpandSubAccTable row={row} canView={!!hasUserAccessToActionButtons()} />
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmModal
        display={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={deleteContactHandler}
        title={intl.formatMessage({id: 'ALERT.SURE'})}
        description={intl.formatMessage({id: 'ALERT.DELETE_MESSAGE'}, {name: row.name})}
      />
    </React.Fragment>
  )
}

const SubAccountsTable = () => {
  const intl = useIntl()
  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [page, setPage] = useState(0)

  const {
    data: contacts,
    isLoading,
    refetch,
  }: any = useQuery(
    ['contacts', limit, offset],
    () =>
      getContactsWithParams({
        limit,
        offset,
      }),
    {cacheTime: 0, staleTime: 0}
  )

  const reloadTable = () => {
    refetch()
  }

  /*Listen to realtime events*/

  useSocket({
    type: 'CONTACT_CREATED',
    callBack: reloadTable,
  })

  useSocket({
    type: 'CONTACT_UPDATED',
    callBack: reloadTable,
  })

  useSocket({
    type: 'CONTACT_DELETED',
    callBack: reloadTable,
  })

  /*************************/
  const handleChangePage = (event: unknown, newPage: number) => {
    setOffset(newPage * limit)
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value, 10))
    setOffset(0)
  }
  return (
    <Box
      sx={{
        width: '100%',
        '& .MuiTablePagination-selectLabel': {
          margin: 0,
        },
        '& .MuiTablePagination-displayedRows': {
          margin: 0,
        },
      }}
    >
      <TableContainer
        component={Paper}
        classes={{root: 'bg-custom'}}
        style={{overflowX: 'auto'}}
      >
        <Table aria-label='collapsible table'>
          <TableHead>
            <TableRow>
              <TableCell style={{padding: '0.8rem'}} />
              <TableCell style={{padding: '0.8rem'}}>
                <h6 className='m-0'>
                  <FormattedMessage id='PROFILE.OVERVIEW.EMAIL' />{' '}
                </h6>
              </TableCell>
              <TableCell style={{padding: '0.8rem'}}>
                <h6 className='m-0'>
                  <FormattedMessage id='LABELS.FULL_ACCESS' />
                </h6>
              </TableCell>
              <TableCell style={{padding: '0.8rem'}}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                {Array(5)
                  .fill(5)
                  ?.map((val, inx: number) => (
                    <TableRow key={'loading-' + inx}>
                      <TableCell padding={'checkbox'} align='left' colSpan={6}>
                        <Skeleton animation='wave' sx={{p: 3, m: 0}} />
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            ) : !!contacts?.data?.length ? (
              contacts?.data?.map((row: Record<string, any>, index: number) => (
                <Row key={row.id} row={row} isOdd={index % 2 !== 0} reloadTable={reloadTable} />
              ))
            ) : (
              <TableRow
                sx={{
                  backgroundColor: 'var(--kt-card-bg)',
                }}
              >
                <TableCell colSpan={6}>
                  <h3 className='p-3 text-center m-0'>
                    <FormattedMessage id='NO_DATA_TABLE' />
                  </h3>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component='div'
        count={contacts?.total ?? 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: 'var(--kt-secondary)'}}
      />
    </Box>
  )
}

export default SubAccountsTable
