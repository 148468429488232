import React, {FC} from 'react'
import {Col, Dropdown, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import IconButton from '../../../../modules/global/IconButton'
import {convertByte} from '../../../../modules/widgets/components/ChartAssistanr'
import {NoteTextSVG} from '../../../../modules/widgets/components/SVGIcons'

const DeviceDiskUsage: FC<{data: any}> = ({data}) => {
  const intl = useIntl()

  return (
    <Dropdown>
      <Dropdown.Toggle variant='Secondary' className='group-item-menu'>
        <IconButton icon='fas fa-list-alt' />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data && Object.keys(data)?.length > 0 ? (
          <div>
            <Col xs={12}>
              <Row>
                <Col xs={4} className='text-center'>
                  {intl.formatMessage({id: 'LABELS.DRIVE_NAME'})}
                </Col>
                <Col xs={4} className='text-center'>
                  {intl.formatMessage({id: 'LABELS.TOTAL'})}
                </Col>
                <Col xs={4} className='text-center'>
                  {intl.formatMessage({id: 'LABELS.USED'})}
                </Col>
              </Row>
              <hr className='mt0 mb0' />
            </Col>
            {data &&
              Object.keys(data).map((item: string, idx: number) => (
                <Col xs={12} key={idx} className='data-item'>
                  <Row>
                    <Col xs={4} className='text-center'>
                      {item}
                    </Col>
                    <Col xs={4} className='cmd-container'>
                      {convertByte(data[item].total)}
                    </Col>
                    <Col xs={4} className='text-center'>
                      {convertByte(data[item].used)}
                    </Col>
                  </Row>
                </Col>
              ))}
          </div>
        ) : (
          <div>{intl.formatMessage({id: 'DESCRIPTIONS.NO_RECORDS'})}</div>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DeviceDiskUsage
