

import{ _axios } from "../../core/inceptor";

export const getServer=(params:string)=>_axios.get(`/servers/${params}`)
export const getIps=(params:string)=>_axios.get(`/servers/${params}/ips`)
export const getServerUptime=(params:any)=>_axios.get(`/servers/${params.id}/checks/uptime?period=${params.period}`)
export const getServerIncident=(params:any)=>_axios.get(`/servers/${params.id}/incidents?${params.query}`)
export const getServerChecks=(serverId:string,)=>_axios.get(`/servers/${serverId}/checks`)
export const postTestNewCheck=(params:FormData)=>_axios.post(`/checks/test`,params)
export const postNewCheck=(params:FormData,id:string)=>_axios.post(`/servers/${id}/checks`,params)
export const removeCheck=(serverId:string,checkId:string)=>_axios.delete(`/servers/${serverId}/checks/${checkId}`)
export const pauseServerChecks=(serverId:string,checkId:string,param:any)=>_axios.patch(`/servers/${serverId}/checks/${checkId}/?pause_until=${param}`)
// export const pauseServerChecks=(serverId:string,checkId:string,param:any)=>

export const unpauseServerChecks=(serverId:string,checkId:string)=>_axios.put(`/servers/${serverId}/checks/${checkId}`,{pause_until: 0})
export const updateServerCheck=(serverId:string,checkId:string,params:any)=>_axios.put(`/servers/${serverId}/checks/${checkId}`,params)

// export const unpauseServerChecks=(serverId:string,checkId:string)=>



// export const updateServerCheck=(serverId:string,checkId:string,params:any)=>

export const geServerGraph=(serverId:string,graphName:string,period:string)=>_axios.get(`/servers/${serverId}/metric/graphs/${graphName}?start=${period}`)

// export const getCpuUsageGraph=(serverId:string)=>_axios.get(`/servers/${serverId}/metric/graphs/cpu-usage`)
// export const getCpuLoadAverageGraph=(serverId:string)=>_axios.get(`/servers/${serverId}/metric/graphs/cpu-load-average`)
// export const getDiskUsageGraph=(serverId:string)=>_axios.get(`/servers/${serverId}/metric/graphs/disk-usage`)
// export const getMemoryUsageGraph=(serverId:string)=>_axios.get(`/servers/${serverId}/metric/graphs/memory-usage`)
// export const getNetworkGraph=(serverId:string)=>_axios.get(`/servers/${serverId}/metric/graphs/network`)
// export const getDiskIOGraph=(serverId:string)=>_axios.get(`/servers/${serverId}/metric/graphs/disk-io`)
export const getNetworkGraph=(serverId:string,period:string,port:string)=>_axios.get(`/servers/${serverId}/metric/graphs/network?start=${period}&port=${port}`)

export const getServerCheckGraph=(serverId:number,checkId:number,graphName:string,period:string)=>_axios.get(`/servers/${serverId}/checks/${checkId}/graphs/${graphName}`)
export const getServerMetricTriggers=(serverId:number)=>_axios.get(`/servers/${serverId}/metric/triggers?per_page=1000`)
export const getServerChecksTriggers=(serverId:number)=>_axios.get(`/servers/${serverId}/checks/triggers?per_page=100`,
// {headers:{'X-Total-Count':true}}
)
export const postCreateTrigger=(serverId:number,params:any)=>_axios.post(`/servers/${serverId}/metric/triggers`,params)
export const postCreateCheckTrigger=(serverId:number,checkId:number,params:any)=>_axios.post(`/servers/${serverId}/checks/${checkId}/triggers`,params)
export const postUpdateCheckTrigger=(serverId:number,checkId:number,triggerId:number,params:any)=>_axios.put(`/servers/${serverId}/checks/${checkId}/triggers/${triggerId}`,params)
export const postDeleteCheckTrigger=(serverId:number,checkId:number,triggerId:number)=>_axios.delete(`/servers/${serverId}/checks/${checkId}/triggers/${triggerId}`)





export const deleteTrigger=(serverId:number,triggerId:number)=>_axios.delete(`/servers/${serverId}/metric/triggers/${triggerId}`)
export const updateTrigger=(serverId:number,triggerId:number,params:any)=>_axios.patch(`/servers/${serverId}/metric/triggers/${triggerId}`,params)







    















