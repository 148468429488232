import { call, put, takeEvery } from "redux-saga/effects";
import {
    types
  } from "../actionTypes";
import { getClientPeriodsRequest, getInvoicesUnpaidCountRequest, 
  getMainServiceRequest, 
  getOrderPendingCountRequest, getPlanPricing, getSMSCreditsAvailableRequest } from "./requests";
interface Response{
    data: any
  }
  function* requestGetSMSCreditsAvailableHandler() {
    try {
      const response: Response  = yield call(getSMSCreditsAvailableRequest);
      
      yield put(
        {
            type: types.GET_SMS_CREDITS_AVAILABLE_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_SMS_CREDITS_AVAILABLE_ERROR,
          }
      );
    }
  }

  function* requestGetInvoicesUnpaidCountHandler() {
    try {
      const response: Response  = yield call(getInvoicesUnpaidCountRequest);
      yield put(
        {
            type: types.GET_INVOICES_UNPAID_COUNT_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_INVOICES_UNPAID_COUNT_ERROR,
          }
      );
    }
  }

  
  function* requestClientPeriodsHandler() {
    try {
      const response: Response  = yield call(getClientPeriodsRequest);
      yield put(
        {
            type: types.GET_CLIENT_PERIODS_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_CLIENT_PERIODS_ERROR,
          }
      );
    }
  }
  function* requestOrderPendingCountHandler() {
    
    try {
      const response: Response  = yield call(getOrderPendingCountRequest);
      
      
      yield put(
        {
            type: types.GET_ORDERS_PENDING_COUNT_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_ORDERS_PENDING_COUNT_ERROR,
          }
      );
    }
  }

  
  function* requestGetMainServiceHandler() {
    
    try {
      const response: Response  = yield call(getMainServiceRequest);
      
      
      yield put(
        {
            type: types.GET_MAIN_SERVICE_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_MAIN_SERVICE_ERROR,
          }
      );
    }
  }

  
  function* requestGetPaidPlanPricingHandler() {
    
    try {
      const response: Response  = yield call(getPlanPricing,'paid');
      
      
      yield put(
        {
            type: types.GET_PAID_PLAN_PRICING_OPTIONS_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_PAID_PLAN_PRICING_OPTIONS_ERROR,
          }
      );
    }
  }

  function* requestGetSMSPlanPricingHandler() {
    
    try {
      const response: Response  = yield call(getPlanPricing,'sms');
      
      
      yield put(
        {
            type: types.GET_SMS_PLAN_PRICING_OPTIONS_SUCCESS,
            payload:response.data
          }
      );
    } catch (e:any) {
      yield put(
        {
            type: types.GET_SMS_PLAN_PRICING_OPTIONS_ERROR,
          }
      );
    }
  }


  function* watcherBillingSaga() {
    yield takeEvery(types.GET_SMS_CREDITS_AVAILABLE, requestGetSMSCreditsAvailableHandler)
    yield takeEvery(types.GET_INVOICES_UNPAID_COUNT, requestGetInvoicesUnpaidCountHandler)
    yield takeEvery(types.GET_CLIENT_PERIODS, requestClientPeriodsHandler)
    yield takeEvery(types.GET_ORDERS_PENDING_COUNT, requestOrderPendingCountHandler)
    yield takeEvery(types.GET_MAIN_SERVICE, requestGetMainServiceHandler)
    yield takeEvery(types.GET_PAID_PLAN_PRICING_OPTIONS, requestGetPaidPlanPricingHandler)
    yield takeEvery(types.GET_SMS_PLAN_PRICING_OPTIONS, requestGetSMSPlanPricingHandler)



  }
  
  export default watcherBillingSaga