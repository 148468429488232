/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {notify} from '../../../../helpers'
import {useIntl} from 'react-intl'
import useUser from '../../../Hooks/useUser'
import { Profile } from '../../../../services/Profile'
import { useDispatch } from 'react-redux'
import * as auth from '../../auth/redux/AuthRedux'
import MuiTextField from '../../global/UI/MuiTextField'


export function CompleteProfile() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const intl = useIntl()
  const user = useUser()
  const dispatch = useDispatch()

  const schema = Yup.object().shape({
    first: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(''),
    last: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(''),
    company: Yup.string().min(2).max(50),
  })

  const initialValues = {
    first: user.first,
    last: user.last,
    company: user.company,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);

      Profile.update(values)
        .then((response:any) => {
          localStorage.setItem('complete-profile', 'true')

          dispatch(
            auth.actions.setUser({
              ...user,
              ...values,
            })
          )

          navigate('/dashboard')
          setLoading(false);
        })
        .catch((err:any) => {
          setLoading(false);

          const error = err.response && (err.response || err.message)
          let data = error?.data
        
          if (!data) {
            return notify({
              type: 'error',
              message: intl.formatMessage({id: 'ERROR.UNSPECIFIEDERROR'}),
            })
          }
          let messages = []
  
          for (let i in data) {
            messages.push(
              <>
                {data[i]}
                <br />
              </>
            )
          }

          notify({type: 'error', message: <>{messages?.[0]}</>})
        });
    },
  })

  useEffect(() => {
    const handlePopState = (event:any) => {
      formik.handleSubmit()
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center d-flex flex-column mb-6'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({id:'AUTH.WELCOME_TO_XITTORING_TITLE'})}</h1>
        <h6 className='text-gray-500'>{intl.formatMessage({id:'DESCRIPTIONS.COMPLETE_PROFILE'})}</h6>
      </div>
      {formik.status && (
        <div className='mb-lg-8 alert alert-danger text-white'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          {intl.formatMessage({id: 'LABELS.FIRST_NAME'})}
        </label>
        <MuiTextField
          placeholder={intl.formatMessage({id: 'LABELS.FIRST_NAME'})}
          {...formik.getFieldProps('first')}
          // className={clsx(
          //   'form-control bg-transparen',
          //   {'is-invalid': formik.touched.first && formik.errors.first},
          //   {
          //     'is-valid': formik.touched.first && !formik.errors.first && formik.values.first,
          //   }
          // )}
          // type='text'
          name='first'
          value={formik.values.first}
          onChange={formik.handleChange}
          autoComplete='off'
          characterLimit={50}
        />
        {formik.touched.first && formik.errors.first && (
          <div className='fv-plugins-message-container'>
            <span className=' text-danger'>{formik.errors.first}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'LABELS.LAST_NAME'})}
            </label>
            {/* end::Label */}
          </div>
        </div>
        <MuiTextField
          autoComplete='off'
          {...formik.getFieldProps('last')}
          // className={clsx(
          //   'form-control bg-transparen',
          //   {'is-invalid': formik.touched.last && formik.errors.last},
          //   {
          //     'is-valid': formik.touched.last && !formik.errors.last && formik.values.last,
          //   }
          // )}
          // type='text'
          name='last'
          placeholder={intl.formatMessage({ id: 'LABELS.LAST_NAME' })}
          characterLimit={50}
        />
        {formik.values.last && formik.touched.last && formik.errors.last && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='text-danger'>{formik.errors.last}</span>
            </div>
          </div>
        )}
      </div>
      {/* begin::Form group */}
      <div className='fv-row '>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>
              {intl.formatMessage({id: 'PROFILE.SETTING.EDIT.COMPANY'})}
            </label>
            {/* end::Label */}
          </div>
        </div>
        <MuiTextField
          autoComplete='off'
          {...formik.getFieldProps('company')}
          // className={clsx('form-control bg-transparen')}
          // type='text'
          name='company'
          placeholder={intl.formatMessage({ id: 'PROFILE.SETTING.EDIT.COMPANY' })}
          characterLimit={50}
        />
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 my-5'
          disabled={loading}
        >
          {!loading && (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'ACTIONS.CONFIRM'})}
            </span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'PLEASE.WAIT'})}

              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </form>
  )
}
