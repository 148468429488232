import {listen} from "../socketHook";
import {createSocketConnection} from '../setup';
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../setup";

type Options = {
    type: 'API_KEY_CREATED' | 'API_KEY_DELETED';
    callBack: (payload: any) => void;
};

export const useSocket = ({ type, callBack }: Options) => {
    const { user} = useSelector<RootState, any>(({auth}) => auth, shallowEqual);
    const channel = 'access' in user ? `contact.${user.id}` : `client.${user.id}`;

    createSocketConnection();

    switch (type) {
        case 'API_KEY_CREATED': {
            return listen(channel, 'ApiCreated', callBack);
        }
        case 'API_KEY_DELETED': {
            return listen(channel, 'ApiDeleted', callBack);
        }
    }
};
