
import { useQuery } from "react-query";
import Http, { _axios } from "../app/store/core/inceptor";


const prepareParams = (params: any) => {
  let formData = new FormData();

  for (let i in params) {
    switch (i) {
      case "full_access":
        formData.append(i, params[i] ? "1" : "0");
        break;
      case "access":
        formData.append(i, JSON.stringify(params[i]));
        break;
      case "file":
        formData.append("avatar", params["file"]);
        break;
      case "email_preferences":
        let categories=params[i]??[]
        
        categories.forEach((category:any) => {
          
          formData.append("email_preferences[]", category);
        });
         break;
      default:
        formData.append(i, params[i]);
    }
  }

  return formData;
};

export const Contact = {
  getList: () => _axios.get(`/contacts`),
  profile: (id: number) => _axios.get(`/contacts/${id}`),
  add: (params: any) => {
    return _axios.post(`/contacts`, prepareParams(params), {
      headers: {
        "Content-Type": "multipart/form-data",
        enctype: "multipart/form-data",
      },
    });
  },
  update: (id: number, params: any) => {
    // delete params.email
    // delete params.username
    let formData = prepareParams(params);

    // formData.append("_method", "PUT");

    return _axios.post(`/contacts/${id}/edit`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        enctype: "multipart/form-data",
      },
    });
  },
  partialUpdate: (id: number, params: object | null) =>
  _axios.post(`/contacts/${id}/edit`, params),
  delete: (id: number) => _axios.delete(`/contacts/${id}`),
  deleteAvatar: (id:number|string) => Http.delete(`contacts/${id}/avatar`),
  getAvatar: (id: number) => _axios.get(`/contacts/${id}/avatar`, { responseType: 'blob' }),
};


export const useContacts=()=>{
  const query=useQuery(['contacts'],Contact.getList,{
    cacheTime: 0,
    staleTime: 0,
  })

  return query
}


export const ResendConfirmationContactReq=(id:number)=>Http.get(`contacts/${id}/resend-confirm-email`)


export const contactShowDashboard=(id:number)=>Http.post("/client-dashboard/enable",{id})
export const contactHideDashboard = (id: number) => Http.post("/client-dashboard/disable", { id })

export const inviteSubAccount = (params: any) => _axios.post(`/contacts/invite`, prepareParams(params),{
  headers: {
    'Content-Type': 'multipart/form-data',
    enctype: 'multipart/form-data',
  },
})