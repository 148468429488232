import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import React from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import dayjs, {Dayjs} from 'dayjs'
import {LocalizationProvider, StaticDatePicker, StaticDateTimePicker} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {Form, Spinner} from 'react-bootstrap'
import useCssVariable from '../../Hooks/useCssVariable'

interface ICustomTimeModal {
  open: boolean
  handleClose: () => void
  title?: string
  onSubmit:(time:Dayjs)=>any
  pending?:boolean
}

const CustomTimeModal = (props: ICustomTimeModal) => {
  const intl = useIntl()
  const [value, setValue] = React.useState<Dayjs | null>(dayjs())
  const {handleClose, open, title = intl.formatMessage({id: 'LABELS.PAUSE_CHECK'}) , onSubmit ,pending=false} = props

  const secondary = useCssVariable('--kt-secondary')


  const handleSubmit=()=>{
    if (!value) {
        return
    }
    onSubmit(value)
  }



  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiPickerStaticWrapper-root ': {
          '& .MuiDialogActions-spacing': {
            display: 'none',
          },
          '& .MuiPickersToolbar-penIconButton ': {
            display: 'none',
          },
          '& .MuiTypography-overline': {
            display: 'none',
          },
        },
        '& .MuiPickerStaticWrapper-content': {
          backgroundColor: secondary,
          borderRadius:"0.5rem",

        },
      }}
    >
      <DialogTitle className='d-flex align-items-start justify-content-between px-4'>
        <h2 className='text-dark  fw-bold'>{title}</h2>
        <span className='fas fa-times fs-1 cursor-pointer' onClick={handleClose} />
      </DialogTitle>
      <DialogContent className='text-dark pt-2 px-4'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateTimePicker
            onChange={(newValue) => setValue(newValue)}
            renderInput={() => <Form.Control />}
            value={value}
            disablePast
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions className='px-4'>
        <button className='btn btn-sm btn-secondary  w-100px ' onClick={handleClose}>
          <FormattedMessage id='ACTIONS.CANCEL' />
        </button>

        <button className='btn btn-sm btn-primary w-100px' onClick={handleSubmit}>
         {pending ? <Spinner animation='grow' size='sm'/>: <FormattedMessage id='LABELS.PAUSE' />}
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomTimeModal
