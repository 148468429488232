import {Droppable} from '@hello-pangea/dnd'
import React from 'react'
import Widget from './Widget'

interface Props {
  column: {
    id: string
    widgetId: string[]
  }
  widgets: {id: string,status:boolean,hasAccess:boolean}[]
}

const Column = ({column, widgets}: Props) => {
  return (
    <div className='h-100'>
      <Droppable droppableId={column.id}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className='d-flex flex-column gap-3 h-100'
          >
            {widgets.map((widget, index) => {
              if ("hasAccess" in widget) {
                if (!widget?.hasAccess) {
                  return;
                }
              }
              
              return <Widget key={widget.id+"/"+index} widget={widget} index={index} />
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

export default Column
