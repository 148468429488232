import axios from 'axios'
import {AuthModel, UserModel} from './_models'

import  { _axios } from '../../../store/core/inceptor'
const API_URL = process.env.REACT_APP_API_URL
const ONLY_ROUTE=process.env.REACT_APP_API_URL_NOT_VERSION

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${ONLY_ROUTE}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`



export function login(loginName: string, password: string,rememberMe:boolean) {
  return axios.post(`${ONLY_ROUTE}/login`,{
    loginName: loginName,
    password: password,
    rememberMe:rememberMe?'on':'off',
    no_redirect: 'true',
  }).then(res=>res)
  .catch(err=>Promise.reject(err))
  
}

export function getToken() {

  return axios({
    method: 'get',
    url: `${ONLY_ROUTE}/sanctum/csrf-cookie`,
  })
}

export function getProfile() {

  return _axios.get( '/client/profile')
}


export function getConfig() {
  
  return _axios.get('/client/config')
}


// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return _axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return _axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}


export function twoFALogin(oneTimePassword:string,rememberMe:boolean ) {
  
  return _axios.post(`${API_URL}/login/2fa/verify`,{
    one_time_password:oneTimePassword,
    no_redirect: true,
    rememberMe:rememberMe
})
  // return axios({
  //   method: 'post',
  //   url: `${ONLY_ROUTE}/login/2fa/verify`,
  //   data: $.param({
  //     one_time_password:oneTimePassword,
  //     no_redirect: true,
  // }),
  //   headers: {
  //       'x-xsrf-token': token
  //   }
  // })
}