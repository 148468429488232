import { List, ListItem, ListItemButton, Switch} from '@mui/material'
import React, {useState} from 'react'
import {Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import { notify } from '../../../helpers'
import {contactHideDashboard, contactShowDashboard} from '../../../services/Contact'
import useCustomizedDashboardValues, {
  customizedDashboardTypes,
  DashboardType,
} from '../../modules/context/CustomizedDashboardCtx'

const ShareDashboard = ({clientDashboards}: {clientDashboards: DashboardType[]}) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [, dispatch] = useCustomizedDashboardValues()

  const handleToggle = async (dash: Record<string, any>) => {
    try {
      const isHidden = dash?.hidden === true
      let flagHidden
      let request
      if (isHidden) {
        request = contactShowDashboard
        flagHidden = false
      } else {
        request = contactHideDashboard
        flagHidden = true
      }
      setLoading(true)
      const rep = await request(dash.id)

      if (flagHidden) {
        notify({
          type:"success",
          message:intl.formatMessage({id:"LABELS.DISABLE.DASHBOARD"},{name:dash.name})
        })
      }else{
        notify({
          type:"success",
          message:intl.formatMessage({id:"LABELS.ENABLE.DASHBOARD"},{name:dash.name})
        })
      }

      dispatch({
        type: customizedDashboardTypes.TOGGLE_SHOW,
        payload: {
          id: dash.id,
          hidden: flagHidden,
        },
      })
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <div className='position-relative'>
    {loading&&  <div className='d-flex flex-center bg-secondary opacity-50 h-100 position-absolute w-100'>
        <Spinner animation='grow' size='sm' variant='primary' />
      </div>}
      <List dense sx={{width: '100%'}}>
        {!!clientDashboards?.length &&
          clientDashboards?.map((dash: Record<string, any>) => {
            const labelId = `checkbox-list-secondary-label-${dash?.name}`
            return (
              <ListItem
                key={dash.id}
                secondaryAction={
                  <Switch size='small' checked={!!!dash?.hidden} inputProps={{'aria-labelledby': labelId}} onChange={() => handleToggle(dash)} />
                }
                disablePadding
                className='mb-2'
                
              >
                <ListItemButton
                  onClick={() => handleToggle(dash)}
                  className='p-2 rounded'
                  disabled={loading}
                >
                  <div className='d-flex flex-column fs-7'>
                    <strong>{dash?.name}</strong>
                  </div>
                </ListItemButton>
              </ListItem>
            )
          })}
      </List>
    </div>
  )
}

export default ShareDashboard
