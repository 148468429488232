import React from 'react'
import {Action, IWidgetType, State} from './reducer'
import ServerSelectGraphs from './ServerSelectGraphs'

interface IStep3 {
  memoizedDispatch: (data: Action) => void
  widgetType: string
  moduleId: number[]
  serverId: number | null
  widgets:IWidgetType[]
  graphAssignMode:boolean
}

const Step3 = (props: IStep3) => {
  const {memoizedDispatch, moduleId, serverId, widgetType,widgets,graphAssignMode} = props
  const handleSelectGraph= (data: any,isCheck:boolean) => {
    if (isCheck) {
      memoizedDispatch({
        type: 'TOGGLE_CHECK',
        payload: data?.id,
      })
    }
    memoizedDispatch({
      type:"TOGGLE_WIDGET",
      payload:{...data,serverId,id:`${data.id}-${serverId}`}
    })
    
  }
  return (
    <div>
      {widgetType === 'server' ? (
        <ServerSelectGraphs serverId={serverId} onSelect={handleSelectGraph} moduleIds={moduleId} widgets={widgets} graphAssignMode={graphAssignMode}/>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Step3
