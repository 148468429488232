import { useEffect } from 'react';


const MetaHead = ({ title }:{title:string}) => {
  useEffect(() => {
    document.title = title;

    // Additional logic or side effects can be added here

  }, [title]);

  return <></>; // or any other JSX if needed
};

export default MetaHead;
