import * as React from 'react'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import useCssVariable from '../../../Hooks/useCssVariable'
import CircularProgress from '@mui/material/CircularProgress';

interface IMultipleSelect {
  options: {
    title: string
    value: any
  }[]
  onChangeHandler: (values: any) => void
  value: any[]
  placeHolder?:string
  size?:string
  isLoading?:boolean
}

export default function MultipleSelect(props: IMultipleSelect) {
  const {options, onChangeHandler, value,placeHolder,size,isLoading} = props
  const borderColor = useCssVariable('--kt-input-border-color')

  return (
    <Autocomplete
      fullWidth
      sx={{
        width: '100%',

        '& input': {
          width: '100%',
        },
        '& .MuiOutlinedInput-root': {
          minHeight: size==="small"? "auto":'43px',
          '& fieldset': {
            // borderColor: borderColor,
            // borderWidth: '1px',
          },
          '&:hover fieldset': {
            // borderColor: borderColor,
            // borderWidth: '1px',
          },
          '&.Mui-focused fieldset': {
            borderColor: borderColor,
            borderWidth: '1px',
          },
        },
      }}
      multiple
      value={value}
      id='checkboxes-tags-demo'
      options={options}
      size='small'
      disableCloseOnSelect
      onChange={(event: any, newValue: any) => {
        onChangeHandler(newValue)
      }}
      slotProps={{
        paper:{
          className:"bg-custom",
        },


      }}
      limitTags={1}
      loading={isLoading}
      isOptionEqualToValue={(option,value)=>{
        return option?.value===value?.value
      }}
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, {selected}) => (
        <li {...props} className={`${option?.isSub ? 'ps-6' : ''} ${props?.className} p-0 `} style={{backgroundColor:selected?borderColor:''}}>
          <Checkbox
            // icon={icon}
            // checkedIcon={checkedIcon}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={(params) => <TextField {...params} placeholder={placeHolder}  InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {isLoading ? <CircularProgress color="inherit" size={15} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }} />}
    />
  )
}
