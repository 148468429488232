import React from 'react'
import {useIntl} from 'react-intl'
import ReactTooltip, { Place } from 'react-tooltip'
import {returnStatus} from './ChecksStatusBox'

interface Props {
  status: number
  is_paused: boolean
  fontSize?: string
  is_under_maintenance?: boolean
  upColor?: string
  downColor?: string
  pauseColor?: string
  maintenanceColor?: string
  place?:Place
  noMargin?:boolean
}

const ChecksStatusArrow = ({
  status = 1,
  is_paused,
  fontSize = '2rem',
  is_under_maintenance,
  upColor,
  downColor,
  maintenanceColor,
  pauseColor,
  place="right",
  noMargin
}: Props) => {
  const bg = is_paused
    ? 'text-dark'
    : status === 1
    ? 'text-success'
    : status === 0
    ? 'text-danger'
    : 'text-dark'

  const id = Math.random()
  const intl = useIntl()

  return (
    <>
      <div
        data-tip
        data-for={String(id)}
        className={`${bg} d-inline ${!noMargin ?"me-2":""} position-relative `}
        style={{zIndex: 10}}
      >
        {!!is_under_maintenance ? (
          <span className='fas fa-wrench fs-2 ' style={{color: maintenanceColor}} />
        ) : is_paused ? (
          <span className='fas fa-pause-circle ' style={{fontSize,color: pauseColor}} />
        ) : status === 1 ? (
          <span className='fas fa-arrow-alt-circle-up  ' style={{fontSize, color: upColor}} />
        ) : (
          <span className='fas fa-arrow-circle-down' style={{fontSize ,color:downColor}} />
        )}
      </div>
      <ReactTooltip id={String(id)} aria-haspopup='true' place={place}>
        {intl.formatMessage({id: returnStatus(status, is_paused, !!is_under_maintenance)})}
      </ReactTooltip>
    </>
  )
}

export default ChecksStatusArrow
