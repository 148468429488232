import React, {Dispatch, useContext, useReducer} from 'react'
import { useQuery } from 'react-query'
import { getDashboards } from '../../../services/customizedDashboard'
import {useUpdateEffect} from '../../Hooks/useUpdateEffect'
import type {State} from '../../pages/customizedDashboard/CustomizedForm/reducer'
import { createDynamicColObject } from '../../pages/customizedDashboard/createDynamicColObject'
import Widget from '../../pages/dashboard/draggable/Widget'

type Column = {
  id: string;
  widgetsIds: string[];
};

type GridListType = 'mode_2' | 'mode_3' | 'mode_4';

type Mode = {
  [key: string]: Column;
};

export type DashboardType = {
  name: string
  id: string
  widgets: State
  default: number
  columns: {
    [key: string]: {
      id: string
      widgetsIds: string[]
    }
  }
  // mode_3: any;
  // mode_4: any;
  // mode_2: any;
  order: number
  column_order?: string[]
  hidden?:boolean
  client_id?: number

}

export const initialData: DashboardType = {
  name: '',
  id: '',
  widgets: {
  widgets: [],
    mode_2: {
      'column-1': {
        id: 'column-1',
        widgetsIds: [],
      },
      'column-2': {
        id: 'column-2',
        widgetsIds: [],
      },
    },
    mode_3: {
      'column-1': {
        id: 'column-1',
        widgetsIds: [],
      },
      'column-2': {
        id: 'column-2',
        widgetsIds: [],
      },
      'column-3': {
        id: 'column-3',
        widgetsIds: [],
      },
    },
    mode_4: {
      'column-1': {
        id: 'column-1',
        widgetsIds: [],
      },
      'column-2': {
        id: 'column-2',
        widgetsIds: [],
      },
      'column-3': {
        id: 'column-3',
        widgetsIds: [],
      },
      'column-4': {
        id: 'column-4',
        widgetsIds: [],
      },
    },
  },
  columns: {
    'column-1': {
      id: 'column-1',
      widgetsIds: [],
    },
    'column-2': {
      id: 'column-2',
      widgetsIds: [],
    },
  },
  default:0,
  // Facilitate reordering of the columns
  column_order: ['column-1', 'column-2', 'column-3', 'column-4'],
  order: 1,
}

/* ========================================================================== */
/*                                ACTION TYPES                                */
/* ========================================================================== */

export const customizedDashboardTypes = {
  RE_ORDER: 'RE_ORDER',
  DISABLE: 'DISABLE',
  ENABLE: 'ENABLE',
  RESET: 'RESET',
  INITIAL: 'INITIAL',
  ADD_WIDGET: 'ADD_WIDGET',
  ADD_DASHBOARD: 'ADD_DASHBOARD',
  EDIT_DASHBOARD: 'EDIT_DASHBOARD',
  REMOVE_DASHBOARD: 'REMOVE_DASHBOARD',
  REMOVE_WIDGET: 'REMOVE_WIDGET',
  EDIT_WIDGET: 'EDIT_WIDGET',
  TOGGLE_SHOW: "TOGGLE_SHOW",
  DEFAULT_DASHBOARD: "DEFAULT_DASHBOARD"
}

type DashboardAction = {
  type: string
  payload: any
}

/* ========================================================================== */
/*                                   REDUCER                                  */
/* ========================================================================== */

const DashboardReducer = (
  state: DashboardType[],
  {type, payload}: DashboardAction
): DashboardType[] => {

  switch (type) {
    case customizedDashboardTypes.ADD_DASHBOARD:
      return [...state, payload]

    case customizedDashboardTypes.EDIT_DASHBOARD:
      const findedIndex = state.findIndex((dash) => dash.id == payload.id)
      const cloneState = [...state]
      const keyName = Object.keys(payload)[1]
      const payloadValue = Object.values(payload)[1]
      if (findedIndex >= 0) {
        cloneState[findedIndex] = {
          ...state[findedIndex],
          [keyName]: payloadValue,
        }

        return cloneState
      }
      return [...state]

    case customizedDashboardTypes.REMOVE_DASHBOARD:
      const filtered = state.filter((dash) => dash.id !== payload.id)

      return filtered

      case customizedDashboardTypes.ADD_WIDGET: {
        const findedDashboardIndex = state?.findIndex((dash) => dash.id == payload.id);
        const gridList = JSON.parse(localStorage.getItem('customizedGraphGridList') as any) || []
        const {value}: {value:GridListType} = gridList?.find((item:any) => item.id == payload.id) || { value: 'mode_2' };

        const cloneState = [...state];
        if (findedDashboardIndex < 0) {
          return cloneState;
        }
        const findedDashboard = cloneState[findedDashboardIndex];
        const lenOfWigets = findedDashboard?.widgets?.widgets.length;
        const ID = `c-widget-${lenOfWigets}`;
      
        if (!findedDashboard.widgets[value]) {
          return cloneState;
        }
      
        const getLeastColumn = (mode:GridListType) => {
          const columns = Object.keys(findedDashboard.widgets[mode]);
          let minColumn = columns[0];
          let minCount = findedDashboard?.widgets[mode][minColumn].widgetsIds.length;
      
          columns.forEach(column => {
            const count = findedDashboard?.widgets[mode][column].widgetsIds.length;
            if (count < minCount) {
              minColumn = column;
              minCount = count;
            }
          });
      
          return minColumn;
        };
      
        const updatedWidgets = { ...findedDashboard.widgets };
        const updatedWidgetsArray = [...findedDashboard.widgets.widgets, { ...payload.state, id: ID }];

        (['mode_2', 'mode_3', 'mode_4'] as GridListType[]).forEach((mode) => {
          if (updatedWidgets[mode]) {
            const Column = getLeastColumn(mode);
            const columnWidgetsIds = updatedWidgets[mode][Column].widgetsIds || [];
            updatedWidgets[mode] = {
              ...updatedWidgets[mode],
              [Column]: {
                ...updatedWidgets[mode][Column],
                widgetsIds: [...new Set([...columnWidgetsIds, ID])],
              },
            };
          }
        });

      cloneState[findedDashboardIndex] = {
        ...findedDashboard,
        widgets: {
          ...updatedWidgets,
          widgets: updatedWidgetsArray,
        },
      };
      
        return cloneState;
      }
      

    case customizedDashboardTypes.RE_ORDER: {
      const findedDashboardIndex = state?.findIndex((dash) => dash.id == payload.id)
      const cloneState = [...state]
      if (findedDashboardIndex < 0) {
        return cloneState
      }
      cloneState[findedDashboardIndex] = {
        ...payload.newState,
      }
      return cloneState
    }

    case customizedDashboardTypes.DISABLE: {
      const findedDashboardIndex = state?.findIndex((dash) => dash.id == payload.DashId)
      const cloneState = [...state]
      if (findedDashboardIndex < 0) {
        return cloneState
      }
      let newWidgets = [...cloneState[findedDashboardIndex].widgets.widgets]
      const findedWidgetIndex=newWidgets?.findIndex((w)=>w.id==payload.id)
      if (findedWidgetIndex < 0) {
        return cloneState
      }
      newWidgets[findedWidgetIndex]={
        ...newWidgets[findedWidgetIndex],
        enable:false
      }
      
    
      cloneState[findedDashboardIndex] = {
        ...cloneState[findedDashboardIndex],
        widgets: {
          ...cloneState[findedDashboardIndex].widgets,
          widgets: newWidgets
        },
      }
      return cloneState
    }
    case customizedDashboardTypes.ENABLE: {
      const findedDashboardIndex = state?.findIndex((dash) => dash.id == payload.DashId)
      const cloneState = [...state]
      if (findedDashboardIndex < 0) {
        return cloneState
      }
      let newWidgets = [...cloneState[findedDashboardIndex].widgets.widgets]

      const findedWidgetIndex=newWidgets?.findIndex((w)=>w.id==payload.id)
      if (findedWidgetIndex < 0) {
        return cloneState
      }
      newWidgets[findedWidgetIndex]={
        ...newWidgets[findedWidgetIndex],
        enable:true
      }
      
    
      cloneState[findedDashboardIndex] = {
        ...cloneState[findedDashboardIndex],
        widgets: {
          ...cloneState[findedDashboardIndex].widgets,
          widgets: newWidgets
        },
      }
      return cloneState
    }

    case customizedDashboardTypes.REMOVE_WIDGET: {
      const findedDashboardIndex = state?.findIndex((dash) => dash.id == payload.DashId)
      const findedDashboard  = state?.find(
        (dash) => dash.id == payload.DashId
      )
    
      const cloneState = [...state]
      if (findedDashboardIndex < 0) {
        return cloneState
      }
      const removeWidgetById = (obj:any, widgetId:string) => {
        const modes = ['mode_2', 'mode_3', 'mode_4'];
        modes.forEach(mode => {
            Object.values(obj.widgets[mode])?.forEach((column:any) => {
                const index = column.widgetsIds.indexOf(widgetId);
                if (index !== -1) {
                    column.widgetsIds.splice(index, 1);
                }
            });
        });
        const widgetIndex = obj.widgets.widgets.findIndex((widget:any) => widget.id === widgetId);
        if (widgetIndex !== -1) {
            obj.widgets.widgets.splice(widgetIndex, 1);
        }
    
        return obj;
      }
      cloneState[findedDashboardIndex] = {
        ...removeWidgetById(findedDashboard, payload.id),

      }
      
      return cloneState
    }

    case customizedDashboardTypes.EDIT_WIDGET: {
      const findedDashboardIndex = state?.findIndex((dash) => dash.id == payload.id)
      const cloneState = [...state]
      if (findedDashboardIndex < 0) {
        return cloneState
      }
      const findedDashboard = cloneState[findedDashboardIndex]

      const findedWidgetIndex=findedDashboard?.widgets?.widgets?.findIndex((w)=>w.id==payload.state.id)
      if (findedWidgetIndex < 0) {
        return cloneState
      }
      findedDashboard.widgets.widgets[findedWidgetIndex]={
        ...payload.state
      }

      cloneState[findedDashboardIndex] = {
        ...findedDashboard,
        
      }
      return cloneState
    }

    case customizedDashboardTypes.INITIAL: {
      return payload
    }


    case customizedDashboardTypes.TOGGLE_SHOW:{
      const findedIndex = state.findIndex((dash) => dash.id == payload.id)
      const cloneState = [...state]
      if (findedIndex >= 0) {
        cloneState[findedIndex] = {
          ...state[findedIndex],
          hidden: payload.hidden,
        }

        return cloneState
      }
      return [...state]
    }
    case customizedDashboardTypes.DEFAULT_DASHBOARD: {
      const {id} = payload
      return state.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            default: item.default === 1 ? 0 : 1,
          }
        } else {
          return {
            ...item,
            default: 0,
          }
        }
      })
    }
       
    // case customizedDashboardTypes.RESET:
    //   return {...initialData}

    default:
      return state
  }
}

/* ========================================================================== */
/*                           CUSTOM HOOK FOR CONTEXT                          */
/* ========================================================================== */

type DashboardContextType = [DashboardType[], Dispatch<DashboardAction>]

const DashboardContext = React.createContext<DashboardContextType>([[], () => {}])

const useCustomizedDashboardValues = (): DashboardContextType => {
  const context = useContext(DashboardContext)

  if (!context) {
    throw new Error('useDashboardValues must be used within a DashboardProvider ')
  }
  return context
}

/* ========================================================================== */
/*                              CONTEXT PROVIDER                              */
/* ========================================================================== */

type CustomizedDashboardProviderProps = {
  children: React.ReactNode
}

export const CustomizedDashboardProvider = ({children}: CustomizedDashboardProviderProps) => {
  // Load state from local storage, if available
  // const initialLocalStorageState = localStorage.getItem('dashboardState')
  // const initialDataOrLocalStorage = initialLocalStorageState
  //   ? JSON.parse(initialLocalStorageState)
  //   : []
  const [state, dispatch] = useReducer(DashboardReducer, [])
  useQuery(['customized-dashboard'], getDashboards, {
    onSuccess(data: any) {
      const updatedData = data?.map((item: any) => {
        if (item.widgets?.mode_2 && item.widgets?.mode_3 && item.widgets?.mode_4) {
          return item; // If modes already exist, return the item as is
        }
        
        return {
          ...item,
          widgets: createDynamicColObject(item)
        };
      });
            
      dispatch({
        type: customizedDashboardTypes.INITIAL,
        payload: updatedData,
      });

    },
  })
  // Save state to local storage whenever it changes
  // useUpdateEffect(() => {
  //   localStorage.setItem('dashboardState', JSON.stringify(state))
  // }, [state])

  return <DashboardContext.Provider value={[state, dispatch]}>{children}</DashboardContext.Provider>
}

export default useCustomizedDashboardValues

/* ---------------------------------- help ---------------------------------- */

// const [state, dispatch] = useCustomizedDashboardValues();

/* ------------------------------------ - ----------------------------------- */
