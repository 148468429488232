import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../modules/auth/models/UserModel'
import {RootState} from '../../setup'
// import {FallbackView} from '../../_metronic/partials'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {FormattedMessage, useIntl} from 'react-intl'
// import usePushNotifications from '../Hooks/usePushNotifications'

import AccessRoute from './AccessRoute'

import UpTime from '../pages/upTime/index'
import ServerDetails from '../pages/ServersOverview/ServerDetails'

//dashboard
import DashboardWrapper from '../pages/dashboard/DashboardWrapper'
//account
import Account from '../pages/account/Account'

import { CompleteProfile } from '../modules/auth/components/CompleteProfile'
import { AuthLayout } from '../../_metronic/layout/AuthLayout'
import moment from 'moment'
/* ------------------------------- //lazy load ------------------------------ */
// const Account = lazy(() => import('../pages/account/Account'))

//tickets
const Tickets = lazy(() => import('../pages/ticket/Tickets'))
const TicketAdd = lazy(() => import('../pages/ticket/TicketAdd'))
const TicketView = lazy(() => import('../pages/ticket/TicketView'))
//contacts
const ContactInvite = lazy(() => import('../pages/contact/ContactInvite'))
const ContactAdd = lazy(() => import('../pages/contact/ContactAdd'))
const ContactEdit = lazy(() => import('../pages/contact/ContactEdit'))
const ContactView = lazy(() => import('../pages/contact/ContactView'))
const Contacts = lazy(() => import('../pages/contact/Contacts'))
const ContactViewBlock = lazy(() => import('../modules/contact/ContactView'))

//profile
const ProfileOverview = lazy(() => import('../modules/client/ProfileOverview'))
const ClientSettings = lazy(() => import('../modules/client/ProfileSetting'))
const ContactSettings = lazy(() => import('../modules/contact/ProfileSetting'))
const ClientProfileHeader = lazy(() => import('../modules/client/ProfileHeader'))
const ContactProfileHeader = lazy(() => import('../modules/contact/ProfileHeader'))
const AccountPreferrence = lazy(() => import('../pages/client/AccountPreferrence'))

//server
const ServersPage = lazy(() => import('../pages/ServersPage'))
const ServerOverview = lazy(() => import('../pages/ServersOverview'))
// const ServerDetails = lazy(() => import('../pages/ServersOverview/ServerDetails'))
const IncidentsServer = lazy(() => import('../pages/ServersOverview/Incidents'))
const Statistics = lazy(() => import('../pages/ServersOverview/Statistics'))
const Processes = lazy(() => import('../pages/ServersOverview/Processes'))
const Integrations = lazy(() => import('../pages/ServersOverview/Integrations'))
const ServerServices = lazy(() => import('../pages/ServersOverview/services'))
const EditServer = lazy(() => import('../pages/ServersOverview/EditServer'))
const ServerMetrics = lazy(() => import('../pages/ServersOverview/Metrics'))

//uptime view
const UptimeOverviewPage = lazy(() => import('../pages/upTimeOverview'))
const UptimeOverviewSettings = lazy(() => import('../pages/upTimeOverview/EditPage'))

//uptime custom report
const UptimeCustomReport = lazy(() => import('../pages/upTime/customReport'))

//new monitoring
const GettingStarted = lazy(() => import('../pages/getting-started/GettingStarted'))

//import uptime
const ImportStepsConfig = lazy(() => import('../pages/importUptime/ImportStepsConfig'))

//logs
const Logs = lazy(() => import('../pages/log/Logs'))
const MailLogView = lazy(() => import('../pages/log/MailLogView'))

//email reports
const ReportsPage = lazy(() => import('../pages/Reports'))
const AddReports = lazy(() => import('../pages/AddOrEditReport'))

//maintenance
const Maintenance = lazy(() => import('../pages/maintenance'))
const MaintenanceAdd = lazy(() => import('../pages/maintenanceAddOrEdit'))

//public status
const PublicStatusPage = lazy(() => import('../pages/PublicStatus'))
const PublicStatusNew = lazy(() => import('../pages/PublicStatusNewOrEdit'))

//roles
const NewNotificationRoles = lazy(() => import('../pages/newNotificationRoles'))
const ConfigRole = lazy(() => import('../pages/newNotificationRoles/Config'))

//error
const Error403 = lazy(() => import('../pages/Error403'))
const Error404 = lazy(() => import('../pages/Error404'))

//wizard
const AccountWizard = lazy(() => import('../pages/AccountWizard'))

//billing
const Billing = lazy(() => import('../pages/Billing/index'))
const CreditCards = lazy(
  () => import('../pages/Billing/MainService/Financials/CreditCards/CreditCards')
)

//incident
const Incidents = lazy(() => import('../pages/Incidents'))
const IncidentOverview = lazy(() => import('../pages/IncidentOverview'))

//ssl
const SSLPage = lazy(() => import('../pages/SSL'))
const SSLView = lazy(() => import('../pages/SSLView'))
const SSLSettings = lazy(() => import('../pages/SSLView/EditPage'))

// custom dashboard
const CustomizedDashboard = lazy(() => import('../pages/customizedDashboard'))
const CustomizedMainPage = lazy(() => import('../pages/customizedDashboard/CustomizedMainPage'))

//search
const SearchPage = lazy(() => import('../pages/Search'))

//trigger management

const TriggerManagement = lazy(() => import('../pages/TriggerManagement'))

//zapier intg
const ZapierAuth = lazy(() => import('../pages/zapierAuth'))

//xitoring CLI
const CLIAuth = lazy(() => import('../pages/CLIAuth'))

//integrations
const IntegrationsPage = lazy(() => import('../pages/metricsPage'))


// import WizardsPage from '../modules/wizards/WizardsPage'
// import Profile from '../pages/Profile'

// import Plans from '../pages/Plans'

// const AccountEdit = lazy(() => import('../pages/account/AccountEdit'))

const PrivateRoutes = () => {
  // const location = useLocation()

  const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel
  const isCompeleteProfile: any = localStorage.getItem('complete-profile')
  
  const isSubaccount = 'access' in user
  const intl = useIntl()
  const accountBreadCrumbs: Array<PageLink> = [
    {
      title: 'Xitoring',
      path: '/',
      isSeparator: false,
      isActive: false,
    },
  ]
  // usePushNotifications()
  const moment = require('moment');

  const checkCreateAndProvider = (user:any) => {
    if (!!user) {
      const now = moment.utc();
      const createdAt = moment(user?.created_at);
      const secondsDifference = now.diff(createdAt, 'seconds');
      return secondsDifference < 12660 && user?.provider != null;
    } else {
       return false
    }
  } 
  const defaultDash = localStorage.getItem('defaultDash')
  const dashboardLocation = !!defaultDash ? `/customized-dashboard/${defaultDash}` : '/dashboard'
  
  return (
    <AccessRoute>
      <Routes>
        <Route element={(!isCompeleteProfile && checkCreateAndProvider(user)) ? <AuthLayout/> : <MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          <Route path='auth/*' element={<Navigate to={dashboardLocation} />} />
          {/* Pages */}
          <Route
            path='dashboard'
            element={
              <>
                <DashboardWrapper />
              </>
            }
          />
          <Route
            path='customized-dashboard'
            element={
              <SuspensedView>
                <CustomizedMainPage />
              </SuspensedView>
            }
          >
            <Route
              path=':id'
              element={
                <SuspensedView>
                  <CustomizedDashboard />
                </SuspensedView>
              }
            />
          </Route>

          {/* Lazy Modules */}
          <Route
            path='profile'
            element={
              <SuspensedView>
                {isSubaccount ? <ContactProfileHeader /> : <ClientProfileHeader />}
              </SuspensedView>
            }
          >
            {isSubaccount ? (
              <>
                <Route
                  path='overview'
                  element={
                    <SuspensedView>
                      {' '}
                      <PageTitle breadcrumbs={accountBreadCrumbs}>
                        {intl.formatMessage({id: 'PROFILE.OVERVIEW.TITLE'})}
                      </PageTitle>
                      <ProfileOverview />
                    </SuspensedView>
                  }
                />

                <Route
                  path='settings'
                  element={
                    <SuspensedView>
                      <PageTitle breadcrumbs={accountBreadCrumbs}>
                        {intl.formatMessage({id: 'PROFILE.OVERVIEW.EDIT'})}
                      </PageTitle>
                      <ContactSettings />
                    </SuspensedView>
                  }
                />
              </>
            ) : (
              <>
                <Route
                  path='overview'
                  element={
                    <SuspensedView>
                      <PageTitle breadcrumbs={accountBreadCrumbs}>
                        {intl.formatMessage({id: 'PROFILE.OVERVIEW.TITLE'})}
                      </PageTitle>
                      <ProfileOverview />
                    </SuspensedView>
                  }
                ></Route>
                <Route
                  path='settings'
                  element={
                    <SuspensedView>
                      <PageTitle breadcrumbs={accountBreadCrumbs}>
                        {intl.formatMessage({id: 'PROFILE.OVERVIEW.EDIT'})}
                      </PageTitle>
                      <ClientSettings />
                    </SuspensedView>
                  }
                ></Route>
               
              </>
            )}
            {/* <Profile /> */}
            <Route
                      path='preferrence'
                      element={
                        <SuspensedView>
                          <PageTitle breadcrumbs={accountBreadCrumbs}>
                            {intl.formatMessage({id: 'LABELS.ACCOUNT.PREFERRENCE'})}
                          </PageTitle>
                          <AccountPreferrence />
                        </SuspensedView>
                      }
                    />
          </Route>

          <Route
            path='/new'
            element={
              <SuspensedView>
                <GettingStarted />
              </SuspensedView>
            }
          />
          <Route
            path='/import-uptime'
            element={
              <SuspensedView>
                <ImportStepsConfig />
              </SuspensedView>
            }
          />
          <Route
            path='/tickets'
            element={
              <SuspensedView>
                <Tickets />
              </SuspensedView>
            }
          />
          <Route
            path='/403'
            element={
              <SuspensedView>
                <Error403 />
              </SuspensedView>
            }
          />
          <Route
            path='/404'
            element={
              <SuspensedView>
                <Error404 />
              </SuspensedView>
            }
          />

          <Route
            path='/wizard'
            element={
              <SuspensedView>
                <AccountWizard />
              </SuspensedView>
            }
          />

          <Route
            path='/servers'
            element={
              <SuspensedView>
                <ServersPage />
              </SuspensedView>
            }
          />
          <Route
            path='/servers/:id'
            element={
              <SuspensedView>
                <ServerOverview />
              </SuspensedView>
            }
          >
            <Route
              path='overview'
              element={
                <SuspensedView>
                  <ServerDetails />
                </SuspensedView>
              }
            />
            <Route
              path='incidents'
              element={
                <SuspensedView>
                  <IncidentsServer />
                </SuspensedView>
              }
            />
            <Route
              path='services'
              element={
                <SuspensedView>
                  <ServerServices />
                </SuspensedView>
              }
            />
            {/* <Route
              path='statistics'
              element={
                <SuspensedView>
                  <Statistics />
                </SuspensedView>
              }
            /> */}
            {/* <Route
              path='triggers'
              element={
                <SuspensedView>
                  <Triggers />
                </SuspensedView>
              }
            /> */}
            <Route
              path='processes'
              element={
                <SuspensedView>
                  <Processes />
                </SuspensedView>
              }
            />
             <Route
              path='metrics'
              element={
                <SuspensedView>
                  <ServerMetrics />
                </SuspensedView>
              }
            />
            <Route
              path='settings'
              element={
                <SuspensedView>
                  <EditServer />
                </SuspensedView>
              }
            />
          </Route>
          <Route path='/uptime' element={<UpTime />} />
          <Route
            path='/uptime/:id'
            element={
              <SuspensedView>
                <UptimeOverviewPage />
              </SuspensedView>
            }
          />

          <Route
            path='/uptime/:id/settings'
            element={
              <SuspensedView>
                <UptimeOverviewSettings />
              </SuspensedView>
            }
          />

          <Route
            path='/ssl'
            element={
              <SuspensedView>
                <SSLPage />
              </SuspensedView>
            }
          />
          <Route
            path='/ssl/:id'
            element={
              <SuspensedView>
                <SSLView />
              </SuspensedView>
            }
          />
          <Route
            path='/ssl/:id/settings'
            element={
              <SuspensedView>
                <SSLSettings />
              </SuspensedView>
            }
          />
          <Route
            path='/serversOverview'
            element={
              <SuspensedView>
                <ServerOverview />
              </SuspensedView>
            }
          />
          <Route
            path='/incidents'
            element={
              <SuspensedView>
                <Incidents />
              </SuspensedView>
            }
          />
          <Route
            path='/incidents/:id'
            element={
              <SuspensedView>
                <IncidentOverview />
              </SuspensedView>
            }
          />
          <Route
            path='/notificationRoles'
            element={
              <SuspensedView>
                <NewNotificationRoles />
              </SuspensedView>
            }
          >
            <Route
              path=':id'
              element={
                <SuspensedView>
                  <ConfigRole />
                </SuspensedView>
              }
            />
          </Route>
          <Route
            path='/billing'
            element={
              <SuspensedView>
                <Billing />
              </SuspensedView>
            }
          />
          <Route
            path='/billing/credit-cards'
            element={
              <SuspensedView>
                <CreditCards />
              </SuspensedView>
            }
          />

          <Route
            path='/tickets/new'
            element={
              <SuspensedView>
                <TicketAdd />
              </SuspensedView>
            }
          />
          <Route
            path='/tickets/:id'
            element={
              <SuspensedView>
                <TicketView />
              </SuspensedView>
            }
          />
          <Route
            path='/statuses/:id/tickets'
            element={
              <SuspensedView>
                <Tickets />
              </SuspensedView>
            }
          />
          <Route
            path='/account'
            element={
              <SuspensedView>
                <Account />
              </SuspensedView>
            }
          />
          {/* <Route
            path='/account/edit'
            element={
              <SuspensedView>
                <AccountEdit />
              </SuspensedView>
            }
          /> */}

          <Route
            path='/account/sub-accounts/invite'
            element={
              <SuspensedView>
                <ContactInvite />
              </SuspensedView>
            }
          />
          <Route
            path='/account/sub-accounts/add'
            element={
              <SuspensedView>
                <ContactAdd />
              </SuspensedView>
            }
          />
          <Route
            path='/account/sub-accounts'
            element={
              <SuspensedView>
                <Contacts />
              </SuspensedView>
            }
          >
            <Route
              path=':id'
              element={
                <SuspensedView>
                  <ContactViewBlock />
                </SuspensedView>
              }
            />
          </Route>
          <Route
            path='/account/sub-accounts/:id/edit'
            element={
              <SuspensedView>
                <ContactEdit />
              </SuspensedView>
            }
          />
          <Route
            path='/logs/mail/:id'
            element={
              <SuspensedView>
                <MailLogView />
              </SuspensedView>
            }
          />
          <Route
            path='/logs/:tab'
            element={
              <SuspensedView>
                <Logs />
              </SuspensedView>
            }
          />
          {/* <Route path='/plans' element={<Plans/>}/> */}

          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />

          <Route
            path='/reports'
            element={
              <SuspensedView>
                <ReportsPage />
              </SuspensedView>
            }
          />
          <Route
            path='/reports/:id'
            element={
              <SuspensedView>
                <AddReports />
              </SuspensedView>
            }
          />
          <Route
            path='/reports/uptime'
            element={
              <SuspensedView>
                <UptimeCustomReport />
              </SuspensedView>
            }
          />
          <Route
            path='/maintenance'
            element={
              <SuspensedView>
                <Maintenance />
              </SuspensedView>
            }
          />
          <Route
            path='/maintenance/:id'
            element={
              <SuspensedView>
                <MaintenanceAdd />
              </SuspensedView>
            }
          />
          <Route
            path='/public-status-page'
            element={
              <SuspensedView>
                <PublicStatusPage />
              </SuspensedView>
            }
          />
          <Route
            path='/public-status-page/:id'
            element={
              <SuspensedView>
                <PublicStatusNew />
              </SuspensedView>
            }
          />
          <Route
            path='/search'
            element={
              <SuspensedView>
                <SearchPage />
              </SuspensedView>
            }
          />
          <Route
            path='/trigger-management'
            element={
              <SuspensedView>
                <TriggerManagement />
              </SuspensedView>
            }
          />
          <Route
            path='/zapier'
            element={
              <SuspensedView>
                <ZapierAuth />
              </SuspensedView>
            }
          />
           <Route
            path='/cli'
            element={
              <SuspensedView>
                <CLIAuth />
              </SuspensedView>
            }
          />
           <Route
            path='/complete-profile'
            element={
              <SuspensedView>
                <CompleteProfile />
              </SuspensedView>
            }
          />

          <Route
            path='/metrics'
            element={
              <SuspensedView>
                <IntegrationsPage />
              </SuspensedView>
            }
          />
        </Route>
      </Routes>
    </AccessRoute>
  )
}

const SuspensedView: FC<{children: React.ReactNode}> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 3,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
