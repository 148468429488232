import React from 'react'
import ReactTooltip from 'react-tooltip'

interface ILongTitle {
  title: string
  id: string
  className?: string
  maxWidth?: boolean
}

const LongTitle = (props: ILongTitle) => {
  const {title, id, className, maxWidth} = props
  return (
    <>
      <span
        className={`${className} text-truncate d-block  fw-bold mb-1`}
        data-tip
        data-for={id}
        style={{
          fontSize: '11px',
          ...(maxWidth ? {maxWidth: '18rem',width: '100%'} : {width: '16ch'}),
        }}
      >
        {title}
      </span>
      {title?.length >= 14 && (
        <ReactTooltip id={id} className='tooltip-wrapper'>
          {title}
        </ReactTooltip>
      )}
    </>
  )
}

export default LongTitle
