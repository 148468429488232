import React, {useEffect, useState} from 'react'
import useAccountScore from '../../../Hooks/useAccountScore'
import {useWindowSize} from '../../../Hooks/useWindowSize'
import {Dialog, DialogActions, DialogContent, DialogTitle, Paper} from '@mui/material'
import {useIntl} from 'react-intl'
import useUser from '../../../Hooks/useUser'
import { useUpdateEffect } from '../../../Hooks/useUpdateEffect'

const Confetti = React.lazy(() => import('react-confetti'))

const Congrate = () => {
  const {score} = useAccountScore()
  const {width, height} = useWindowSize()
  const user=useUser()

  const [open, setOpen] = useState(false)

  const intl = useIntl()
  const onClose = () => {
    setOpen(false)
  }

  useUpdateEffect(() => {
    if (!user?.wizard?.completedSteps?.is_force) {
      if (score === 100 ) {
        setOpen(true)
      } else {
        setOpen(false)
      }
    }

  }, [score])

  return (
    <>
      <Confetti
        width={width?width - 50:500}
        height={height?height - 50:500}
        run={score === 100}
        recycle={true}
        tweenDuration={20000}
        numberOfPieces={open ?400:0}
        gravity={0.1}
      />

      <Dialog onClose={onClose} open={open} PaperComponent={Paper} disableEnforceFocus>
        <DialogTitle
          className='d-flex align-items-start justify-content-between text-dark  fw-bold'
          component={'h1'}
        >
          {intl.formatMessage({id: 'LABELS.CONGRATE'})}

          <span className='fas fa-times fs-1 cursor-pointer' onClick={onClose} />
        </DialogTitle>
        <DialogContent className='text-dark pt-2'>
          {intl.formatMessage({id: 'LABELS.CONGRATE.TEXT'})}
        </DialogContent>
        <DialogActions>
          <button className='btn btn-sm btn-secondary ' onClick={onClose}>
            {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
          </button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Congrate
