import {call, put, takeEvery} from 'redux-saga/effects'
import { handleErrorWithStatusCode } from '../../../modules/widgets/components/Common'
import {types} from '../actionTypes'
import { addNewCheckRequest, getModuleCount, getUpTime } from './requests'

interface Response {
    data: any
  }
function* requestModuleCount() {
    try {
      const response: Response = yield call(getModuleCount)
      yield put({
        type: types.GET_MODULE_COUNT_SUCCESS,
        payload:response.data
  
      })
    } catch (e: any) {
      yield put({
        type: types.GET_MODULE_COUNT_ERROR,
        error:e.data.message
  
      })
    }
  }

  function* requestUpTime(action:any) {
    try {
      const response: Response = yield call(getUpTime,action.params)
      yield put({
        type: types.GET_UPTIME_SUCCESS,
        payload:response.data
  
      })
    } catch (e: any) {
      yield put({
        type: types.GET_UPTIME_ERROR,
        error:e.data.message
  
      })
    }
  }

  
  function* addNewCheckHandler(action:any) {
    try {
      const response: Response = yield call(addNewCheckRequest,action.params)
      yield put({
        type: types.ADD_NEW_CHECK_SUCCESS,
        payload:response.data
  
      })
    } catch (e: any) {
      yield put({
        type: types.ADD_NEW_CHECK_ERROR,
        error:handleErrorWithStatusCode(e)
  
      })
    }
  }

function* watcherUpTimeSaga() {
    yield takeEvery(types.GET_MODULE_COUNT, requestModuleCount)
    yield takeEvery(types.GET_UPTIME, requestUpTime)
    yield takeEvery(types.GET_UPTIME_REFETCH, requestUpTime)
    yield takeEvery(types.ADD_NEW_CHECK, addNewCheckHandler)



  }
  
  export default watcherUpTimeSaga
  