import Swal from "sweetalert2";
import {toast} from "react-toastify";

interface Confirm {
    title: string
    text?: string
    callback: any
  }
  

export function confirm({title, text='', callback}: Confirm) {

    Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm'
    }).then((result:any) => {
        if (result.isConfirmed) {
            callback();
        }
    });
}

interface Notify {
    type: string
    message: any
}

export function notify({type, message}: Notify) {
    
    const position = 'bottom-right';
    const theme=localStorage.getItem("kt_theme_mode_menu")==="dark"?"dark":"light";

    if (type == 'success') {
        return toast.success(message, {position: position,theme:theme});
    }
    if (type == 'error') {
        return toast.error(message, {position: position,theme:theme});
    }

    //error
    toast.error(message, {position: position});
}