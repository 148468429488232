/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import {useLocation, useParams} from 'react-router-dom'
import {ToolbarHelperModal} from './ToolbarHelperModal'
import {useIntl} from 'react-intl'
import './style.css'
import {useWindowSize} from '../../../../app/Hooks/useWindowSize'
import TimeZoneSelect from '../../../../app/modules/global/TimeZoneSelect'
import useLocationGetter from '../../../../app/Hooks/useLocationGetter'
import {Box} from '@mui/material'
const Toolbar1: FC = () => {
  const [modalShow, setModalShow] = React.useState(false)
  const location = useLocation()
  const intl = useIntl()
  const helpClickHandler = () => {
    setModalShow(true)
  }
  const {urlWithChild, parent} = useLocationGetter()
  const {width} = useWindowSize()
  // let urlLocation = useParams()['*']
  // let rootLocation = urlLocation?.substring(0, urlLocation.indexOf('/'))
  // if (rootLocation?.length == 0) {
  //   rootLocation = location.pathname.substring(1)
  // }

  return (
    <Box sx={{display: {md: 'none'}}}>
      <div className='toolbar' id='kt_toolbar'>
        <div id='kt_toolbar_container' className={clsx('d-flex flex-stack container')}>
          <DefaultTitle />
          <TimeZoneSelect show={width <= 768} />
          <div>
            <div className='' id='helpModalButton'>
              <span
                className='btn btn-sm btn-flex btn-light btn-secondary fw-bolder'
                onClick={helpClickHandler}
              >
                <KTSVG
                  path='/media/icons/duotune/general/gen046.svg'
                  className='svg-icon-2 svg-icon-gray-500 me-1'
                />
                {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_HELP'})}
              </span>

              <ToolbarHelperModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                location={urlWithChild}
                parent={parent}
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}

export {Toolbar1}
