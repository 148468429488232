export function hasUserAccess(gate: string, user: any) {

    //is client
    if ('parent' in user === false) {
        return true;
    }

    //has full access
    if (user.full_access === 1) {
        return true;
    }

    if (gate === "infrastructure" && user.access_infrastructure) {
        return true;
    }

    const access = user?.access;

    if (access[gate] === 1) {
        return true;
    }

    return false;
}