import {useState, useMemo} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'
import {FormattedMessage, useIntl} from 'react-intl'
import useCustomizedDashboardValues, {
  customizedDashboardTypes,
} from '../../modules/context/CustomizedDashboardCtx'
import {useCreateDashboard} from '../../../services/customizedDashboard'
import useMainConfig from '../../Hooks/useMainConfig'
import useUser from '../../Hooks/useUser'
import {TextField} from '@mui/material'
import DashboardMangementForm from './DashboardMangementForm'
import ShareDashboard from './ShareDashboard'
import PaidBadge from '../../modules/global/PaidBadge'
import CustomDialog from '../../modules/global/UI/CustomDialog'

const CreateNewDashboardModal = ({show, setShow}: any) => {
  const [customizedDashboards, dispatch] = useCustomizedDashboardValues()
  const user = useUser()
  const [showLimit, setShowLimit] = useState(false)
  const [name, setName] = useState('')
  const {mutateAsync, isLoading} = useCreateDashboard()
  const {is_free_plan, checkLimittedPlan} = useMainConfig()
  // const {checkLimittedPlan} = useActionControll('custom_dashboards')

  const intl = useIntl()
  const isSubAccount = 'access' in user && 'parent' in user

  const handleClose = () => {
    setShow(false)
  }

  const userDashboards = useMemo(
    () =>
      customizedDashboards?.filter((dash: Record<string, any>) =>
        isSubAccount ? !!dash.contact_id : !!dash.client_id
      ),
    [customizedDashboards]
  )

  const clientDashboards = useMemo(
    () => customizedDashboards?.filter((dash: Record<string, any>) => !!dash.client_id),
    [customizedDashboards]
  )

  const activeDashboards = useMemo(
    () =>
      customizedDashboards?.filter((dash: Record<string, any>) =>
        isSubAccount ? !!dash.contact_id || !dash.hidden : !!dash.client_id
      ),
    [customizedDashboards]
  )

  const handleCreate = async () => {
    try {
      if (is_free_plan || checkLimittedPlan('custom_dashboards')) {
        return setShowLimit(true)
      }
      if (!name.trim()) {
        return
      }

      const response = await mutateAsync({
        name,
        widgets: {
          widgets: [],
          mode_2: {
            'column-1': {
              id: 'column-1',
              widgetsIds: [],
            },
            'column-2': {
              id: 'column-2',
              widgetsIds: [],
            },
          },
          mode_3: {
            'column-1': {
              id: 'column-1',
              widgetsIds: [],
            },
            'column-2': {
              id: 'column-2',
              widgetsIds: [],
            },
            'column-3': {
              id: 'column-3',
              widgetsIds: [],
            },
          },
          mode_4: {
            'column-1': {
              id: 'column-1',
              widgetsIds: [],
            },
            'column-2': {
              id: 'column-2',
              widgetsIds: [],
            },
            'column-3': {
              id: 'column-3',
              widgetsIds: [],
            },
            'column-4': {
              id: 'column-4',
              widgetsIds: [],
            },
          },
        },
        columns: {
          'column-1': {
            id: 'column-1',
            widgetsIds: [],
          },
          'column-2': {
            id: 'column-2',
            widgetsIds: [],
          },
        },
        default: 0,
        // Facilitate reordering of the columns
        column_order: ['column-1', 'column-2'],
        order: 0,
      })

      if (response?.data) {
        dispatch({
          type: customizedDashboardTypes.ADD_DASHBOARD,
          payload: response?.data,
        })
      }

      setName('')
    } catch (error) {}
  }

  return (
    // <Modal show={show} onHide={handleClose} centered>
    // <Modal.Header closeButton>
    //   <Modal.Title>{intl.formatMessage({id: 'LABELS.DASHBOARD.MANAGEMENT'})}</Modal.Title>
    // </Modal.Header>
    // <Modal.Body>
    // <form onSubmit={(e) => {
    //     e.preventDefault()
    //     handleCreate()
    // }}>
    // <TextField
    //   id='outlined-basic'
    //   label={intl.formatMessage({id: 'LABELS.CREATE.NEW.DASHBOARD'})}
    //   variant='outlined'
    //   fullWidth
    //   sx={{
    //     '& .MuiInputBase-adornedEnd': {
    //       paddingRight: 0,
    //     },
    //   }}
    //   autoComplete='off'
    //   value={name}
    //   size='small'
    //   onChange={(e) => setName(e.target.value)}
    //   // onKe
    //   InputProps={{
    //     endAdornment: (
    //       <Button
    //         size='sm'
    //         onClick={handleCreate}
    //         style={{borderTopLeftRadius: '1px', borderBottomLeftRadius: '1px'}}
    //         className='w-100px d-flex gap-1'
    //       >
    //         {isLoading ? (
    //           <Spinner animation='grow' size='sm' />
    //         ) : (
    //           intl.formatMessage({id: 'ACTIONS.CREATE'})
    //         )}
    //         {(is_free_plan || checkLimittedPlan('custom_dashboards')) && <PaidBadge />}
    //       </Button>
    //     ),
    //   }}
    // />
    // </form>
    // <section className='mt-8'>
    //   <h4>
    //     <FormattedMessage id='LABELS.YOUR.DASHBOARDS' />
    //   </h4>
    //   <DashboardMangementForm customizedDashboards={userDashboards} />
    // </section>

    // {isSubAccount && (
    //   <section className='mt-8'>
    //     <h4>
    //       <FormattedMessage id='LABELS.YOUR.CLIENT.DASHBOARDS' />
    //     </h4>
    //     <ShareDashboard clientDashboards={clientDashboards} />
    //   </section>
    // )}
    // </Modal.Body>
    // <Modal.Footer className='py-2'>
    //   <Button size='sm' variant='secondary' onClick={handleClose}>
    //     {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
    //   </Button>
    // </Modal.Footer>
    // </Modal>
    <CustomDialog
      open={show}
      onClose={handleClose}
      title={intl.formatMessage({id: 'LABELS.DASHBOARD.MANAGEMENT'})}
      footer={
        <Button size='sm' variant='secondary' onClick={handleClose}>
          {intl.formatMessage({id: 'SERVICEMODAL.BUTTON_CLOSE'})}
        </Button>
      }
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleCreate()
        }}
      >
        <TextField
          id='outlined-basic'
          label={intl.formatMessage({id: 'LABELS.CREATE.NEW.DASHBOARD'})}
          variant='outlined'
          fullWidth
          sx={{
            '& .MuiInputBase-adornedEnd': {
              paddingRight: 0,
            },
          }}
          autoComplete='off'
          value={name}
          size='small'
          onChange={(e) => setName(e.target.value)}
          // onKe
          InputProps={{
            endAdornment: (
              <Button
                size='sm'
                onClick={handleCreate}
                style={{borderTopLeftRadius: '1px', borderBottomLeftRadius: '1px'}}
                className='w-100px d-flex gap-1'
              >
                {isLoading ? (
                  <Spinner animation='grow' size='sm' />
                ) : (
                  intl.formatMessage({id: 'ACTIONS.CREATE'})
                )}
                {(is_free_plan || checkLimittedPlan('custom_dashboards')) && <PaidBadge />}
              </Button>
            ),
          }}
        />
      </form>
      <section className='mt-8'>
        <h4>
          <FormattedMessage id='LABELS.YOUR.DASHBOARDS' />
        </h4>
        <DashboardMangementForm customizedDashboards={userDashboards} />
      </section>

      {isSubAccount && (
        <section className='mt-8'>
          <h4>
            <FormattedMessage id='LABELS.YOUR.CLIENT.DASHBOARDS' />
          </h4>
          <ShareDashboard clientDashboards={clientDashboards} />
        </section>
      )}
    </CustomDialog>
  )
}

export default CreateNewDashboardModal
