import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useWindowSize} from '../../../../app/Hooks/useWindowSize'
import TimeZoneSelect from '../../../../app/modules/global/TimeZoneSelect'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useThemeMode} from '../../../partials'
import {useLayout} from '../../core'
import {Header} from './Header'
import {DefaultTitle} from './page-title/DefaultTitle'
import {Topbar} from './Topbar'

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const {width} = useWindowSize()
  const theme = useThemeMode()


  return (
    <div
      id='kt_header'
      className={clsx('header', classes.header.join(' '), 'align-items-stretch')}
      {...attributes.headerMenu}
      // style={{zIndex:100}}
    >
      <div className={clsx('d-flex align-items-stretch justify-content-between container')}>
        {aside.display && (
          <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
            <div
              className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x mt-1' />
            </div>
          </div>
        )}
        {!aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/images/logos/logo_dark.png')}
                className='h-30px'
              />{' '}
            </Link>
          </div>
        )}

        {aside.display && (
          <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
            <Link to='/dashboard' className='d-lg-none'>
              <img
                alt='Logo'
                src={toAbsoluteUrl(
                  theme.mode === 'light'
                    ? '/media/images/logos/logo_dark.png'
                    : '/media/images/logos/logo_white.png'
                )}
                className='h-30px'
              />
            </Link>
          </div>
        )}

        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch ' id='kt_header_nav'>
              {/* <TimeZoneSelect show={width > 768} /> */}
              <div className='d-flex align-items-stretch ' id='kt_toolbar_container'>
                <DefaultTitle />
              </div>
              <Header />
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <DefaultTitle />
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <Topbar />
          </div>
        </div>
      </div>
    </div>
  )
}
