import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

interface Props{
  canView:boolean
  row:Record<string,any>
}

const ExpandSubAccTable = (props:Props) => {
  const {row, canView} = props
  const {id, username, title,name}=row

  return (
    <div className="alert alert-secondary border border-dark text-gray-300 position-relative fs-7 my-2">
      <div className="d-flex">
        <span className=" pe-1 fw-bold text-gray-800">
          <FormattedMessage id="LABELS.USERNAME" /> :  {" "}
        </span>
        <span className="text-gray-800">{username || "-"}</span>
      </div>
      <div className="d-flex my-2">
        <span className="text-gray-800 pe-1 fw-bold">
          <FormattedMessage id="LABELS.TITLE" /> :{" "}
        </span>
        <span className="text-gray-800">{title || "- "}</span>
      </div>
      <div className="d-flex">
        <span className="text-gray-800 pe-1 fw-bold">
          <FormattedMessage id="LABELS.NAME" /> : {" "}
        </span>
        <span className="text-gray-800">{name || " - "}</span>
      </div>
      {canView && (
        <Link to={`/account/sub-accounts/${id}`}>
          <button className="btn btn-sm btn-primary table-sub-comp-btn">
            <FormattedMessage id="ACTIONS.VIEW" />
          </button>
        </Link>
      )}
    </div>
  );
};

export default ExpandSubAccTable;
