import {isHasAccess} from '../../../../helpers/isHasAccess'

export const CheckWidgets = (configWidgets: Record<string, any>, user: Record<string, any>) => {
  let widgetsStatus = {...configWidgets?.widgets}

  widgetsStatus['widget-1'].hasAccess = isHasAccess('tickets', user)
  widgetsStatus['widget-10'].hasAccess = isHasAccess('billing', user)
  widgetsStatus['widget-11'].hasAccess = isHasAccess('billing', user)
  widgetsStatus['widget-12'].hasAccess = isHasAccess('billing', user)
  widgetsStatus['widget-13'].hasAccess = isHasAccess('billing', user)
  widgetsStatus['widget-15'].hasAccess = isHasAccess('billing', user)
  widgetsStatus['widget-16'].hasAccess = isHasAccess('billing', user)
  widgetsStatus['widget-2'].hasAccess = isHasAccess(null, user)
  widgetsStatus['widget-3'].hasAccess = isHasAccess(null, user)
  widgetsStatus['widget-4'].hasAccess = isHasAccess(null, user)
  widgetsStatus['widget-5'].hasAccess = isHasAccess(null, user)
  widgetsStatus['widget-6'].hasAccess = true
  widgetsStatus['widget-7'].hasAccess = isHasAccess(null, user)
  widgetsStatus['widget-8'].hasAccess = true
  widgetsStatus['widget-9'].hasAccess = isHasAccess(null, user)
  widgetsStatus['widget-14'].hasAccess = true
  widgetsStatus['widget-17'].hasAccess = true
  widgetsStatus['widget-18'].hasAccess = true
  widgetsStatus['widget-19'].hasAccess = true
  widgetsStatus['widget-20'].hasAccess = true
  return widgetsStatus
}
