import React, {useState, useLayoutEffect} from 'react'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../_metronic/partials'

const useCssVariable = (variable: string) => {
  const theme = useThemeMode()
  const [state, setState] = useState(getCSSVariableValue(variable))

  useLayoutEffect(() => {
    setState(getCSSVariableValue(variable))
  }, [theme.mode])

  return state
}

export default useCssVariable
