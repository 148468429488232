import {Draggable} from '@hello-pangea/dnd'
import React, {useCallback} from 'react'
import {stringToBoolean} from '../../../../_metronic/helpers'
import useDashboardValues, {DashboardTypes} from '../../../modules/context/DashboardCtx'
import {componentWidgets, ComponentWidgetType} from './InitialData'

type WidgetType = {
  id: string
  status: boolean
}

interface Props {
  widget: WidgetType
  index: number
}

const Widget = ({widget, index}: Props) => {
  const [state, dispatch] = useDashboardValues()

  const EL = componentWidgets?.[widget.id as keyof ComponentWidgetType]

  const onRemove = useCallback(() => {
    dispatch({
      type: DashboardTypes.DISABLE,
      payload: widget.id,
    })
  }, [])
  return (
    <>
      {!!stringToBoolean(widget.status) && (
        <div>
          <Draggable draggableId={widget.id} index={index}>
            {(provided, snapshot) => (
              <div
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                //   className={`${snapshot.isDragging?"shadow-lg p-3 mb-5 bg-body rounded":""}`}
              >
                <div style={{cursor: 'move'}}>{EL && <EL onRemove={onRemove} />}</div>
              </div>
            )}
          </Draggable>
        </div>
      )}
    </>
  )
}

const customComparator = (prevProps: any, nextProps: any) => {
  return stringToBoolean(nextProps.widget.status) == stringToBoolean(prevProps.widget.status)
}

export default React.memo(Widget, customComparator)
