import { useMediaQuery } from '@mui/material'
import {useIntl} from 'react-intl'



const ShortcutKeyModal = () => {
  const intl = useIntl()
  const isMobile = useMediaQuery('(max-width:600px)')

  const ShortcutKeyItem = ({id, name}: any) => {
    return (
      <div className='d-flex align-items-center gap-1 p-2 mb-4' style={{width: isMobile ? '49%' : '33%'}}>
        <span className='fs-4 w-25px justify-content-center badge badge-dark'>X</span>+
        <span className='fs-4 w-25px justify-content-center badge badge-dark'>{id}</span>
        <span className='fs-5'>{name}</span>
      </div>
    )
  }

  const items = [
    {id: 1, name: intl.formatMessage({id: 'MENU.DASHBOARD'})},
    {id: 2, name: intl.formatMessage({id: 'MENU.NEW.MONITORING'})},
    {id: 3, name: intl.formatMessage({id: 'MENU.INCIDENTS'})},
    {id: 4, name: intl.formatMessage({id: 'MENU.SERVERS'})},
    {id: 5, name: intl.formatMessage({id: 'MENU.UPTIME'})},
    {id: 6, name: intl.formatMessage({id: 'LABELS.CREATE.SSL.TITLE'})},
    {id: 7, name: intl.formatMessage({id: 'LABELS.TRIGGER.MANAGEMENT'})},
    {id: 8, name: intl.formatMessage({id: 'MENU.NOTIFICATION_ROLES'})},
    {id: 9, name: intl.formatMessage({id: 'PUBLIC.STATUS'})},
    {id: 0, name: intl.formatMessage({id: 'LABELS.METRICS'})},
  ]

  return (
    <div className='d-flex flex-wrap'>
      {items.map((item) => (
        <ShortcutKeyItem key={item.id} id={item.id} name={item.name} />
      ))}
      <div className='d-flex align-items-center gap-1 p-2 mb-4' style={{width: isMobile ? '49%' : '33%'}}>
        <span className='fs-4 justify-content-center badge badge-dark'>Ctrl</span>+
        <span className='fs-4 w-25px justify-content-center badge badge-dark'>k</span>
        <span className='fs-5'>{intl.formatMessage({id: 'MENU.SEARCH'})}</span>
      </div>
      <div className='d-flex align-items-center gap-1 p-2 mb-4 text-nowrap' style={{width: isMobile ? '49%' : '33%'}}>
        <span className='fs-4 justify-content-center badge badge-dark'>Shift</span>+
        <span className='fs-4 w-25px justify-content-center badge badge-dark'>?</span>
        <span className='fs-5'>{intl.formatMessage({id: 'LABELS.KEYBOARD_SHORTCUTS'})}</span>
      </div>
    </div>
  )
}

export default ShortcutKeyModal
