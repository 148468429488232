import { call, put, takeEvery } from "redux-saga/effects";
import {
  addNewNotificationRoleRequest,
  deleteNotificationRoleRequest,
  editNotificationRoleRequest,
  getMainSettings,
  getSelectedNotificationRoleRequest,
  testNotificationRoleRequest
} from "./requests";
import {
  types
} from "../actionTypes";
interface Response {
  data: any
}


function* requestNotificationRolesMainSettings() {
  try {
    const response: Response = yield call(getMainSettings);
    yield put(
      {
        type: types.GET_NOTIFICATION_ROLES_MAIN_SETTINGS_SUCCESS,
        payload: response.data
      }
    );
  } catch (e: any) {
    yield put(
      {
        type: types.GET_NOTIFICATION_ROLES_MAIN_SETTINGS_ERROR,
        error: e.data
      }
    );
  }
}


function* requestNotificationRolesTest(action: any) {
  try {
    const response: Response = yield call(testNotificationRoleRequest, action.params);
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLES_TEST_SUCCESS,
        payload: response.data
      }
    );
  } catch (e: any) {
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLES_TEST_ERROR,
        error: e.data
      }
    );
  }
}


function* requestNotificationRolesAdd(action: any) {
  try {
    
    const response: Response = yield call(addNewNotificationRoleRequest, action.params);
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLES_ADD_SUCCESS,
        payload: response.data
      }
    );
  } catch (e: any) {
    
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLES_ADD_ERROR,
        error: e.data
      }
    );
  }
}


function* requestNotificationRolesEdit(action: any) {
  try {
    const response: Response = yield call(editNotificationRoleRequest, action.params, action.id);
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLES_EDIT_SUCCESS,
        payload: response.data
      }
    );
  } catch (err: any) {
    const error = err.response && (err.response || err.message)
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLES_EDIT_ERROR,
        error:error?.data
      }
    );
  }
}


function* requestNotificationRolesDelete(action: any) {
  try {
    const response: Response = yield call(deleteNotificationRoleRequest, action.id);
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLES_DELETE_SUCCESS,
        payload: response.data
      }
    );
  } catch (err: any) {
    const error = err.response && (err.response || err.message)
        let data = error?.data
     
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLES_DELETE_ERROR,
        error: data
      }
    );
  }
}



function* requestGetNotificationRole(action: any) {
  try {
    const response: Response = yield call(getSelectedNotificationRoleRequest, action.id);
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLE_SUCCESS,
        payload: response.data
      }
    );
  } catch (e: any) {
    yield put(
      {
        type: types.REQUEST_NOTIFICATION_ROLE_ERROR,
        error: e.data
      }
    );
  }
}



function* watcherNotificationRolesSaga() {
  yield takeEvery(types.GET_NOTIFICATION_ROLES_MAIN_SETTINGS, requestNotificationRolesMainSettings)
  yield takeEvery(types.REQUEST_NOTIFICATION_ROLES_TEST, requestNotificationRolesTest)
  yield takeEvery(types.REQUEST_NOTIFICATION_ROLES_ADD, requestNotificationRolesAdd)
  yield takeEvery(types.REQUEST_NOTIFICATION_ROLES_EDIT, requestNotificationRolesEdit)
  yield takeEvery(types.REQUEST_NOTIFICATION_ROLES_DELETE, requestNotificationRolesDelete)
  yield takeEvery(types.REQUEST_NOTIFICATION_ROLE, requestGetNotificationRole)


}

export default watcherNotificationRolesSaga