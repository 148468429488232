import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export interface actionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  configRequested: "[Request Config] Action",
  setConfig: "[Set Config] Action",
};

const configInitialState = {
  language: undefined,
  attachment: undefined,
  avatar: undefined
};

export const configReducer = persistReducer(
  { storage, key: "xitoring-app-config", whitelist: ["language", "attachment", "avatar"] },
  (state: any = configInitialState, action: any) => {
    switch (action.type) {
      case actionTypes.setConfig: {
        const config = action.payload?.config;
        return { ...state, ...config };
      }
      default:
        return {language: state.language, attachment: state.attachment, avatar: state.avatar};
    }
  }
);

export const configActions = {
  requestConfig: () => ({
    type: actionTypes.configRequested,
  }),
  setConfig: (config: object | null) => ({
    type: actionTypes.setConfig,
    payload: { config },
  }),
};
