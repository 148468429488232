import React from 'react'
import {Form, Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useDeleteDashboard, useEditDashboard} from '../../../services/customizedDashboard'
import useCustomizedDashboardValues, {
  customizedDashboardTypes,
  DashboardType,
} from '../../modules/context/CustomizedDashboardCtx'
import IconButton from '../../modules/global/IconButton'
import ConfirmPopover from '../../modules/global/UI/ConfirmPopover'
import {useNavigate} from 'react-router-dom'
import {useLocalStorage} from '../../Hooks/useLocalStorage'

const DashboardItemForm = ({dashboard}: {dashboard: DashboardType}) => {
  const [, dispatch] = useCustomizedDashboardValues()
  const {mutateAsync, isLoading} = useEditDashboard()
  const {mutateAsync: favDashReq, isLoading: favDashLoading} = useEditDashboard()
  const {mutateAsync: deleteReq, isLoading: loadingDelete} = useDeleteDashboard()
  const [edit, setEdit] = React.useState(false)
  const [name, setName] = React.useState(dashboard?.name)
  const navigate = useNavigate()
  const [customizedDashboards] = useCustomizedDashboardValues()

  const intl = useIntl()

  const handleEdit = () => {
    setEdit(true)
  }
  const defaultDash = localStorage.getItem('defaultDash')

  const handleFavDash = async () => {
    try {
      const response = await favDashReq({
        id: dashboard.id,
        dashboard: {
          default: !!dashboard.default ? 0 : 1,
        },
      })

      dispatch({
        type: customizedDashboardTypes.DEFAULT_DASHBOARD,
        payload: {
          id: dashboard.id,
        },
      })
      if (defaultDash == dashboard.id) {
        localStorage.setItem('defaultDash', '')
      } else {
        localStorage.setItem('defaultDash', dashboard.id)
      }
    } catch (error) {}
  }
  const handleFinishEdit = async () => {
    if (!name?.trim()) {
      return
    }
    try {
      const response = await mutateAsync({
        id: dashboard.id,
        dashboard: {
          name,
        },
      })
      dispatch({
        type: customizedDashboardTypes.EDIT_DASHBOARD,
        payload: {
          id: dashboard.id,
          name,
        },
      })
    } catch (error) {}

    setEdit(false)
  }

  const handleNavigate = (customizedDashboards: any) => {
    // const selectedItem = customizedDashboards.find((item: any) => item.default === true || item.default === 1)
    if (defaultDash == dashboard.id) {
      navigate('/dashboard')
      localStorage.removeItem('defaultDash')
    } 
  }

  const handleRemove = async () => {
    try {
      await deleteReq({
        id: dashboard.id,
      })
      dispatch({
        type: customizedDashboardTypes.REMOVE_DASHBOARD,
        payload: {
          id: dashboard.id,
        },
      })
      await handleNavigate(customizedDashboards)
    } catch (error) {}
  }

  return (
    <div className='d-flex justify-content-between align-items-center mt-3'>
      {!edit ? (
        <h6 className='m-0 fs-7'>{dashboard?.name}</h6>
      ) : (
        <Form.Control
          onChange={(e) => setName(e.target.value)}
          value={name}
          size='sm'
          className='w-auto  bg-transparent border border-dark p-2 min-h-auto'
          autoFocus
        />
      )}
      <div className='d-flex align-items-center gap-1'>
        <IconButton
          iconSize='fs-3'
          // ${!!dashboard.oder ? 'text-primary' : ''}
          icon={`fas fa-star ${!!dashboard.default ? 'text-warning' : ''} `}
          onClick={handleFavDash}
          tooltip={intl.formatMessage({id: 'LABELS.SET_DEFAULT_DASHBOARD'})}
          key='fav'
          pending={favDashLoading}
          disabled={favDashLoading}
        />
        {!edit ? (
          <IconButton
            iconSize='fs-3'
            icon='fas fa-edit '
            onClick={handleEdit}
            tooltip={intl.formatMessage({id: 'ACTIONS.EDIT'})}
            key='edit'
            pending={isLoading}
            disabled={isLoading}
          />
        ) : (
          <IconButton
            iconSize='fs-3'
            icon='fas fa-check '
            onClick={handleFinishEdit}
            tooltip={intl.formatMessage({id: 'LABELS.DONE'})}
            key='finish'
            pending={isLoading}
            disabled={isLoading}
            //   pending={editMutate.isLoading}
            //   disabled={removeMutate.isLoading || editMutate.isLoading}
          />
        )}
        <ConfirmPopover
          onConfirm={handleRemove}
          text={intl.formatMessage({id: 'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SURE.DESCRIPTION'})}
        >
          <IconButton
            iconSize='fs-3'
            icon='fas fa-trash'
            // onClick={handleRemove}
            tooltip={intl.formatMessage({id: 'ACTIONS.REMOVE'})}
            key='remove'
            pending={loadingDelete}
            disabled={loadingDelete}
          />
        </ConfirmPopover>
      </div>
    </div>
  )
}

export default React.memo(DashboardItemForm)
