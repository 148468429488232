
import Http, { _axios } from '../app/store/core/inceptor'
import {loadTableParams} from '../helpers'

export const Server = {
  getLogs: (query: any) => {
    
    return _axios.get(`/servers/logs`, {
      params: {
        ...(!!query.page && {page:query.page}),
        ...(!!query.per_page && {per_page:query.per_page}),
      },
    })
  },
}

export const updateServer = (id: string, obj: Record<string, any>) =>
  Http.put(`/servers/${id}`, obj)

export const deleteServer = (id: string) => Http.delete(`/servers/${id}`)

export const getServers = () => Http.get('/groups?append=devices').then(res=>res.data)

export const createHeartbeat=(data:Record<string,any>)=>Http.post('/heartbeat/add',data)

export const removeHeartbeat=(id:string)=>Http.delete(`/heartbeat/${id}`)

export const getServerData=(id:any,getIntegration?:boolean)=>Http.get(`/servers/${id}`,{
  params:{
    'attach-integrations':+!!getIntegration
  }
})


export const updateHeartbeat = (id: string, data: Record<string, any>) =>
  Http.put(`/heartbeat/${id}`, data)

export const getDeviceIPs = (id: string) =>
  Http.get<string[]>(`/servers/${id}/ips`).then((res) => res)




export const putServerCheckTrigger = (
  serverId: string,
  checkId: string,
  triggerId: string,
  data: Record<string, any>
) => Http.put(`/servers/${serverId}/checks/${checkId}/triggers/${triggerId}`, data)
export const removeServerCheckTrigger = (serverId: string, checkId: string, triggerId: string) =>
  Http.delete(`/servers/${serverId}/checks/${checkId}/triggers/${triggerId}`)

export const createHeartbeatXitogent = (data:Record<string,any>) =>
  Http.post(`/heartbeat/add`, data)
export const postServerCheck=(id:string,data:Record<string,any>)=>Http.post(`/servers/${id}/checks`,data)
export const manualDicoveryAPI=(id:string)=>Http.post(`/servers/${id}/checks/manual-discovery`)



export const postServerCheckTrigger=(serverId:string,checkId:string,data:Record<string,any>)=>Http.post(`/servers/${serverId}/checks/${checkId}/triggers`,data)


export const getMetricTriggersServer=(id:string|number)=>Http.get(`/servers/${id}/metric/triggers?per_page=1000`).then(res=>res.data)

export const saveTrigger = (id: string, data: Record<string, any>) =>
  Http.post(`/servers/${id}/metric/triggers`, data)


export const getMetricGraph = (
  id: string,
  name: string,
  filter?: string,
  since?: string,
  until?: string
) => Http.get(`/servers/${id}/metric/graphs/${name}`, {
    params:
      filter === 'custom'
        ? {start: since, end: until}
        : {
            start: filter,
          },
  })

  
export const removeTrigger = (id: string, triggerID: string) =>
  Http.delete(`/servers/${id}/metric/triggers/${triggerID}`)

export const updateTrigger = (id: string, triggerID: string, data: Record<string, any>) =>
  Http.put(`/servers/${id}/metric/triggers/${triggerID}`,data)


  export const testCheck=(data:Record<string,any>)=>{
  return Http.post("/checks/test",data)
  } 


  export const getNetworkGraph = (
    id: string,
    name: string,
    filter?: string,
    since?: string,
    until?: string,
    port?: string,
  ) =>
    Http.get(`/servers/${id}/metric/graphs/${name}`, {
      params:
        filter === 'custom'
          ? {start: since, end: until, port:port }
          : {
              start: filter,
              port: port,
            },
    })



export const getRegions=()=>Http.get('/regions')


export interface IgetAllTriggers {
  per_page: number | string
  page: number | string
  types:any[]
  notification_roles:any[]
  selectedCheck:Record<string,any>|null
  selectedServer:Record<string,any>|null
  emptyRoles:boolean
}

export const getAllTriggers=(query:IgetAllTriggers)=>Http.get(`/trigger`,{
  params:{
    ...(!!query.page && {page:query.page}),
    ...(!!query.per_page && {per_page:query.per_page}),
    ...(!!query.types?.length && {types:query.types}),
    ...(!!query.notification_roles?.length && {notification_roles:query.notification_roles}),
    ...(!!query.selectedCheck && {check:query.selectedCheck}),
    ...(!!query.selectedServer && {server:query.selectedServer}),
    ...(!!query.emptyRoles && {empty_notification_role:+query.emptyRoles}),

  },
  // paramsSerializer: params => {
  //   // Custom params serializer to prevent array serialization
  //   return Object.entries(params)
  //     .map(([key, value]) => `${key}=${value}`)
  //     .join('&');
  // },
})

export const removeBulkTriggers=(triggers:readonly number[])=>Http.delete(`/trigger`,{
  params:{
    triggers:triggers
  }
})

interface INotificationAddRemove {
  id: number
  action: string
  triggers: readonly number[]
}

export const NotificationAddRemove = ({id,action,triggers}: INotificationAddRemove) => {
  return Http.put(`/trigger/notification-role/${id}/${action}`,{triggers})
}


export const getAllChecks=()=>Http.get('/check')
export const getAllServers=()=>Http.get('/servers?only_server=1')

export const getTriggersWithNoRoles=()=>Http.get("/trigger?empty_notification_role=1",{
  headers:{
    "X-Only-Count":true
  }
})


export const getTriggersCountByRole=(id:string)=>Http.get(`/trigger`,{
  params:{
    notification_roles:[+id]
  },
  headers:{
    "X-Only-Count":true
  }
  
})

export const getServerByIntegration=(integrations:string[]|number[])=>Http.get('/servers',{
  params:{
    only_server:1,
    integrations
  }
})