import React from 'react'
import {Spinner} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useQuery} from 'react-query'
import {getSMS} from '../../../services/dashboard'
import useMainConfig from '../../Hooks/useMainConfig'

interface Props {
  onRemove: () => void
}

const SMSCredits = ({onRemove}: Props) => {
  const {data: smsCreditsData, isLoading}: any = useQuery(['dashboard-sms-credits'], getSMS)

  const main = useMainConfig()

  const intl = useIntl()

  const onClose = () => {
    onRemove()
  }

  return (
    <div>
      <div className={`card  card-xl-stretch bg-primary`}>
        <div className='card-header border-0  p-4 py-0'>
          <h3 className='card-title align-items-start flex-column'></h3>
          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-white'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={onClose}
            >
              <span className='fas fa-times fs-3 text-white' />
            </button>
          </div>
        </div>
        {isLoading ? (
          <div className='h-150px text-center d-flex justify-content-center align-items-center'>
            <Spinner variant='light' animation='grow' />
          </div>
        ) : (
          <div className='card-body p-5  pt-0  d-flex gap-3 align-items-end'>
            <span className={`svg-icon-primary text-white  fs-4x  fas fa-mobile-alt`} />
            <div className='d-flex flex-column gap-1 align-items-start '>
              <div className={` fw-bold fs-1 text-white d-flex gap-1 lh-sm align-items-center`}>
                {+smsCreditsData + (main?.options?.sms?.quantity - main?.options?.sms?.used)}
              </div>
              <div className={`fw-semibold text-white fs-7`}>
                {intl.formatMessage({id: 'REMAINING.SMS'})}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default React.memo(SMSCredits)
